import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ADDONS_MAIN_JOB_STATUS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import { reducer } from 'app/const/Reducer';
import JobStatusManageDelete from './ManageDelete';
import JobStatusManageActive from './ManageActiveJobStatus';
import ErrorPage from 'app/modules/error';
import { LIST_ACTION_BASIC } from 'app/const/Settings';

const ACTIVE_TAB = LIST_ACTION_BASIC.ACTIVE;
const DELETE_TAB = LIST_ACTION_BASIC.DELETE;

const JobStatusManage = () => {
    const { t } = useTranslation(['pageTitle', 'addons']);
    const activeFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_job_status) || {};

    const [state, dispatchState] = useReducer(reducer, {
        tabView: ACTIVE_TAB
    });

    const { tabView } = state;

    const _handleChangeTab = (tab) => {
        if (tab === tabView) return;
        dispatchState((prevState) => ({
            ...prevState,
            tabView: tab
        }));
    };

    const _renderTabView = () => {
        switch (tabView) {
            case ACTIVE_TAB:
                return <JobStatusManageActive />;
            case DELETE_TAB:
                return <JobStatusManageDelete />;
            default:
                return null;
        }
    };

    const _renderTabOptions = () =>
        Object.values(LIST_ACTION_BASIC).map((item) => {
            if (item === LIST_ACTION_BASIC.ARCHIVE) return null;
            return (
                <button
                    key={item}
                    className={classNames('tab-items', {
                        'active-tab-selector': tabView === item
                    })}
                    type="button"
                    onClick={() => _handleChangeTab(item)}
                >
                    {t(`addons:${item}`)}
                </button>
            );
        });

    if (!activeFieldAddon['custom_job_status']) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="addons-wrap__container">
            <div className="flexcenter gap-8 header">
                <Link to={addBranchPath(ADDONS_MAIN_JOB_STATUS)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('pageTitle:custom_job_status')}
                </Link>
                <div className="tabs">
                    <div className="btn-item ml-0">{_renderTabOptions()}</div>
                </div>
            </div>
            {_renderTabView()}
        </div>
    );
};

export default JobStatusManage;
