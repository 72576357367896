import React, { Suspense, useEffect, useReducer, useRef } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { getDocumentDetail, actionJobDocument, updateStatusEsign } from 'app/const/api/Document';
import { COMPONENT_TYPES } from 'app/modules/jobdetail/const/Document';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import DocumentService from './service';
import ReactModal from 'react-modal';
import { actionOpenDocumentDetail } from 'common/redux/actions/documentAction';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'app/modules/jobdetail/tabs/documents/edit/Loading';
import loadable from '@loadable/component';
import { GET_LIST_DOCUMENTS } from 'app/const/Api';
import { GOOGLE_PLACES_API_URL } from 'app/const/Keys';
import { DOCUMENT_LIBRARY } from '../addons/documents/constants';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';

const Options = loadable(() => import('app/modules/jobdetail/tabs/documents/edit/Options'));
const ButtonSave = loadable(() => import('app/components/button/ButtonSave'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const IconPen = loadable(() => import('assets/icon/IconPen'));
const DocumentForm = loadable(() => import('app/modules/jobdetail/tabs/documents/form'));
const DocumentPreview = loadable(() => import('app/modules/jobdetail/tabs/documents/preview'));
const IconGoto = loadable(() => import('assets/icon/IconGoto'));
const IconClose = loadable(() => import('assets/icon/IconClose'));
const ConfirmEsign = loadable(() => import('../jobdetail/tabs/documents/components/ConfirmEsign'));

export default function DocumentDetail({ customerId }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isActiveEdit: false,
        isVisible: false,
        documentName: '',
        data: {},
        onDelete: () => {},
        onUpdate: () => {}
    });

    const refDocumentPreview = useRef(null);
    const refDocumentEdit = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refConfirmEsign = useRef(null);
    const refModalConfirm = useRef(null);
    const dispatch = useDispatch();

    const { data: finalData, onDelete, onUpdate, isActiveEdit, isLoading, documentName, isVisible } = state;

    const GOOGLE_MAPS_CLIENT_KEY = useSelector(({ auth }) => auth.user.settings.google.geo_key);

    useEffect(() => {
        if (typeof window.google !== 'object' || typeof window.google.maps !== 'object') {
            const googleMapScript = document.createElement('script');
            googleMapScript.src = GOOGLE_PLACES_API_URL(GOOGLE_MAPS_CLIENT_KEY);
            window.document.body.appendChild(googleMapScript);
        }
    }, []);

    useEffect(() => {
        if (!isVisible) {
            const scripts = Array.from(document.getElementsByClassName('preview-document-css'));
            scripts.forEach((element) => {
                element.remove();
            });
        }
    }, [isVisible]);

    const {
        id: parentDocumentId,
        document_id: jobDocumentId,
        e_sign: documentESign,
        fields: dataFields = {},
        has_signature: isHasSingature,
        ishas_customer_signature: isHasCustomerSignature,
        customer_signed: customerSigned,
        type: typeDocument
    } = finalData;

    const isDocumentStatic = typeDocument === DOCUMENT_LIBRARY.PDF;

    function _handleCloseForm(e) {
        e && e.stopPropagation();

        _handleCloseEdit();
    }

    const _handleDisableEdit = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                isActiveEdit: false
            };
        });
    };

    const _checkActiveEdit = () => {
        if (finalData.status !== 'signed') {
            _handleActiveEdit();
        } else {
            refConfirmEsign.current.open(parentDocumentId, documentName);
        }
    };

    const _handleActiveEdit = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                isActiveEdit: true
            };
        });
    };

    const _afterConfirmEsign = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                isActiveEdit: true,
                isLoading: true
            };
        });

        _getDataDocument(parentDocumentId);
        _handleUpdateStatus({ status: 'draft' });
    };

    function _handleOpenEdit({ id, name, onDeleteProp = () => {}, onUpdateProp = () => {} }) {
        dispatchState({
            isLoading: true,
            isVisible: true,
            documentName: name,
            onUpdate: onUpdateProp,
            onDelete: onDeleteProp
        });
        _getDataDocument(id);
    }

    function _handleCloseEdit() {
        dispatchState({
            isVisible: false,
            isActiveEdit: false
        });
        dispatch(actionOpenDocumentDetail(null));
    }

    function _getDataDocument(documentId) {
        clientQuery(getDocumentDetail(documentId), { method: 'GET' }, _getDataDocumentSuccess, _getDataDocumentFailed);
    }

    function _getDataDocumentSuccess(response) {
        dispatchState({
            isLoading: false,
            data: response.data
        });
    }

    function _getDataDocumentFailed({ message }) {
        refModalConfirm.current.open('', message);
    }

    function _handleChangeTab() {
        refDocumentPreview.current.openFormPreView(refDocumentEdit.current.getValue());
    }

    const _handleRealTimePreView = () => {
        const finalRefPreview = refDocumentPreview.current;
        if (finalRefPreview?.checkIsOpen) {
            refDocumentPreview.current.changeFormPreview(refDocumentEdit.current.getValue());
        }
    };

    function _handleSaveDocument(e) {
        e && e.stopPropagation();
        const documentData = refDocumentEdit.current.getValue();
        const params = {};

        documentData.forEach((currentField) => {
            switch (currentField.type) {
                case COMPONENT_TYPES.IMAGE:
                    if (currentField.multiple) {
                        params[currentField.name] = currentField.data.length
                            ? currentField.data.map((item) => {
                                  if (typeof item === 'string') {
                                      return item;
                                  } else {
                                      return {
                                          object_key: item.object_key,
                                          object_tag: item.object_tag
                                      };
                                  }
                              })
                            : '';
                        break;
                    }

                    if (!currentField.value || !currentField.key || !currentField.tag) {
                        params[currentField.name] = currentField.value;
                        break;
                    }
                    params[currentField.name] = {
                        object_key: currentField.key,
                        object_tag: currentField.tag
                    };
                    break;
                case COMPONENT_TYPES.SIGNATURE:
                    if (!currentField.value || !currentField.key || !currentField.tag) {
                        params[currentField.name] = currentField.value;
                        break;
                    }
                    params[currentField.name] = {
                        object_key: currentField.key,
                        object_tag: currentField.tag
                    };
                    break;
                default:
                    params[currentField.name] = currentField.value;
                    break;
            }
        });

        clientQuery(
            actionJobDocument(finalData.id),
            { method: 'PUT', data: params },
            _handleSaveDocumentSuccess,
            _handleSaveDocumentFailed
        );
    }

    function _handleSaveDocumentSuccess(response) {
        const responseData = response.data;

        refStatusBar.current.showStatusBar(
            'success',
            response?.message?.toString() || t('common:success'),
            LIST_STATUS.SUCCESS
        );
        refButtonSave.current.removeLoading();
        _handleDisableEdit();
        dispatchState((prev) => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    status: responseData.status
                }
            };
        });
        onUpdate(finalData.id, responseData);
    }

    function _handleSaveDocumentFailed(reponse) {
        refStatusBar.current.showStatusBar('success', reponse?.message?.toString(), LIST_STATUS.ERROR);
        refButtonSave.current.removeLoading();
    }

    const _handleDisableBtnSave = (type) => {
        refButtonSave.current && refButtonSave.current.setDisable(type);
    };

    function _handleChange(e) {
        e && e.stopPropagation();
        const finalValue = e.target.checked ? 0 : 1;

        dispatchState({
            data: {
                ...finalData,
                e_sign: finalValue
            }
        });

        clientQuery(updateStatusEsign(finalData.id), { method: 'PUT', data: { value: finalValue } });
    }

    const _handleUpdateStatus = (data) => {
        dispatchState((prev) => {
            return {
                ...prev,
                data: { ...prev.data, logs: data.logs }
            };
        });

        onUpdate(finalData.id, data);
    };

    const _handleDelete = (id) => {
        const _handleDeleteSuccess = () => onDelete(id);
        clientQuery(GET_LIST_DOCUMENTS, { data: { ids: [id] }, method: 'DELETE' }, _handleDeleteSuccess);
    };

    function _renderLogs() {
        return finalData.logs?.map((item) => {
            return (
                <div key={item} className="log-items">
                    <IconPen />
                    <span className="log-items__label">{item}</span>
                </div>
            );
        });
    }

    const _renderDocumentDetail = () => {
        if (!isVisible) {
            return false;
        }

        if (isLoading) {
            return (
                <div className="modal container-modal wrapper-has-preview is-preview-document open">
                    <div className="modal__overlay bg-fixed" />
                    <div className="modal__container --preview-r">
                        <div className="wrap-container">
                            <Loading />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <ReactModal
                isOpen
                onRequestClose={_handleCloseEdit}
                ariaHideApp={false}
                shouldCloseOnEsc
                style={{ overlay: { zIndex: 99999, background: 'transparent' } }}
                className="modal container-modal wrapper-has-preview is-preview-document open"
            >
                <Suspense
                    fallback={
                        <>
                            <div className="modal__overlay bg-fixed" />
                            <div className="modal__container --preview-r">
                                <div className="wrap-container">
                                    <Loading />
                                </div>
                            </div>
                        </>
                    }
                >
                    <div className="modal__overlay bg-fixed" />
                    <div className="modal__container --preview-r">
                        <div className="wrap-container">
                            <div
                                className={`wrap-container__form flex-column wrap-document-edit ${
                                    isActiveEdit ? 'has-edit' : ''
                                }`}
                            >
                                <div className="wrap-header">
                                    <div className="wrap-header__conts">
                                        <IconGoto />
                                        <h3 className="title-h1 flex-1">{state.documentName}</h3>
                                        <Options
                                            eSign={!!!documentESign && !!!customerSigned}
                                            customerId={customerId}
                                            documentId={finalData.id}
                                            onDelete={_handleDelete}
                                            onCloseForm={_handleCloseForm}
                                            onUpdateStatus={_handleUpdateStatus}
                                        />
                                        <div
                                            onClick={_handleCloseEdit}
                                            className="v2-btn-default --icon-lg --transparent js-close-form"
                                        >
                                            <IconClose />
                                        </div>
                                    </div>
                                    <div className="wrap-header__conts rows-description">
                                        <div className="check-items">
                                            {!isDocumentStatic && (!!isHasSingature || !!isHasCustomerSignature) && (
                                                <>
                                                    <input
                                                        onChange={(e) => _handleChange(e)}
                                                        id="check-signature"
                                                        type="checkbox"
                                                        checked={!!!documentESign && !!!customerSigned}
                                                    />
                                                    <div className="item-checkbox">
                                                        <label htmlFor="check-signature">
                                                            <span>{t('common:require_an_e_signature')}</span>
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {!isDocumentStatic && !isActiveEdit && (
                                            <div
                                                className="v2-btn-default has-icon mr-1 js-edit-docs"
                                                onClick={_checkActiveEdit}
                                            >
                                                <IconPen />
                                                {t('common:edit')}
                                            </div>
                                        )}
                                        <div onClick={_handleChangeTab} className="v2-btn-default js-preview-docs">
                                            {t('setting:preview')}
                                        </div>
                                    </div>
                                    <StatusBar ref={refStatusBar} />
                                    <div className="rows-description">{_renderLogs()}</div>
                                </div>
                                <DocumentForm
                                    disableButtonSave={_handleDisableBtnSave}
                                    documentId={jobDocumentId}
                                    fields={dataFields}
                                    ref={refDocumentEdit}
                                    onChangeField={_handleRealTimePreView}
                                />
                                <div className="wrap-footer">
                                    <div
                                        onClick={(e) => _handleDisableEdit(e)}
                                        className="v2-btn-default --transparent js-close-form mr-2"
                                    >
                                        {t('common:cancel')}
                                    </div>
                                    <ButtonSave ref={refButtonSave} onSave={_handleSaveDocument} />
                                </div>
                            </div>
                            <DocumentPreview
                                documentId={jobDocumentId}
                                ref={refDocumentPreview}
                                parentDocumentId={parentDocumentId}
                            />
                        </div>
                    </div>
                </Suspense>
            </ReactModal>
        );
    };

    return (
        <>
            {_renderDocumentDetail()}
            <DocumentService onOpen={_handleOpenEdit} />
            <ConfirmEsign ref={refConfirmEsign} onOpenEdit={_afterConfirmEsign} />
            <GdConfirm
                ref={refModalConfirm}
                title={t('notification')}
                listButton={{ close: true, confirm: true }}
                titleConfirm={t('confirm')}
                onClose={_handleCloseEdit}
                onConfirm={_handleCloseEdit}
            />
        </>
    );
}
