import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { ONBOARDING } from 'app/config/routes';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconRocket from 'assets/icon/IconRocket';
import { getPlanUser } from 'common/utils/PermissionUtils';

const QuestlogHeader = (props, ref) => {
    const { pathname } = useLocation();
    const { t } = useTranslation(['header']);
    const history = useHistory();
    const questLogActive = useSelector(({ auth }) => auth.user.settings.questlog.question_active);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(userProfile);
    const [state, dispatchAction] = useReducer(reducer, { isShow: !!questLogActive });
    const { isShow } = state;

    useImperativeHandle(ref, () => ({ setActive: handleActive }));

    const handleActive = (value) => {
        dispatchAction({ isShow: value });
    };

    const _handleClick = () => {
        history.push(addBranchPath(ONBOARDING));
    };

    if (!isShow) return null;

    return (
        <div
            className={classNames('questlog-menu v2-btn-default --icon-lg tooltip', {
                active: isTrial || matchPath(pathname, { path: addPrefixPath(ONBOARDING), exact: true })
            })}
            onClick={_handleClick}
        >
            <IconRocket />
            <p className="tooltiptext bottom">{t('questlog')}</p>
        </div>
    );
};

export default forwardRef(QuestlogHeader);
