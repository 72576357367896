import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GET_CLIENT_SECRET } from 'app/const/api/V2';
import { TYPE_CARD_US_BANK, TYPE_CONFIRM_SCA } from 'app/const/Customers';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';

const StripeContentInfo = forwardRef(
    ({ isVisible = true, isHideLinked = false, onGetAdditionalData = () => ({}) }, ref) => {
        const { stripe } = useSelector(({ auth }) => auth.user.settings);
        const { key: stripePublicKey, sca: isActiveSCA } = stripe;
        const refForm = useRef(null);

        useImperativeHandle(ref, () => ({
            _getTokenCardStripe: () => refForm.current._getTokenCardStripe(),
            confirmSCA: refForm?.current ? refForm.current.confirmSCA : () => {}
        }));

        if (!!!stripePublicKey) return null;
        const stripePromise = stripePublicKey && loadStripe(stripePublicKey);
        return (
            <Elements stripe={stripePromise}>
                <FormInformation
                    isVisible={isVisible}
                    isActiveSCA={isActiveSCA}
                    isHideLinked={isHideLinked}
                    onGetAdditionalData={onGetAdditionalData}
                    ref={refForm}
                />
            </Elements>
        );
    }
);

const FormInformation = forwardRef(
    ({ isActiveSCA, isVisible, onGetAdditionalData = () => ({}), isHideLinked = false }, ref) => {
        const { t } = useTranslation();
        const elements = useElements();
        const stripe = useStripe();

        useImperativeHandle(ref, () => ({ _getTokenCardStripe, confirmSCA: _handleConfirmSCA }));

        const _handleConfirmSCA = (dataIntent, onCallback) => {
            if (dataIntent) {
                const metadata = onGetAdditionalData() || {};
                const { payment_type, secret, payment_method } = dataIntent;

                stripe[TYPE_CONFIRM_SCA[payment_type] || TYPE_CONFIRM_SCA.DEFAULT](secret, {
                    payment_method,
                    payment_intent_data: { metadata }
                }).then(function (result) {
                    if (result.error) {
                        onCallback(result?.error?.message || t('common:please_try_again'), LIST_STATUS.ERROR, true);
                    } else if (payment_type !== TYPE_CARD_US_BANK) {
                        onCallback(t('report:successfully'), LIST_STATUS.SUCCESS, true);
                    }
                });
            }
        };

        const _confirmStripeSCA = async () => {
            const response = await clientQuery(GET_CLIENT_SECRET, { method: 'GET' });

            if (response?.success) {
                const card = elements.getElement(CardNumberElement);
                return stripe
                    .confirmCardSetup(response.data.client_secret, {
                        payment_method: {
                            card: { ...card, name: 'tinh ne' }
                        }
                    })
                    .then(function (result) {
                        if (result?.error) {
                            return {
                                message: result.error.message.toString() || t('common:please_try_again')
                            };
                        }
                        return _createCardTokenStripe(result.setupIntent.payment_method);
                    });
            } else {
                return {
                    message: response?.message?.toString() || t('common:please_try_again')
                };
            }
        };

        const _createCardTokenStripe = async (token) => {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            if (!stripe || !elements) return { message: t('common:please_try_again') };

            const card = elements.getElement(CardNumberElement);
            const additionalOptionsData = onGetAdditionalData() || {};
            const result = await stripe.createToken(card, additionalOptionsData);

            if (result.error) {
                // Handle error
                return result.error;
            } else {
                // Result contains a token.
                return { ...result, token: { ...result.token, payment_method: token } };
            }
        };

        const _getTokenCardStripe = () => {
            if (isActiveSCA) return _confirmStripeSCA();
            return _createCardTokenStripe();
        };

        if (!isVisible) return null;
        return (
            <div className={classNames('content-info', { 'mb-3': isHideLinked })}>
                <div className="rows --inmodal">
                    <div className="txt relative">
                        <span className="flex-1 txt-ellipsis">{t('customers:card_number')}</span>
                    </div>
                    <CardNumberElement options={{ showIcon: true }} />
                </div>

                <div className="rows --inmodal d-flex mt-5">
                    <div className="col col-cvc">
                        <div className="txt">
                            <span className="flex-1 txt-ellipsis" title={t('customers:month')}>
                                {t('customers:month')}
                            </span>
                        </div>
                        <CardExpiryElement />
                    </div>

                    <div className="col col-cvc">
                        <div className="txt">
                            <span className="flex-1 txt-ellipsis" title={t('customers:cvc')}>
                                {t('customers:cvc')}
                            </span>
                        </div>
                        <CardCvcElement />
                    </div>
                </div>
            </div>
        );
    }
);

export default StripeContentInfo;
