import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import GdSMSChangeLogs from 'app/components/smsChangeLogs';
import { reducer } from 'app/const/Reducer';
import Item from 'app/modules/jobdetail/tabs/settingmessaging/sms/Item';
import IconSentSms from 'assets/icon/IconSentSms';

const SMSTo = forwardRef(
    ({ selected = [], openOptions, isFirstTimeFetch, data, isDisplayChangeLog = false, locationId = null }, ref) => {
        const { t } = useTranslation();
        const [state, dispatchState] = useReducer(reducer, {
            options: selected.length > 0 ? selected : [{ id: '', phone: t('customers:none'), type: 'empty' }]
        });

        useEffect(() => {
            dispatchState({
                options: selected.length > 0 ? selected : [{ id: '', phone: t('customers:none'), type: 'empty' }]
            });
        }, [selected]);

        const finalOptions = state.options;

        function _addNewItem() {
            dispatchState({
                options: state.options.concat({ id: '', phone: t('customers:none'), type: 'empty' })
            });
        }

        function _getValue() {
            return finalOptions.filter((item) => item.id.length !== 0);
        }

        useImperativeHandle(ref, () => ({
            getValue: _getValue
        }));

        function _removeItem(position) {
            const listTemp = [...state.options];
            dispatchState({
                options: listTemp.filter((item, index) => position !== index)
            });
        }

        function _handleSelectOption(newItem, position) {
            let listTemp = [...state.options];
            listTemp = listTemp.map((item, index) => {
                if (index === position) {
                    return newItem;
                }
                return item;
            });
            dispatchState({
                options: listTemp
            });
        }

        function _renderListPhones() {
            const optionSelected = state.options.map((item) => item.id);
            return state.options.map((item, index) => {
                return (
                    <Item
                        key={index.toString()}
                        total={optionSelected.length}
                        ids={optionSelected}
                        addItem={_addNewItem}
                        selectOption={_handleSelectOption}
                        openOptions={openOptions}
                        removeItem={_removeItem}
                        dataPhone={item}
                        index={index}
                        isFirstTimeFetch={isFirstTimeFetch}
                        data={data}
                    />
                );
            });
        }

        return (
            <div className="lines">
                <div className="txt svg-conversation">
                    <IconSentSms isHasColor />
                    <span className="flex-1 txt-ellipsis">
                        {t('customers:sms_to')}
                    </span>
                </div>
                {_renderListPhones()}
                {isDisplayChangeLog && <GdSMSChangeLogs locationId={locationId} />}
            </div>
        );
    }
);

SMSTo.displayName = 'SMS_To';

export default SMSTo;
