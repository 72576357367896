import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

import ButtonCopy from 'app/components/button/ButtonCopy';
import {
    CODE_HIGHT_LIGHT,
    CODE_HIGHT_LIGHT_1,
    CODE_HIGHT_LIGHT_2,
    CODE_HIGHT_LIGHT_3,
    KEY_IS_EMBED_CHATBOT,
    KEY_PORTAL_TOKEN,
    KEY_SUB_DOMAIN,
    LINK_POST_CUSTOMER_PORTAL
} from 'app/const/addons';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconBachelorsHat from 'assets/icon/IconBachelorsHat';

const BookingShowCode = ({ scriptInfo = {}, hideBookingCode = false, isChatBot = false }) => {
    const { t } = useTranslation(['addons']);
    const [isShow, setIsShow] = useState(false);

    const _handleToggleShow = () => setIsShow(!isShow);

    return (
        <>
            {isShow && <BookingCode scriptInfo={scriptInfo} hideBookingCode={hideBookingCode} isChatBot={isChatBot} />}
            <div className="d-flex justify-space-between align-center mt-3">
                <div className="v2-btn-default --purple svg-purple --icon-r ml-n2" onClick={_handleToggleShow}>
                    {t(`addons:${isShow ? 'hide_code' : 'show_code'}`)}
                    {isShow ? <IconArrowDown isUp /> : <IconArrowDown />}
                </div>
                <LinkPortal />
            </div>
        </>
    );
};

export const BookingCode = ({
    scriptInfo = {},
    hideBookingCode,
    isChatBot,
    wrapperClassName = 'show-code mt-5 open',
    hideDescriptionBookingCode = false
}) => {
    const { t } = useTranslation(['addons']);

    const codeHightLightFormat = () => {
        let content = CODE_HIGHT_LIGHT;
        content = content.replace(KEY_PORTAL_TOKEN, scriptInfo.account_id);
        content = content.replace(KEY_SUB_DOMAIN, scriptInfo.src);
        content = content.replace(KEY_IS_EMBED_CHATBOT, !!isChatBot);

        return content;
    };
    const finalContent = codeHightLightFormat();

    return (
        <div id="show_code" className={wrapperClassName}>
            {hideDescriptionBookingCode ? null : (
                <p className="mb-1">{t('your_website_installation_code_for_both_addons')}</p>
            )}
            {isChatBot ? (
                <div className="mb-1">{t('addons:only_embed_this_code_when_not_using_customer_portal_embed')}</div>
            ) : null}
            <div className="rows">
                {t('add_the_following_code_just_before_the_closing')}
                <div className="status-btn tag-body fs-13 bg-background-grey violet-name">
                    <span>&lt;</span>
                    {t('body')}
                    <span>&gt;</span>
                </div>
                {t('tag')}
            </div>
            <SyntaxHighlighter
                language="javascript"
                showLineNumbers
                style={coy}
                lineNumberStyle={{ textAlign: 'left', minWidth: '10px' }}
                customStyle={{
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #e3e8ee',
                    backgroundColor: '#f9f9f9',
                    lineHeight: '22px',
                    fontSize: '13px'
                }}
            >
                {finalContent}
            </SyntaxHighlighter>
            {isChatBot ? (
                <div className="flex-betweenitems mt-2">
                    <ButtonCopy
                        content={finalContent}
                        className="v2-btn-default --icon-lg --grey tooltip"
                        tooltipText={t('copy_to_clipboard')}
                    />
                    <LinkPortal />
                </div>
            ) : (
                <ButtonCopy
                    content={finalContent}
                    className="v2-btn-default has-icon --grey mtop-8 tooltip"
                    tooltipText={t('copy_to_clipboard')}
                />
            )}
            {!hideBookingCode && (
                <div className="show-code__more">
                    {t('use_one_of_the_following_codes_to_link_directly')}
                    <ItemCodeCopy code={CODE_HIGHT_LIGHT_1} />
                    <ItemCodeCopy code={CODE_HIGHT_LIGHT_2} />
                    <ItemCodeCopy code={CODE_HIGHT_LIGHT_3} />
                </div>
            )}
        </div>
    );
};

const ItemCodeCopy = ({ code = '' }) => {
    return (
        <div className="form-installation">
            <div className="installation-content-code" dangerouslySetInnerHTML={{ __html: code.display }} />
            <ButtonCopy content={code.value} className="v2-btn-default --icon-lg --grey tooltip" />
        </div>
    );
};

export const LinkPortal = ({ path = LINK_POST_CUSTOMER_PORTAL }) => {
    const { t } = useTranslation('addons');

    const handleLinkClick = (event) => {
        event.preventDefault();
        window.open(path, '_blank');
    };

    return (
        <Link to={path} onClick={handleLinkClick} className="svg-purple purple-default link-portal --md">
            <IconBachelorsHat />
            <span className="txt-ellipsis ml-1">{t('i_need_detailed_instructions')}</span>
        </Link>
    );
};

export default BookingShowCode;
