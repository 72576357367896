import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    ADDONS_JOB_STATUS_MANAGE as ADDONS_JOB_STATUS_MANAGE_ROUTE,
    ADDONS_JOB_STATUS_PERMISSION as ADDONS_JOB_STATUS_PERMISSION_ROUTE
} from 'app/config/routes';
import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { ADDONS_JOB_STATUS_MANAGE, ADDONS_JOB_STATUS_PERMISSION } from 'app/const/addons';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { checkPermission } from 'common/utils/PermissionUtils';
import { ListItemsUsers } from '../../components/CustomFieldsFooter';

const AddonsJobStatusOptionsFooter = ({ keyword, items = null, upgrade }) => {
    const { t } = useTranslation('addons');
    const { profile, permissions } = useSelector(({ auth }) => auth.user);
    const isAccessManage = [PERMISSIONS.addCustomJobStatus, PERMISSIONS.editCustomJobStatus].some((permission) =>
        checkPermission(permissions?.enabled, permission)
    );
    const activeFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_job_status);
    const isSuperAdmin = profile.role === ACCOUNT_ROLE.SUPERADMIN;

    const disableAccess =
        !activeFieldAddon[keyword] ||
        !!upgrade ||
        (keyword === ADDONS_JOB_STATUS_MANAGE && !isAccessManage && !isSuperAdmin) ||
        (keyword === ADDONS_JOB_STATUS_PERMISSION && !isSuperAdmin);

    return (
        <div className="boxs__footer">
            {items && isNaN(items) ? <ListItemsUsers data={items} /> : null}
            <Link
                to={addBranchPath(
                    keyword === ADDONS_JOB_STATUS_MANAGE
                        ? ADDONS_JOB_STATUS_MANAGE_ROUTE
                        : ADDONS_JOB_STATUS_PERMISSION_ROUTE
                )}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': disableAccess
                })}
            >
                {t('manage')}
                <IconCogWheel />
            </Link>
        </div>
    );
};

export default AddonsJobStatusOptionsFooter;
