import GdConfirm from 'app/components/confirm';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import { getDeleteServiceBooking } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceAdd from './ServiceAdd';
import ServiceAssign from './ServiceAssign';
import ServiceEditLearnMore from './ServiceEditLearnMore';
import ServiceItem from './ServiceItem';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';

const ServiceList = ({ data = [], isDisable = false, onShowAlert = () => {} }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { services: data, isLoading: true });
    const { services } = state;

    const refAddService = useRef(null);
    const refAssign = useRef(null);
    const refConfirm = useRef(null);
    const refEditLearnMore = useRef(null);

    const _renderListService = () => {
        if (!services.length) return <GridEmpty className="tables-empty mx-3" />;
        return services.map((item) => (
            <ServiceItem
                key={item.id}
                onAssign={_handleAssign}
                onDelete={_handleDelete}
                onEditLearnMore={_handleEdit}
                {...item}
            />
        ));
    };

    const _handleAddService = () => refAddService.current._open(services.map((item) => item.id));
    const _handleAddSuccess = (services) => {
        onShowAlert({
            id: 'update_service_success',
            message: t('addons:update_service_successfully'),
            type: LIST_STATUS.SUCCESS
        });
        dispatchState({ services });
    };

    const _handleEdit = (id, learnMore) => refEditLearnMore.current._open(id, learnMore);
    const _handleEditSuccess = (data, message) => {
        onShowAlert({
            id: 'update_learn_more_success',
            message,
            type: LIST_STATUS.SUCCESS
        });

        dispatchState({
            services: services.map((item) => {
                if (item.id === data.id) return { ...item, learn_more: data.learn_more };
                return item;
            })
        });
    };

    const _handleAssign = (idService, idSchedule) => refAssign.current._open(idService, idSchedule);
    const _handleAssignSuccess = (data, message, idUpdate) => {
        dispatchState({
            services: services.map((item) => {
                if (item.id === idUpdate) return { ...item, schedule: data };
                return item;
            })
        });
        onShowAlert({
            id: 'update_assign_success',
            message,
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleDelete = (id) => refConfirm.current.open(id);
    const _handleDeleteSuccess = (idDelete) => {
        clientQuery(getDeleteServiceBooking(idDelete), { data: {}, method: 'DELETE' });
        dispatchState({ services: services.filter((item) => item.id !== idDelete) });
        onShowAlert({
            id: 'update_assign_success',
            message: t('delete_successfully'),
            type: LIST_STATUS.SUCCESS
        });
    };

    return (
        <>
            <div className={classNames('boxs boxs--booking', { 'is-disable': isDisable })}>
                <div className="boxs__header border-bottom-border-color-grey">
                    <div className="title cursor-pointer">{t('addons:add_your_service')}</div>
                    <div className="v2-btn-default has-icon --grey btn-modal" onClick={_handleAddService}>
                        <IconPlus />
                        {t('addons:add_a_service')}
                    </div>
                </div>
                <div className="boxs__contents --service">{_renderListService()}</div>
            </div>

            {/* Modals */}
            <ServiceAdd ref={refAddService} onAddSuccess={_handleAddSuccess} />
            <ServiceAssign ref={refAssign} onAssignSuccess={_handleAssignSuccess} />
            <ServiceEditLearnMore ref={refEditLearnMore} onEditSuccess={_handleEditSuccess} />
            <GdConfirm
                ref={refConfirm}
                title={t('are_you_sure_delete_this_item')}
                message={t('delete')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleDeleteSuccess}
            />
        </>
    );
};

export default ServiceList;
