import { roundingNumber } from 'common/utils/NumberUtils';
import React from 'react';
import Discount from 'app/modules/jobdetail/tabs/addinvoice/form/summary/Discount';
import Deposit from '../summary/Deposit';
import { useTranslation } from 'react-i18next';

export default function PackageSummary({ packageData }) {
    const { t } = useTranslation();
    const packageDiscount = packageData?.discount?.total?.value;

    function _renderTaxs() {
        let resArr = [];
        const listTaxs = [...(packageData.taxes || [])];

        listTaxs.forEach(function (item) {
            let itemFind = {};

            itemFind = resArr.find((entry) => entry.id === item.id);

            if (!itemFind) {
                resArr.push({ ...item });
            } else {
                resArr = resArr.map((newItem) => {
                    if (newItem.id === item.id) {
                        const oldValue = parseFloat(itemFind?.total?.value);
                        return {
                            ...newItem,
                            total: {
                                value: roundingNumber(oldValue + parseFloat(item.total.value))
                            }
                        };
                    }
                    return newItem;
                });
            }
        });

        return resArr.map((itemTax) => {
            return (
                <div key={itemTax.id} className="sm-row is-tax-value">
                    <div className="txt">{`${itemTax.name} (${itemTax.rate}%)`}</div>
                    <div className="detail txt-ellipsis">{itemTax.total.value}</div>
                </div>
            );
        });
    }

    return (
        <div className="items-payment-number">
            <div className="sm-row">
                <div className="txt"> {t('common:subtotal')} </div>
                <div className="detail txt-ellipsis">{packageData?.subtotal || '0.00'}</div>
            </div>
            <div className="sm-row edit-discount">
                <Discount packageId={packageData.id} />
                {parseFloat(packageDiscount) !== 0 && <div className="detail txt-ellipsis">{packageDiscount}</div>}
            </div>
            {_renderTaxs()}
            <div className="sm-row">
                <div className="txt"> Total </div>
                <div className="detail txt-ellipsis">{packageData?.total || '0.00'}</div>
            </div>
            <div className="sm-row edit-discount">
                <Deposit packageId={packageData.id} />
                {parseFloat(packageData.deposit.total.value) !== 0 && (
                    <div className="detail txt-ellipsis">{packageData.deposit.total.value}</div>
                )}
            </div>
        </div>
    );
}
