import { getJobStatus } from 'common/utils/JobStatusUtils';
import { JOB_STATE } from 'app/modules/jobdetail/const';
import { STATUSES_EXCEPT_MAGNET } from '../const';
import { JOB_ACTIVE } from '../const/Job';
import { JOB_CANCELED, JOB_COMPLETED, JOB_TERMINATE_SERVICE } from 'app/const/Job';

export const actionJobMove = ({ data }, jobList) => {
    return jobList.map((item) => {
        if (item.jobId === data.id) return { ...item, start: data.start, schedule: data.schedule, tile: data.tile };
        return item;
    });
};

export const actionJobStatus = (jobData, jobList, needClearMagnet = false) => {
    // eslint-disable-next-line no-undef
    const jobPreviewCurrentId = global.jobPreviewId;
    const currentJob = jobData.current_job;
    const currentJobData = currentJob.job || {};
    const { status: newStatus } = currentJobData;
    const jobId = parseInt(currentJobData.id);
    const newJob = jobData.new_job;
    const newJobData = newJob?.job;
    let jobPreviewUpdate = null;
    let oldIsExceptMagnet = false;

    let newJobs = jobList.map((item) => {
        const currentJobId = parseInt(item.jobId);

        if (currentJobId === jobId) {
            // Check if job status is except magnet and change to available status
            if (STATUSES_EXCEPT_MAGNET.includes(parseInt(item.status))) oldIsExceptMagnet = true;
            item.status = newStatus;
            item.previously_completed = currentJobData.previously_completed;
            item.editable = currentJob.editable;
            item.resourceEditable = currentJob.editable;
            item.draft_invoice = currentJob.draft_invoice;
            item.colorEvent = currentJob.color || item.colorEvent;
        }

        if (jobId === currentJobId && currentJobId === parseInt(jobPreviewCurrentId) && !item.isDriveTime) {
            jobPreviewUpdate = item;
        }

        if (newJob && newJobData) {
            if (parseInt(newJob.job.id) === currentJobId) {
                item.status = newJobData.status;
                item.previously_completed = newJobData.previously_completed || 0;
                item.recurrence = '';
                item.editable = newJob.editable;
                item.draft_invoice = newJob.draft_invoice;
                item.colorEvent = newJob.color || item.colorEvent;
                item.active_job = JOB_ACTIVE.ACTIVE;
                item.job_state = JOB_STATE.ACTIVE;
                if (currentJobId === parseInt(jobPreviewCurrentId)) {
                    jobPreviewUpdate = item;
                }
            }
        }

        // Clear magnet props
        if (needClearMagnet) {
            item['is_magnet'] = false;
            item['magnet'] = null;
            item['colorDistance'] = '';
            item['indexJob'] = 0;
        }
        return item;
    });

    const finalType = getJobStatus(newStatus)?.type;

    if (finalType === JOB_TERMINATE_SERVICE) {
        const { parent_job_id: currentParentJob, job_no: currentJobNo, prev_status: prevStatus } = currentJobData;

        const prevStatusType = getJobStatus(prevStatus)?.type;

        if (prevStatusType !== JOB_COMPLETED && prevStatusType !== JOB_CANCELED) {
            newJobs = newJobs.filter(
                (eventDetail) => currentParentJob !== eventDetail.parent_job_id || eventDetail.job_no <= currentJobNo
            );
        }
    }

    return { list: newJobs, jobPreview: jobPreviewUpdate, oldIsExceptMagnet };
};

export const actionDeleteJob = (jobData, events) => {
    /* eslint-disable no-undef */
    const jobPreviewCurrentId = global.jobPreviewId;
    const jobDeleted = jobData.jobs || [];
    let isJobPreview = false;
    let newEvents = [...events];
    jobDeleted.forEach((item) => {
        const currentParentJob = item.parent.toString();
        const jobComplete = currentParentJob === '0' ? 1 : 0;
        const jobId = item.id.toString();

        if (jobComplete === 1) {
            newEvents = newEvents.filter((eventDetail) => {
                if (eventDetail.jobId !== jobId) {
                    if (jobPreviewCurrentId === jobId) {
                        isJobPreview = true;
                    }
                    return eventDetail;
                }
            });
        } else {
            newEvents = newEvents.filter((eventDetail) => {
                if (currentParentJob === eventDetail.parent_job_id && eventDetail.previously_completed === 0) {
                    if (jobPreviewCurrentId === eventDetail.id) {
                        isJobPreview = true;
                    }
                } else {
                    return eventDetail;
                }
            });
        }
    });

    return {
        list: newEvents,
        isJobPreview
    };
};

export const actionMoveWPOnCalendar = ({ data = [], isMoveWPToNormal = false, isMoveToWP = false, events = [] }) => {
    // Case 1: Move job to job work pool -> immediately add new job to work pool
    if (isMoveToWP) return [...data, ...events];
    // Case 2: Move job workpool to job normal -> immediately remove job from work pool
    if (isMoveWPToNormal) return events.filter((event) => !data.some((job) => job.jobId === event.jobId));
    return events;
};
