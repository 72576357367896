import {
    CUSTOMERS_ACCOUNT,
    CUSTOMERS_CONTACTS,
    CUSTOMERS_CREDITS,
    CUSTOMERS_DETAIL,
    CUSTOMERS_DOCUMENTS,
    CUSTOMERS_ESTIMATES,
    CUSTOMERS_INVOICES,
    CUSTOMERS_JOBS,
    CUSTOMERS_LOCATIONS,
    CUSTOMERS_PAYMENTS
} from 'app/config/routes';
import { LIST_ACTION_SEND_ACH_INVITATION } from 'app/modules/jobdetail/const';
import IconMerge from 'assets/icon/IconMerge';
import IconSync from 'assets/icon/IconSync';
import IconTime from 'assets/icon/IconTime';
import IconTrash from 'assets/icon/IconTrash';
import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import React from 'react';

export const MAX_PHONE = 4;
export const CUSTOMER_MERGE_TYPE = 'merge_customer';

export const LIST_STATUS = {
    active: 1,
    archived: 2,
    deleted: 3
};
export const CUSTOMER_DETAIL_INVOICE_TYPE = {
    RECURRENCE: 'recurrence',
    INVOICE: 'invoice'
};
const ACTIVE = 'active';
const ARCHIVED = 'archived';
const DELETED = 'deleted';

export const ADD_CUSTOMER = 'add_customer';
export const IMPORT_CUSTOMER = 'import_customer';

// eslint-disable-next-line no-undef
export const CUSTOMERS_DOWNLOAD_SAMPLE_FILE = process.env.REACT_APP_CUSTOMERS_DOWNLOAD_SAMPLE_FILE;

const TAB_ACTIVE = { id: 1, name: ACTIVE, value: ACTIVE };
const TAB_ARCHIVED = { id: 1, name: ARCHIVED, value: ARCHIVED };
const TAB_DELETE = { id: 1, name: DELETED, value: DELETED };

export const ORDER = {
    ASC: 'asc',
    DESC: 'desc'
};

// menu
export const LIST_ITEM_SIDE_MENU = [
    {
        id: 'total_customer',
        idFilter: 'total_customer',
        title: 'total_customers',
        tooltip: 'total_customer_tooltip'
    },
    {
        id: 'with_service',
        idFilter: 'w_service',
        title: 'with_service'
    },
    {
        id: 'without_service',
        idFilter: 'wo_service',
        title: 'without_service'
    },
    { id: 'line1', type: 'line' },
    {
        id: 'total_leads',
        idFilter: 'lead',
        title: 'total_leads',
        classIcon: '--lead',
        tooltip: 'total_leads_tooltip'
    },
    { id: 'line2', type: 'line' },
    {
        id: 'bounced_email',
        idFilter: 'bounced',
        title: 'bounced_email',
        classIcon: '--red',
        tooltip: 'bounced_email_tooltip'
    },
    {
        id: 'bounced_phone',
        idFilter: 'bounced_phone',
        title: 'bounced_phone',
        classIcon: '--red',
        tooltip: 'bounced_phone_tooltip'
    },
    { id: 'line3', type: 'line' },
    {
        id: 'expired_credit_card',
        idFilter: 'expired',
        title: 'expired_credit_card',
        classIcon: '--red'
    },
    {
        id: 'expiring_less_60_days',
        idFilter: 'expiring',
        title: 'expiring_less_than_60_days'
    }
];

export const LIST_ACTION_BASIC = {
    MERGE_ACCOUNTS: 'merge_accounts',
    SYNC_TO_QB: 'sync_to_qb',
    DELETE: 'deleted',
    ARCHIVED: 'archived',
    ACTIVE: 'active'
};

const BUTTON_MERGE_ACCOUNTS = {
    id: 3,
    label: 'merge_accounts',
    className: 'v2-btn-default has-icon',
    iconClassName: <IconMerge />,
    value: LIST_ACTION_BASIC.MERGE_ACCOUNTS
};

const BUTTON_ACTION_SYNC_TO_QB = {
    id: 1,
    label: 'sync_to_qb',
    className: 'v2-btn-default has-icon',
    iconClassName: <IconSync />,
    value: LIST_ACTION_BASIC.SYNC_TO_QB
};

const BUTTON_ACTION_DELETE = {
    id: 2,
    label: 'delete',
    className: 'v2-btn-default --grey has-icon',
    iconClassName: 'svg-delete-grey',
    iconSvg: <IconTrash />,
    value: LIST_ACTION_BASIC.DELETE
};

const BUTTON_ACTION_ARCHIVED = {
    id: 4,
    label: 'archive',
    className: 'v2-btn-default --grey has-icon',
    iconClassName: 'svg-delete-grey',
    iconSvg: <IconTime />,
    value: LIST_ACTION_BASIC.ARCHIVED
};

const BUTTON_ACTION_ACTIVE = {
    id: 5,
    label: 'active',
    className: 'v2-btn-default --grey has-icon',
    iconClassName: 'svg-delete-grey',
    iconSvg: <IconTime />,
    value: LIST_ACTION_BASIC.ACTIVE
};

export const LIST_BUTTON_ACTION_CUSTOMERS = [BUTTON_MERGE_ACCOUNTS, BUTTON_ACTION_SYNC_TO_QB, BUTTON_ACTION_DELETE];

const LIST = 'list';
const MAP = 'map';

const TAB_LIST = { id: 1, name: LIST, value: LIST };
const TAB_MAP = { id: 2, name: MAP, value: MAP };

export const LIST_TAB_CUSTOMERS = [TAB_LIST, TAB_MAP];

export const LIST_BUTTON_ACTION_CUSTOMERS_DETAIL_CREDIT = [BUTTON_ACTION_DELETE];

export const LIST_BUTTON_ACTION_CUSTOMERS_DETAIL_CONTACT = [BUTTON_ACTION_DELETE];

export const LIST_BUTTON_ACTION_CUSTOMERS_DETAIL_ESTIMATE = {
    [ACTIVE]: [BUTTON_ACTION_DELETE, BUTTON_ACTION_ARCHIVED],
    [ARCHIVED]: [BUTTON_ACTION_ACTIVE, BUTTON_ACTION_DELETE],
    [DELETED]: [BUTTON_ACTION_ACTIVE, BUTTON_ACTION_ARCHIVED]
};

export const LIST_BUTTON_ACTION_CUSTOMERS_DETAIL_LOCATION = [BUTTON_ACTION_DELETE];

//// Page Estimate
export const ESTIMATE_STATUS = {
    1: { id: 1, name: 'sent' },
    2: { id: 2, name: 'draft' },
    3: { id: 3, name: 'pending' },
    4: { id: 4, name: 'lost' }
};

export const LIST_TAB_ESTIMATE = [TAB_ACTIVE, TAB_ARCHIVED, TAB_DELETE];
//// End Page Estimate

export const LIST_TAB_INVOICE = [TAB_ACTIVE, TAB_ARCHIVED, TAB_DELETE];

export const CUSTOMER_MAIN_NAVIGATION = [
    { id: 'account', title: 'account', to: CUSTOMERS_ACCOUNT },
    { id: 'contacts', title: 'contacts', to: CUSTOMERS_CONTACTS },
    { id: 'locations', title: 'locations', to: CUSTOMERS_LOCATIONS }
];
export const CUSTOMER_SUB_NAVIGATION = [
    { id: 'timeline', title: 'notes', to: CUSTOMERS_DETAIL },
    { id: 'jobs', title: 'jobs', to: CUSTOMERS_JOBS },
    { id: 'invoices', title: 'invoices', to: CUSTOMERS_INVOICES },
    { id: 'estimates', title: 'estimates', to: CUSTOMERS_ESTIMATES },
    { id: 'payments', title: 'payments', to: CUSTOMERS_PAYMENTS },
    { id: 'credits', title: 'credits', to: CUSTOMERS_CREDITS },
    { id: 'documents', title: 'documents', to: CUSTOMERS_DOCUMENTS }
    // { id: 'customer_portal', title: 'customer_portal', to: CUSTOMERS_PORTAL },
    // { id: 'statement', title: 'statement', to: CUSTOMERS_STATEMENTS }
];

export const AMOUNT_TAGS_TO_SHOW = 3;

const LIST_VALUE_CUSTOMER_IMPORT = {
    STATUS: 'customer_status',
    ACCOUNT: 'customer_account',
    BALANCE: 'customer_balance',
    FIRST_NAME: 'customer_firstname',
    LAST_NAME: 'customer_lastname',
    EMAIL: 'customer_email',
    COMPANY: 'customer_company',
    SOURCE: 'customer_source',
    TOP_NOTE: 'customer_top_note',
    PHONE_MOBILE: 'customer_phone_mobile',
    PHONE_HOME: 'customer_phone_home',
    PHONE_MAIN: 'customer_phone_main',
    PHONE_WORK: 'customer_phone_work',
    PHONE_HOME_FAX: 'customer_phone_home_fax',
    PHONE_WORK_FAX: 'customer_phone_work_fax',
    ADDRESS_LINE_1: 'customer_address_line_1',
    ADDRESS_LINE_2: 'customer_address_line_2',
    CITY: 'customer_city',
    STATE: 'customer_state',
    ZIP: 'customer_zip',
    LOCATION_NOTES: 'customer_location_notes',
    BILLING_ADDRESS_LINE_1: 'customer_billing_address_line_1',
    BILLING_ADDRESS_LINE_2: 'customer_billing_address_line_2',
    BILLING_CITY: 'customer_billing_city',
    BILLING_STATE: 'customer_billing_state',
    BILLING_ZIP: 'customer_billing_zip',
    ALT_FIRST_NAME: 'customer_alt_first_name',
    ALT_LAST_NAME: 'customer_alt_last_name',
    ALT_EMAIL: 'customer_alt_email',
    ALT_PHONE_MOBILE: 'customer_alt_phone_mobile',
    ALT_PHONE_HOME: 'customer_alt_phone_home',
    ALT_PHONE_WORK: 'customer_alt_phone_work',
    ALT_PHONE_MAIN: 'customer_alt_phone_main',
    ALT_PHONE_HOME_FAX: 'customer_alt_phone_home_fax',
    ALT_PHONE_WORK_FAX: 'customer_alt_phone_work_fax',
    SELECT_ATTRIBUTE: 'select_attribute',
    ALT_BILL_TO_NAME: 'customer_bill_to',
    CUSTOMER_TAGS: 'customer_tags',
    LOCATION_TAGS: 'location_tags',
    LOCATION_TAX_1: 'location_tax_1',
    LOCATION_TAX_2: 'location_tax_2'
};

export const CUSTOMERS_IMPORT_OPTIONS = [
    {
        id: 1,
        title: '',
        value: null,
        isHiddenTitle: true,
        children: [{ id: null, title: LIST_VALUE_CUSTOMER_IMPORT.SELECT_ATTRIBUTE, value: null }]
    },
    {
        id: 2,
        title: 'customers',
        children: [
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.STATUS,
                title: LIST_VALUE_CUSTOMER_IMPORT.STATUS,
                value: LIST_VALUE_CUSTOMER_IMPORT.STATUS
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ACCOUNT,
                title: LIST_VALUE_CUSTOMER_IMPORT.ACCOUNT,
                value: LIST_VALUE_CUSTOMER_IMPORT.ACCOUNT
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.BALANCE,
                title: LIST_VALUE_CUSTOMER_IMPORT.BALANCE,
                value: LIST_VALUE_CUSTOMER_IMPORT.BALANCE
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.FIRST_NAME,
                title: LIST_VALUE_CUSTOMER_IMPORT.FIRST_NAME,
                value: LIST_VALUE_CUSTOMER_IMPORT.FIRST_NAME
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.LAST_NAME,
                title: LIST_VALUE_CUSTOMER_IMPORT.LAST_NAME,
                value: LIST_VALUE_CUSTOMER_IMPORT.LAST_NAME
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.EMAIL,
                title: LIST_VALUE_CUSTOMER_IMPORT.EMAIL,
                value: LIST_VALUE_CUSTOMER_IMPORT.EMAIL
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.COMPANY,
                title: LIST_VALUE_CUSTOMER_IMPORT.COMPANY,
                value: LIST_VALUE_CUSTOMER_IMPORT.COMPANY
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.SOURCE,
                title: LIST_VALUE_CUSTOMER_IMPORT.SOURCE,
                value: LIST_VALUE_CUSTOMER_IMPORT.SOURCE
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.TOP_NOTE,
                title: LIST_VALUE_CUSTOMER_IMPORT.TOP_NOTE,
                value: LIST_VALUE_CUSTOMER_IMPORT.TOP_NOTE
            }
        ]
    },
    {
        id: 6,
        title: LIST_VALUE_CUSTOMER_IMPORT.CUSTOMER_TAGS,
        children: [
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.CUSTOMER_TAGS,
                title: LIST_VALUE_CUSTOMER_IMPORT.CUSTOMER_TAGS,
                value: LIST_VALUE_CUSTOMER_IMPORT.CUSTOMER_TAGS
            }
        ]
    },
    {
        id: 3,
        title: 'phones',
        children: [
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MOBILE,
                title: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MOBILE,
                value: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MOBILE
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME,
                title: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME,
                value: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MAIN,
                title: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MAIN,
                value: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MAIN
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK,
                title: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK,
                value: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME_FAX,
                title: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME_FAX,
                value: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME_FAX
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK_FAX,
                title: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK_FAX,
                value: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK_FAX
            }
        ]
    },
    {
        id: 4,
        title: 'location',
        children: [
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_1,
                title: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_1,
                value: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_1
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_2,
                title: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_2,
                value: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_2
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.CITY,
                title: LIST_VALUE_CUSTOMER_IMPORT.CITY,
                value: LIST_VALUE_CUSTOMER_IMPORT.CITY
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.STATE,
                title: LIST_VALUE_CUSTOMER_IMPORT.STATE,
                value: LIST_VALUE_CUSTOMER_IMPORT.STATE
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ZIP,
                title: LIST_VALUE_CUSTOMER_IMPORT.ZIP,
                value: LIST_VALUE_CUSTOMER_IMPORT.ZIP
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_NOTES,
                title: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_NOTES,
                value: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_NOTES
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_1,
                title: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_1,
                value: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_1
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_2,
                title: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_2,
                value: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_2
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_1,
                title: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_1,
                value: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_1
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_2,
                title: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_2,
                value: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_2
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_CITY,
                title: LIST_VALUE_CUSTOMER_IMPORT.BILLING_CITY,
                value: LIST_VALUE_CUSTOMER_IMPORT.BILLING_CITY
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_STATE,
                title: LIST_VALUE_CUSTOMER_IMPORT.BILLING_STATE,
                value: LIST_VALUE_CUSTOMER_IMPORT.BILLING_STATE
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ZIP,
                title: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ZIP,
                value: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ZIP
            }
        ]
    },
    {
        id: 7,
        title: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAGS,
        children: [
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAGS,
                title: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAGS,
                value: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAGS
            }
        ]
    },
    {
        id: 5,
        title: 'contacts',
        children: [
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_FIRST_NAME,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_FIRST_NAME,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_FIRST_NAME
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_LAST_NAME,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_LAST_NAME,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_LAST_NAME
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_EMAIL,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_EMAIL,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_EMAIL
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MOBILE,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MOBILE,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MOBILE
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MAIN,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MAIN,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MAIN
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME_FAX,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME_FAX,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME_FAX
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK_FAX,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK_FAX,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK_FAX
            },
            {
                id: LIST_VALUE_CUSTOMER_IMPORT.ALT_BILL_TO_NAME,
                title: LIST_VALUE_CUSTOMER_IMPORT.ALT_BILL_TO_NAME,
                value: LIST_VALUE_CUSTOMER_IMPORT.ALT_BILL_TO_NAME
            }
        ]
    }
];

export const ALL_OPTIONS_IMPORT_CUSTOMER = [
    { id: null, name: LIST_VALUE_CUSTOMER_IMPORT.SELECT_ATTRIBUTE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ACCOUNT, name: LIST_VALUE_CUSTOMER_IMPORT.ACCOUNT },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_1, name: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_1 },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_2, name: LIST_VALUE_CUSTOMER_IMPORT.ADDRESS_LINE_2 },
    { id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_1, name: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_1 },
    { id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_2, name: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ADDRESS_LINE_2 },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_FIRST_NAME, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_FIRST_NAME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_LAST_NAME, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_LAST_NAME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_EMAIL, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_EMAIL },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MOBILE, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MOBILE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MAIN, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MAIN },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME_FAX, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME_FAX },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK_FAX, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK_FAX },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ALT_BILL_TO_NAME, name: LIST_VALUE_CUSTOMER_IMPORT.ALT_BILL_TO_NAME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.BALANCE, name: LIST_VALUE_CUSTOMER_IMPORT.BALANCE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.COMPANY, name: LIST_VALUE_CUSTOMER_IMPORT.COMPANY },
    { id: LIST_VALUE_CUSTOMER_IMPORT.CITY, name: LIST_VALUE_CUSTOMER_IMPORT.CITY },
    { id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_CITY, name: LIST_VALUE_CUSTOMER_IMPORT.BILLING_CITY },
    { id: LIST_VALUE_CUSTOMER_IMPORT.EMAIL, name: LIST_VALUE_CUSTOMER_IMPORT.EMAIL },
    { id: LIST_VALUE_CUSTOMER_IMPORT.FIRST_NAME, name: LIST_VALUE_CUSTOMER_IMPORT.FIRST_NAME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.LAST_NAME, name: LIST_VALUE_CUSTOMER_IMPORT.LAST_NAME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_NOTES, name: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_NOTES },
    { id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_1, name: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_1 },
    { id: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_2, name: LIST_VALUE_CUSTOMER_IMPORT.LOCATION_TAX_2 },
    { id: LIST_VALUE_CUSTOMER_IMPORT.TOP_NOTE, name: LIST_VALUE_CUSTOMER_IMPORT.TOP_NOTE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MOBILE, name: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MOBILE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME, name: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME },
    { id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MAIN, name: LIST_VALUE_CUSTOMER_IMPORT.PHONE_MAIN },
    { id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK, name: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK },
    { id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME_FAX, name: LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME_FAX },
    { id: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK_FAX, name: LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK_FAX },
    { id: LIST_VALUE_CUSTOMER_IMPORT.STATUS, name: LIST_VALUE_CUSTOMER_IMPORT.STATUS },
    { id: LIST_VALUE_CUSTOMER_IMPORT.SOURCE, name: LIST_VALUE_CUSTOMER_IMPORT.SOURCE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.STATE, name: LIST_VALUE_CUSTOMER_IMPORT.STATE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_STATE, name: LIST_VALUE_CUSTOMER_IMPORT.BILLING_STATE },
    { id: LIST_VALUE_CUSTOMER_IMPORT.ZIP, name: LIST_VALUE_CUSTOMER_IMPORT.ZIP },
    { id: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ZIP, name: LIST_VALUE_CUSTOMER_IMPORT.BILLING_ZIP }
];

export const PHONES_EXCEPT_ATTRIBUTE = [
    LIST_VALUE_CUSTOMER_IMPORT.PHONE_MOBILE,
    LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME,
    LIST_VALUE_CUSTOMER_IMPORT.PHONE_MAIN,
    LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK,
    LIST_VALUE_CUSTOMER_IMPORT.PHONE_HOME_FAX,
    LIST_VALUE_CUSTOMER_IMPORT.PHONE_WORK_FAX
];

export const CONTACT_PHONES_EXCEPT_ATTRIBUTE = [
    LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MOBILE,
    LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME,
    LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK,
    LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_MAIN,
    LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_HOME_FAX,
    LIST_VALUE_CUSTOMER_IMPORT.ALT_PHONE_WORK_FAX
];

export const LIMIT_SELECTED_PHONES_ATTRIBUTE = 4;
export const LIMIT_SELECTED_CONTACT_PHONES_ATTRIBUTE = 2;
export const LIMIT_SHOW_ROW_IMPORT = 3;

export const KEY_IMPORTER_LOCAL_STORAGE = 'importer_customer';
export const TYPE_PAYMENT_FOR = { 1: 'invoice', 2: 'credit' };

export const INTERVAL_OPTIONS_PLANS_SQUARE = [
    {
        id: '1',
        name: 'daily',
        value: '1',
        interval_count: 1
    },
    {
        id: '2',
        name: 'once_every_30_days',
        value: '2',
        interval_count: 30
    },
    {
        id: '3',
        name: 'once_every_60_days',
        value: '3',
        interval_count: 60
    },
    {
        id: '4',
        name: 'once_every_90_days',
        value: '4',
        interval_count: 90
    },
    {
        id: '5',
        name: 'weekly',
        value: '5',
        interval_count: 1
    },
    {
        id: '6',
        name: 'every_other_week',
        value: '6',
        interval_count: 2
    },
    {
        id: '7',
        name: 'monthly',
        value: '7',
        interval_count: 1
    },
    {
        id: '8',
        name: 'every_two_month',
        value: '8',
        interval_count: 2
    },
    {
        id: '9',
        name: 'quarterly',
        value: '9',
        interval_count: 3
    },
    {
        id: '10',
        name: 'every_four_month',
        value: '10',
        interval_count: 4
    },
    {
        id: '11',
        name: 'every_six_month',
        value: '11',
        interval_count: 6
    },
    {
        id: '12',
        name: 'yearly',
        value: '12',
        interval_count: 1
    },
    {
        id: '13',
        name: 'twice_a_year',
        value: '13',
        interval_count: 2
    }
];
export const INTERVAL_OPTIONS_PLANS = [
    {
        id: 'day',
        name: 'daily',
        value: 'day',
        interval_count: 1
    },
    {
        id: 'week',
        name: 'weekly',
        value: 'week',
        interval_count: 1
    },
    {
        id: 'month',
        name: 'monthly',
        value: 'month',
        interval_count: 1
    },
    {
        id: 'quarter',
        name: 'every_3_months',
        value: 'month',
        interval_count: 3
    },
    {
        id: 'semiannual',
        name: 'every_6_months',
        value: 'month',
        interval_count: 6
    },
    {
        id: 'year',
        name: 'yearly',
        value: 'year',
        interval_count: 1
    },
    {
        id: 'custom',
        name: 'custom',
        value: 'custom'
    }
];

export const INTERVAL_OPTIONS = {
    square: INTERVAL_OPTIONS_PLANS_SQUARE,
    stripe: INTERVAL_OPTIONS_PLANS
};
export const INTERVAL_CUSTOM = {
    id: 'custom',
    name: 'Custom',
    value: 'custom'
};
export const INTERVAL_CUSTOM_OPTIONS_PLANS = [
    {
        id: 'day',
        name: 'Daily',
        value: 'day'
    },
    {
        id: 'week',
        name: 'Weekly',
        value: 'week'
    },
    {
        id: 'month',
        name: 'Monthly',
        value: 'month'
    },
    {
        id: 'year',
        name: 'Yearly',
        value: 'year'
    }
];

export const TYPE_RE_ACTIVE_SUBSCRIPTION = 'TYPE_RE_ACTIVE_SUBSCRIPTION';
export const TYPE_DELETE_IMMEDIATELY_SUBSCRIPTION = 'TYPE_DELETE_IMMEDIATELY_SUBSCRIPTION';

export const DEFAULT_CUSTOMER_ADD_LOCATION_TABS = {
    appointment_confirmations: 3,
    appointment_reminders: 3,
    appointment_followups: 3,
    broadcasts: 3,
    late_payment_reminders: 3
};

export const CUSTOMER_STATUSES = [
    { id: 0, name: 'new_leads', value: 2 },
    { id: 1, name: 'active_customer', value: 1 },
    { id: 2, name: 'inactive_customer', value: 0 }
];
export const DEFAULT_SOURCE = { id: '', name: 'how_did_you_hear_about_us?', value: 'none' };
export const BILLED_INVOICE = 2;

export const STATUS_DOCUMENT = {
    DRAFT: 'draft',
    SIGNED: 'signed'
};

export const PAYMENT_STATUS_FAIL = {
    FAIL: -1,
    PENDING: 0
};

export const DEFAULT_STATUS_PAYMENT = {
    DEFAULT: 1,
    NOT_DEFAULT: 0
};

export const PAYMENT_CARD_CUSTOMER = {
    SQUARE: 'square',
    STRIPE: 'stripe',
    ACH: '_ach'
};

export const SPECIAL_PAYMENT_CARD = {
    ...PAYMENT_CARD_CUSTOMER,
    SQUARE_API: 'square API'
};

export const MERCHANT_ID = {
    [PAYMENT_CARD_CUSTOMER.STRIPE]: 1,
    [capitalizeFirstLetter(PAYMENT_CARD_CUSTOMER.STRIPE)]: 1,
    [PAYMENT_CARD_CUSTOMER.SQUARE]: 2,
    [capitalizeFirstLetter(PAYMENT_CARD_CUSTOMER.SQUARE)]: 2
};

export const TYPE_SEARCH_DEFAULT = 'main';

export const TYPE_TAGS = {
    TAG_DEFAULT: 1,
    TAG_CUSTOMER: 2,
    TAG_CREW: 3,
    TAG_INVOICE: 4,
    TAG_CONVERSATION: 5
};
export const LIMIT_TAGS = {
    TAG_INVOICE: 5
};
export const TYPE_TAGS_UPDATE = {
    TAG_LOCATION: 1,
    TAG_INVOICE: 2
};

export const LIMIT_ATTACHMENT = 20480;

export const STATUS_CUSTOMER = ['inactive', 'active', 'lead'];

export const TYPE_SELECT_PHONE = {
    CALL: 'call',
    SMS: 'sms',
    COPY: 'copy'
};

export const TYPE_ACTION_ACCOUNT_ACH = {
    SET_DEFAULT: 'set_default',
    VERIFY: 'verify',
    TEXT_REMINDER: 'text_verification_reminder',
    EMAIL_REMINDER: 'email_verification_reminder',
    REMINDER: 'email_and_text_verification_reminder',
    DELETE: 'delete'
};

export const TYPE_REMINDER = {
    [TYPE_ACTION_ACCOUNT_ACH.TEXT_REMINDER]: LIST_ACTION_SEND_ACH_INVITATION.SEND_BY_SMS.id,
    [TYPE_ACTION_ACCOUNT_ACH.EMAIL_REMINDER]: LIST_ACTION_SEND_ACH_INVITATION.SEND_BY_EMAIL.id,
    [TYPE_ACTION_ACCOUNT_ACH.REMINDER]: LIST_ACTION_SEND_ACH_INVITATION.SEND_BY_EMAIL_AND_SMS.id
};

export const STATUS_ACH = {
    PENDING: 1,
    VERIFY: 2
};

export const TYPE_MICRODEPOSITS_ACH = {
    DESCRIPTOR_CODE: 'descriptor_code',
    MICRODEPOSITS: 'microdeposits'
};

export const TYPE_MICRODEPOSITS_ACH_VERIFY = {
    [TYPE_MICRODEPOSITS_ACH.MICRODEPOSITS]: 1,
    [TYPE_MICRODEPOSITS_ACH.DESCRIPTOR_CODE]: 2
};

export const TYPE_CARD_ID = {
    [SPECIAL_PAYMENT_CARD.STRIPE]: 1,
    [SPECIAL_PAYMENT_CARD.ACH]: 2,
    [SPECIAL_PAYMENT_CARD.SQUARE]: 3,
    [SPECIAL_PAYMENT_CARD.SQUARE_API]: 3
};

export const TYPE_CARD_US_BANK = 'us_bank_account';

export const TYPE_CONFIRM_SCA = {
    [TYPE_CARD_US_BANK]: 'confirmUsBankAccountPayment',
    DEFAULT: 'confirmCardPayment'
};

export const TYPE_EMAIL_ACH = {
    INVITE: 16,
    VERIFY: 17
};

export const NAME = 'name';
export const ADDRESS = 'address';
export const CUSTOMER_LOCATION_SEARCH_KEY = [NAME, ADDRESS];
