import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GdConfirm from 'app/components/confirm';
import { COMMON, DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_TABLE_ITEM_TYPE, USER_DELETED_ID } from 'app/const/App';
import { JOB_EXCLUDE, NEW_JOB_REPORT, REPORT_TABLE } from 'app/const/Job';
import { ITEM, JOB_STATUS, getAllOptionsFilter, getDataFilter } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import FilterDropdown from 'app/modules/report/components/FilterDropdown';
import { convertParamsJobStatus, getListJobStatus } from 'common/utils/JobStatusUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

const FilterReport = ({
    isPermission = false,
    reportType = '',
    filters = [],
    tab = '',
    companyUsers = [],
    forceRerender = false,
    isShowAvatar = false,
    isShowUserDeleted = false,
    isMapDropdownTagByName = false,
    isFirstTimeAccess = false,
    onSelect = () => {}
}) => {
    const { t } = useTranslation(['report', 'common']);
    const dispatch = useDispatch();
    const { SELECTED, COLUMNS, PAYMENT_FOR, BANK_ACH, CUSTOMER_ID } = COMMON;
    const isFirstTime = useRef({ current: true });
    const oldMode = useRef('');
    const { smsplivo, mdu_tracking, quickbooks, stripe } = useSelector(({ auth }) => auth?.user?.settings?.addons);
    const isHaveACH = !!stripe?.ach;
    const refConfirm = useRef(null);

    const listFilter = useMemo(() => {
        const objectTemp = {};
        // define list filter v2-dropdown depends filters props and fn getDataFilter
        filters.map((item) => {
            objectTemp[item] = getDataFilter(reportType, item);
        });
        return objectTemp;
    }, [filters]);

    const [stateFilter, setStateFilter] = useState(listFilter);

    useEffect(() => {
        // did mount --> set isFirstTime = false
        setParamsReport(1);
        isFirstTime.current = false;
    }, []);

    useEffect(() => {
        setParamsReport(2);
    }, [stateFilter]);

    useEffect(() => {
        // is not first time --> execute (prevent did mount)
        !isFirstTime.current && setParamsReport(3);
        oldMode.current = '';
    }, [tab, forceRerender]);

    const setParamsReport = (type) => {
        let params = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType)) || getDefaultParams(reportType);

        if (isPermission) {
            params['columns'] = params['columns'].filter((item) => {
                if (!quickbooks && (item === 'qb_sync' || item === 'qb_id')) return false;
                if (!mdu_tracking && item === 'units') return false;
                if (!smsplivo && item === 'sms') return false;
                if (!isHaveACH && item === BANK_ACH) return false;
                return true;
            });
        }

        switch (type) {
            // mount: get local --> update filter --> call api
            case 1: {
                const newStateFilter = { ...stateFilter };
                filters.map((item) => {
                    newStateFilter[item].selected = params[item];
                });
                setStateFilter(newStateFilter);
                return null;
            }

            // update: get state --> call api --> update local storage
            case 2: {
                filters.map((item) => {
                    const selected = stateFilter[item]?.selected;
                    params = {
                        ...params,
                        [item]: selected || (!isNaN(selected) && selected !== '') ? selected : DEFAULT_ALL
                    };
                });

                if (oldMode.current && oldMode.current !== 'columns') {
                    params = {
                        ...params,
                        currentPage: 1
                    };
                }

                setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), { ...params });

                !isFirstTime.current && onSelect(params, oldMode.current);
                return null;
            }

            // change tab: define filter + get local --> update state --> call api
            case 3: {
                const objectTemp = {};
                filters.map((item) => {
                    objectTemp[item] = {
                        ...getDataFilter(reportType, item),
                        selected: params[item],
                        forceRerender
                    };
                });
                setStateFilter(objectTemp);
                return null;
            }

            default:
                return null;
        }
    };

    const getListOption = ({ item, params, callBack }) => {
        const currentItem = stateFilter[item] || {};
        const propsDefault = { item, callBack };

        !currentItem.isFetched &&
            dispatch(
                currentItem.action(
                    params || currentItem['params'],
                    ({ data }) => _actionSuccess({ data, ...propsDefault }),
                    ({ message }) => _actionFailure({ message, ...propsDefault })
                )
            );
    };

    const _handleCallBack = ({ callBack = null }) => {
        if (!callBack) return;
        callBack();
    };

    const _actionSuccess = ({ data = [], item = {}, callBack = null }) => {
        const dataResponse = [...data];
        const finalData = isMapDropdownTagByName
            ? dataResponse.map((item) => ({ ...item, id: item?.name || '' }))
            : dataResponse;
        _handleCallBack({ callBack });
        oldMode.current = '';
        setStateFilter((prevState) => {
            const oldData = prevState[item];
            if (oldData?.isFetched) return prevState;

            const responseOptions = finalData;
            let nextOptionsFilter = oldData?.options;
            if (responseOptions) {
                nextOptionsFilter = nextOptionsFilter?.concat(responseOptions);
            }

            return { ...prevState, [item]: { ...prevState[item], options: nextOptionsFilter, isFetched: true } };
        });
    };

    const _actionFailure = ({ message, item = {}, callBack = null }) => {
        _handleCallBack({ callBack });
        refConfirm.current?.open(null, message);
        setStateFilter((prevState) => ({
            ...prevState,
            [item]: { ...prevState[item], options: [], isFetched: false }
        }));
    };

    const handleChangeFilter = (value, selected) => {
        isFirstTime.current = false;
        oldMode.current = value;
        setStateFilter((prev) => {
            const newState = { ...prev };

            if (selected === DEFAULT_ALL) {
                switch (value) {
                    case COLUMNS:
                        newState[COLUMNS].selected = newState[COLUMNS].options.map((column) => column.value);
                        break;
                    case PAYMENT_FOR:
                        newState[PAYMENT_FOR].selected = DEFAULT_ALL;
                        break;
                    case CUSTOMER_ID:
                        newState[value].selected = DEFAULT_ALL;
                        break;
                    default:
                        newState[value][SELECTED] = getAllOptionsFilter(reportType, value);
                        break;
                }
            } else {
                newState[value][SELECTED] = selected;
            }

            return newState;
        });
    };

    const dataFilter = useMemo(() => {
        const newData = { ...stateFilter };

        filters.forEach((item) => {
            const itemFilter = stateFilter[item];
            if (!itemFilter) return null;
            newData[item]['title'] = t(`report:${itemFilter['title']}`);
            newData[item]['titleSelectAllOption'] = t(`report:${itemFilter['titleSelectAllOption']}`);
            newData[item]['onSelect'] = handleChangeFilter;
            newData[item]['onGetAsyncOptions'] = itemFilter.action
                ? getListOption
                : () => {
                      return true;
                  };
            if (item === 'staffs') {
                const data = companyUsers.map(({ id, first_name, last_name, avatar }) => ({
                    id: id,
                    name: first_name.concat(' ', last_name),
                    value: id,
                    avatar: isShowAvatar ? avatar : null
                }));

                if (isShowUserDeleted) {
                    data.push({ id: USER_DELETED_ID, name: t('report:user_deleted'), value: USER_DELETED_ID });
                    if (reportType === REPORT_TYPE.WORK_LOG && isFirstTimeAccess) {
                        newData[item]['selected'] = data.reduce(
                            (acc, currentItem) => (currentItem.id !== USER_DELETED_ID ? [...acc, currentItem.id] : acc),
                            []
                        );
                    }
                }

                newData[item]['options'] = data;
                newData[item]['isShowAvatar'] = isShowAvatar;
            }

            if (
                item === 'columns' &&
                (reportType === REPORT_TYPE.LOCATION_CUSTOMER_LIST || reportType === REPORT_TYPE.CUSTOMERS)
            ) {
                const columns = [];

                newData[item]['options'].forEach((item) => {
                    if (!smsplivo && item.id === LIST_TABLE_ITEM_TYPE.SMS) return;
                    if (!mdu_tracking && item.id === LIST_TABLE_ITEM_TYPE.UNITS) return;
                    if (
                        !quickbooks &&
                        (item.id === LIST_TABLE_ITEM_TYPE.QB_SYNC_CREDIT ||
                            item.id === LIST_TABLE_ITEM_TYPE.QB_ID_COLUMN)
                    )
                        return;
                    if (!isHaveACH && item.id === BANK_ACH) return;
                    columns.push(item);
                });

                newData[item]['options'] = columns;
            }

            if (reportType === REPORT_TYPE.REVENUE_BY_ITEM && item === ITEM) {
                newData[item]['isShowMerchant'] = true;
                newData[item]['isShowDeposit'] = true;
            }

            if (item === JOB_STATUS) {
                newData[item]['options'] = getListJobStatus({
                    filter: JOB_EXCLUDE[reportType === REPORT_TYPE.NEW_JOB ? NEW_JOB_REPORT : REPORT_TABLE],
                    isIncludeDeleted: true
                });
                newData[item]['selected'] = convertParamsJobStatus(newData[item]['selected']);
            }

            newData[item]['classWrapper'] = `header-items has-bg-blue ${newData[item]['className'] || ''}`;
        });

        return newData;
    }, [stateFilter, companyUsers]);

    function _renderFilter() {
        return filters.map((item) => {
            return (
                <FilterDropdown
                    key={item}
                    {...dataFilter[item]}
                    reportType={reportType}
                    isPopper
                    isUseToggle
                    isJobStatus={item === JOB_STATUS}
                />
            );
        });
    }

    return (
        <Fragment>
            {_renderFilter()}
            <GdConfirm
                ref={refConfirm}
                title={t('common:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('common:confirm')}
            />
        </Fragment>
    );
};

export default FilterReport;
