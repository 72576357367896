import React, { Suspense, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSendTestEmail from 'app/components/button/ButtonSendTestEmail';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GdRichText from 'app/components/richtext';
import { PRESIGN_TYPES } from 'app/const/App';
import {
    getVariableCompany,
    getVariableTechUser,
    handleGetEmailTemplateVariable,
    ID_CONTENT_PREVIEW,
    ID_TITLE_PREVIEW,
    TYPE_ATTACHMENTS_TEMPLATE,
    TYPE_TEMPLATE_ID
} from 'app/const/Settings';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ButtonPreview from 'app/modules/settings/templates/components/ButtonPreview';
import IconEmail from 'assets/icon/IconEmail';
import IconSent from 'assets/icon/IconSent';
import { handlePreviewTemplate } from '../../utils';
import { getEmailRichTextSetup } from '../constant';
import { useSettingTemplate } from '../SettingTemplateContext';

// lazy import attachments component
const Attachments = React.lazy(() => import('./Attachments'));
const BoxEmailContent = () => {
    const { t } = useTranslation();
    const {
        template,
        idTypeTemplate,
        currentReminder,
        activeIndex,
        typePreviewing,
        onGetCurrentData,
        onUpdateDataReminder
    } = useSettingTemplate();
    const { company, settings, profile } = useSelector(({ auth }) => auth.user);
    const refRichtext = useRef(null);
    const refAlert = useRef(null);
    const refSubject = useRef(null);
    const refPlainText = useRef(null);
    const refSubjectFocus = useRef(false);

    useLayoutEffect(() => {
        const dataCurrentReminder = onGetCurrentData();
        const isCurrentPlainText = dataCurrentReminder?.is_plain_text || false;
        if (refSubject.current) {
            refSubject.current.value = dataCurrentReminder.subject;
            _handleChangeContent(refSubject.current.value, true);
        }
        if (refRichtext.current) {
            const dataEmail = isCurrentPlainText
                ? dataCurrentReminder?.plain_email_content || ''
                : dataCurrentReminder?.email_content || '';
            refRichtext.current.setValue(dataEmail, isCurrentPlainText);
        }
        if (refPlainText.current) refPlainText.current.setIsActive(isCurrentPlainText);
    }, [activeIndex, typePreviewing]);

    const _handleChangeContent = (value, isTitle = false) => {
        const content = _handleGetContent(value);
        const dataCurrentReminder = onGetCurrentData();
        const isPlainText = dataCurrentReminder.is_plain_text || false;
        const isPreviewEmail = typePreviewing && typePreviewing === 'email';

        if (!isTitle) {
            if (isPreviewEmail) {
                const titleDiv = document.getElementById(ID_CONTENT_PREVIEW);
                if (titleDiv) titleDiv.innerHTML = content;
            }
            onUpdateDataReminder(isPlainText ? 'plain_email_content' : 'email_content', value);
        } else {
            if (isPreviewEmail) {
                const titleDiv = document.getElementById(ID_TITLE_PREVIEW);
                if (titleDiv) titleDiv.innerHTML = `${t('jobDetail:subject')}: ${content}`;
            }
            onUpdateDataReminder('subject', value);
        }
    };

    const _handleGetCurrentFocus = () => {
        return refSubjectFocus.current;
    };

    const _handleSubjectFocus = () => {
        refSubjectFocus.current = true;
    };

    const _handleGetDataTemplate = () => {
        const attachments = onGetCurrentData()?.attachments || [];
        return {
            subject: refSubject.current.value,
            email_content: refRichtext.current.getValue(),
            attachments: attachments.map((item) => item.key)
        };
    };

    const _handleSendTestMailSuccess = (message) => {
        refAlert.current.showStatusBar({ id: 'success-send', message, type: LIST_STATUS.SUCCESS });
    };

    const _handleGetContent = (value) => {
        const { review_engine, time_window } = settings.addons;
        return handlePreviewTemplate(
            value,
            getVariableCompany(company),
            handleGetEmailTemplateVariable(review_engine, time_window, company),
            getVariableTechUser(profile)
        );
    };

    const _handleInsertVariable = (value) => {
        const input = refSubject.current;
        //IE support
        if (document.selection) {
            input.focus();
            const sel = document.selection.createRange();
            sel.text = value;
        }
        //MOZILLA and others
        else if (input.selectionStart || input.selectionStart === '0') {
            var startPos = input.selectionStart;
            var endPos = input.selectionEnd;
            input.value =
                input.value.substring(0, startPos) + value + input.value.substring(endPos, input.value.length);
        } else {
            input.value += value;
        }

        const titleDiv = document.getElementById(ID_TITLE_PREVIEW);
        if (titleDiv) titleDiv.innerHTML = _handleGetContent(input.value);
        refSubject.current.focus();
        onUpdateDataReminder('subject', input.value);
    };

    const _handleRichtextFocus = () => {
        refSubjectFocus.current = false;
    };

    const _handlePlainText = (isPlainText) => {
        onUpdateDataReminder('is_plain_text', isPlainText);
        refRichtext.current.plainText(!isPlainText);
    };

    const _handleAttachFiles = (attachment) => {
        const currentData = onGetCurrentData();
        onUpdateDataReminder('attachments', [...(currentData?.attachments || []), attachment]);
    };

    const _handleRemoveAttach = (idAttach) => {
        const currentData = onGetCurrentData();
        onUpdateDataReminder(
            'attachments',
            currentData?.attachments.filter((item) => (item.object_key || item.key) !== idAttach)
        );
    };

    const _handleInsertImg = (dataImg) => {
        const currentData = onGetCurrentData();
        onUpdateDataReminder('attachments', [
            ...(currentData?.attachments || []),
            { ...dataImg, type: TYPE_ATTACHMENTS_TEMPLATE.INSERTED }
        ]);
    };

    const currentAttachments = useMemo(() => {
        const currentData = onGetCurrentData();
        return currentData?.attachments?.filter((item) => item.type === TYPE_ATTACHMENTS_TEMPLATE.ATTACHED) || [];
    }, [idTypeTemplate]);

    return (
        <>
            <AlertCustomer ref={refAlert} />
            <div className="content-insert-variable">
                <div className="box-use-variable">
                    <div className="box-header flex-betweenitems mb-1 flex-wrap row-gap-8">
                        <div className="box-header__left flexcenter flex-1">
                            <div className="title flexcenter svg-email-blue">
                                <IconEmail isEmailAddress />
                                {t('email')}
                            </div>
                            <GdSwitchCheckbox
                                ref={refPlainText}
                                wrapClassName="switch large dots"
                                textUnChecked={t('setting:plan_text')}
                                textChecked={t('setting:plan_text')}
                                onChangeImmediately={_handlePlainText}
                            />
                        </div>
                        <ButtonSendTestEmail
                            className="v2-btn-default has-icon"
                            icon={<IconSent isSendMail />}
                            onGetDataEmail={_handleGetDataTemplate}
                            onSendSuccess={_handleSendTestMailSuccess}
                        />
                        <ButtonPreview typePreview="email" />
                    </div>

                    <div className="box-subject mb-3">
                        <p className="title-sm">{t('subject_line')}</p>
                        <input
                            ref={refSubject}
                            className="field-input no-effect"
                            type="text"
                            onFocus={_handleSubjectFocus}
                            onChange={(e) => _handleChangeContent(e.target.value, true)}
                            defaultValue={currentReminder.subject}
                        />
                    </div>

                    <div className="wrap-content">
                        <p className="title-sm">{t('email_body')}</p>
                        <GdRichText
                            ref={refRichtext}
                            shouldGetFocus
                            valueRestore={currentReminder.default_email_content}
                            defaultContent={currentReminder.email_content}
                            typePresign={PRESIGN_TYPES.EMAIL_INBOX}
                            toolbarConfig={getEmailRichTextSetup(template, idTypeTemplate)}
                            onInsertImgSuccess={_handleInsertImg}
                            onInsertVariable={_handleInsertVariable}
                            onGetFocus={_handleGetCurrentFocus}
                            onFocus={_handleRichtextFocus}
                            onChange={_handleChangeContent}
                        />
                    </div>

                    {idTypeTemplate === TYPE_TEMPLATE_ID.EMAIL_INBOX ? (
                        <Suspense fallback={null}>
                            <Attachments
                                data={currentAttachments}
                                onRemove={_handleRemoveAttach}
                                onAttachFiles={_handleAttachFiles}
                            />
                        </Suspense>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default BoxEmailContent;
