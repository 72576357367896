import React from 'react';

const LoadingSettings = ({ isPortalAI = false }) => {
    return (
        <div className="wrap-loading box-setting">
            <div className="flex-column gap-16">
                <div className="flexcenter">
                    <div className="flex-1">
                        <div className="avt-img --large loading"></div>
                    </div>
                    <div className="flexcenter">
                        <div className="v2-btn-default --grey loading btn-md">
                            <div className="loading --animation --line --full"></div>
                        </div>
                        <div className="v2-btn-default bg-white-cultured --icon-lg --transparent loading ml-2"></div>
                    </div>
                </div>
                {isPortalAI ? null : (
                    <div className="flex-column gap-5 mt-1">
                        <div className="loading --animation --line --onefifth" />
                        <div className="v2-btn-default --grey loading w-100" />
                    </div>
                )}
            </div>
            {isPortalAI ? (
                <div className="setting-portal flex-column gap-16">
                    <div className="line">
                        <div className="txt">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="field">
                            <div className="v2-dropdown v2-btn-default --grey loading --full ml-0">
                                <div className="loading --animation --line --fourth"></div>
                            </div>
                        </div>
                    </div>
                    <div className="line">
                        <div className="txt">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="field">
                            <div className="loading v2-btn-default --grey --half">
                                <div className="loading --animation --line --threefourth"></div>
                                <div className="loading --animation --line --threefourth mt-0 ml-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className="line">
                        <div className="txt">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="field">
                            <div className="v2-dropdown v2-btn-default --grey loading --full ml-0">
                                <div className="loading --animation --line --fourth"></div>
                            </div>
                        </div>
                    </div>
                    <div className="line">
                        <div className="txt">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="field">
                            <div className="v2-dropdown v2-btn-default --grey loading --full ml-0">
                                <div className="loading --animation --line --fourth"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex-column gap-10 box-setting__container">
                    <div className="loading --animation --line --onefourth" />
                    <div className="flex-betweenitems my-2">
                        <div className="loading --animation --line --half" />
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
            )}
            {isPortalAI ? null : (
                <div className="container-print">
                    <div className="wrap-tables flex-column relative">
                        <div className="tables table-multi-column table-setting scrolls-x">
                            <div className="rows --fixed --header py-1">
                                <div className="col col-day">
                                    <div className="loading --animation --line --threefourth --light"></div>
                                </div>
                                <div className="col col-day">
                                    <div className="loading --animation --line --threefourth --light"></div>
                                </div>
                                <div className="col col-lg">
                                    <div className="loading --animation --line --half --light"></div>
                                </div>
                                <div className="col col-lg">
                                    <div className="loading --animation --line --onefourth --light"></div>
                                </div>
                            </div>
                            <div className="tables-list">
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="loading --animation --line --half"></div>
                                    </div>
                                    <div className="col col-lg"></div>
                                </div>
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="v2-btn-default loading w-100 --grey btn-plus">
                                            <div className="loading --animation --line --full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-day">
                                        <div className="loading --animation --line --threefourth"></div>
                                    </div>
                                    <div className="col col-lg">
                                        <div className="loading --animation --line --half"></div>
                                    </div>
                                    <div className="col col-lg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="d-flex justify-end">
                <div className="v2-btn-default loading btn-x-sm --grey">
                    <div className="loading --animation --line --full"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingSettings;
