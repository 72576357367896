import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import IconCogWheel from 'assets/icon/IconCogWheel';
import AddonsChatbotFooter from '../chatbot/components/AddonsChatbotFooter';
import FooterManageDocument from '../documents/components/manage/FooterManageDocument';
import AddonsJobStatusOptionsFooter from '../jobStatus/components/AddonsJobStatusOptionsFooter';
import AddonsOptionsFooterMultiTracking from '../multiUnit/components/AddonsOptionsFooterMultiTracking';
import OpportunityAddonsOptionsFooter from '../opportunity/components/OpportunityAddonsOptionsFooter';
import { VOIPAddonsOptionsFooter } from '../voip/components/VOIPAddonsOptionsFooter';
import AddonFooterWithManageUser from './AddonFooterWithManageUser';
import AddonsOptionsFooterBlockedNumbers from './AddonsOptionsFooterBlockedNumbers';
import AddonsOptionsFooterReview from './AddonsOptionsFooterReview';
import AddonsOptionsFooterSMS from './AddonsOptionsFooterSMS';
import AdvanceRoutingFooter from './AdvanceRoutingFooter';
import CustomFieldsFooter from './CustomFieldsFooter';
import FooterSubscription from './FooterSubscription';
import SurchargeAddonsManager from './SurchargeAddonsManager';
import FooterManageServicePlan from './FooterManageServicePlan';
import FooterManageEstimate from './FooterManageEstimate';
import AddonsFooterPortalWidget from './AddonsFooterPortalWidget';
import AddonsFooterSentricon from '../device/sentricon/AddonsFooterSentricon';

const AddonsOptionsFooter = ({ keyword, handleManagerSuccess, isEnabled, ...props }) => {
    switch (keyword) {
        case ADDONS_FOOTER_OPTIONS.STRIPE_SURCHARGE:
        case ADDONS_FOOTER_OPTIONS.STRIPE_ACH:
        case ADDONS_FOOTER_OPTIONS.SQUARE_SURCHARGE:
            return <SurchargeOptions {...props} keyword={props?.items?.[0]?.keyword || keyword} />;
        case ADDONS_FOOTER_OPTIONS.SERVICE_REVIEW:
        case ADDONS_FOOTER_OPTIONS.LIKELY_SERVICE:
            return (
                <AddonsOptionsFooterReview
                    keyword={keyword}
                    handleManagerSuccess={handleManagerSuccess}
                    isEnabled={isEnabled}
                    {...props}
                />
            );
        case ADDONS_FOOTER_OPTIONS.MULTI_UNIT:
            return <AddonsOptionsFooterMultiTracking isEnabled={isEnabled} status {...props} />;
        case ADDONS_FOOTER_OPTIONS.SUBSCRIPTION:
            return <FooterSubscription isEnabled={isEnabled} status {...props} />;
        case ADDONS_FOOTER_OPTIONS.SQUARE_SUBSCRIPTION:
            return <FooterSubscription isSquare isEnabled={isEnabled} status {...props} />;
        case ADDONS_FOOTER_OPTIONS.VOIP_AFTER_HOURS:
        case ADDONS_FOOTER_OPTIONS.VOIP_PERSONAL:
        case ADDONS_FOOTER_OPTIONS.VOIP_GROUP:
        case ADDONS_FOOTER_OPTIONS.VOIP_ACTIVE_USER:
        case ADDONS_FOOTER_OPTIONS.VOIP_AUTO_ATTENDANT:
        case ADDONS_FOOTER_OPTIONS.VOIP_HOLD_MUSIC:
        case ADDONS_FOOTER_OPTIONS.VOIP_VOICEMAIL_DROP:
            return <VOIPAddonsOptionsFooter keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.OPPORTUNITY_MANAGE:
        case ADDONS_FOOTER_OPTIONS.OPPORTUNITY_PERMISSION:
            return <OpportunityAddonsOptionsFooter keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.JOB_STATUS_MANAGE:
        case ADDONS_FOOTER_OPTIONS.JOB_STATUS_PERMISSION:
            return <AddonsJobStatusOptionsFooter keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.SMS_BLOCKED_NUMBERS:
        case ADDONS_FOOTER_OPTIONS.VOIP_BLOCKED_NUMBERS:
            return <AddonsOptionsFooterBlockedNumbers keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.SMS_AFTER_HOURS:
            return <AddonsOptionsFooterSMS isEnabled={isEnabled} status={status} {...props} />;
        case ADDONS_FOOTER_OPTIONS.CUSTOM_FIELDS:
        case ADDONS_FOOTER_OPTIONS.PERMISSIONS:
            return <CustomFieldsFooter keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.BEST_AVAILABLE_TIME:
        case ADDONS_FOOTER_OPTIONS.JOB_MAGNET:
            return <AdvanceRoutingFooter keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.DOCUMENT_LIBRARY:
            return <FooterManageDocument keyword={keyword} isEnabled={isEnabled} {...props} />;
        case ADDONS_FOOTER_OPTIONS.MANAGE_DYNAMIC_ESTIMATE:
        case ADDONS_FOOTER_OPTIONS.MANAGE_ESTIMATE_PACKAGE:
            return <FooterManageEstimate keyword={keyword} />;
        case ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL:
        case ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS:
            return <AddonsChatbotFooter keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.SMART_VIEWS_PERMISSIONS:
            return <AddonFooterWithManageUser keyword={keyword} {...props} />;
        case ADDONS_FOOTER_OPTIONS.ADDONS_SERVICE_PLAN_MANAGE_TEMPLATES:
            return <FooterManageServicePlan {...props} />;
        case ADDONS_FOOTER_OPTIONS.SNEAKY_WIDGET:
            return <AddonsFooterPortalWidget />;
        case ADDONS_FOOTER_OPTIONS.SENTRICON:
            return <AddonsFooterSentricon isEnabled={isEnabled} {...props} />;
        default:
            return null;
    }
};

const SurchargeOptions = ({ keyword, upgrade = 0, onDisplayAlert = () => {} }) => {
    const refManager = useRef(null);
    const { t } = useTranslation(['addons']);
    const isDisplayManage = !!!upgrade;

    const _handleOpenManager = () => {
        refManager.current._open();
    };

    return (
        <>
            <div className="boxs__footer --multi">
                <p className="grey-extrlight txt-ellipsis">{t('addons:review_your_state')}</p>
                {isDisplayManage && (
                    <div className="v2-btn-default svg-purple --purple --icon-r btn-modal" onClick={_handleOpenManager}>
                        {t('addons:manage')}
                        <IconCogWheel />
                    </div>
                )}
            </div>
            {isDisplayManage && (
                <SurchargeAddonsManager ref={refManager} keyword={keyword} onDisplayAlert={onDisplayAlert} />
            )}
        </>
    );
};

export default AddonsOptionsFooter;
