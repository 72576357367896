import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { getTabsPeriod } from 'app/modules/jobdetail/const';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import WeeklyRepeat from 'app/modules/jobdetail/tabs/settingschedule/repeat/WeeklyRepeat';

const RepeatWeekly = () => {
    const { t } = useTranslation();
    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);

    const invoiceRepeatCurrent = invoiceRepeatData.offset;
    const weeklyInterval = invoiceRepeatCurrent.interval;
    const tabsPeriod = getTabsPeriod();
    const finalTabActive =
        weeklyInterval === tabsPeriod.MODE_1_3 || weeklyInterval === tabsPeriod.MODE_2_4
            ? weeklyInterval
            : tabsPeriod.MODE_EVERY;
    const isTab_1_3 = finalTabActive === tabsPeriod.MODE_1_3;
    const isTab_2_3 = finalTabActive === tabsPeriod.MODE_2_4;
    const isTabWeekly = finalTabActive === tabsPeriod.MODE_EVERY;

    function _changeTab(e, value) {
        e.stopPropagation();

        _handleChangeValue(value === tabsPeriod.MODE_EVERY ? 1 : value);
    }
    const days = {
        MO: invoiceRepeatCurrent.MO,
        TU: invoiceRepeatCurrent.TU,
        WE: invoiceRepeatCurrent.WE,
        TH: invoiceRepeatCurrent.TH,
        FR: invoiceRepeatCurrent.FR,
        SA: invoiceRepeatCurrent.SA,
        SU: invoiceRepeatCurrent.SU
    };

    function _handleChangeValue(interval) {
        _prepareData(interval, days);
    }

    function _prepareData(interval, days) {
        const invoiceRepeatDetail = invoiceRepeatData.offset;

        const refValue = {
            ...invoiceRepeatData,
            offset: {
                ...invoiceRepeatDetail,
                ...days,
                interval: interval
            }
        };
        _sendDataToContext(refValue);
    }

    function _sendDataToContext(data) {
        updateInvoiRepeatContext(data);
    }

    function _handleChangeWeekDay(days) {
        _prepareData(weeklyInterval, days);
    }

    return (
        <div className="tab-panel active">
            <div className="rows mt-3">
                <div className="txt pl-6"> {t('smartView:period')} </div>
                <div className="detail d-flex">
                    <div className="tabs">
                        <div className="btn-item m-0">
                            <button
                                onClick={(e) => _changeTab(e, tabsPeriod.MODE_1_3)}
                                className={`tab-items ${isTab_1_3 ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                {t('jobDetail:1st_3rd')}
                            </button>
                            <button
                                onClick={(e) => _changeTab(e, tabsPeriod.MODE_2_4)}
                                className={`tab-items ${isTab_2_3 ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                {t('jobDetail:2nd_4th')}
                            </button>
                            <button
                                onClick={(e) => _changeTab(e, tabsPeriod.MODE_EVERY)}
                                className={`tab-items ${isTabWeekly ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                {t('jobDetail:every')}
                            </button>
                        </div>
                    </div>

                    {isTabWeekly && (
                        <div className="field-every ml-2">
                            <div className="tab-conts active">
                                <div className="d-flex align-center">
                                    <FormInterval interval={weeklyInterval} onChange={_handleChangeValue} />
                                    <span className="text-last">{t('jobDetail:weeks')}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <WeeklyRepeat {...days} onChange={_handleChangeWeekDay} />
        </div>
    );
};

export default RepeatWeekly;
