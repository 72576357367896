import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { ADDONS_SERVICE_PLAN, SETTINGS_SERVICE_PLAN } from 'app/config/routes';
import { SETTINGS_GET_LIST_SERVICE_PLAN, getServicePlanDetail } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { NEVER, PENDING_SERVICE_KEY, SERVICE_PLAN_DELAY_TYPE_OPTIONS, VALIDATE_SERVICE_PLAN } from 'app/const/Settings';
import ErrorPage from 'app/modules/error';
import { TABS_REPEAT } from 'app/modules/jobdetail/const';
import IconPlus from 'assets/icon/IconPlus';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { isActiveFeature } from 'common/utils/PermissionUtils';
import MainHeaderSettings from '../components/MainHeaderSettings';
import ItemService from './ItemService';
import ListService from './ListService';
import LoadingServicePlanDetail from './LoadingServicePlanDetail';
import Summary from './Summary';

const SettingAddServicePlan = () => {
    const { t } = useTranslation('setting');
    const servicePlanAddon = useSelector(({ auth }) => auth.user.settings.addons.service_plan);
    const { id: servicePlanId } = useParams();
    const history = useHistory();
    const [state, dispatchState] = useReducer(reducer, {
        serviceName: '',
        serviceSelected: { plan_item_id: uuidv4() },
        pendingServices: [],
        isLoading: false,
        summary: []
    });
    const {
        pendingServices: finalPendingServices,
        isLoading: finalIsLoading,
        serviceSelected: finalServiceSelected,
        serviceName: finalServiceName,
        summary
    } = state;
    const { plan_item_id: finalPlanItemId, id: finalServiceSelectedId } = finalServiceSelected;
    const refServicePlanName = useRef(null);
    const refButtonSave = useRef(null);
    const refService = useRef(null);
    const refStatusBar = useRef(null);

    const havePermission = !!servicePlanAddon && isActiveFeature(ADDONS_SERVICE_PLAN);

    useEffect(() => {
        if (!havePermission) return;
        if (servicePlanId) _getServiceDetail();
    }, [servicePlanId]);

    const _getServiceDetail = () => {
        dispatchState((prev) => ({ ...prev, isLoading: true }));
        const _handleSuccess = ({ data }) => {
            const { name, services = [], summary } = data || {};
            let serviceSelected = {};
            let pendingServices = [];
            let planItemOptions = [];
            services.forEach((item, index) => {
                const { id, name, delay, plan_item_id, trigger_plan_item_id } = item || {};
                const isNotInfiniteService = _checkNotInfiniteService(item.recurrence);
                if (!delay) {
                    serviceSelected = { ...item, isNotInfiniteService };
                } else {
                    pendingServices = [
                        ...pendingServices,
                        {
                            ...item,
                            service_id: id,
                            serviceTrigger:
                                planItemOptions.find((planItem) => planItem.plan_item_id === trigger_plan_item_id) ||
                                {},
                            triggerPlanItemOptions: [...planItemOptions],
                            isNotInfiniteService
                        }
                    ];
                }
                if (index !== services.length - 1) {
                    planItemOptions = [...planItemOptions, { id, plan_item_id, name, isNotInfiniteService }];
                }
            });
            dispatchState((prev) => ({
                ...prev,
                serviceName: name || '',
                serviceSelected,
                pendingServices,
                isLoading: false,
                summary: summary || []
            }));
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'get_detail_wrong', message, refAlert: refStatusBar });
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(getServicePlanDetail(servicePlanId), { data: {}, method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleAddService = () => {
        dispatchState((prev) => {
            const { pendingServices: prevPendingServices } = prev;
            return {
                ...prev,
                pendingServices: [
                    ...prevPendingServices,
                    {
                        id: Date.now(),
                        delay: { value: 1, type: SERVICE_PLAN_DELAY_TYPE_OPTIONS[0].id },
                        service_id: 0,
                        plan_item_id: uuidv4(),
                        trigger_plan_item_id: '',
                        triggerPlanItemOptions: [{ ...prev.serviceSelected }, ...prevPendingServices],
                        serviceTrigger: {},
                        name: '',
                        isNotInfiniteService: false
                    }
                ]
            };
        });
    };

    const _getErrorMessage = ({ name = '' }) => {
        return t('customers:cannot_be_blank', { name: t(name) });
    };

    const _scrollIntoViewAndActiveElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'auto', block: 'center' });
            element.classList.add('active');
        }
    };

    const _handleSave = () => {
        const planName = refServicePlanName.current?.value?.trim();
        const serviceId = refService.current?._getValue();
        const invalidService = finalPendingServices.find(
            ({ service_id, trigger_plan_item_id }) => !service_id || !trigger_plan_item_id
        );
        const msgError = [];
        if (!planName) {
            msgError.push(_getErrorMessage({ name: VALIDATE_SERVICE_PLAN.PLAN_NAME }));
            refServicePlanName.current && refServicePlanName.current.focus();
        }
        if (!serviceId || !finalPlanItemId) {
            msgError.push(_getErrorMessage({ name: VALIDATE_SERVICE_PLAN.SERVICE }));
            _scrollIntoViewAndActiveElement(`main_service_${finalPlanItemId}`);
        }
        if (invalidService) {
            msgError.push(_getErrorMessage({ name: VALIDATE_SERVICE_PLAN.PENDING_SERVICES }));
            const { service_id, trigger_plan_item_id, plan_item_id } = invalidService;
            if (!service_id) _scrollIntoViewAndActiveElement(`pending_service_${plan_item_id}`);
            if (!trigger_plan_item_id) _scrollIntoViewAndActiveElement(`trigger_service_${plan_item_id}`);
        }
        if (!!msgError.length) {
            showStatusBar({ id: 'validate_error', message: msgError, refAlert: refStatusBar });
            _removeLoading();
            return;
        }

        const _handleSuccess = () => {
            history.push(addBranchPath(SETTINGS_SERVICE_PLAN));
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'save_fail', message, refAlert: refStatusBar });
            _removeLoading();
        };
        clientQuery(
            SETTINGS_GET_LIST_SERVICE_PLAN,
            {
                data: {
                    id: servicePlanId || undefined,
                    name: planName,
                    service_id: serviceId,
                    plan_item_id: finalPlanItemId,
                    pending_services: finalPendingServices.map((item) => ({
                        delay: item.delay,
                        service_id: item.service_id,
                        plan_item_id: item.plan_item_id,
                        trigger_plan_item_id: item.trigger_plan_item_id
                    }))
                },
                method: servicePlanId ? 'PUT' : 'POST',
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleDeletePendingService = (value) => {
        dispatchState((prev) => {
            return {
                ...prev,
                pendingServices: prev.pendingServices
                    .filter(({ plan_item_id }) => plan_item_id !== value)
                    .map((service) => {
                        const { triggerPlanItemOptions, trigger_plan_item_id: finalTriggerPlanItemId } = service;

                        return {
                            ...service,
                            triggerPlanItemOptions: triggerPlanItemOptions.filter(
                                ({ plan_item_id }) => plan_item_id !== value
                            ),
                            trigger_plan_item_id: finalTriggerPlanItemId === value ? '' : finalTriggerPlanItemId
                        };
                    })
            };
        });
    };

    const _handleChangeDataPendingService = (id, value, keyUpdate) => {
        dispatchState((prev) => {
            const { pendingServices: prevPendingServices } = prev;
            const serviceUpdate = prevPendingServices.find(({ plan_item_id }) => plan_item_id === id) || {};
            const isUpdateServiceId = keyUpdate === PENDING_SERVICE_KEY.SERVICE_ID;
            if (isUpdateServiceId && serviceUpdate.service_id === value.id) return prev;

            return {
                ...prev,
                pendingServices: prevPendingServices.map((service, index) => {
                    const {
                        plan_item_id: finalPlanItemId,
                        delay,
                        trigger_plan_item_id: triggerPlanItemId,
                        triggerPlanItemOptions
                    } = service;

                    const isExistPlanItem = triggerPlanItemOptions.some(({ plan_item_id }) => plan_item_id === id);
                    if (finalPlanItemId === id) {
                        return isUpdateServiceId || keyUpdate === PENDING_SERVICE_KEY.TRIGGER_PLAN_ITEM_ID
                            ? {
                                  ...service,
                                  [keyUpdate]: isUpdateServiceId ? value.id : value.plan_item_id,
                                  name: isUpdateServiceId ? value.name : service.name,
                                  isNotInfiniteService: isUpdateServiceId
                                      ? _checkNotInfiniteService(value.recurrence)
                                      : service.isNotInfiniteService
                              }
                            : { ...service, delay: { ...delay, [keyUpdate]: value } };
                    }

                    if (isUpdateServiceId && (isExistPlanItem || index > prevPendingServices.indexOf(serviceUpdate))) {
                        const finalTriggerPlanItemId = id === triggerPlanItemId ? '' : triggerPlanItemId;
                        const planItemDetails = {
                            name: value.name,
                            isNotInfiniteService: _checkNotInfiniteService(value.recurrence)
                        };
                        if (isExistPlanItem) {
                            return {
                                ...service,
                                trigger_plan_item_id: finalTriggerPlanItemId,
                                triggerPlanItemOptions: triggerPlanItemOptions
                                    .filter(({ plan_item_id }) => !(plan_item_id === id && !value.id))
                                    .map((item) =>
                                        item.plan_item_id === id && !!value.id
                                            ? { ...item, id: value.id, service_id: value.id, ...planItemDetails }
                                            : item
                                    )
                            };
                        }

                        return {
                            ...service,
                            trigger_plan_item_id: finalTriggerPlanItemId,
                            ...planItemDetails,
                            triggerPlanItemOptions: [
                                ...triggerPlanItemOptions,
                                { id: value.id, service_id: value.id, plan_item_id: id, ...planItemDetails }
                            ]
                        };
                    }

                    return service;
                })
            };
        });
    };

    const _handleChangeService = (data = {}) => {
        if (finalServiceSelectedId === data.id) return;
        const planItemDetails = {
            id: data.id,
            name: data.name,
            isNotInfiniteService: _checkNotInfiniteService(data.recurrence)
        };
        dispatchState((prev) => ({
            ...prev,
            serviceSelected: { ...prev.serviceSelected, ...planItemDetails },
            pendingServices: prev.pendingServices.map((item) => ({
                ...item,
                triggerPlanItemOptions: item.triggerPlanItemOptions.map((item) =>
                    item.id === prev.serviceSelected.id ? { ...item, ...planItemDetails } : item
                ),
                trigger_plan_item_id:
                    item.trigger_plan_item_id === prev.serviceSelected.plan_item_id ? '' : item.trigger_plan_item_id
            }))
        }));
    };

    const _checkNotInfiniteService = (recurrence = {}) => {
        const recurrenceOffset = recurrence.offset;
        return (
            !recurrenceOffset || !(recurrenceOffset.frequency !== TABS_REPEAT.OFF && recurrenceOffset.ends === NEVER)
        );
    };

    if (!havePermission) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <Fragment>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <div className="contents-pages service-edit c-job-details --service-plan">
                    <div className="wrap-content-service wrapper-box-edit">
                        <div className="content-services">
                            <div className="content-services__right scrolls">
                                <StatusBar ref={refStatusBar} />
                                <div className="dashboard-job-detail">
                                    {finalIsLoading ? (
                                        <LoadingServicePlanDetail />
                                    ) : (
                                        <Fragment>
                                            <div className="container-setting-center">
                                                <div className="header-modal">
                                                    <h3 className="name-tabs">{t('service_plan')}</h3>
                                                </div>
                                                <div className="new-service schedule-wrapper form-tabpane-details">
                                                    <div className="details-job">
                                                        <div className="rows">
                                                            <div className="txt">
                                                                <IconServiceTemplate />
                                                                <span className="txt-ellipsis">{t('plan_name')}</span>
                                                            </div>
                                                            <div className="details">
                                                                <input
                                                                    ref={refServicePlanName}
                                                                    className="field-input"
                                                                    type="text"
                                                                    defaultValue={finalServiceName}
                                                                    placeholder={t('plan_name')}
                                                                    autoFocus
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="rows row-has-line border-top-border-color-grey">
                                                            <div className="txt">
                                                                <span className="txt-ellipsis">
                                                                    {t('select_your_initial_service')}
                                                                </span>
                                                            </div>
                                                            <div className="details">
                                                                <ListService
                                                                    id={`main_service_${finalPlanItemId}`}
                                                                    ref={refService}
                                                                    serviceSelected={finalServiceSelected}
                                                                    onChangeService={_handleChangeService}
                                                                />
                                                            </div>
                                                        </div>
                                                        {finalPendingServices.map((item) => (
                                                            <ItemService
                                                                key={item.plan_item_id}
                                                                item={item}
                                                                onChangeData={_handleChangeDataPendingService}
                                                                onDelete={_handleDeletePendingService}
                                                            />
                                                        ))}
                                                        <div className="rows row-has-line border-top-border-color-grey">
                                                            <div
                                                                className={classNames('v2-btn-default has-icon', {
                                                                    'is-disable':
                                                                        !finalServiceSelectedId ||
                                                                        finalPendingServices.some(
                                                                            ({ service_id, trigger_plan_item_id }) =>
                                                                                !service_id || !trigger_plan_item_id
                                                                        )
                                                                })}
                                                                onClick={_handleAddService}
                                                            >
                                                                <IconPlus />
                                                                {t('add_service')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {servicePlanId && !!summary.length ? <Summary summary={summary} /> : null}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="form-footer">
                            <div className="form-footer__action">
                                <Link to={addBranchPath(SETTINGS_SERVICE_PLAN)} className="v2-btn-default">
                                    {t('common:close')}
                                </Link>
                                <ButtonSave ref={refButtonSave} disabled={finalIsLoading} onSave={_handleSave} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SettingAddServicePlan;
