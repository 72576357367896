import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_VALUE } from 'app/modules/jobdetail/const/Invoice';
import AddInvoiceContextProvider from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import InvoiceNumber from 'app/modules/jobdetail/tabs/addinvoice/form/info/InvoiceNumber';
import InvoiceService from 'app/modules/jobdetail/tabs/addinvoice/form/items';
import InvoiceNotes from 'app/modules/jobdetail/tabs/addinvoice/form/notes';
import InvoiceRepeat from 'app/modules/jobdetail/tabs/addinvoice/form/settings/ButtonRepeat';
import InvoiceSummary from 'app/modules/jobdetail/tabs/addinvoice/form/summary';
import InvoiceTerms from 'app/modules/jobdetail/tabs/addinvoice/form/terms';
import IconTrash from 'assets/icon/IconTrash';

const InvoicService = forwardRef(({ tabActive, onDelete, onTriggerBtnSave = () => {} }, ref) => {
    const { t } = useTranslation();
    const { repeatSettings = {}, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    const refSummary = useRef();
    const { invoice: refDataInvoice, start: dateRef, id: serviceId } = repeatSettings;

    useImperativeHandle(ref, () => ({
        getValue: _getValue
    }));

    function _getValue() {
        return {
            invoiceData: refSummary.current?.getValue() || null,
            serviceData: repeatSettings
        };
    }

    const defaultData = () => {
        if (!refDataInvoice) {
            return null;
        } else {
            return {
                ...DEFAULT_VALUE,
                customer_id: null,
                location_id: null,
                locationInfo: {},
                date: dateRef,
                ...refDataInvoice,
                id: null,
                recurrence: !!!refDataInvoice?.recurrence
                    ? {
                          ...DEFAULT_VALUE.recurrence,
                          offset: {
                              ...DEFAULT_VALUE.recurrence.offset,
                              nextDateInvoice: dateRef, // Date next invoice
                              dateIssue: dateRef // Date create invoice
                          }
                      }
                    : {
                          ...refDataInvoice.recurrence,
                          offset: {
                              ...refDataInvoice.recurrence.offset,
                              nextDateInvoice: dateRef, // Date next invoice
                              dateIssue: dateRef // Date create invoice
                          }
                      },
                settingService: true,
                isJobInvoice: true,
                number: serviceId ? 'invoice_number_service' : '',
                note_repeat: 0,
                payment_terms: refDataInvoice.payment_terms || {}
            };
        }
    };

    function _handleRemoveInvoice(e) {
        e.stopPropagation();
        onChangeDataRepeatContext({ invoice: null });
        onDelete();
    }

    const invoiceContext = defaultData();

    if (!invoiceContext) {
        return false;
    }

    return (
        <div
            className={`tab-conts form-tabpane-details show-form-edit ${tabActive ? 'tab-content-active' : ''}`}
            id="tab_inoive_detail_setting"
        >
            <div className="container-setting-center">
                <div className="container-column form-edit-template has-form">
                    <div className="content-elm-edit header-modal">
                        <h3 className="name-tabs">{t('setting:invoice')}</h3>
                        <div
                            onClick={(e) => _handleRemoveInvoice(e)}
                            className="v2-btn-default has-icon --delete"
                            tabIndex="0"
                        >
                            <IconTrash />
                            {t('common:remove_invoice')}
                        </div>
                    </div>
                    <AddInvoiceContextProvider invoiceData={invoiceContext} onUpdateButtonSave={onTriggerBtnSave}>
                        {tabActive && (
                            <div className="rows --header-info">
                                <InvoiceRepeat />
                            </div>
                        )}

                        <InvoiceNumber display={false} />
                        <InvoiceService showOnTime />
                        <InvoiceSummary ref={refSummary} />

                        {tabActive && (
                            <div className="content-elm-edit rows note-details">
                                <div className="dashboard-wrapper --main">
                                    <div className="content-top-notes">
                                        <InvoiceTerms isInvoice />
                                        <InvoiceNotes isInvoice />
                                    </div>
                                </div>
                            </div>
                        )}
                    </AddInvoiceContextProvider>
                </div>
            </div>
        </div>
    );
});

export default InvoicService;
