import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { saveJobMeta } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import Assign from 'app/modules/calendar/job/components/Assign';
import Customer from 'app/modules/calendar/job/components/Customer';
import Header from 'app/modules/calendar/job/components/Header';
import JobNote from 'app/modules/calendar/job/components/jobnote';
import Location from 'app/modules/calendar/job/components/Location';
import Payment from 'app/modules/calendar/job/components/Payment';
import Services from 'app/modules/calendar/job/components/Services';
import Status from 'app/modules/job/status';
import SmsJobDetail from 'app/modules/jobdetail/components/sms/Sms';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { actionGetJobSummary, actionOpenTimeOff, actionUpdateEmoji } from 'common/redux/actions/job';
import { clientQuery } from 'common/utils/ApiUtils';
import { TYPE_JOB_META } from '../const/Job';
import EmojiPopper from '../EmojiPopper';
import { handleRemoveTimeWindowEl } from '../ultil/Calendar';
import PreviewNoteCollapse from './components/collapse';

const JobPreView = () => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isVisible: false,
        id: '',
        jobPastActive: null
    });

    const dispatch = useDispatch();

    const {
        isLoading: finalIsLoading,
        isVisible: finalIsVisible,
        id: finalJobid,
        customer: finalCustomer = {},
        location: finalLocation = {},
        invoice: finalInvoice,
        status: finalStatus,
        jobPastActive
    } = state;

    function _handleSetPastActive(job) {
        dispatchState({ jobPastActive: job });
    }

    function _handleOpenJob(jobData) {
        if (!jobData) {
            finalIsVisible && _closeJob(true);
            return false;
        }
        const jobId = jobData.jobId || jobData?.id;

        if (jobId === finalJobid) {
            dispatchState({
                status: jobData.status,
                recurrence: jobData.recurrence,
                previously_completed: jobData.previously_completed,
                schedule: { ...jobData.schedule },
                emoji: jobData.emoji
            });

            return false;
        }

        dispatchState({
            isLoading: true,
            isVisible: true,
            ...jobData
        });

        dispatch(actionGetJobSummary({ jobId: jobId }, _getJobSumarySuccess, _getJobSumaryFailed));
        dispatch(actionOpenTimeOff(null));
    }

    function _getJobSumarySuccess(response) {
        dispatchState({
            isLoading: false,
            ...response.data
        });
    }

    function _getJobSumaryFailed() {}

    function _closeJob(isCloseWithNotData = false) {
        // Remove time window on calendar
        if (!isCloseWithNotData) handleRemoveTimeWindowEl();
        dispatchState({
            isLoading: true,
            isVisible: false,
            id: ''
        });
        // eslint-disable-next-line no-undef
        global.jobPreviewId = null;
    }

    const _handleUpdateSuccess = () => {
        dispatch(
            actionGetJobSummary({ jobId: finalJobid, fields: 'invoice' }, _getJobSumarySuccess, _getJobSumaryFailed)
        );
    };

    const _handleUpdateEmoji = (emoji) => {
        dispatchState((prev) => ({ ...prev, emoji }));
    };

    const _handleSaveEmoji = () => {
        const { parent_job_id: jobIdParent, emoji: newEmoji } = state;
        if (!newEmoji) return;

        clientQuery(
            saveJobMeta(jobIdParent),
            {
                method: 'PUT',
                toFormData: false,
                data: { content: newEmoji, type: TYPE_JOB_META.EMOJI }
            },
            () => dispatch(actionUpdateEmoji({ id: jobIdParent, emoji: newEmoji }))
        );
    };

    const _handleUpdateCustomer = (balance) => {
        if (!!finalInvoice) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    invoice: {
                        ...prev.invoice,
                        balance
                    }
                };
            });
        }
    };

    function _renderJobPreView() {
        if (!finalIsVisible) return null;

        return (
            <div className="sidebar-left-content sidebar-preview job-preview">
                <div className="sidebar-preview__wrap">
                    <Header
                        onClose={_closeJob}
                        jobData={state}
                        setPastActive={_handleSetPastActive}
                        finalJobPastActive={jobPastActive}
                    />
                    <div className="flex-betweenitems">
                        <Status status={parseInt(finalStatus)} jobData={state} finalJobPastActive={jobPastActive} />
                        <EmojiPopper
                            placementDropdown="bottom-end"
                            notCloseOnClick
                            listEmoji={state.emoji}
                            onChange={_handleUpdateEmoji}
                            onClose={_handleSaveEmoji}
                            isDisable={!state.active_job}
                        />
                    </div>
                    {!!state.schedule && <Assign jobData={state} />}
                </div>
                <div className="sidebar-content pb-8">
                    <Customer customerDetail={finalCustomer} />

                    <Location
                        jobId={finalJobid}
                        customerId={finalCustomer.id}
                        finalCustomer={finalCustomer}
                        customerLocation={finalLocation}
                    />

                    {!finalIsLoading && !!finalInvoice && (
                        <Payment invoice={finalInvoice} onUpdateSuccess={_handleUpdateSuccess} />
                    )}

                    {!finalIsLoading && (
                        <PreviewNoteCollapse
                            title={`${finalCustomer.full_name}’s ${t('calendar:top_note')}`}
                            note={state.top_note}
                            extraClass="is-topnote"
                            isInnerHTML
                        />
                    )}

                    {!finalIsLoading && <JobNote notes={state.job_notes} />}
                </div>
                <CustomerSmsWithJob
                    finalIsLoading={finalIsLoading}
                    finalJobid={finalJobid}
                    finalCustomer={finalCustomer}
                />
            </div>
        );
    }

    return (
        <Fragment>
            <Services onOnpenJob={_handleOpenJob} />
            {_renderJobPreView()}
            {finalIsVisible && <RealtimeCreditBalance customerId={finalCustomer.id} onUpdate={_handleUpdateCustomer} />}
        </Fragment>
    );
};

const CustomerSmsWithJob = ({ finalJobid, finalCustomer, finalIsLoading }) => {
    if (finalIsLoading) {
        return false;
    }

    return (
        <SmsJobDetail jobId={finalJobid} isJobPreview customer={finalCustomer} keyTriggerOpen={TYPE_OPEN_SMS.PREVIEW} />
    );
};

const RealtimeCreditBalance = ({ customerId, onUpdate = () => {} }) => {
    const { customerBalance } = useContext(SocketContext);

    useEffect(() => {
        if (customerBalance) {
            if (customerBalance.customer_id === customerId) onUpdate(customerBalance.balance_currency);
        }
    }, [customerBalance]);
};

export default JobPreView;
