import 'app/modules/onBoarding/css/onboarding.scss';
import classNames from 'classnames';
import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { getPlanUser } from 'common/utils/PermissionUtils';
import Sidebar from './components/Sidebar';
import OnBoardingFAQ from './components/faq';
import OnBoardingVideoLibrary from './components/videoLibrary';
import OnBoardingVideoPopular from './components/videoPopular';

const OnBoarding = () => {
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(userProfile);

    useLayoutEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('onboarding-page');
        return () => {
            mainPageDiv.classList.remove('onboarding-page');
        };
    }, []);

    return (
        <div className={classNames('container-wrap flex-column gap-16 relative', { '--trial': isTrial })}>
            <div className="flextop gap-16 flex-1 scrolls relative">
                <Sidebar isTrial={isTrial} />
                <div className="onboarding-container scrolls">
                    <OnBoardingVideoPopular isTrial={isTrial} />
                    <OnBoardingFAQ isTrial={isTrial} />
                    <OnBoardingVideoLibrary />
                </div>
            </div>
        </div>
    );
};

export default OnBoarding;
