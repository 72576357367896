import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconTask from 'assets/icon/IconTask';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import { updateSchedule } from 'common/redux/actions/calendar';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { overCountString } from 'common/utils/StringUtils';
import { CALENDAR_MODES, COLOR_SCHEDULES, getResourceWorkPool, RESOURCE_WORK_POOL_ID } from '../../const';
import AvatarEvent from './AvatarEvent';
import { RESOURCE_EVENT_STYLE } from './EventStyles';

export function ResourceContent({
    date = null,
    schedules = null,
    incWorkPool = false,
    isMagnetResource = false,
    isDayResource = false,
    isHorizontal = false,
    tasks = [],
    resource = {},
    onOpenTasks = () => {}
}) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const { schedules: calendarSchedules, view } = useSelector(({ calendar }) => calendar);
    const extendedProps = resource?._resource?.extendedProps || {};
    const userId = extendedProps.user_id;
    const isResourceWorkPool = resource._resource.id === RESOURCE_WORK_POOL_ID;
    const i18nLanguageCode = i18n.language;

    const _getData = useMemo(() => {
        if (!companyUsers.length) {
            return {};
        }
        return getUserInfo(userId) || {};
    }, [userId, companyUsers]);

    const [state, dispatchState] = useReducer(reducer, {
        user: { name: extendedProps.name },
        totalSms: 0
    });

    const { user } = state;
    const totalTasks = tasks.length || 0;
    let timer = null;

    useEffect(() => {
        if (!isHorizontal) {
            if (timer) {
                clearTimeout(timer);
                return;
            }
            timer = setTimeout(() => {
                const [resourceColumn, resourceLane] = document.querySelectorAll(
                    `[data-resource-id="${resource._resource.id}"]`
                );
                if (resourceColumn) {
                    const childrenCell = resourceColumn.querySelector('.fc-datagrid-cell-frame');
                    if (childrenCell) childrenCell.style.height = `${resourceLane.offsetHeight - 1}px`;
                }
            }, 0);
        }
    });

    useEffect(() => {
        if (!!companyUsers.length) {
            dispatchState({
                user: {
                    ..._getData,
                    currentDate: resource?._context?.calendarApi?.getDate(),
                    schedule: { ...extendedProps, id: resource.id },
                    name: extendedProps.name
                }
            });
        }
    }, [companyUsers]);

    const _handleOpenTasksList = () => {
        totalTasks && onOpenTasks({ tasks, customer: user });
    };

    const _handleResourceClick = () => {
        if (calendarSchedules.length <= 1 || resource.id === RESOURCE_WORK_POOL_ID) return;
        const newSchedules = [user.schedule];
        if (incWorkPool) newSchedules.push(getResourceWorkPool());
        dispatch(updateSchedule({ schedules: newSchedules, incWorkPool }));
    };

    if (!user) return null;

    if (isHorizontal && resource._resource.parentId.length === 0) {
        const userName = user.name || '';
        const isCurrentDay = moment(date).utc().startOf('day').isSame(moment(), 'day');
        const styleTodayBG = isCurrentDay
            ? view === CALENDAR_MODES.AGENDA_DAY
                ? {}
                : RESOURCE_EVENT_STYLE.todayBgColor
            : {};

        return (
            <>
                {isDayResource && (
                    <div style={RESOURCE_EVENT_STYLE.colHeader}>
                        <p style={RESOURCE_EVENT_STYLE.colHeaderDate}>
                            {moment(date).utc().locale(i18nLanguageCode).format('D')}
                        </p>
                        <p>{moment(date).utc().locale(i18nLanguageCode).format('ddd')}</p>
                    </div>
                )}
                <div
                    data-date={moment(date).utc().locale(i18nLanguageCode).format('YYYY-MM-DD')}
                    data-resource-id={resource?._resource?.id || ''}
                    style={{ ...RESOURCE_EVENT_STYLE.schedulesWrap, ...styleTodayBG }}
                    onClick={_handleResourceClick}
                >
                    {isResourceWorkPool ? (
                        <div className="schedule-workpool flexcenter gap-4" title={t('calendar:work_pool_cap')}>
                            <span className="txt-workpool nowrap fs-13">{t('calendar:work_pool_cap')}</span>
                        </div>
                    ) : (
                        <span style={RESOURCE_EVENT_STYLE.scheduleName} className="user-character">
                            {userName}
                        </span>
                    )}
                </div>
                <EventColor
                    date={date}
                    incWorkPool={incWorkPool}
                    resource={resource?._resource || {}}
                    schedules={schedules}
                    isMagnetResource={isMagnetResource}
                />
            </>
        );
    }

    return (
        <Fragment>
            <div className="username hidden-view-large btn-view-tasks">
                <span className="avt-img relative">
                    <AvatarEvent
                        icon={isResourceWorkPool ? <IconWorkOrder stroke="#FFF" /> : null}
                        avatar={user.avatar}
                        name={user.name || ''}
                    />
                    <span className="status online" />
                </span>
                <span className="user-info">
                    <span className="name">{user.name}</span>
                </span>
                <span className="col-icon">
                    <span
                        className={classNames('v2-btn-default --icon-lg view-listtask', {
                            'has-notify': !!totalTasks
                        })}
                        onClick={_handleOpenTasksList}
                    >
                        <IconTask />
                        <span className="budget">{overCountString(totalTasks)}</span>
                    </span>
                </span>
            </div>
        </Fragment>
    );
}

const EventColor = ({ resource, date, schedules = null, isMagnetResource = false, incWorkPool = false }) => {
    const rangeTime = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer.rangeTime);
    const calendarSchedules = useSelector(({ calendar }) => calendar.schedules);

    const finalBorderColor = useMemo(() => {
        let schedulesCheck = schedules || calendarSchedules;
        if (incWorkPool) schedulesCheck = [...schedulesCheck, getResourceWorkPool()];
        const isOnlyOne = schedulesCheck.length <= 1;
        const indexSchedule =
            isOnlyOne && !isMagnetResource ? 0 : schedulesCheck.findIndex((schedule) => schedule.id === resource.id);

        let borderColor = COLOR_SCHEDULES[indexSchedule % COLOR_SCHEDULES.length];
        if (isMagnetResource) return borderColor;
        const rangeFinal = rangeTime;
        if (isOnlyOne) {
            const index = rangeFinal.findIndex((item) => item === moment.utc(date).startOf('days').unix()) || 0;
            borderColor = COLOR_SCHEDULES[index % COLOR_SCHEDULES.length];
        }

        return borderColor;
    }, [resource, date, rangeTime]);

    const indexRange = useMemo(() => {
        if (Array.isArray(rangeTime))
            return rangeTime.findIndex((item) => item === moment.utc(date).startOf('days').unix()) || 0;
        return 0;
    }, [rangeTime]);

    return (
        <div
            style={{ width: '100%', height: '5px', backgroundColor: finalBorderColor }}
            data-resource-id-color={`${resource.id}_${indexRange}`}
        />
    );
};
