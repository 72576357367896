import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { SETTINGS_SERVICE } from 'app/config/routes';
import { getSericeDetail } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { TABS } from 'app/modules/jobdetail/const';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { DEFAULT_VALUE } from 'app/modules/jobdetail/const/Invoice';
import DataRepeatContextProvider from 'app/modules/jobdetail/contexts/DataRepeatContext';
import DocumentsService from 'app/modules/settings/addservice/documents';
import EstimateService from 'app/modules/settings/addservice/estimate';
import ServiceInfo from 'app/modules/settings/addservice/info';
import InvoiceService from 'app/modules/settings/addservice/invoice';
import MaterialService from 'app/modules/settings/addservice/materials';
import SideBar from 'app/modules/settings/addservice/SideBar';
import MainHeaderSettings from 'app/modules/settings/components/MainHeaderSettings';
import { useEnterKeydownClick } from 'common/hooks';
import { resetServiceList } from 'common/redux/actions/settings/serviceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import TodoService from '../addservice/todo';
import LoadingService from './Loading';

export default function AddService() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const refButtonSave = useRef(null);
    const [state, dispatchState] = useReducer(reducer, {
        isVisibleCreate: false,
        start: '',
        schedule: null,
        tab: TABS.DETAIL,
        isLoading: true,
        serviceData: {}
    });
    const { tab: finalCurrentTab, isLoading: finalIsLoading, serviceData: finalServiceData } = state;

    const refInvoiceData = useRef(null);
    const refEstimateData = useRef(null);
    const refStatusBar = useRef(null);
    const refServiceColor = useRef(null);
    const { id: serivceId } = useParams();

    useEnterKeydownClick(true);

    useEffect(() => {
        clientQuery(getSericeDetail(serivceId), { method: 'GET' }, _getSericeDetailSuccess, _getSericeDetailFailed);
    }, []);

    function _getSericeDetailSuccess(response) {
        dispatchState({
            isLoading: false,
            serviceData: response.data
        });
    }

    function _getSericeDetailFailed(err) {
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    }

    const defauleData = useMemo(() => {
        if (finalIsLoading) {
            return false;
        }
        const todosData = finalServiceData.todos || {};
        const totalCheckList = todosData.data?.length;

        return {
            isAddService: true,
            id: finalServiceData.id,
            name: finalServiceData.name,
            repeat: finalServiceData.repeat,
            recurrence: finalServiceData.recurrence,
            exception: finalServiceData.exception,
            length: finalServiceData.length,
            color_code: finalServiceData.color_code,
            documents: finalServiceData.documents,
            materials: finalServiceData.materials,
            estimate: finalServiceData.estimate,
            invoice: finalServiceData.invoice,
            always_confirmed: finalServiceData.always_confirmed,
            todos: {
                data: todosData.data || [],
                renderShowMore: totalCheckList > 9,
                total: !!totalCheckList
            },
            job_cycle: finalServiceData?.job_cycle || 1
        };
    }, [finalIsLoading]);

    function _handleSaveService(callback) {
        const { invoiceData, serviceData } = refInvoiceData.current?.getValue() || null;
        const estimateData = refEstimateData.current?.getValue() || null;
        const serviceTime = refServiceColor.current.getValue();

        const convertedInvoice = invoiceData ? _handleSaveInvoice(invoiceData, true) : null;

        if (serviceData.name.trim().length === 0) {
            if (finalCurrentTab !== TABS.DETAIL) {
                dispatchState({
                    tab: TABS.DETAIL
                });
            }
            document.getElementById('input_service_name').classList.add('field-error');
            _showStatusError();
            refButtonSave.current.removeLoading();
            return false;
        }

        if (convertedInvoice === false) {
            return false;
        }
        const refDataEstimate = estimateData ? _handleSaveInvoice(estimateData, false) : null;

        if (refDataEstimate === false) {
            return false;
        }

        const { todos: serviceTodos = {} } = serviceData;

        const newJobData = {
            service: {
                ...serviceTime,
                name: serviceData.name,
                always_confirmed: serviceData.always_confirmed,
                color_code: serviceData.color_code,
                recurrence: { ...serviceData.recurrence.offset },
                exception: serviceData.exception,
                job_cycle: serviceData?.job_cycle || 1
            },
            invoice: convertedInvoice,
            estimate: refDataEstimate,
            documents: serviceData.documents.map((item) => item.parent_id),
            materials: serviceData.materials
                .filter((item) => item.paramAdd)
                .map((itemMap) => {
                    return {
                        ...itemMap.paramAdd,
                        id: itemMap.isTemp ? '' : itemMap.id //Remove id with case new material
                    };
                }),
            todos: {
                data: serviceTodos?.data || []
            },
            update_job_todo_list: serviceData.update_job_todo_list || typeof callback === 'function' ? 1 : 0
        };

        clientQuery(
            getSericeDetail(serivceId),
            { method: 'PUT', data: newJobData },
            (response) => _handleUpdateServiceSuccess(response, callback),
            (response) => _handleUpdateServiceFailed(response, callback)
        );
    }

    function _handleUpdateServiceSuccess(response, callback) {
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || t('common:success'),
            LIST_STATUS.SUCCESS
        );
        dispatch(resetServiceList());
        if (typeof callback === 'function') {
            callback(LIST_STATUS.SUCCESS, response);
        } else {
            dispatchState({
                tab: TABS.DETAIL
            });

            setTimeout(() => {
                _handleCancel();
            }, 1000);
        }

        refButtonSave.current.removeLoading();
    }

    function _handleUpdateServiceFailed(response, callback) {
        if (typeof callback === 'function') {
            callback(LIST_STATUS.ERROR);
        }
        _showStatusError(response?.message?.toString() || t('common:please_try_again'));
        refButtonSave.current.removeLoading();
    }

    function _showStatusError(message) {
        refStatusBar.current.showStatusBar(
            'show_error',
            message || t('common:one_or_more_invoice_field_not_complete'),
            LIST_STATUS.ERROR
        );
    }

    const _checkDeposit = (isInvoice, depositValue, total, packageId) => {
        const finalValueDeposit = parseFloat(depositValue.total.value);

        if (parseFloat(total) >= 0 && finalValueDeposit > parseFloat(total)) {
            document.getElementById(`deposit_cost_${packageId}`).classList.add('field-error');
            _handleError(
                t('common:message_error_value_deposit_with_name', {
                    name: t(`common:${isInvoice ? 'invoice' : 'estimate'}`)
                }),
                isInvoice
            );
            return false;
        }
        return true;
    };

    const _handleError = (msg, isInvoice) => {
        _showStatusError(msg);

        dispatchState({
            tab: isInvoice ? TABS.INVOICE : TABS.ESTIMATE
        });

        refButtonSave.current.removeLoading();
        return false;
    };

    function _handleSaveInvoice(refValue, isInvoice) {
        const { items: listItems, type: estimateType, packages: estimatePackages, total: invoiceTotal } = refValue;
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        const itemsSubmit = [];
        let checkPass = true;

        if (!isInvoice && estimateType === ESTIMATE_TYPE.PACKAGES) {
            let checkPassDeposit = true;

            estimatePackages.every((itemPackage) => {
                const { id: packageId, name: packageName, deposit, total } = itemPackage;

                checkPassDeposit = _checkDeposit(isInvoice, deposit, total, packageId);

                if (!checkPassDeposit) {
                    return false;
                }

                if (!!!packageName) {
                    checkPass = false;
                    document.getElementById(`package_item_name_${packageId}`).classList.add('field-error');
                }

                if (!listItems.some((lineItem) => lineItem.package_id === packageId)) {
                    checkPass = false;
                    document.getElementById(`package_item_detail_${packageId}`).classList.remove('dp-hide');
                }

                return true;
            });

            if (!checkPassDeposit) {
                return false;
            }
        } else {
            if (!_checkDeposit(isInvoice, finalDeposit, invoiceTotal, '')) {
                return false;
            }
        }

        if (!checkPass) {
            return _handleError('', isInvoice);
        }

        listItems.forEach((element) => {
            const tax1Detail = element.tax1 || {};
            const tax2Detail = element.tax2 || {};
            const checkItemCost = element.cost.value.length !== 0;
            const serviceItemId = element.item_id;
            const itemId = element.id;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: element.cost.value,
                    tax1_id: tax1Detail?.id || '',
                    tax2_id: tax2Detail?.id || '',
                    tax1_name: tax1Detail?.name || '',
                    tax2_name: tax2Detail?.name || '',
                    tax1_rate: tax1Detail.rate || '',
                    tax2_rate: tax2Detail.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    type: element.type || '',
                    package_id: element.package_id || ''
                });
            } else {
                checkPass = false;

                if (!checkItemCost) {
                    document.getElementById(`service_item_cost_${itemId}`).classList.add('field-error');
                }
                if (!serviceItemId) {
                    document.getElementById(`service_item_detail_${itemId}`).classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            return _handleError('', isInvoice);
        }

        const recurrence = { ...refValue.recurrence };
        const offset = recurrence.offset;
        const finalDiscount = { ...refValue.discount };
        delete offset.dateIssue;
        delete offset.nextDateInvoice;
        delete finalDiscount.total;

        return {
            customer_id: refValue.customer_id,
            discount: finalDiscount,
            deposit: isInvoice ? null : finalDeposit,
            number: refValue.number,
            po_number: refValue.po_number,
            date: refValue.date,
            items: itemsSubmit,
            subtotal: refValue.subtotal,
            total: refValue.total,
            trigger_action: '1',
            recurrence: {
                action: recurrence.action,
                offset: offset
            },
            location_id: refValue.location_id,
            terms: refValue.terms,
            note: refValue.note,
            packages: estimatePackages,
            type: estimateType,
            payment_terms_id: refValue.payment_terms.id || ''
        };
    }

    function _handleChangeTab(tabChange) {
        dispatchState({ tab: tabChange });
    }

    function _handleDeleteTab() {
        dispatchState({ tab: TABS.DETAIL });
    }

    function _handleCancel() {
        history.goBack();
    }

    const _handleTriggerButtonSave = (value) => {
        refButtonSave.current.setDisable(value);
    };

    function _renderServiceDetail() {
        if (finalIsLoading) {
            return <LoadingService />;
        }
        return (
            <DataRepeatContextProvider defaultData={{ ...defauleData }} onSaveService={_handleSaveService}>
                <div className="content-services contents">
                    <SideBar onChange={_handleChangeTab} tabActive={finalCurrentTab} />
                    <div className="content-services__right tab-contents flex-column scrolls">
                        <StatusBar ref={refStatusBar} />
                        <ServiceInfo ref={refServiceColor} tabActive={finalCurrentTab === TABS.DETAIL} />
                        <InvoiceService
                            tabActive={finalCurrentTab === TABS.INVOICE}
                            ref={refInvoiceData}
                            onDelete={_handleDeleteTab}
                            onTriggerBtnSave={_handleTriggerButtonSave}
                        />
                        <EstimateService
                            tabActive={finalCurrentTab === TABS.ESTIMATE}
                            ref={refEstimateData}
                            onDelete={_handleDeleteTab}
                            onTriggerBtnSave={_handleTriggerButtonSave}
                        />
                        <MaterialService tabActive={finalCurrentTab === TABS.MATERIALS} />
                        <DocumentsService tabActive={finalCurrentTab === TABS.DOCUMENTS} />
                        <TodoService tabActive={finalCurrentTab === TABS.CHECK_LIST} />
                    </div>
                </div>
            </DataRepeatContextProvider>
        );
    }

    return (
        <Fragment>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <div className="contents-pages service-edit c-job-details">
                    <div className="wrap-content-service accessible-tabs-container wrapper-box-edit">
                        {_renderServiceDetail()}
                        <div className="form-footer">
                            <div className="form-footer__action">
                                <Link to={addBranchPath(SETTINGS_SERVICE)} className="v2-btn-default">
                                    {t('common:close')}
                                </Link>
                                <ButtonSave ref={refButtonSave} onSave={_handleSaveService} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
