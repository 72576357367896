import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import { DELAY_TIME, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT } from 'app/const/Reports';
import { URL_EXPORT_HISTORY_MAIL } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGridColumns } from 'app/const/report/EmailHistory';
import { EMAIL_HISTORY_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertParamFields, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_EMAIL_HISTORY_GET_LIST } from 'app/const/Api';
import { handleAbortController } from 'app/modules/customer/utils';

const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

function ReportEmailHistory() {
    const { t } = useTranslation(['report']);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        data: [],
        totalPage: 1,
        isLoading: true,
        total: 0,
        refreshScreen: 0,
        isLoadingMore: false
    });

    const { isLoadingMore, total: finalTotal, data: finalData, isLoading: finalIsLoading } = dataReport;

    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.EMAIL_HISTORY);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.EMAIL_HISTORY);
    const refreshScreen = dataReport.refreshScreen;
    const abortController = useRef(null);

    useEffect(() => {
        getListReport(true, paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        let timeoutId = null;
        if (isLoadingMore) {
            timeoutId = setTimeout(() => {
                getListReport(false, { ...paramsReport, offset: finalData.length }, true);
            }, DELAY_TIME);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isLoadingMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        params = getNewAPIRequest(params);
        const checkShouldLoading = !notShowLoading && !finalIsLoading;

        checkShouldLoading &&
            dispatchActionReport((prev) => ({
                ...prev,
                data: isReset ? [] : prev.data,
                isLoading: !!checkShouldLoading || prev.isLoading
            }));

        delete params?.columns;
        clientQuery(
            REPORT_EMAIL_HISTORY_GET_LIST,
            {
                data: convertParamFields({ params, isEmptyFields: isLoadingMore }),
                method: 'GET',
                abortController: abortController.current
            },
            (res) => getListSuccess({ ...res, isReset }),
            getListFailed
        );
    };

    const getListSuccess = ({ data, total, isReset }) => {
        dispatchActionReport((prev) => {
            const newData = isReset ? data : [...prev.data, ...data];
            const newTotal = total ?? prev.total ?? 0;
            return {
                ...prev,
                data: newData,
                total: newTotal,
                isLoading: false,
                isLoadingMore: false,
                refreshScreen: refreshScreen + 1
            };
        });
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocal);
        getListReport(true, { ...params, offset: 0 });
    };

    const _handleScroll = () => {
        const numberData = finalData.length;

        if (!!numberData && !isLoadingMore && finalTotal > numberData) {
            dispatchActionReport((prev) => ({ ...prev, isLoadingMore: true }));
        }
    };

    const getListFailed = ({ isAborted = false }) => {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadingMore: false }));
    };

    const getNewAPIRequest = (params) => {
        if (!params) return undefined;
        if (params.customer_id === -1) {
            params.customer_id = null;
        }
        if (!params.total) {
            params.total = 1;
        }
        params.limit = REPORT_LIMIT;
        return params;
    };

    function _renderHeaderLeft() {
        return (
            <HeaderBottom
                typeReport={REPORT_TYPE.EMAIL_HISTORY}
                filters={EMAIL_HISTORY_LIST_FILTER}
                isLoading={dataReport.isLoading}
                handleUpdate={_handleUpdate}
            />
        );
    }

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: dataReport.total })}
                activePrint
                url={URL_EXPORT_HISTORY_MAIL}
                params={getNewAPIRequest(paramsReport)}
                pageExport={LIST_EXPORT.EXPORT_REPORT_EMAIL_HISTORY}
                isDisable={dataReport.isLoading}
                refresh={refreshScreen}
            />
        );
    }

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.EMAIL_HISTORY,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.EMAIL_HISTORY}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <div className="wrap-tables flex-column">
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            content={dataReport.data}
                            fileTranslation="report"
                            handleClick={() => {}}
                            {...getGridColumns(paramsReport?.columns, paramsReport?.order)}
                            isScroll
                            isLoadmore={isLoadingMore}
                            onScrollToEnd={_handleScroll}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportEmailHistory;
