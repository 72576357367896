import classNames from 'classnames';
import React, { forwardRef, useId, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { DEFAULT_ALL } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { INACTIVE_HOURS } from '../constants';
import SettingReplyBehavior from './SettingReplyBehavior';

const RowSettingReply = (
    { id = '', label = '', active = 0, all_day = 0, active_hours = 0, inactive_hours = DEFAULT_ALL },
    ref
) => {
    const uuid = useId();
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { activeDay: active, allDay: all_day });
    const { activeDay, allDay } = state;

    const refAllDay = useRef(null);
    const refActiveHours = useRef(null);
    const refAfterHours = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _getValue }));

    const _getValue = () => {
        return {
            active: activeDay,
            all_day: allDay,
            active_hours: refActiveHours.current ? (refActiveHours.current.getValue()?.id ?? 0) : 0,
            inactive_hours: refAfterHours.current ? (refAfterHours.current.getValue()?.id ?? DEFAULT_ALL) : DEFAULT_ALL
        };
    };

    const _handleChangeActiveDay = (e) => {
        e.preventDefault();
        dispatchState((prev) => ({ ...prev, activeDay: e.target.checked ? 1 : 0 }));
    };

    const _handleChangeAllDay = (checked) => {
        dispatchState((prev) => ({ ...prev, allDay: checked ? 1 : 0 }));
    };

    return (
        <div className="rows">
            <div className="col col-day">
                <Checkbox
                    key={activeDay}
                    id={uuid}
                    checked={!!activeDay}
                    value={id}
                    onChangeValue={_handleChangeActiveDay}
                >
                    {label}
                </Checkbox>
            </div>
            <div className={classNames('col col-day', { 'is-disable': !activeDay })}>
                <GdSwitchCheckbox
                    ref={refAllDay}
                    id={`${id}-all-day`}
                    wrapClassName="switch large"
                    textPosition="right"
                    defaultChecked={!!allDay}
                    onChangeImmediately={_handleChangeAllDay}
                />
            </div>
            <div className={classNames('col col-lg', { 'is-disable': !activeDay })}>
                {!activeDay ? t('addons:unavailable') : null}
                <SettingReplyBehavior
                    ref={refActiveHours}
                    id="active_hours"
                    defaultValue={active_hours}
                    isHidden={!activeDay}
                />
            </div>
            <div className={classNames('col col-lg', { 'is-disable': !!allDay })}>
                <SettingReplyBehavior
                    ref={refAfterHours}
                    id="inactive_hours"
                    behaviorHour={INACTIVE_HOURS}
                    defaultValue={inactive_hours}
                    isHidden={!activeDay}
                />
            </div>
        </div>
    );
};

export default forwardRef(RowSettingReply);
