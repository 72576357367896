import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { ADDONS_BOOKING_SETTINGS } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';

const ServiceOptions = ({ dataOptions = {}, isDisable = false }) => {
    const { t } = useTranslation();

    const _handleChange = (value, id) => {
        clientQuery(ADDONS_BOOKING_SETTINGS, { data: { keyword: id, value: value ? 1 : 0 }, method: 'PUT' });
    };

    return (
        <div className={classNames('boxs boxs--booking', { 'is-disable': isDisable })}>
            <div className="boxs__header">
                <div className="title txt-ellipsis">{t('addons:website_services')}</div>
            </div>
            <div className="boxs__contents">
                <p className="description mb-5">{t('addons:website_services_desc')}</p>
                <div className="rows">
                    <div className="rows__name">{t('addons:card_payment_required')}</div>
                    <GdSwitchCheckbox
                        id="card_payment_required"
                        textChecked={t('on')}
                        textUnChecked={t('off')}
                        inputClassName="toggle toggle-round"
                        defaultChecked={dataOptions.card_payment_required}
                        onChange={_handleChange}
                    />
                </div>
                <div className="rows">
                    <div className="rows__name">{t('addons:display_service_pricing')}</div>
                    <GdSwitchCheckbox
                        id="display_service_pricing"
                        textChecked={t('on')}
                        textUnChecked={t('off')}
                        inputClassName="toggle toggle-round"
                        defaultChecked={dataOptions.display_service_pricing}
                        onChange={_handleChange}
                    />
                </div>
                <div className="rows">
                    <div className="rows__name">{t('addons:same_day_booking')}</div>
                    <GdSwitchCheckbox
                        id="same_day_bookings"
                        textChecked={t('on')}
                        textUnChecked={t('off')}
                        inputClassName="toggle toggle-round"
                        defaultChecked={dataOptions.same_day_bookings}
                        onChange={_handleChange}
                    />
                </div>
                <div className="rows">
                    <div className="rows__name">{t('addons:excule_weekends')}</div>
                    <GdSwitchCheckbox
                        id="exclude_weekends"
                        textChecked={t('on')}
                        textUnChecked={t('off')}
                        inputClassName="toggle toggle-round"
                        defaultChecked={dataOptions.exclude_weekends}
                        onChange={_handleChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default ServiceOptions;
