import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { reducer } from 'app/const/Reducer';
import IconPlay from 'assets/icon/IconPlay';
import { checkTypeAgentChatbot } from '../utils';

const ButtonAIChatBot = ({ typeAgent = ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS, onOpen = () => {} }, ref) => {
    const { t } = useTranslation('addons');
    const isAgentPortal = checkTypeAgentChatbot({ typeAgent, conditionCheck: ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL });
    const [state, dispatchState] = useReducer(reducer, { isDisable: !!isAgentPortal });
    const { isDisable } = state;

    useImperativeHandle(ref, () => ({ setDisable: _handleSetDisable }));

    const _handleSetDisable = (value) => {
        dispatchState((prev) => ({ ...prev, isDisable: value }));
    };

    const _handleClick = () => {
        _handleSetDisable(true);

        setTimeout(() => {
            onOpen();
        }, 0);

        if (isAgentPortal) {
            window.GorillaDesk && window.GorillaDesk('showChat');
        }
    };

    const _getTextButton = () => {
        if (isAgentPortal) return 'test_portal_chatbot';
        return 'test_chatbot';
    };
    const buttonText = _getTextButton();

    return (
        <div className="box-test-sms boxs-notification w-100">
            <div
                className={classNames('v2-btn-main has-icon --bg-green w-100 justify-center', {
                    'is-disable': isDisable,
                    'active-chatbot': isDisable
                })}
                onClick={_handleClick}
            >
                <IconPlay isWhite />
                <span className="txt-chatbot txt-ellipsis" title={t(buttonText)}>
                    {t(buttonText)}
                </span>
            </div>
        </div>
    );
};

export default forwardRef(ButtonAIChatBot);
