import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ACTION_LIST_TEMPLATE, TEMPLATE_KEYWORD } from 'app/const/Notes';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import Header from './Header';
import Items from './Items';
import Loading from './Loading';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { handleAbortController } from 'app/modules/customer/utils';

const List = (
    {
        typeTemplate = TEMPLATE_KEYWORD.NOTE,
        onGetDetail = () => {},
        onAddNew = () => {},
        onShowStatusBar = () => {},
        onFinally = () => {}
    },
    ref
) => {
    const { id } = useParams();
    const { t } = useTranslation('setting');
    const { titleHeader, getListTemplate, updateTemplate, tagTemplate } = ACTION_LIST_TEMPLATE[typeTemplate];
    const abortController = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        selected: null,
        isLoading: true,
        keyword: '',
        isLoadmore: false,
        total: 0,
        isLoadSearch: false,
        haveLoadMore: false,
        cursor: ''
    });

    const {
        data: finalData,
        selected: finalSelected,
        isLoading: finalIsLoading,
        keyword: finalKeyword,
        isLoadmore: finalIsLoadmore,
        total: finalTotal,
        isLoadSearch: finalIsLoadSearch,
        cursor: finalCursor,
        haveLoadMore
    } = state;

    const defaultParams = {
        keyword: finalKeyword,
        type: id,
        offset: 0,
        limit: 15,
        total: 1,
        cursor: '',
        fields: 'setting'
    };

    useImperativeHandle(ref, () => ({
        _addItemToList,
        _updateItemToList
    }));

    useEffect(() => {
        _getList({ keyword: '' });

        return () => {
            handleAbortController(abortController);
        };
    }, [id]);

    const _getList = (params = {}, shouldLoading = true) => {
        if (!finalIsLoading && shouldLoading) dispatchState({ isLoading: true });
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _success = ({ data, total, cursor, show_more }) => {
            const firstItem = data[0];
            dispatchState({
                data,
                isLoading: false,
                selected: firstItem?.id || null,
                total,
                isLoadSearch: false,
                cursor,
                haveLoadMore: show_more,
                ...params
            });
            onGetDetail(firstItem);
            abortController.current = null;
        };
        const _fail = ({ isAborted, message }) => {
            dispatchState((prev) => ({
                ...prev,
                data: [],
                isLoading: isAborted ? prev.isLoading : false,
                isLoadSearch: isAborted ? prev.isLoadSearch : false
            }));
            !isAborted && onShowStatusBar('error', message, LIST_STATUS.ERROR);
        };
        const _finally = () => {
            onFinally();
        };

        clientQuery(
            getListTemplate,
            {
                method: 'GET',
                data: { ...defaultParams, ...params },
                abortController: abortController.current
            },
            _success,
            _fail,
            _finally
        );
    };

    const _loadmore = () => {
        dispatchState({
            isLoadmore: true
        });
        const _success = ({ data, cursor, show_more }) => {
            dispatchState({
                data: [...finalData, ...data],
                isLoadmore: false,
                cursor,
                haveLoadMore: show_more
            });
        };
        const _fail = () => {
            dispatchState({
                data: [],
                isLoadmore: false
            });
        };

        clientQuery(
            getListTemplate,
            {
                method: 'GET',
                data: { ...defaultParams, offset: finalData.length, cursor: finalCursor }
            },
            _success,
            _fail
        );
    };

    const _search = (value) => {
        dispatchState({
            keyword: value,
            isLoadSearch: true
        });
        _getList({ keyword: value }, false);
    };

    const _addItemToList = () => {
        _getList({}, false);
    };

    const _updateItemToList = (itemUpdated) => {
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === itemUpdated.id) {
                        return {
                            ...item,
                            ...itemUpdated
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _deleteNote = (id) => {
        const _success = ({ message }) => {
            const newData = finalData.filter((item) => item.id !== id);
            dispatchState({ data: newData });
            onShowStatusBar('success', message, LIST_STATUS.SUCCESS);
            id === finalSelected && onGetDetail(null);
            _getList({}, false);
        };

        const _fail = ({ message }) => {
            onShowStatusBar('error', message, LIST_STATUS.ERROR);
        };

        clientQuery(updateTemplate(id), { method: 'DELETE', data: {} }, _success, _fail);
    };

    const _selectNote = (item) => {
        const { id: newSelected } = item || {};
        if (newSelected !== finalSelected) {
            dispatchState({ selected: item.id });
            onGetDetail(item);
        }
    };

    const _handleScroll = (e) => {
        if (!finalIsLoadmore && isScrollToEndBottom(e.target) && (finalData.length < finalTotal || haveLoadMore)) {
            _loadmore();
        }
    };

    const _handleAddNew = () => {
        dispatchState({ selected: null });
        onAddNew(parseInt(id));
    };

    if (finalIsLoading) return <Loading />;

    return (
        <div className="wrap-list-items flex-auto">
            <Header type={id} onAdd={_handleAddNew} titleTemplate={titleHeader} />
            <SearchOption
                isSearchIcon
                defaultValue={''}
                placeholder={t('search')}
                parentStyle="wrap-list-items__search"
                onSearch={_search}
            />
            <Items
                type={id}
                items={finalData}
                selected={finalSelected}
                onDelete={_deleteNote}
                onSelect={_selectNote}
                onScroll={_handleScroll}
                isLoadmore={finalIsLoadmore}
                isLoadSearch={finalIsLoadSearch}
                tagTemplate={tagTemplate}
            />
        </div>
    );
};
export default forwardRef(List);
