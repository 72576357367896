import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDInputNumber from 'app/components/input/InputNumber';
import { reducer } from 'app/const/Reducer';
import { DUE_DATE, DUE_DATE_TYPE, LIST_DUE_DATE, LIST_PAYMENT_FEE, PAYMENT_FEE, PAYMENT_FEE_TYPE } from '../const';
import DropdownPaymentTerms from './DropdownPaymentTerms';

const HeaderPaymentTerms = (
    { termId = '', termName = '', titleTemplate = null, dueDate = {}, paymentFee = {} },
    ref
) => {
    const { t } = useTranslation('setting');

    const { type: dueDateType, value: dueDateValue } = dueDate;
    const { type: paymentFeeType, value: paymentFeeValue } = paymentFee;
    const [state, dispatchState] = useReducer(reducer, {
        [DUE_DATE_TYPE]: dueDateType,
        [PAYMENT_FEE_TYPE]: paymentFeeType
    });
    const { [DUE_DATE_TYPE]: finalDueDateType, [PAYMENT_FEE_TYPE]: finalPaymentFeeType } = state;

    const refInput = useRef(null);
    const refInputDueDate = useRef(null);
    const refInputLatePaymentFee = useRef(null);
    const refDropdownDueDate = useRef(null);
    const refDropdownPaymentFee = useRef(null);

    useImperativeHandle(ref, () => ({ _clearInputs, _resetInputs, _getValues }));

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, [DUE_DATE_TYPE]: dueDateType, [PAYMENT_FEE_TYPE]: paymentFeeType }));
    }, [termId]);

    const _getValues = () => {
        return {
            name: refInput.current.value,
            [DUE_DATE]: { type: finalDueDateType, value: refInputDueDate.current.value },
            [PAYMENT_FEE]: { type: finalPaymentFeeType, value: refInputLatePaymentFee.current.value }
        };
    };

    const _autoFocus = () => refInput.current.focus();

    const _clearInputs = () => {
        refInput.current.value = '';
        _autoFocus();
        refInputDueDate.current.clear();
        refInputLatePaymentFee.current.clear();
    };

    const _resetInputs = () => {
        refInput.current.value = termName;
        _autoFocus();
        refInputDueDate.current.reset();
        refInputLatePaymentFee.current.reset();
    };

    const _handleChangeItem = ({ key, value }) => {
        dispatchState((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <div className="template-header">
            {titleTemplate}
            <div className="template-header__name mt-2">
                <div className="col col-name">
                    <p className="title">{t('term_name')}</p>
                    <input
                        ref={refInput}
                        type="text"
                        className="field-input"
                        placeholder={t('term_name')}
                        defaultValue={termName}
                    />
                </div>
                <div className="col col-date has-many-field">
                    <p className="title">{t('due_date')}</p>
                    <div className="has-many-field__items phone-field">
                        <GDInputNumber
                            ref={refInputDueDate}
                            className="field-input"
                            name={DUE_DATE}
                            type="number"
                            placeholder={0}
                            defaultValue={dueDateValue}
                            exceptNegative
                            isStringValue
                            exceptZero
                        />
                        <DropdownPaymentTerms
                            ref={refDropdownDueDate}
                            keyGetValue={DUE_DATE_TYPE}
                            data={LIST_DUE_DATE}
                            type={finalDueDateType}
                            onHandleChange={_handleChangeItem}
                        />
                    </div>
                </div>
                <div className="col col-fee has-many-field">
                    <p className="title">{t('fee')}</p>
                    <div className="has-many-field__items phone-field">
                        <GDInputNumber
                            ref={refInputLatePaymentFee}
                            className="field-input"
                            name={PAYMENT_FEE}
                            type="number"
                            placeholder={0}
                            defaultValue={paymentFeeValue}
                            exceptNegative
                            isStringValue
                            exceptZero
                        />
                        <DropdownPaymentTerms
                            ref={refDropdownPaymentFee}
                            keyGetValue={PAYMENT_FEE_TYPE}
                            data={LIST_PAYMENT_FEE}
                            type={finalPaymentFeeType}
                            onHandleChange={_handleChangeItem}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default forwardRef(HeaderPaymentTerms);
