import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import PlanDetail from '../plan';
import { reducer } from 'app/const/Reducer';
import { JOB_STATE } from 'app/modules/jobdetail/const';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_SERVICE_PLAN_DATE } from 'app/const/Api';
import Summary from './Summary';

function PlanJobs({ servicePlan, triggerUpdate, servicePlanId, summary = [] }, ref) {
    const itemRefs = useRef([]);

    const [state, dispatchState] = useReducer(reducer, {
        plans: [...servicePlan],
        shouldUpdate: 0
    });

    const { plans: currentPlans, shouldUpdate } = state;

    useImperativeHandle(ref, () => ({
        getValue: _getJobData
    }));

    useEffect(() => {
        if (triggerUpdate) {
            dispatchState({
                plans: [...servicePlan],
                shouldUpdate: triggerUpdate
            });
        }
    }, [triggerUpdate]);

    const _getJobData = () => {
        return itemRefs.current.map((item) => item.getValue());
    };

    const _getSuccessfully = ({ data }) => {
        dispatchState((prev) => {
            return {
                ...prev,
                plans: prev.plans.map((item, index) => {
                    return {
                        ...item,
                        start: data[index].date
                    };
                })
            };
        });
    };

    const _updateNewDate = (id, newDate, position, haveTriggerOther) => {
        if (haveTriggerOther) {
            clientQuery(
                GET_SERVICE_PLAN_DATE,
                {
                    method: 'POST',
                    toFormData: false,
                    data: {
                        service_plan_id: servicePlanId,
                        services: _getJobData().map((item, index) => {
                            const isThisPosition = index === position;
                            return {
                                recurrence: item.recurrence,
                                exception: item.exception,
                                is_update: isThisPosition,
                                date: isThisPosition ? newDate : item.date
                            };
                        })
                    }
                },
                _getSuccessfully
            );
        } else {
            dispatchState((prev) => {
                return {
                    ...prev,
                    plans: prev.plans.map((item) => {
                        if (item.plan_item_id === id) {
                            return {
                                ...item,
                                start: newDate
                            };
                        }
                        return item;
                    })
                };
            });
        }
    };

    const _checkIsNextJob = (planCheckNext, trigger_plan_item_id) => {
        return planCheckNext.some(
            (item) => item.job_state === JOB_STATE.ACTIVE && item.service_plan.plan_item_id === trigger_plan_item_id
        );
    };

    const onTriggerActiveJob = ({ status, plan_item_id }) => {
        dispatchState((prev) => {
            let newPlans = [...prev.plans].map((item) => {
                if (item.plan_item_id === plan_item_id) {
                    return {
                        ...item,
                        status: status
                    };
                }

                if (item.service_plan.trigger_plan_item_id === plan_item_id) {
                    return {
                        ...item,
                        job_state: JOB_STATE.ACTIVE,
                        isNexJob: false
                    };
                }
                return item;
            });

            newPlans = newPlans.map((item) => {
                return {
                    ...item,
                    isNexJob:
                        item.job_state === JOB_STATE.IN_ACTIVE &&
                        _checkIsNextJob(newPlans, item.service_plan.trigger_plan_item_id)
                };
            });

            return {
                ...prev,
                plans: newPlans,
                shouldUpdate: Date.now()
            };
        });
    };

    const _renderItems = () => {
        return currentPlans.map((item, index) => {
            const currentItem = currentPlans[index] || {};

            return (
                <PlanDetail
                    ref={(el) => (itemRefs.current[index] = el)}
                    key={index}
                    data={{ ...item, job_state: item.job_state || JOB_STATE.CREATING }}
                    index={index}
                    onUpdateDate={_updateNewDate}
                    defaultStart={currentItem.start}
                    shouldUpdate={shouldUpdate}
                    onTriggerActiveJob={onTriggerActiveJob}
                />
            );
        });
    };

    return (
        <div className="details-job__content detail-plan schedule-wrapper">
            {_renderItems()}
            {!!summary.length ? <Summary summary={summary} /> : null}
        </div>
    );
}

export default forwardRef(PlanJobs);
