import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CURRENCY_SYMBOL } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { PERCENT_VALUE } from 'app/const/setting/SettingGlobalOverride';
import { LIST_PAYMENT_FEE } from 'app/modules/settings/templatesManager/components/notes/const';
import { checkEmptyObject } from 'common/utils/ObjectUtils';

const PaymentTermName = ({ name = '', due_date = {}, fee = {} }, ref) => {
    const { t } = useTranslation(['customers', 'jobDetail']);
    const [state, dispatchState] = useReducer(reducer, { selectedItem: { name, due_date, fee } });
    const {
        selectedItem: { name: finalName, due_date: dueDate, fee: finalFee }
    } = state;
    const { value: dueDateValue } = dueDate || {};
    const { value: feeValue, type: feeType } = finalFee || {};
    const paymentFee = LIST_PAYMENT_FEE.find(({ type }) => type === feeType);
    const paymentFeeValue = paymentFee?.value;

    useImperativeHandle(ref, () => ({
        setItem: (item) => _handleSelectedItem(item)
    }));

    const _handleSelectedItem = (item) => {
        if (!item || checkEmptyObject(item)) return;
        dispatchState((prev) => ({ ...prev, selectedItem: item }));
    };

    if (!finalName) return <span className="txt-ellipsis">{t('jobDetail:insert')}</span>;

    return (
        <p className="txt-ellipsis word-break flex-1">
            {finalName}
            {dueDateValue ? (
                <span>
                    <span className="dots" />
                    {t('customers:net')} {dueDateValue}
                </span>
            ) : null}
            {feeValue ? (
                <span>
                    <span className="dots" />
                    {paymentFeeValue === DEFAULT_CURRENCY_SYMBOL ? paymentFeeValue : null}
                    {feeValue}
                    {paymentFeeValue === PERCENT_VALUE ? paymentFeeValue : null}
                </span>
            ) : null}
        </p>
    );
};

export default forwardRef(PaymentTermName);
