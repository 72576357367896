import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import IconLoading from 'assets/icon/IconLoading';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ALL, CUSTOMER_SEARCH_LIMIT, KEY_CODE_ESCAPE } from 'app/const/App';
import { getListLocation } from 'common/redux/actions/customers/locationAction';
import IconLocation from 'assets/icon/IconLocation';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { getLocationDefaultAll } from 'app/modules/calendar/const/Job';

const FilterLocations = ({ customerId = '', selected = DEFAULT_ALL, title = '', onSelect, isDocCustomer = false }) => {
    const { t } = useTranslation(['report', 'common']);
    const finalTitle = useRef(title || t('customers:all_location'));
    const oldCustomer = useRef(customerId);
    const refSearch = useRef(null);

    const dispatch = useDispatch();

    const [state, setState] = useReducer(reducer, {
        isLoading: true,
        options: [],
        fistTime: true,
        showMore: false,
        isVisible: false
    });

    const { isVisible: finalIsVisible, options: finalOptions } = state;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_customer_location');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_customer_location');

        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    /**
     * Reset text select location to all location
     */
    useEffect(() => {
        if (customerId !== oldCustomer.current && !finalIsVisible) {
            finalTitle.current = title;
        }
    }, [customerId]);

    /**
     * Fetch with 2 cases
     * 1. Modal is open and first time open
     * 2. Modal is open and next customer != old customer
     */
    useEffect(() => {
        if (finalIsVisible && (state.fistTime || customerId !== oldCustomer.current)) {
            finalTitle.current = title;

            setState({ isLoading: true, options: [] });

            const params = { limit: CUSTOMER_SEARCH_LIMIT, offset: 0, customer_id: customerId };

            _getListCustomerLocation(params);

            oldCustomer.current = customerId;
        }
    }, [finalIsVisible, customerId]);

    function _getListCustomerLocation(params, isScroll = false) {
        dispatch(
            getListLocation(
                params,
                (params) => _getListCustomerLocationSuccess(params, isScroll),
                _getListCustomerLocationFailed
            )
        );
    }

    function _getListCustomerLocationSuccess({ data, show_more }, isScroll = false) {
        setState({
            isLoading: false,
            options: isScroll ? [...finalOptions, ...data] : data,
            fistTime: false,
            showMore: show_more
        });
    }

    function _getListCustomerLocationFailed() {
        setState({
            isLoading: false,
            fistTime: false
        });
    }

    const handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    const handleSelectOption = (e, value) => {
        e.stopPropagation();

        if (value.id !== selected) {
            finalTitle.current = isDocCustomer ? `${value.name}` : value.address;

            setState({ isVisible: false });

            onSelect(value);
        }
        return false;
    };

    const handleSelectAll = (e) => {
        if (DEFAULT_ALL !== selected) {
            e.stopPropagation();

            handleOpen(e);
            finalTitle.current = title;
            DEFAULT_ALL !== selected && onSelect(getLocationDefaultAll());
            return false;
        }
    };

    function handleOnScrollContent(e) {
        if (!state.isLoading && isScrollToEndBottom(e.target) && state.showMore) {
            setState({ isLoading: true });
            _getListCustomerLocation(
                {
                    limit: CUSTOMER_SEARCH_LIMIT,
                    offset: finalOptions.length,
                    customer_id: customerId
                },
                true
            );
        }
    }

    const renderSelectAllOption = () => {
        return (
            <div className={`content-checked__all`} onClick={handleSelectAll}>
                <div className={classNames('items', { active: selected === DEFAULT_ALL })}>
                    {t('customers:all_location')}
                </div>
            </div>
        );
    };

    const renderList = (list) => {
        return list.map((item) => {
            const idDocs = item.id;
            const finalTitle = isDocCustomer ? `${item.name}` : item.address;

            return (
                <li
                    key={idDocs}
                    onClick={(e) => {
                        handleSelectOption(e, item);
                    }}
                    title={finalTitle}
                    className={classNames('items', { active: idDocs === selected })}
                >
                    <p className="txt-ellipsis">{finalTitle}</p>
                </li>
            );
        });
    };

    function _renderListOptions() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="v2-dropdown__menu content-checked">
                {renderSelectAllOption()}

                <ul id="show_result_customer_location" className="scrolls" onScroll={handleOnScrollContent}>
                    {renderList(finalOptions)}
                    {finalOptions.length === 0 && !state.isLoading && (
                        <li className="items justify-center pointer-events-none">
                            <div className="loading -ajaxbar">
                                {t('common:no_data_to_display', { title: t('common:locations') })}
                            </div>
                        </li>
                    )}
                    {state.isLoading && (
                        <li className="items justify-center">
                            <div className="loading -ajaxbar">
                                <IconLoading />
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    return (
        <div ref={refSearch} className={`v2-dropdown select-list ${finalIsVisible ? 'active' : ''}`}>
            <div className="dropbtn items has-icon" onClick={(e) => handleOpen(e)}>
                <IconLocation />
                <span className="txt-ellipsis flex-1">{title}</span>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </div>
            {_renderListOptions()}
        </div>
    );
};

export default FilterLocations;
