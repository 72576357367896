import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import { TYPE_TEMPLATE } from 'app/const/Settings';
import { LIST_STATUS } from 'app/const/Status';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { actionUpdateEmailTemplate } from 'common/redux/actions/email/template';
import { clientQuery } from 'common/utils/ApiUtils';
import { useSettingTemplate } from '../SettingTemplateContext';
import { _handleGetURLCreate, _handleGetURLTemplate } from '../utils';

const FooterTemplate = ({ onAlert = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        onCloseTemplate,
        onGetDataSave,
        template,
        typeTemplate,
        isCreateTemplate,
        messageCreateSuccess,
        messageUpdateSuccess,
        onUpdateSuccess
    } = useSettingTemplate();
    const { upgrade } = template;
    const refButtonSave = useRef(null);

    const _handleSave = () => {
        const dataSubmit = onGetDataSave();

        // Show alert if any reminder has sms_content with more than 600 characters
        // and stop saving
        if (dataSubmit.isOverSMS) {
            onAlert({
                id: LIST_STATUS.ERROR,
                message: t('setting:sms_message_limit'),
                type: LIST_STATUS.ERROR
            });
            refButtonSave.current?.removeLoading();
            return;
        }

        const _handleSaveSuccess = ({ data, message }) => {
            onAlert({
                id: LIST_STATUS.SUCCESS,
                message: message.length ? message : isCreateTemplate ? messageCreateSuccess : messageUpdateSuccess,
                type: LIST_STATUS.SUCCESS
            });
            onUpdateSuccess(data, message);
            if (TYPE_TEMPLATE.EMAIL_INBOX === typeTemplate) dispatch(actionUpdateEmailTemplate());
        };

        const _handleSaveFailed = ({ message }) => {
            onAlert({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
        };

        const _handleSaveFinally = () => {
            refButtonSave.current?.removeLoading();
        };

        clientQuery(
            isCreateTemplate ? _handleGetURLCreate(typeTemplate) : _handleGetURLTemplate(typeTemplate, template.id),
            { data: dataSubmit, toFormData: false, method: isCreateTemplate ? 'POST' : 'PUT' },
            _handleSaveSuccess,
            _handleSaveFailed,
            _handleSaveFinally
        );
    };

    return (
        <div className="wrap-footer" style={{ zIndex: 3, backgroundColor: '#fff' }}>
            <div className="v2-btn-default --transparent" onClick={onCloseTemplate}>
                {t('cancel')}
            </div>
            {!!upgrade ? (
                <Link to={addBranchPath(SETTINGS_PLANS)} className="v2-btn-main has-icon svg-white-stroke">
                    <IconUpgrade />
                    {t('upgrade_to_pro')}
                </Link>
            ) : (
                <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" title={t('save')} onSave={_handleSave} />
            )}
        </div>
    );
};

export default FooterTemplate;
