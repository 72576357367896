import React from 'react';

const LoadingBox = () => {
    return (
        <div className="wrap-loading boxs boxs-has-footer --setting-portal">
            <div className="boxs__contents flex-column gap-16">
                <div className="line">
                    <div className="txt">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="field">
                        <div className="loading v2-btn-default --grey --half">
                            <div className="loading --animation --line --threefourth"></div>
                            <div className="loading --animation --line --threefourth mt-0 ml-2"></div>
                        </div>
                    </div>
                </div>
                <div className="line">
                    <div className="txt">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="field">
                        <div className="v2-dropdown v2-btn-default --grey loading --full ml-0">
                            <div className="loading --animation --line --fourth"></div>
                        </div>
                    </div>
                </div>
                <div className="line">
                    <div className="txt">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="field">
                        <div className="v2-dropdown v2-btn-default --grey loading --full ml-0">
                            <div className="loading --animation --line --fourth"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxs__footer">
                <div className="v2-btn-default loading btn-x-sm --grey">
                    <div className="loading --animation --line --full"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingBox;
