import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_PORTAL } from 'app/config/routes';
import { ADDONS_PORTAL_SETTING } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import AddonBack from '../../components/AddonBack';
import { BACKGROUND_VARIANT_OPTION, SETTING_PORTAL_WIDGET, TAB_BUTTON_SHAPE } from '../constant';
import ItemBackgroundVariant from './ItemBackgroundVariant';
import ItemButtonShape from './ItemButtonShape';
import ItemPortalColor from './ItemPortalColor';
import LoadingBox from './LoadingBox';
import LoadingHeader from './LoadingHeader';

const PortalWidgetSetting = () => {
    const activeCustomerPortal = useSelector(({ auth }) => auth.user.settings.addons.customer_portal);
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, data: {} });
    const { isLoading, data = {} } = state;
    const { background_variant, button_shape = TAB_BUTTON_SHAPE[0].value, color } = data;
    const finalColor =
        SETTING_PORTAL_WIDGET.LIST_COLOR_PORTAL.find(({ color: itemColor }) => itemColor === color) ||
        SETTING_PORTAL_WIDGET.DEFAULT_COLOR;
    const finalBackgroundVariant =
        BACKGROUND_VARIANT_OPTION[background_variant?.toUpperCase()] || BACKGROUND_VARIANT_OPTION.SOLID_COLOR;

    const refButtonSave = useRef(null);
    const refAlert = useRef(null);

    useEffect(() => {
        if (!activeCustomerPortal) return;
        _getPortalWidget();
    }, []);

    const _getPortalWidget = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState({ data: data || {}, isLoading: false });
        };
        const _handleFailed = ({ message }) => {
            _showAlert({ message });
            dispatchState({ isLoading: false });
        };
        clientQuery(ADDONS_PORTAL_SETTING, { data: {}, method: 'GET' }, _handleSuccess, _handleFailed);
    };

    const _handleSave = () => {
        const _handleSuccess = () => {
            _showAlert({ message: t('addons:update_portal_widget_successfully'), success: true });
            _removeLoading();
        };
        const _handleFailed = () => {
            _showAlert({ message: t('addons:update_portal_widget_failed') });
            _removeLoading();
        };
        clientQuery(ADDONS_PORTAL_SETTING, { data, method: 'PUT', toFormData: false }, _handleSuccess, _handleFailed);
    };

    const _showAlert = ({ message, success = false }) => {
        showStatusBar({ id: 'portal-widget', message, success, refAlert });
    };

    const _removeLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const _handleChangeValue = ({ value = '', key = '' }) => {
        dispatchState((prev) => ({ data: { ...prev.data, [key]: value } }));
    };

    if (!activeCustomerPortal) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container">
            {isLoading ? (
                <LoadingHeader />
            ) : (
                <div className="header">
                    <AddonBack title={t('addons:customer_portal')} path={ADDONS_PORTAL} />
                </div>
            )}
            <div className="scrolls">
                <div className="boxs-wrapper">
                    <GDStatusBar ref={refAlert} />
                    {isLoading ? (
                        <LoadingBox />
                    ) : (
                        <div className="boxs boxs-has-footer --setting-portal">
                            <div className="boxs__contents flex-column gap-16">
                                <ItemButtonShape buttonShape={button_shape} onChangeValue={_handleChangeValue} />
                                <ItemPortalColor portalColor={finalColor} onChangeValue={_handleChangeValue} />
                                <ItemBackgroundVariant
                                    backgroundVariant={finalBackgroundVariant}
                                    onChangeValue={_handleChangeValue}
                                />
                            </div>
                            <div className="boxs__footer">
                                <ButtonSave ref={refButtonSave} disabled={isLoading} onSave={_handleSave} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PortalWidgetSetting;
