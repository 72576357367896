import React, { useReducer, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import GdConfirm from 'app/components/confirm';
import StatusBar from 'app/components/status/statusbar';
import { ACTION_CHANGE_ORDER_ITEM, actionMaterialItem } from 'app/const/api/Material';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import AddItem from './AddItem';
import Header from './Header';
import Item from './Item';

export default function Column({ data }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        ...data
    });

    const finalParentType = data.type;
    const { items: finalItems = [], name } = state;
    const refStatusBar = useRef(null);
    const refConfirm = useRef(null);

    function _handleUpdateHeader(mewData) {
        dispatchState({
            ...mewData
        });
    }

    function _handleAddNewMessage(id, message, type) {
        refStatusBar.current.showStatusBar(id, message, type);
    }

    function _handleUpdateItem(itemId, newData) {
        dispatchState((prevState) => {
            let tempItems = [...prevState.items];
            tempItems = tempItems.map((item) => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        ...newData
                    };
                }
                return item;
            });
            return {
                ...prevState,
                items: tempItems
            };
        });
    }

    function _openConfirmEdit(finalItemId) {
        refConfirm.current.open(finalItemId);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: 'none',
        opacity: isDragging ? 0.7 : 1,
        border: 1
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function _onDragEnd(result) {
        if (!result.destination) {
            return false;
        }

        let newItems = reorder([...finalItems], result.source.index, result.destination.index);

        newItems = newItems.map((item, index) => {
            return {
                ...item,
                sort_order: index + 1
            };
        });

        dispatchState({
            items: newItems
        });

        clientQuery(ACTION_CHANGE_ORDER_ITEM, {
            method: 'PUT',
            data: {
                id: result.draggableId,
                to: result.destination.index + 1
            }
        });
    }

    function _renderItems() {
        return (
            <DragDropContext onDragEnd={_onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {finalItems.map((currentItem, index) => {
                                const serviceItemId = currentItem.id;
                                return (
                                    <Draggable key={serviceItemId} draggableId={serviceItemId} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Item
                                                    onConfirm={_openConfirmEdit}
                                                    onUpdate={_handleUpdateItem}
                                                    onShowMessage={_handleAddNewMessage}
                                                    dataItem={currentItem}
                                                    parentType={finalParentType}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    function _handleDeleteItem(deleteId) {
        let tempItems = [...finalItems];
        tempItems = tempItems
            .filter((item) => item.id !== deleteId)
            .map((newItem, index) => {
                return {
                    ...newItem,
                    sort_order: index + 1
                };
            });
        dispatchState({
            items: tempItems
        });
    }

    function _handleDelete(deleteId) {
        _handleDeleteItem(deleteId);

        clientQuery(actionMaterialItem(deleteId), { method: 'DELETE' }, _handleDeleteSuccess);
    }

    function _handleDeleteSuccess(response) {
        refStatusBar.current.showStatusBar(
            new Date().getTime(),
            response?.message?.toString() || t('addons:delete_success'),
            LIST_STATUS.SUCCESS
        );
    }

    function _handleAddNew(newData) {
        let tempItems = [...finalItems, newData];

        tempItems = tempItems.map((item, index) => {
            return {
                ...item,
                sort_order: index + 1
            };
        });

        dispatchState({
            items: tempItems
        });
    }

    return (
        <div className={`boxs --material ${state.status ? 'active' : ''}`}>
            <StatusBar ref={refStatusBar} />
            <Header
                onUpdate={_handleUpdateHeader}
                onShowMessage={_handleAddNewMessage}
                id={state.id}
                type={state.type}
                name={state.name}
                status={state.status}
            />
            <div className="boxs__contents">
                {_renderItems()}
                <AddItem
                    parentType={finalParentType}
                    onAdd={_handleAddNew}
                    onUpdate={_handleUpdateItem}
                    onDelete={_handleDeleteItem}
                    onShowMessage={_handleAddNewMessage}
                    sortOrder={(finalItems?.length || 0) + 1}
                    placeholder={name}
                />
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('addons:delete_item')}
                message={t('common:are_you_sure_delete_this_item')}
                listButton={{ cancel: true, confirm: true }}
                onConfirm={_handleDelete}
            />
        </div>
    );
}
