import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_DEVICE_TRACKING } from 'app/config/routes';
import { ADDONS_STATUS_ACTIVE } from 'app/const/addons';
import { ADDONS_DEVICE_TRACKING_SETTING } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import AddonBack from '../../components/AddonBack';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';
import { ADDON_LEVEL } from '../../constants';
import { KEYWORD_SENTRICON } from './constant';
import Loading from './Loading';

const SentriconManage = () => {
    const { t } = useTranslation();
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const isActiveSentricon = useSelector(({ auth }) => auth.user.settings.addons.device_tracking?.sentricon);
    const { isBasicPlan } = getPlanUser(userProfile);
    const havePermission = !isBasicPlan && !!isActiveSentricon;
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, data: {} });
    const { isLoading, data } = state;
    const { sentricon = {} } = data || {};
    const { branch_code = '' } = sentricon;

    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refBranchCode = useRef(null);

    useEffect(() => {
        if (!havePermission) return;
        _getDeviceTrackingSetting();
    }, []);

    const _getDeviceTrackingSetting = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState({ data: data || {}, isLoading: false });
        };
        const _handleFailed = ({ message }) => {
            _showAlert({ message });
            dispatchState({ isLoading: false });
        };
        clientQuery(ADDONS_DEVICE_TRACKING_SETTING, { data: {}, method: 'GET' }, _handleSuccess, _handleFailed);
    };

    const _handleSave = () => {
        const finalCode = refBranchCode.current ? refBranchCode.current.value.trim() : '';
        if (!finalCode) {
            _showAlert({ message: t('customers:cannot_be_blank', { name: t('addons:sentricon_branch_code') }) });
            refBranchCode.current && refBranchCode.current.focus();
            _removeLoading();
            return;
        }
        const _handleSuccess = ({ message }) => {
            _showAlert({ message, success: true });
            _removeLoading();
        };
        const _handleFailed = ({ message }) => {
            _showAlert({ message });
            _removeLoading();
        };
        clientQuery(
            ADDONS_DEVICE_TRACKING_SETTING,
            {
                data: { value: finalCode, keyword: KEYWORD_SENTRICON },
                method: 'PUT',
                toFormData: false
            },
            _handleSuccess,
            _handleFailed
        );
    };

    const _showAlert = ({ message, success = false }) => {
        showStatusBar({ id: 'device-tracking-sentricon', message, success, refAlert });
    };

    const _removeLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container">
                <div className="header">
                    <AddonBack path={ADDONS_DEVICE_TRACKING} />
                </div>
                <div className="scrolls">
                    <GDStatusBar ref={refAlert} />
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="boxs-wrapper boxs-wrapper--sms">
                            <div className="boxs boxs--no-border --hasline">
                                <AddonsBoxHeader
                                    keyword={KEYWORD_SENTRICON}
                                    name={t('addons:sentricon')}
                                    status={ADDONS_STATUS_ACTIVE}
                                    level={ADDON_LEVEL.PRO}
                                    isHideCheckbox
                                />
                                <div className="boxs__contents">
                                    <p className="description">{t('addons:sentricon_desc')}</p>
                                    <p className="description mt-3">{t('addons:sentricon_sub_desc')}</p>
                                </div>
                            </div>
                            <div className="has-form">
                                <span className="txt mb-1">{t('addons:enter_sentricon_branch_code')}</span>
                                <div className="flexcenter gap-8">
                                    <input
                                        id={KEYWORD_SENTRICON}
                                        ref={refBranchCode}
                                        className="field-input flex-1"
                                        placeholder={t('addons:enter_sentricon_branch_code')}
                                        defaultValue={branch_code}
                                    />
                                    <ButtonSave ref={refButtonSave} disabled={isLoading} onSave={_handleSave} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SentriconManage;
