import { AUTH_TYPE } from 'common/redux/actions/authAction';
import { ACCESS_TOKEN, KEY_CURRENT_BRANCH, KEY_MODAL_SMS_CHARGE_FAILED } from 'app/const/App';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { setJobStatusGlobal } from 'common/utils/JobStatusUtils';
import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import i18n from 'assets/i18n';
import { EN } from 'app/const/Settings';

const initialState = {
    signUpSuccess: false,
    errorActivate: false,
    errorMessage: null,
    forgotPasswordSuccess: {},
    changePasswordSuccess: {},
    isLogin: false,
    errors: {},
    user: null
};

const authReducer = (
    state = {
        signUpSuccess: false,
        errorActivate: false,
        errorMessage: null,
        forgotPasswordSuccess: {},
        changePasswordSuccess: {},
        isLogin: false,
        errors: {},
        user: null
    },
    action
) => {
    const payload = action?.payload || null;
    const jobStatus = payload?.settings?.job_statuses;
    if (!!jobStatus) setJobStatusGlobal(jobStatus);

    switch (action.type) {
        case AUTH_TYPE.CLEAR_USER_DATA: {
            i18n.changeLanguage(EN);
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(KEY_CURRENT_BRANCH);
            setLocalStorage(KEY_MODAL_SMS_CHARGE_FAILED, true);
            window.location.reload();
            return initialState;
        }
        case AUTH_TYPE.SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                signUpSuccess: true
            };
        }

        case AUTH_TYPE.SIGNUP_USER_ERROR: {
            return {
                ...state,
                errorMessage: action.payload
            };
        }

        case AUTH_TYPE.CLEAR_INFO_SIGNUP: {
            return initialState;
        }

        case AUTH_TYPE.ACTIVATE_USER_ACCOUNT_ERROR: {
            return {
                ...state,
                errorActivate: true,
                errorMessage: action.payload
            };
        }

        case AUTH_TYPE.LOGIN_USER_REQUESTING:
            return {
                ...state,
                isLogin: false,
                errors: {}
            };
        case AUTH_TYPE.LOGIN_USER_SUCCESS:
            localStorage.setItem(ACCESS_TOKEN, action?.payload?.token);
            localStorage.setItem(KEY_CURRENT_BRANCH, action?.payload?.company.branch.id);

            return {
                ...state,
                isLogin: true,
                token: action?.payload?.token,
                user: action?.payload,
                errors: {}
            };
        case AUTH_TYPE.LOGIN_USER_ERROR:
            localStorage.removeItem(ACCESS_TOKEN);
            return {
                ...state,
                isLogin: false,
                errors: action.payload
            };
        case AUTH_TYPE.USER_RETRIEVE_SUCCESS: {
            return {
                ...state,
                isLogin: true,
                user: action?.payload,
                errors: {}
            };
        }
        case AUTH_TYPE.FORGOT_PASSWORD_USER_REQUESTING:
            return {
                ...state,
                errors: {}
            };
        case AUTH_TYPE.FORGOT_PASSWORD_USER_SUCCESS:
            return {
                ...state,
                errors: {},
                forgotPasswordSuccess: action.payload
            };
        case AUTH_TYPE.FORGOT_PASSWORD_USER_ERROR:
            return {
                ...state,
                errors: action.payload
            };
        case AUTH_TYPE.CHANGE_PASSWORD_USER_REQUESTING:
            return {
                ...state,
                errors: {}
            };
        case AUTH_TYPE.CHANGE_PASSWORD_USER_SUCCESS:
            return {
                ...state,
                errors: {},
                changePasswordSuccess: action.payload
            };
        case AUTH_TYPE.CHANGE_PASSWORD_USER_ERROR:
            return {
                ...state,
                errors: action.payload
            };
        case AUTH_TYPE.UPDATE_COMPANY_SETTINGS:
            const newState = { ...state };
            newState['user']['company'] = { ...state.user.company, ..._getPayloadCompanyCompress(payload) };
            newState['user']['settings'] = { ...state.user.settings, timezone: payload.timezone };
            return newState;

        case AUTH_TYPE.UPDATE_SETTINGS:
            const newSettings = { ...state };
            newSettings['user']['settings'] = { ...state.user.settings, ...payload };
            return newSettings;
        case AUTH_TYPE.GET_EMAIL_INBOX_SIGNATURE_SUCCESS:
            const preState = { ...state };
            const newReponseData = { ...action.payload.data };

            return {
                ...preState,
                user: {
                    ...preState.user,
                    signature: newReponseData.signature,
                    isActiveEmail: !!newReponseData.account,
                    isFetchSignature: true
                }
            };
        case AUTH_TYPE.UPDATE_EMAIL_INBOX_SIGNATURE:
            return { ...state, user: { ...state.user, signature: action.payload } };
        case AUTH_TYPE.UPDATE_ADDONS_STATUS:
            const addonsUpdate = { ...state };
            const newSettingsAddons = { ...addonsUpdate.user.settings.addons };
            newSettingsAddons[payload.keyword.toLowerCase()] = payload.data;
            addonsUpdate['user']['settings']['addons'] = {
                ...state.user.settings.addons,
                ...newSettingsAddons
            };
            return addonsUpdate;
        case AUTH_TYPE.ACTIVE_SMS_CHARGE:
            const activeSmsCharge = { ...state };
            activeSmsCharge['user']['notifications']['sms'] = null;
            return activeSmsCharge;
        case AUTH_TYPE.UPDATE_URL_CUSTOMER_PORTAL:
            const newUrlPortal = { ...state };
            newUrlPortal['user']['settings'] = { ...state.user.settings, ...payload };
            return newUrlPortal;
        case AUTH_TYPE.UPDATE_HEADER_PIN_MENU:
            const newPinHeader = { ...state };
            newPinHeader['user']['settings'] = { ...state.user.settings, header_menu: payload };
            return newPinHeader;
        case AUTH_TYPE.UPDATE_SAVE_FILTER_TIMELINE:
            const newDataSettingsTimeLine = { ...state };
            newDataSettingsTimeLine['user']['settings'] = { ...state.user.settings, ...payload };
            return newDataSettingsTimeLine;
        case AUTH_TYPE.UPDATE_ADDON_SMS_SUPPORT:
            const newStateSms = { ...state };
            newStateSms['user']['company'] = { ...state.user.company, ...payload };
            return newStateSms;
        case AUTH_TYPE.UPDATE_ACCOUNT_PLAN:
            const newStatePlan = { ...state };
            newStatePlan['user']['profile']['plan'] = { ...state.user.profile.plan, ...payload };
            return newStatePlan;
        case AUTH_TYPE.UPDATE_PROFILE:
            const newProfile = { ...state };
            newProfile['user']['profile'] = { ...state.user.profile, ...payload };
            return newProfile;
        case AUTH_TYPE.UPDATE_TRACKING:
            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        ...state.user.settings,
                        time_tracking: { ...(state?.user?.settings?.time_tracking || {}), ...(payload || {}) }
                    }
                }
            };
        case AUTH_TYPE.FAIL_PERMISSION:
            const newPermissions = { ...state };
            newPermissions['user']['permissions'] = {
                ...state.user.permissions,
                enabled: state.user.permissions.enabled.filter((item) => item !== payload)
            };
            return newPermissions;
        case AUTH_TYPE.UPDATE_JOB_STATUS:
            return { ...state, user: { ...state.user, settings: { ...state.user.settings, job_statuses: payload } } };
        case AUTH_TYPE.UPDATE_ADVANCE_ROUTING:
            const newStateAdvanceRouting = { ...state };
            newStateAdvanceRouting['user']['settings']['addons']['routing'][payload.keyword] = payload.data;
            return newStateAdvanceRouting;
        default:
            return state;
    }
};

const _getPayloadCompanyCompress = (payload) => {
    const result = {
        name: payload.name,
        email: payload.email,
        phone: payload.phone,
        website: payload.website,
        date_format: payload.date_format,
        country: payload.country,
        currency: payload.currency,
        distance_type: payload.distance_type || ROUTING_UNITS.KM['value'],
        holiday: payload.holiday || { value: false, countries: [] }
    };

    if (payload.newLogoUrl) result['logo'] = payload.newLogoUrl;
    if (payload.coordinate) {
        result['lat'] = payload.coordinate.lat;
        result['lng'] = payload.coordinate.lng;
    }
    return result;
};

export default authReducer;
