import classNames from 'classnames';
import React, { forwardRef, Fragment, useImperativeHandle, useReducer, useRef } from 'react';

import Loading from 'app/components/templatenotes/Loading';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { reducer } from 'app/const/Reducer';
import { GET_TEMPLATES_NOTE } from 'app/const/api/Notes';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { PAYMENT_TERM_OFF } from 'app/modules/settings/templatesManager/components/notes/const';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { clientQuery } from 'common/utils/ApiUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import PaymentTermName from './PaymentTermName';

const LocationPaymentTerm = forwardRef(({ id, defaultSelected = null }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        selected: defaultSelected || PAYMENT_TERM_OFF,
        data: [PAYMENT_TERM_OFF],
        isLoading: true,
        show_more: false,
        isLoadingMore: false,
        isFirstTime: true,
        cursor: ''
    });
    const { selected, data: finalData, show_more, isLoading, isLoadingMore, isFirstTime, cursor } = state;

    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({ _getValue: () => selected.id || '' }));

    const getListPaymentTerm = (cursor = '', isLoadingMore = false) => {
        if (!isFirstTime && !isLoadingMore) return;
        if (isLoadingMore) dispatchState((prev) => ({ ...prev, isLoadingMore: true }));
        clientQuery(
            GET_TEMPLATES_NOTE,
            { method: 'GET', data: { type: NOTES_TEMPLATE_TYPE.PAYMENT_TERMS, cursor } },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleSuccess = ({ data, show_more, cursor }) => {
        dispatchState((prev) => ({
            ...prev,
            isLoading: false,
            data: [...finalData, ...data],
            show_more,
            isLoadingMore: false,
            isFirstTime: false,
            cursor
        }));
    };

    const _handleFail = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false, isLoadingMore: false, isFirstTime: false }));
    };

    const _handleSelect = (item) => {
        dispatchState((prev) => ({ ...prev, selected: item }));
        refDropdown.current._closeDropdown();
    };

    const _handleOnScrollContent = (e) => {
        if (!isLoadingMore && isScrollToEndBottom(e.target) && show_more && !isFirstTime) {
            getListPaymentTerm(cursor, true);
        }
    };

    return (
        <CalendarDropdown
            id={id}
            ref={refDropdown}
            isDropUp
            isLoading={isLoading}
            customDropButton={() => (
                <Fragment>
                    <PaymentTermName {...selected} />
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </Fragment>
            )}
            wrapperClassName="dropdown-template"
            onVisible={getListPaymentTerm}
            onSelect={_handleSelect}
        >
            <ul className="container-column scrolls" onScroll={_handleOnScrollContent}>
                {finalData.map((item) => {
                    const { id } = item;
                    return (
                        <li
                            key={id}
                            className={classNames('items', { active: id === selected.id })}
                            onClick={() => _handleSelect(item)}
                        >
                            <PaymentTermName {...item} />
                        </li>
                    );
                })}
                {isLoadingMore ? <Loading /> : null}
            </ul>
        </CalendarDropdown>
    );
});

export default LocationPaymentTerm;
