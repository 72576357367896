import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import './styles/index.scss';
import i18n from 'assets/i18n';

const EmojiMart = ({ handleOnClick = () => {} }) => {
    const addEmoji = (value) => handleOnClick(value);
    return (
        <Picker
            data={data}
            autoFocus
            theme="light"
            onEmojiSelect={addEmoji}
            previewPosition="none"
            perLine={8}
            locale={i18n.language}
        />
    );
};

export default EmojiMart;
