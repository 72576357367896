import React, { Fragment, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { SETTINGS_SERVICE } from 'app/config/routes';
import { ACTION_SERIVES } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { TABS } from 'app/modules/jobdetail/const';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { DEFAULT_VALUE } from 'app/modules/jobdetail/const/Invoice';
import DataRepeatContextProvider from 'app/modules/jobdetail/contexts/DataRepeatContext';
import MainHeaderSettings from 'app/modules/settings/components/MainHeaderSettings';
import { useEnterKeydownClick } from 'common/hooks';
import { resetServiceList } from 'common/redux/actions/settings/serviceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import DocumentsService from './documents';
import EstimateService from './estimate';
import ServiceInfo from './info';
import InvoiceService from './invoice';
import MaterialService from './materials';
import SideBar from './SideBar';
import TodoService from './todo';

export default function AddService() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const refButtonSave = useRef(null);
    const [state, dispatchState] = useReducer(reducer, {
        tab: TABS.DETAIL
    });
    const finalCurrentTab = state.tab;
    const refInvoiceData = useRef(null);
    const refEstimateData = useRef(null);
    const refStatusBar = useRef(null);
    const refServiceColor = useRef(null);
    const history = useHistory();

    useEnterKeydownClick(true);

    const defaultData = useMemo(() => {
        return {
            isAddService: true,
            name: '',
            repeat: 0,
            recurrence: {
                offset: {},
                summary: t('calendar:once')
            },
            exception: {
                options: {
                    first: null,
                    second: null,
                    third: null
                }
            },
            length: 30,
            color_code: '#1E1FE1',
            documents: [],
            materials: [],
            always_confirmed: 0,
            todos: null
        };
    }, []);

    function _handleSaveService() {
        const { invoiceData, serviceData } = refInvoiceData.current?.getValue() || null;
        const estimateData = refEstimateData.current?.getValue() || null;
        const serviceTime = refServiceColor.current.getValue();

        if (serviceData.name.trim().length === 0) {
            if (finalCurrentTab !== TABS.DETAIL) {
                dispatchState({
                    tab: TABS.DETAIL
                });
            }
            document.getElementById('input_service_name').classList.add('field-error');
            _showStatusError();
            refButtonSave.current.removeLoading();
            return false;
        }

        const convertedInvoice = invoiceData ? _handleSaveInvoice(invoiceData, true) : null;

        if (convertedInvoice === false) {
            return false;
        }
        const refDataEstimate = estimateData ? _handleSaveInvoice(estimateData, false) : null;

        if (refDataEstimate === false) {
            return false;
        }

        const { todos: serviceTodos = {} } = serviceData;

        const newJobData = {
            service: {
                ...serviceTime,
                name: serviceData.name,
                always_confirmed: serviceData.always_confirmed,
                color_code: serviceData.color_code,
                recurrence: { ...serviceData.recurrence.offset },
                exception: serviceData.exception,
                job_cycle: serviceData?.job_cycle || 1
            },
            invoice: convertedInvoice,
            estimate: refDataEstimate,
            documents: serviceData.documents.map((item) => item.parent_id),
            materials: serviceData.materials.filter((item) => item.paramAdd).map((itemMap) => itemMap.paramAdd),
            todos: {
                data: serviceTodos?.data || []
            }
        };

        clientQuery(ACTION_SERIVES, { method: 'POST', data: newJobData }, _createJobSuccess, _createServiceailed);
    }

    function _createJobSuccess(response) {
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || t('common:success'),
            LIST_STATUS.SUCCESS
        );
        refButtonSave.current.removeLoading();
        dispatch(resetServiceList());
        setTimeout(() => {
            _handleCalcel();
        }, 1000);
    }

    function _createServiceailed(response) {
        _showStatusError(response?.message?.toString() || t('common:please_try_again'));
        refButtonSave.current.removeLoading();

        setTimeout(() => {
            _handleCalcel();
        }, 1000);
    }

    function _showStatusError(message, isInvoice) {
        refStatusBar.current.showStatusBar(
            'show_error',
            message ||
                t(
                    `common:${
                        isInvoice
                            ? 'one_or_more_invoice_field_not_complete'
                            : 'one_or_more_your_fields_are_not_complete'
                    }`
                ),
            LIST_STATUS.ERROR
        );
    }

    const _checkDeposit = (isInvoice, depositValue, total, packageId) => {
        const finalValueDeposit = parseFloat(depositValue.total.value);

        if (parseFloat(total) >= 0 && finalValueDeposit > parseFloat(total)) {
            document.getElementById(`deposit_cost_${packageId}`).classList.add('field-error');
            _handleError(
                t('common:message_error_value_deposit_with_name', {
                    name: t(`common:${isInvoice ? 'invoice' : 'estimate'}`)
                }),
                isInvoice
            );
            return false;
        }
        return true;
    };

    const _handleError = (msg, isInvoice) => {
        _showStatusError(msg, isInvoice);

        dispatchState({
            tab: isInvoice ? TABS.INVOICE : TABS.ESTIMATE
        });

        refButtonSave.current.removeLoading();
        return false;
    };

    function _handleSaveInvoice(refValue, isInvoice) {
        const { items: listItems, type: estimateType, packages: estimatePackages, total: invoiceTotal } = refValue;
        const itemsSubmit = [];
        let checkPass = true;
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        if (!isInvoice && estimateType === ESTIMATE_TYPE.PACKAGES) {
            let checkPassDeposit = true;

            estimatePackages.every((itemPackage) => {
                const { id: packageId, name: packageName, deposit, total } = itemPackage;

                checkPassDeposit = _checkDeposit(isInvoice, deposit, total, packageId);

                if (!checkPassDeposit) {
                    return false;
                }

                if (!!!packageName) {
                    checkPass = false;
                    document.getElementById(`package_item_name_${packageId}`).classList.add('field-error');
                }

                if (!listItems.some((lineItem) => lineItem.package_id === packageId)) {
                    checkPass = false;
                    document.getElementById(`package_item_detail_${packageId}`).classList.remove('dp-hide');
                }

                return true;
            });

            if (!checkPassDeposit) {
                return false;
            }
        } else {
            if (!_checkDeposit(isInvoice, finalDeposit, invoiceTotal, '')) {
                return false;
            }
        }

        if (!checkPass) {
            return _handleError('', isInvoice);
        }

        listItems.forEach((element) => {
            const checkItemCost = element.cost.value.length !== 0;
            const { item_id: serviceItemId, id: itemId, tax1: tax1Detail, tax2: tax2Detail } = element;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: element.cost.value,
                    tax1_id: tax1Detail?.id || '',
                    tax2_id: tax2Detail?.id || '',
                    tax1_name: tax1Detail?.name || '',
                    tax2_name: tax2Detail?.name || '',
                    tax1_rate: tax1Detail?.rate || '',
                    tax2_rate: tax2Detail?.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    type: element.type || '',
                    package_id: element.package_id || ''
                });
            } else {
                checkPass = false;

                if (!checkItemCost) {
                    const elmCost = document.getElementById(`service_item_cost_${itemId}`);
                    elmCost && elmCost.classList.add('field-error');
                }
                if (!serviceItemId) {
                    const elmItemId = document.getElementById(`service_item_detail_${itemId}`);
                    elmItemId && elmItemId.classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            return _handleError('', isInvoice);
        }

        const recurrence = { ...refValue.recurrence };
        const offset = recurrence.offset;
        const finalDiscount = { ...refValue.discount };
        delete offset.dateIssue;
        delete offset.nextDateInvoice;
        delete finalDiscount.total;

        return {
            customer_id: refValue.customer_id,
            discount: finalDiscount,
            deposit: isInvoice ? null : finalDeposit,
            number: refValue.number,
            po_number: refValue.po_number,
            date: refValue.date,
            items: itemsSubmit,
            subtotal: refValue.subtotal,
            total: refValue.total,
            trigger_action: '1',
            recurrence: {
                action: recurrence.action,
                offset: offset
            },
            location_id: refValue.location_id,
            terms: refValue.terms,
            note: refValue.note,
            packages: estimatePackages,
            type: estimateType,
            payment_terms_id: refValue.payment_terms.id || ''
        };
    }

    function _handleChangeTab(tabChange) {
        dispatchState({ tab: tabChange });
    }

    function _handleDeleteTab() {
        dispatchState({ tab: TABS.DETAIL });
    }

    function _handleCalcel() {
        history.goBack();
    }

    const _handleTriggerButtonSave = (value) => {
        refButtonSave.current.setDisable(value);
    };

    return (
        <Fragment>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <div className="contents-pages service-edit c-job-details">
                    <div className="wrap-content-service accessible-tabs-container wrapper-box-edit">
                        <DataRepeatContextProvider defaultData={{ ...defaultData }}>
                            <div className="content-services contents">
                                <SideBar
                                    classStyle="content-services__left"
                                    onChange={_handleChangeTab}
                                    tabActive={finalCurrentTab}
                                />
                                <div className="content-services__right tab-contents flex-column scrolls">
                                    <StatusBar ref={refStatusBar} />
                                    <ServiceInfo ref={refServiceColor} tabActive={finalCurrentTab === TABS.DETAIL} />
                                    <InvoiceService
                                        tabActive={finalCurrentTab === TABS.INVOICE}
                                        ref={refInvoiceData}
                                        onDelete={_handleDeleteTab}
                                        onTriggerBtnSave={_handleTriggerButtonSave}
                                    />
                                    <EstimateService
                                        tabActive={finalCurrentTab === TABS.ESTIMATE}
                                        ref={refEstimateData}
                                        onDelete={_handleDeleteTab}
                                        onTriggerBtnSave={_handleTriggerButtonSave}
                                    />
                                    <MaterialService tabActive={finalCurrentTab === TABS.MATERIALS} />
                                    <DocumentsService tabActive={finalCurrentTab === TABS.DOCUMENTS} />

                                    <TodoService
                                        tabActive={finalCurrentTab === TABS.CHECK_LIST}
                                        onDelete={_handleDeleteTab}
                                    />
                                </div>
                            </div>
                        </DataRepeatContextProvider>
                        <div className="form-footer">
                            <div className="form-footer__action">
                                <Link to={addBranchPath(SETTINGS_SERVICE)} className="v2-btn-default">
                                    {t('common:close')}
                                </Link>
                                <ButtonSave ref={refButtonSave} onSave={_handleSaveService} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
