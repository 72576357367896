import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { getDefaultCustomerDetailParams } from 'app/const/customer/CustomerParams.js';
import {
    KEY_CUSTOMER_DETAIL_CREDIT_LOCAL_STORAGE,
    KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE,
    KEY_CUSTOMER_DETAIL_ESTIMATE_LOCAL_STORAGE,
    KEY_CUSTOMER_DETAIL_LOCATION_LOCAL_STORAGE,
    KEY_CUSTOMER_DETAIL_INVOICE_LOCAL_STORAGE,
    COMMON,
    DEFAULT_PAGE,
    KEY_REPORT_LOCAL_STORAGE
} from 'app/const/App';
import { DEFAULT_CUSTOMER_ADD_LOCATION_TABS, LIMIT_ATTACHMENT } from 'app/const/Customers';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { REPORT_TYPE } from 'app/const/Reports';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { convertLinkInString } from 'common/utils/StringUtils';
import { KEY_LOCAL_SHOW_SUB_LOCATION } from '../const';
import IconBell from 'assets/icon/IconBell';
import IconInvoice from 'assets/icon/IconInvoice';
import i18n from 'assets/i18n';
import { LIST_OPTIONS_SHCEDULE } from '../const/Email';

export const customersToTable = (data) =>
    data.map((item) => {
        return {
            id: item.id,
            status: item.status,
            account_number: item.account_number,
            customer: item.customer,
            title: item.title || '',
            first_name: item.customer?.first_name || '',
            last_name: item.customer?.last_name || '',
            emails: item.emails,
            added_by: item.added_by,
            phones: item.phones.all,
            phones_office: item.phones.office,
            phones_mobile: item.phones.mobile,
            phones_home: item.phones.home,
            top_note: item.top_note,
            tags: item.tags,
            service: item.job?.name,
            next_service_date: item.job?.next_date,
            last_service_date: item.job?.last_date,
            service_address: item.service,
            service_address_1: item.service.line1,
            service_address_2: item.service.line2,
            service_address_city: item.service.city,
            service_address_state: item.service.state,
            service_address_zip: item.service.zip,
            billing_address: item.billing,
            billing_address_1: item.billing.line1,
            billing_address_city: item.billing.city,
            billing_address_state: item.billing.state,
            billing_address_zip: item.billing.zip,
            tax_rate_1: item.taxes.tax1?.rate ? `${item.taxes.tax1.rate}%` : '',
            tax_rate_2: item.taxes.tax2?.rate ? `${item.taxes.tax2.rate}%` : '',
            source: item.source,
            cards: item.cards,
            bank_accounts: item.bank_accounts || [],
            quickbooks: item.quickbooks,
            qb_id: item.quickbooks.id || null,
            balance: item.balance,
            company: item.company,
            created_at: item.created_at,
            sub_locations: getSubLocations(item.sub_locations || [], { customer: item.customer, status: item.status })
        };
    });

export const customersWithSubLocations = (prevData = [], customerData = []) => {
    const lastCustomerId = prevData[prevData.length - 1]?.id || '';
    const newDataConverted = customersToTable(customerData || []);
    // If last customer data is the same as the first customer data,
    // Add sub_locations to the last prevData
    if (lastCustomerId === newDataConverted[0]?.id) {
        const customer = newDataConverted.shift();
        const lastCustomer = prevData[prevData.length - 1];
        lastCustomer['sub_locations'] = [...lastCustomer['sub_locations'], ...(customer['sub_locations'] || [])];
        return [...prevData, ...newDataConverted];
    }
    return [...prevData, ...newDataConverted];
};

const getSubLocations = (data, additionalData = {}) => {
    return data.map((item) => ({
        id: item.id,
        phones: item.phones,
        emails: item.emails,
        service_address: item.service,
        service_address_1: item.service.line1,
        service_address_2: item.service.line2,
        service_address_city: item.service.city,
        service_address_state: item.service.state,
        service_address_zip: item.service.zip,
        billing_address: item.billing,
        billing_address_1: item.billing.line1,
        billing_address_city: item.billing.city,
        billing_address_state: item.billing.state,
        billing_address_zip: item.billing.zip,
        ...additionalData
    }));
};

export const customersDocuments = (data) =>
    data.map((item) => {
        return {
            id: item.id,
            document: item.number,
            number: item.number,
            status: item.status,
            account_number: item.account_number,
            customer: item.customer,
            email: item.emails,
            type: item.type,
            name: item.name,
            job: item.job,
            created_by: item.created_by,
            date_created: item.date_created,
            key_status: item.key_status
        };
    });

export const customerStatements = ({ data }) =>
    data.map((item) => {
        const { city = '', state = '', street1 = '', zip = '' } = item.service || {};
        let fullServiceAddress = '';
        let fullStreet = '';

        if (street1) fullStreet += `${street1}`;
        if (city) fullServiceAddress += `${city},`;
        if (state) fullServiceAddress += ` ${state}`;
        if (zip) fullServiceAddress += ` ${zip}`;

        return {
            id: item.id,
            date: item.date,
            invoice: `#${item.number}`,
            po_number: item?.po_number || '',
            service: item.service_name,
            payment_date: item?.payment_date || '',
            address: `${fullStreet} ${fullServiceAddress}`,
            amount: item.amount,
            paid: item.paid,
            balance: item.balance,
            method: item.method,
            locationName: item.location_name || ''
        };
    });

export const generateImportNumberKey = (user_id) =>
    `notification_customer_${user_id}${Math.floor(Math.random() * 1000)}${new Date().getTime()}`;

export const getLocalStorageCustomerPage = ({ namePage = null, customerId = null, key = COMMON.PARAMS }) => {
    const listNamesPage = [
        KEY_CUSTOMER_DETAIL_CREDIT_LOCAL_STORAGE,
        KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE,
        KEY_CUSTOMER_DETAIL_ESTIMATE_LOCAL_STORAGE,
        KEY_CUSTOMER_DETAIL_LOCATION_LOCAL_STORAGE,
        KEY_CUSTOMER_DETAIL_INVOICE_LOCAL_STORAGE
    ];
    if (!listNamesPage.includes(namePage)) return false;

    let result = getLocalStorage(namePage);

    if (!result) {
        result = {
            [COMMON.PARAMS]: getDefaultCustomerDetailParams(namePage) || {},
            [COMMON.CURRENT_PAGE]: DEFAULT_PAGE
        };
        if (customerId) result.params.customer_id = customerId;
        setLocalStorage(namePage, result);
    }

    switch (key) {
        case COMMON.PARAMS:
            return result[COMMON.PARAMS];
        case COMMON.CURRENT_PAGE:
            return result[COMMON.CURRENT_PAGE];
        case COMMON.ALL:
            return result;
        default:
            return false;
    }
};

export const transformToCustomerType = ({ first_name = '', last_name = '', id = null }) => {
    const firstLetterFirstName = !!first_name ? first_name[0] : '';
    const firstLetterLastName = !!last_name ? last_name[0] : '';

    return {
        avatar: firstLetterFirstName.concat(firstLetterLastName).toUpperCase(),
        id: id,
        first_name: first_name,
        last_name: last_name,
        full_name: first_name?.concat(' ', last_name)
    };
};

export const generateCheckNumber = (item) => {
    return item.check_number ? `${item.method_name} #${item.check_number}` : item.method_name;
};

export const _returnCheckValue = (value, currency) => {
    if (!value) {
        return '';
    }
    const valueReturn = transformToCurrency(Math.abs(value), currency);

    return Number(value) < 0 ? `, (${valueReturn})` : `, ${valueReturn}`;
};

export const customerCredits = (data, currency) =>
    data.map((item) => {
        const { method_name, check_value, check_number } = item || {};

        const methodName = check_number ? `${generateCheckNumber(item)}` : `${method_name}`;

        return {
            id: item.id,
            method: `${methodName}${_returnCheckValue(item.check_value, currency)}`,
            date: item.date,
            amount: item.amount,
            status: item.status,
            quickbooks: item.quickbooks || {},
            check_value: check_value,
            method_name: method_name,
            check_number: check_number
        };
    });

export const customerLocation = (data) =>
    data.map((item) => {
        return {
            ...customerLocationItem(item),
            active_service: item.active_service || []
        };
    });

export const customerLocationItem = (data) => {
    let sms = '';
    let email = '';
    const {
        address: { service, billing }
    } = data;
    const serviceAddress = service.line1 || service.line2 || '';
    if (Array.isArray(data.messaging_preferences?.sms_to)) sms = data.messaging_preferences?.sms_to?.[0]?.phone || '';
    if (Array.isArray(data.messaging_preferences?.email_to))
        email = data.messaging_preferences?.email_to?.[0]?.email || '';

    return {
        id: data.id,
        deleted: data.deleted,
        location_name: data.location_name || '',
        notes: convertLinkInString(data.location_note || ''),
        sms,
        email,
        billing_emails: data.billing_emails || [],
        tags: data.tags || [],
        invoice_tags: data.invoice_tags || [],
        taxes: data.taxes || [],
        units: data.units || 0,
        address_to: data.address_to || '',
        service_address: serviceAddress,
        bill_to: billing.bill_to || '',
        billing_email: data.billing_email || [],
        billing_email_cc: data.billing_email_cc || [],

        address: data.address || {},

        service_address_city: service?.city || '',
        service_address_state: service?.state || '',
        service_address_zip: service?.zip || '',

        billing_address_1: billing?.line1 || '',
        billing_address_2: billing?.line2 || '',
        billing_address_city: billing?.city || '',
        billing_address_state: billing?.state || '',
        billing_address_zip: billing?.zip || '',

        address_lat: service?.address_lat || '',
        address_lng: service?.address_lng || ''
    };
};

export const customerEstimates = (data) =>
    data.map((item) => {
        return {
            id: item.id,
            date: item.date,
            job: item?.job || COMMON.N_A,
            location: item.location,
            number: item.number,
            status: item.status,
            total: item.total,
            invoice_number: item.invoice_number,
            invoice_id: item.invoice_id
        };
    });

export const customersPayments = (data, currency) =>
    data.map((item) => {
        const { check_number: checkNumber, memo, check_value } = item || {};

        const paymentCheckNumber = checkNumber ? `#${checkNumber}` : '' || memo ? ` / ${memo}` : '';

        const paymentMethod = `${item.method_name} ${paymentCheckNumber || ''}${_returnCheckValue(
            check_value,
            currency
        )}`;

        let methodCredit = '';

        if (!item.invoice_id) {
            methodCredit += `${i18n.t('customers:credit')} / ${paymentMethod}`;
        } else {
            methodCredit += paymentMethod;
        }

        return {
            id: item.id,
            key: item.key,
            invoice_number: item.invoice_number,
            method_name: item.method_name,
            date: item.date || '',
            amount: item.amount || '',
            invoice_status: item.invoice_status,
            payment_status: item.payment_status,
            invoice_id: item.invoice_id || '',
            total_received: item.invoice_received || '',
            invoice_total: item.invoice_total || '',
            amount_due: item.invoice_balance || '',
            check_number: item.check_number || '',
            memo: memo || '',
            methodName: !!item.invoice_id ? paymentMethod : methodCredit || '',
            quickbooks: item.quickbooks || {},
            payment_fee: item.payment_fee,
            payment_amount: item.payment_amount,
            check_value: item.check_value || ''
        };
    });

export const formatBillingAddress = (data = {}) => {
    let fullBillingAddress = '';
    const billingAddress = `${data.billing_address_1} ${data.billing_address_2}` || '';

    if (data.billing_city) {
        fullBillingAddress += `${data.billing_city}, `;
    }
    if (data.billing_state) {
        fullBillingAddress += data.billing_state;
    }
    if (data.billing_zip) {
        fullBillingAddress += ` ${data.billing_zip}`;
    }

    return {
        address: billingAddress,
        fullAddress: fullBillingAddress
    };
};

export const numberItemChecked = (totalSelected = 0, items = '', item = '') => {
    return `${totalSelected > 1 ? items || i18n.t('common:items') : item || i18n.t('common:item')}`;
};

export const sortPaymentMethod = (data = []) => {
    return data.sort((a, b) => {
        if (a.is_default === b.is_default) {
            if (a.is_default && a.name === PAYMENT_MERCHANT_KEYWORD.STRIPE)
                return -1; // a default comes first
            else return 0;
        } else if (a.is_default) {
            return -1; // a comes first
        } else {
            return 1; // b comes first
        }
    });
};

export const resetPagingLocation = ({ params, key }) => {
    const newParams = { ...params, offset: 0, currentPage: 1 };
    setLocalStorage(key, newParams);
    return newParams;
};

export const limitSizeAttachment = (size = 0) => {
    return size / 1024 > LIMIT_ATTACHMENT;
};

export const _getDefaultListPhones = (listPhone) => {
    if (!listPhone?.length) return null;
    const defaultListPhones = listPhone.map((phoneItem, index) => {
        return {
            id: phoneItem?.contact_id?.concat('_', index),
            phone: phoneItem.phone,
            type: {
                id: phoneItem.phone_type_id,
                name: phoneItem.phone_type
            }
        };
    });
    return defaultListPhones;
};

export const getParamsCustomerList = () => {
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CUSTOMERS);
    const paramsCustomers = getLocalStorage(keyLocalStore) || getDefaultParams(REPORT_TYPE.CUSTOMERS);
    !paramsCustomers && setLocalStorage(keyLocalStore, paramsCustomers);
    paramsCustomers['sub_locations'] = getLocalStorage(KEY_LOCAL_SHOW_SUB_LOCATION) === 'show' ? 1 : 0;
    return paramsCustomers;
};

export const handleAbortController = (abortController = null) => {
    if (!!abortController?.current) abortController.current.abort();
};

export const OVERRIDE_TYPE = {
    MESSAGING: 'MESSAGING',
    HIDE_INVOICE_BALANCE: 'HIDE_INVOICE_BALANCE',
    SHOW_INVOICE_BALANCE: 'SHOW_INVOICE_BALANCE'
};

export const OVERRIDE_TYPE_KEY = {
    [OVERRIDE_TYPE.MESSAGING]: 'location_preference',
    [OVERRIDE_TYPE.HIDE_INVOICE_BALANCE]: 'hide_balance',
    [OVERRIDE_TYPE.SHOW_INVOICE_BALANCE]: 'hide_balance'
};

export const MESSAGING_EMAIL_VALUE = 2;

export const defaultMessagingValue = (activate_sms = false) => {
    const defaultValue = { ...DEFAULT_CUSTOMER_ADD_LOCATION_TABS };
    if (activate_sms) return defaultValue;

    for (const key in defaultValue) {
        if (Object.hasOwn(defaultValue, key)) {
            defaultValue[key] = MESSAGING_EMAIL_VALUE;
        }
    }

    return defaultValue;
};

export const CUSTOMER_OVERRIDE_OPTIONS = [
    { id: 1, name: 'messaging', value: OVERRIDE_TYPE.MESSAGING, icon: <IconBell /> },
    {
        id: 0,
        name: 'hide_invoice_balance',
        value: OVERRIDE_TYPE.HIDE_INVOICE_BALANCE,
        icon: <IconInvoice isGray />
    },
    {
        id: 2,
        name: 'show_invoice_balance',
        value: OVERRIDE_TYPE.SHOW_INVOICE_BALANCE,
        icon: <IconInvoice isGray />
    }
];
export const getListOptionSchedule = () => {
    return LIST_OPTIONS_SHCEDULE.map((item) => ({ ...item, name: i18n.t(`customers:${item.name}`) }));
};
