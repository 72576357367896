import { FIELDS_TYPE, OPERATOR_IDS } from './types';

export const PAST_ID = 'ago';
export const FUTURE_ID = 'from now';
export const CUSTOM_ID = 'custom';
export const NOW_ID = 'now';

export const DATE_FIELD_TYPES = [
    FIELDS_TYPE.CREATED_DATE,
    FIELDS_TYPE.DATE_WITHOUT_PRESENT,
    FIELDS_TYPE.LATEST_CALL_CREATED_DATE,
    FIELDS_TYPE.CUSTOMER_CREATED_DATE
];
export const RANGE_BEFORE_AFTER = { ON_OR_AFTER: 'onOrAfterMoment', BEFORE: 'beforeMoment' };
export const RANGE_PAST_FUTURE = { FUTURE: 'FUTURE', PAST: 'PAST' };
export const RANGE_START_END = { START: 'START', END: 'END' };
export const RANGE_TIMES = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
export const RANGE_DAYS = ['smartView:24_hours', 'smartView:7_days', 'smartView:30_days'];

export const OPTIONS_DATE_EXACTLY = [
    { id: 'today', value: 'today', label: 'customers:today' },
    { id: 'tomorrow', value: 'tomorrow', label: 'customers:tomorrow' },
    { id: 'this week', value: 'this week', label: 'customers:this_week' },
    { id: 'this month', value: 'this month', label: 'customers:this_month' },
    { id: 'this quarter', value: 'this quarter', label: 'customers:this_quarter' },
    { id: 'this year', value: 'this year', label: 'customers:this_year' },
    '__DIVIDER_ITEM__',
    { id: 'yesterday', value: 'yesterday', label: 'customers:yesterday' },
    { id: 'last week', value: 'last week', label: 'customers:last_week' },
    { id: 'last month', value: 'last month', label: 'customers:last_month' },
    { id: 'last quarter', value: 'last quarter', label: 'customers:last_quarter' },
    { id: 'last year', value: 'last year', label: 'customers:last_year' },
    { id: PAST_ID, value: PAST_ID, label: 'customers:days_ago' },
    '__DIVIDER_ITEM__',
    { id: 'tomorrow', value: 'tomorrow', label: 'customers:tomorrow' },
    { id: 'next week', value: 'next week', label: 'customers:next_week' },
    { id: 'next month', value: 'next month', label: 'customers:next_month' },
    { id: 'next quarter', value: 'next quarter', label: 'customers:next_quarter' },
    { id: 'next year', value: 'next year', label: 'customers:next_year' },
    { id: FUTURE_ID, value: FUTURE_ID, label: 'customers:days_from_now' },
    '__DIVIDER_ITEM__',
    { id: CUSTOM_ID, value: CUSTOM_ID, label: 'customers:custom_date' }
];

export const OPTIONS_DATE_RANGE = [
    { id: NOW_ID, value: NOW_ID, label: 'customers:now' },
    '__DIVIDER_ITEM__',
    ...OPTIONS_DATE_EXACTLY
];

export const OPTIONS_DATE_WITHIN = [
    { id: '24 hours ago', value: '24 hours ago', label: 'customers:the_last_24_hours' },
    { id: '7 days ago', value: '7 days ago', label: 'customers:the_last_7_days' },
    { id: '30 days ago', value: '30 days ago', label: 'customers:the_last_30_days' },
    { id: PAST_ID, value: PAST_ID, label: 'customers:the_last' },
    '__DIVIDER_ITEM__',
    { id: '24 hours from now', value: '24 hours from now', label: 'customers:the_next_24_hours' },
    { id: '7 days from now', value: '7 days from now', label: 'customers:the_next_7_days' },
    { id: '30 days from now', value: '30 days from now', label: 'customers:the_next_30_days' },
    { id: FUTURE_ID, value: FUTURE_ID, label: 'customers:the_next' }
];

export const DATE_OPTIONS = {
    [OPERATOR_IDS.IS]: OPTIONS_DATE_EXACTLY,
    [OPERATOR_IDS.NOT]: OPTIONS_DATE_EXACTLY,
    [OPERATOR_IDS.BEFORE]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.ON_OR_BEFORE]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.ON_OR_AFTER]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.AFTER]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.BETWEEN]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.NOT_BETWEEN]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.WITHIN]: OPTIONS_DATE_WITHIN,
    [OPERATOR_IDS.NOT_WITHIN]: OPTIONS_DATE_WITHIN
};
