import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getNestedValue } from 'common/utils/FunctionUtils';
import { EVENT_TYPES } from '../../const';
import { DEFAULT_COLUMNS_LIST_VIEW } from '../../const/Columns';

const EventContentListView = ({ event = {}, holiday = {}, additionalCells = [] }) => {
    const { t } = useTranslation();
    const propsEvent = event._def.extendedProps || {};
    const calendarColumns = useSelector(({ calendar }) => calendar.columns);
    const isHoliday = propsEvent.type === EVENT_TYPES.HOLIDAY;

    return (
        <div
            className={classNames('rows')}
            data-schedule-id={propsEvent?.schedule?.id || 1}
            data-color-holiday={propsEvent.countryColor || holiday?.color || ''}
            style={{ '--color-holiday': propsEvent.countryColor || holiday?.color || '' }}
        >
            {DEFAULT_COLUMNS_LIST_VIEW.map((item) => {
                if (!!!item.cell) return null;
                if (item.key === 'emoji' && !calendarColumns.includes(item.key)) return null;
                if (item.key === 'customer_company_name' && !calendarColumns.includes(item.key)) return null;
                const key = item.key;
                const isNotJob = [EVENT_TYPES.TIMEOFF, EVENT_TYPES.EVENT].includes(propsEvent.type);

                // Update key service_address for timeoff and event
                let keyValue = isHoliday ? item.keyValueHoliday || item.keyValue : item.keyValue;
                if (key === 'service_address' && isNotJob) keyValue = 'location.address';

                // Update value for timeoff and event
                let value = getNestedValue(propsEvent, keyValue);
                if (key === 'service_type' && isNotJob) value = propsEvent?.tile?.header;
                if (key === 'job_status' && !isHoliday) {
                    value = {
                        label: isNotJob
                            ? propsEvent.type === EVENT_TYPES.TIMEOFF
                                ? 'Time Off'
                                : 'Custom Event'
                            : propsEvent?.status,
                        color: propsEvent?.colorEvent?.border
                    };
                }

                const Cell = isHoliday ? item.cellHoliday || item.cell : item.cell;
                return (
                    <Cell
                        key={key}
                        value={key === 'calendar_time' && isHoliday ? t('calendar:all_day') : value}
                        isNotTypeJob={isNotJob}
                        className={item.className}
                        labelClassName={item.labelClassName}
                    />
                );
            })}
            {additionalCells.map((item) => {
                if (!!!item.cell) return null;
                return (
                    <item.cell
                        key={item.key}
                        value={getNestedValue(propsEvent, item.keyValue)}
                        className={item.className}
                        labelClassName={item.labelClassName}
                    />
                );
            })}
        </div>
    );
};

export default EventContentListView;
