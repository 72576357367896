import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { timeConvert } from 'app/modules/jobdetail/const/Utils';
import IconClock from 'assets/icon/IconClock';
import IconSync from 'assets/icon/IconSync';
import IconFuture from 'assets/icon/IconFuture';
import IconCalendar from 'assets/icon/IconCalendar';
import { useTranslation } from 'react-i18next';
import { CrewAssign } from '../settingschedule/assign/CrewGroup';

const ScheduleJobDetail = ({
    isShowSetting = true,
    jobData,
    isShowIconLeft = false,
    moreClassess = '',
    isShowTextWPool = false,
    onOpenSettingsSchedule = () => {}
}) => {
    const { t, i18n } = useTranslation(['jobDetail']);

    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);
    const schedulesReducer = useSelector((state) => state.schedules.data.schedules) || [];

    const jobEvent = { ...jobData.event };

    const { schedule: jobSchedule, is_workpool: isWorkPool = false, crew_ids: crewIds, event, job } = jobData;

    const assignees = event?.assignees || [];
    const isCompltedOrCancel = job?.previously_completed || 0;

    function _openScheduleSetting(e) {
        e && e.stopPropagation();
        onOpenSettingsSchedule();
    }

    function _renderLoading() {
        return (
            <div className="wrap-loading details-job mt-3">
                <div className="rows justify-start">
                    <div className="txt flex-auto">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="details mt-2">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
        );
    }

    function _renderAvatar(scheduleDetail, isName) {
        let userId = scheduleDetail.user_id;

        if (isName) {
            userId = schedulesReducer.find(
                (item) => item.name === scheduleDetail || item.nickname === scheduleDetail
            )?.user_id;
        }

        const userDetail = userId ? companyUsers.find((user) => user.id === userId) : null;

        if (userDetail?.avatar) {
            return <img src={userDetail.avatar} alt="" width="24" height="24" />;
        }

        const finalName = isName ? scheduleDetail : scheduleDetail.name;

        return <div className="avt fs-11">{finalName?.substring(0, 2)}</div>;
    }

    const _renderListAssign = () => {
        return assignees.map((item, index) => {
            return (
                <div key={item.id} className="tech-name">
                    <div className="avt-img">{_renderAvatar(item, true)}</div>
                    <span className="txt-ellipsis">{item}</span>
                    {!index && <span className="txt-primary">{t('common:primary')}</span>}
                </div>
            );
        });
    };

    function _renderListSchedule() {
        if (!!isCompltedOrCancel && !!assignees.length) {
            return _renderListAssign();
        }

        return jobSchedule
            .sort(function (a, b) {
                return b.is_primary - a.is_primary;
            })
            .map((item) => {
                return (
                    <div key={item.id} className="tech-name">
                        <div className="avt-img">{_renderAvatar(item)}</div>
                        <span className="txt-ellipsis">{item.name}</span>
                        {!!item.is_primary && <span className="txt-primary">{t('common:primary')}</span>}
                    </div>
                );
            });
    }

    function _renderJobLength() {
        const jobLength = timeConvert(jobEvent.length);
        const { hour, minute } = jobLength;

        const textHout = hour > 0 ? (hour < 10 ? '0'.concat(hour, 'h ') : hour.toString().concat('h ')) : '';
        const textMinute = minute > 0 ? (minute < 10 ? '0'.concat(minute, 'm ') : minute.toString().concat('m ')) : '';

        return (
            <span>
                {textHout}
                {textMinute}
            </span>
        );
    }

    if (jobData.isLoading) return _renderLoading();

    return (
        <Fragment>
            <div className={`${moreClassess} details-job__elm rows has-line-bottom`}>
                <div className="txt">
                    {isShowIconLeft && <IconCalendar />} {t('jobDetail:scheduling')}
                </div>
                <div className="details detail-schedule">
                    {isShowSetting && (
                        <div className="open-schedule right-icon" onClick={(e) => _openScheduleSetting(e)}>
                            <div className="v2-btn-default just-icon tooltip">
                                <IconFuture />
                                <p className="tooltiptext top --right">{t('jobDetail:edit_scheduling')}</p>
                            </div>
                        </div>
                    )}

                    <div className="schedule-time">
                        <div className="info-time">
                            <div className="info-time__label">
                                <span>
                                    {moment(jobEvent.start)
                                        .utc()
                                        .locale(i18n.language)
                                        .format(`dddd, ${convertPhpFormatToMoment(date_format)}`)}
                                </span>
                                <span className="ml-2">{_renderJobLength()}</span>
                            </div>
                            <div className="info-time__svg">
                                {!!jobData.job.locked ? <IconClock clockBlue /> : <IconClock isOpenClock />}
                            </div>
                        </div>
                        <div className="info-time">
                            <span className="info-time__label">{`${moment(jobEvent.start)
                                .utc()
                                .format('hh:mmA')} - ${moment(jobEvent.end).utc().format('hh:mmA')}`}</span>
                        </div>
                        <div className="info-time --repeat">
                            <div className="info-time__label">
                                <IconSync />
                                {jobEvent.recurrence.summary}
                                <div className="red-default w-100">{jobEvent.exception.summary}</div>
                            </div>
                        </div>
                    </div>
                    <div className="schedule-user">
                        {_renderListSchedule()}
                        <CrewAssign listCrew={crewIds} isHiddenClose />
                        {!!isWorkPool && isShowTextWPool && (
                            <span className="workpool-notify fw-bold flex-auto mt-1">{t('jobDetail:work_pool')}</span>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ScheduleJobDetail;
