import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { BookingCode } from '../../booking/components/BookingShowCode';

const AddonsChatbotPortalFooter = ({ isDisable = false, path = '', scriptInfo = {} }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isShowCode: false });
    const { isShowCode } = state;

    const _handleToggleShowCode = () => {
        dispatchState((prev) => ({ ...prev, isShowCode: !prev.isShowCode }));
    };
    return (
        <div className={classNames('boxs__footer --has-code', { active: isShowCode })}>
            <div className="flex-betweenitems w-100">
                <div className="v2-btn-default svg-purple btn-bg-purple --icon-r --sm" onClick={_handleToggleShowCode}>
                    {t(`addons:${isShowCode ? 'hide_code' : 'show_code'}`)}
                    {isShowCode ? <IconArrowDown isUp /> : <IconArrowDown />}
                </div>
                <Link
                    to={addBranchPath(path)}
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': isDisable
                    })}
                >
                    {t('addons:manage')} <IconCogWheel />
                </Link>
            </div>
            {isShowCode ? (
                <BookingCode
                    scriptInfo={scriptInfo}
                    isChatBot
                    hideBookingCode
                    hideDescriptionBookingCode
                    wrapperClassName="show-code mt-3 w-100"
                />
            ) : null}
        </div>
    );
};

export default AddonsChatbotPortalFooter;
