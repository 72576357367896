import { ACCOUNT_PLANS_DOWNGRADE, ACCOUNT_PLANS_THANKS, ACCOUNT_PLANS_UPGRADE } from 'app/config/routes';

export const LIST_TYPE_TERM = {
    MONTH: 'month',
    YEAR: 'year'
};

export const LIST_TYPE_PACKAGE = {
    BASIC: 'basic',
    PRO: 'pro',
    GROWTH: 'growth'
};

export const PLAN_PACKAGE_NAME = {
    [LIST_TYPE_PACKAGE.BASIC]: 'basic_plan',
    [LIST_TYPE_PACKAGE.PRO]: 'pro_plan',
    [LIST_TYPE_PACKAGE.GROWTH]: 'growth_plan'
};

export const LIST_TERM_SETTING_PLANS = [
    { id: 1, name: 'monthly', value: 'month', key: 'month' },
    { id: 2, name: 'yearly', value: 'year', key: 'annual' }
];

export const LIST_AMOUNT_USERS_PER_ACCOUNT_SETTING_PLAN = (number = 30) => {
    const list = [];
    const isOver = number > 30;
    if (isOver) {
        for (let item = 1; item <= number; item++) {
            if (item === 1 || item % 5 === 0) {
                list.push({ id: item, value: item, classWrapper: 'range-number' });
            }
        }
    } else {
        for (let item = 1; item <= number; item++) {
            list.push({ id: item, value: item, classWrapper: item === 1 || item % 5 === 0 ? 'range-number' : '' });
        }
    }

    return list;
};

export const LIST_PACKAGE_SETTING_PLANS = [
    {
        id: 'basic',
        name: 'basic_plan',
        value: 'basic',
        features: [
            { id: 1, name: 'unlimited_admin_users' },
            { id: 2, name: 'unlimited_mobile_devices' },
            { id: 3, name: 'unlimited_support' },
            { id: 4, name: 'invoices_estimates_work_orders' },
            { id: 6, name: 'advanced_invoice_frequencies' },
            { id: 7, name: 'job_and_customer_mapping' },
            { id: 8, name: 'basic_routing' },
            { id: 9, name: 'square_and_stripe_integration' },
            { id: 10, name: 'broadcast_messaging' },
            { id: 11, name: 'note_and_todo_list_templates' },
            { id: 12, name: 'material_tracking_and_reporting' },
            { id: 13, name: 'triggered_automations' },
            { id: 14, name: 'automated_email_and_sms' },
            { id: 15, name: 'dashboard_and_reporting' }
        ]
    },
    {
        id: 'pro',
        name: 'pro_plan',
        value: 'pro',
        features: [
            { id: 1, name: 'all_basic_features_plus', isHideTooltip: true },
            { id: 2, name: 'customer_online_portal' },
            { id: 3, name: 'online_booking' },
            { id: 4, name: 'stripe_and_square_subscriptions' },
            { id: 5, name: 'routing_with_drive_matrix' },
            { id: 6, name: 'review_generation' },
            { id: 7, name: 'documents_and_e_signatures' },
            { id: 8, name: 'custom_document_builds' },
            { id: 9, name: 'device_tracking_and_bar_coding' },
            { id: 10, name: 'multi_dwelling_units' },
            { id: 11, name: 'production_commission_tracking' },
            { id: 12, name: 'sales_commission_tracking' },
            { id: 13, name: 'quick_books_online_sync' },
            { id: 14, name: 'gps_device_tracking' },
            { id: 15, name: 'email_inbox_sync' },
            { id: 16, name: 'zapier_integration' },
            { id: 17, name: 'open_api_access' },
            { id: 18, name: 'time_clocking' },
            { id: 19, name: 'batch_tax_and_line_item_increase', isTooltipDefault: true }
        ]
    },
    {
        id: 'growth',
        name: 'growth_plan',
        value: 'growth',
        features: [
            { id: 1, name: 'all_pro_features_plus', isHideTooltip: true },
            { id: 14, name: 'drive_time' },
            { id: 12, name: 'best_available_time' },
            { id: 13, name: 'job_magnet' },
            { id: 10, name: 'dynamic_estimates' },
            { id: 15, name: 'estimate_packages' },
            { id: 2, name: 'smart_views' },
            { id: 3, name: 'opportunity_creation' },
            { id: 4, name: 'custom_opportunity_pipelines' },
            { id: 6, name: 'sales_pipeline_reporting' },
            { id: 9, name: 'custom_fields' },
            { id: 11, name: 'custom_job_status' },
            { id: 8, name: 'multi_branch' }
        ]
    }
];

export const getRoutePlanSuccess = ({ isUpgrade = false, isTrial = false }) => {
    if (isTrial) return ACCOUNT_PLANS_THANKS;
    if (isUpgrade) return ACCOUNT_PLANS_UPGRADE;
    return ACCOUNT_PLANS_DOWNGRADE;
};
const LIMIT_INCREMENT = 75;
const LIMIT_DECREMENT = 1;
export const SEAT_SELECTION = {
    INCREMENT: 'INCREMENT',
    DECREMENT: 'DECREMENT',
    TYPING: 'TYPING',
    MAX: LIMIT_INCREMENT,
    MIN: LIMIT_DECREMENT
};
