import { DATE_FORMAT_ENGLISH, DATE_FORMAT_SPANISH } from 'app/const/App';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { REPORT_BATCH_ACTION_ITEMS } from 'app/const/report/ReportTypeContent';
import { EN } from 'app/const/Settings';
import RessignJob from 'app/modules/calendar/components/modal/RessignJob';
import Schedules from 'app/modules/calendar/job/components/Schedules';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import classNames from 'classnames';
import { actionJobReassign } from 'common/redux/actions/job';
import { batchActionsRequest } from 'common/redux/actions/reports/batchAction';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const Assign = ({ jobData }) => {
    const finalPrimary = jobData.schedule.primary;
    if (!finalPrimary) return null;

    const [state, dispatchState] = useReducer(reducer, {
        isVisibleSchedule: false,
        schedule: finalPrimary
    });

    const { t, i18n } = useTranslation('calendar');
    const oldSchedule = useRef(null);
    const refDropDown = useRef(null);
    const refRessignJob = useRef(null);

    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const schedulesReducer = useSelector(({ schedules }) => schedules);

    const { name: serviceName, event, emoji } = jobData;

    const { isVisibleSchedule: finalIsVisibleScheudle, schedule } = state;
    const { user_id: scheduleId } = schedule;
    const i18nLanguageCode = i18n.language;

    const dispatch = useDispatch();

    const userDetail = useMemo(() => {
        return companyUsers.find((user) => user.id === scheduleId);
    }, [scheduleId]);

    useEffect(() => {
        if (finalPrimary.id !== schedule?.id) {
            dispatchState({ schedule: finalPrimary });
        }
    }, [finalPrimary]);

    useEffect(() => {
        if (finalIsVisibleScheudle) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisibleScheudle]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_list_schedule_job_preview');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _handleCloseSchedule();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_list_schedule_job_preview');

        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _handleCloseSchedule();
        }
    };

    function _handleChangeAssign(newSchedule) {
        oldSchedule.current = schedule;

        dispatchState({ schedule: newSchedule, isVisibleSchedule: false });

        if (jobData.event.repeat === 1) {
            refRessignJob.current &&
                refRessignJob.current._showOptions(
                    { revert: _handleReAssignFailed },
                    newSchedule?.id,
                    _updateNewAssign
                );
        } else {
            _updateNewAssign(0, newSchedule?.id);
        }
    }

    function _updateNewAssign(all, newScheduleId) {
        const params = {
            value: REPORT_BATCH_ACTION_ITEMS.SERVICE_LOOKUP_REASSIGN_JOBS,
            ids: [jobData?.id].toString(),
            all: all,
            schedule: newScheduleId.toString(),
            strict: jobData.recurrence.length === 0 ? 1 : 0,
            // eslint-disable-next-line no-undef
            socket_id: global.mainCalendarSocketId
        };

        dispatch(
            batchActionsRequest(
                params,
                (response) => _handleReAssignSuccess(response, all, newScheduleId),
                _handleReAssignFailed
            )
        );
    }

    function _handleReAssignSuccess(response, all, newScheduleId) {
        const infoEvent = {
            all: all,
            event: { ...jobData },
            revert: _handleReAssignFailed,
            newResource: schedulesReducer.data.schedules.find((item) => item.id === newScheduleId)
        };

        dispatch(actionJobReassign(infoEvent));
    }

    function _handleReAssignFailed() {
        dispatchState({ schedule: oldSchedule.current });
    }

    function _renderAvatar() {
        if (userDetail?.avatar) {
            return <img src={userDetail.avatar} alt="" width="24" height="24" />;
        } else {
            return <div className="avt fs-11">{schedule?.name?.substring(0, 2)}</div>;
        }
    }

    function _renderServiceName() {
        return (
            <Fragment>
                {Array.isArray(emoji) && <span className="emoji-content">{emoji.join('')} </span>}
                {serviceName}
            </Fragment>
        );
    }

    function _handleOpenSchedule() {
        dispatchState({ isVisibleSchedule: !finalIsVisibleScheudle });
    }

    function _handleCloseSchedule() {
        dispatchState({ isVisibleSchedule: false });
    }

    return (
        <div className="job-information">
            <div className="job-information__label fs-13 fw-600">{_renderServiceName()}</div>
            <div className="job-information__label">
                {`${moment(event.start)
                    .utc()
                    .locale(i18nLanguageCode)
                    .format(i18nLanguageCode === EN ? DATE_FORMAT_ENGLISH : DATE_FORMAT_SPANISH)}, ${moment(event.start)
                    .utc()
                    .format('h:mma')} - ${moment(event.end).utc().format('h:mma')}`}
            </div>
            <div
                ref={refDropDown}
                className={classNames('v2-dropdown dropdown-listname', {
                    disable: jobData.previously_completed !== 0 || !!jobData.locked,
                    active: finalIsVisibleScheudle
                })}
            >
                <div className={`dropbtn v2-btn-default btn-listname tooltip`} onClick={() => _handleOpenSchedule()}>
                    <span className="avt-img">{_renderAvatar()}</span>
                    <span className="btn-listname__label">{schedule.name}</span>
                    <span className="svg-dropup">
                        <IconDropUpDown />
                    </span>
                    <p className="tooltiptext top">{t('reassign')}</p>
                </div>
                {finalIsVisibleScheudle && (
                    <Schedules isVisible selected={schedule?.id} onSelect={_handleChangeAssign} />
                )}
            </div>

            <RessignJob ref={refRessignJob} />
        </div>
    );
};

export default Assign;
