import i18n from 'assets/i18n';
import { COLORS_MAGNET_RANGE } from '../../const';
import { convertOneEvent } from '../../ultil/Calendar';
import { UN_MAGNET_VALUE } from './components/constants';

export const calculateDistanceRanges = (maxDistance = 0) => {
    const numRanges = COLORS_MAGNET_RANGE.length;

    const rangeSize = Math.ceil(maxDistance / numRanges);
    const distanceRanges = [];

    for (let i = 0; i < numRanges; i++) {
        distanceRanges.push({ min: i * rangeSize + 1, max: (i + 1) * rangeSize, color: COLORS_MAGNET_RANGE[i] });
    }

    return distanceRanges;
};

export const getJobDistanceColor = (value, distanceRanges) => {
    // If the value is zero, add 1 to it to make it positive
    const distance = Math.ceil(value <= 0 ? value + 1 : value);
    if (value <= 0) return COLORS_MAGNET_RANGE[0];
    if (distance < 0) throw new Error(i18n.t('calendar:distance_cannot_be_negative'));

    // Binary search to find the correct range
    let left = 0;
    let right = distanceRanges.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const range = distanceRanges[mid];
        if (distance >= range.min && distance <= range.max) {
            return range.color;
        } else if (distance < range.min) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }

    // If no range is found return the color for the furthest range
    return distanceRanges[distanceRanges.length - 1].color;
};

/**
 * Convert jobs to events with distance and colorDistance
 * @param {object[]} jobs - jobs to convert
 * @param {number} maxDistance - max distance of filter distance
 * @returns {Event[]} events with distance and colorDistance
 */
export const jobMagnetConvert = (jobs, maxDistance = 0) => {
    try {
        if (!jobs.length) return [];

        const distanceRanges = calculateDistanceRanges(maxDistance);
        return jobs.map((job) => {
            const jobDistance = job.magnet.distance;
            const colorDistance = getJobDistanceColor(jobDistance.value, distanceRanges);
            return {
                ...convertOneEvent(job),
                editable: !job.job.locked,
                indexJob: job.magnet.number,
                distance: jobDistance,
                time: job.magnet.time,
                colorDistance
            };
        });
    } catch (error) {
        console.error('Error in jobMagnetConvert', error);
        return [];
    }
};

export const calculateDistance = (events = [], maxDistance = 0) => {
    try {
        const eventLength = events.length || 0;
        if (!eventLength) return [];

        const distanceRanges = calculateDistanceRanges(maxDistance);
        return events.map((event) => {
            const colorDistance = getJobDistanceColor(event.distance.value, distanceRanges);
            return { ...event, colorDistance };
        });
    } catch (error) {
        console.error('Error in calculateDistance', error);
        return [];
    }
};

export const convertMagnetEvent = (events, selectedEvent = {}) => {
    return events.map((event) => {
        const result = { ...convertOneEvent(event), magnet: null, editable: false, resourceEditable: false };
        if (selectedEvent.eventId === event.event.id) result['magnetSelected'] = true;
        return result;
    });
};

export const getDefaultValue = (value, options) => {
    if (!Array.isArray(options)) throw new Error(i18n.t('calendar:options_must_be_an_array'));
    if (isNaN(value)) return options[0];
    const lastIndex = options.length - 1;
    if (value === options[lastIndex].value) return options[lastIndex];
    const result = options.find((item) => item.value === value);
    if (result) return result;
    return { value, label: `${value}` };
};

export const applyMagnetEvents = (events = [], magnets = []) => {
    const magnetMap = new Map(magnets.map((magnet) => [magnet.id, magnet]));
    events.forEach((event) => {
        if (event.type === 'job' && magnetMap.has(event.jobId)) {
            const magnet = magnetMap.get(event.jobId);
            event.is_magnet = true;
            event.schedule_ids = magnet.schedule_ids || [magnet.schedule_id];
            event.magnet_jobs = magnet.magnet_jobs;
        }
    });
    return events;
};

export const modifyMagnetEvents = ({ events = [], magnets = [], activate = false }) => {
    const magnetMap = new Map(magnets.map((magnet) => [magnet.id + '_' + magnet.schedule_ids?.[0] + '_job', magnet]));
    return events.map((item) => {
        if (magnetMap.has(item.id)) {
            const dataMagnet = magnetMap.get(item.id);
            const dataEvent = activate
                ? {
                      time: dataMagnet.time || 0,
                      magnet: dataMagnet.distance,
                      colorDistance: dataMagnet.colorDistance,
                      indexJob: dataMagnet.number
                  }
                : UN_MAGNET_VALUE;
            return { ...item, ...dataEvent };
        }
        return item;
    });
};
