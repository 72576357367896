import classNames from 'classnames';
import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdAvatar from 'app/components/avatar';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { COMPANY_USERS } from 'app/const/Api';
import { LIST_USER_VOIP } from 'app/const/api/Voip';
import { ACCOUNT_ROLE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { VOIP_TECHNICIAN_SEAT } from 'app/const/Voip';
import IconLoading from 'assets/icon/IconLoading';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import { getDataWithFreeSeat } from 'common/utils/FunctionUtils';
import { convertParamFields } from 'common/utils/ReportUtils';
import { ActiveUsersContext } from './ActiveUsersProvider';

export const ActiveUserHeader = ({
    users = [],
    account = {},
    isVoipTechnicianSeat = false,
    isLoading = false,
    onAddUser = () => {},
    handleFailed = () => {}
}) => {
    const { data: dataActiveUser } = useContext(ActiveUsersContext);
    const { t } = useTranslation('addons');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: false, isFirstTime: true });
    const { data, isLoading: finalIsLoading, isFirstTime } = state;
    const finalData = (isVoipTechnicianSeat ? data : companyUsers).filter(
        (item) => !users.some((user) => user.id === item.id)
    );

    const { user_quantity: maxUser = 0 } = account || {};
    const finalUsers = getDataWithFreeSeat({ data: users, checkFreeSeat: isVoipTechnicianSeat });
    const isMaxUserActive = finalUsers.length >= maxUser;

    const refDropdown = useRef(null);

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, isFirstTime: true }));
    }, [dataActiveUser]);

    const _handleClickItem = ({ id, avatar, full_name }) => {
        const freeSeat = isVoipTechnicianSeat ? 1 : 0;
        refDropdown.current._close();
        const _handleSuccess = () => {
            onAddUser({ id, avatar, full_name, is_free_seat: freeSeat });
            dispatchState((prev) => ({ ...prev, isFirstTime: true }));
        };
        clientQuery(
            LIST_USER_VOIP,
            { method: 'POST', toFormData: false, data: { user_id: id, is_free_seat: freeSeat } },
            _handleSuccess,
            handleFailed
        );
    };

    const _handleOpen = () => {
        if (!isFirstTime || !isVoipTechnicianSeat) return;
        dispatchState((prev) => ({ ...prev, isLoading: true }));
        const _handleSuccess = ({ data = [] }) => {
            dispatchState((prev) => ({
                ...prev,
                data: (data || []).filter(({ role }) => role === ACCOUNT_ROLE.TECHNICIAN.toLowerCase()),
                isLoading: false,
                isFirstTime: false
            }));
        };
        const _handleFailed = () => {
            dispatchState((prev) => ({ ...prev, isLoading: false, isFirstTime: false }));
        };
        clientQuery(
            COMPANY_USERS,
            {
                data: convertParamFields({ params: { [VOIP_TECHNICIAN_SEAT.ROLE]: VOIP_TECHNICIAN_SEAT.ROLE } }),
                method: 'GET'
            },
            _handleSuccess,
            _handleFailed
        );
    };

    return (
        <div
            className={classNames('header flex-betweenitems', {
                'black justify-end mt-3': isVoipTechnicianSeat,
                'align-center black pt-0': !isVoipTechnicianSeat
            })}
        >
            {isVoipTechnicianSeat ? null : <p className="fw-600">{t('active_voip_users')}</p>}
            <div className="flexcenter gap-8">
                {isVoipTechnicianSeat ? null : (
                    <p className={classNames('fw-600', { 'orange-default': isMaxUserActive })}>
                        {t('available_seats', { available: `${finalUsers.length}/${maxUser}` })}
                    </p>
                )}
                <DropdownPopper
                    ref={refDropdown}
                    id="add_active_user"
                    wrapperClassName={classNames('v2-dropdown dropdown-add-user', { 'is-disable': isLoading })}
                    buttonClassName="v2-btn-main has-icon svg-white"
                    wrapperListClass="v2-dropdown__menu content-user scrolls"
                    isDisable={isVoipTechnicianSeat ? false : isMaxUserActive}
                    customButton={
                        <Fragment>
                            <IconPlus />
                            {t(isVoipTechnicianSeat ? 'add_technician_user' : 'add_user')}
                        </Fragment>
                    }
                    isUseToggle
                    onOpen={_handleOpen}
                >
                    <DropdownListUser isLoading={finalIsLoading} data={finalData} onClickItem={_handleClickItem} />
                </DropdownPopper>
            </div>
        </div>
    );
};

const DropdownListUser = ({ isLoading = false, data = [], onClickItem = () => {}, update = () => {} }) => {
    const { t } = useTranslation('addons');

    useEffect(() => {
        update();
    }, [data, update]);

    return (
        <ul>
            {isLoading ? (
                <div className="items justify-center">
                    <div className="loading --ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            ) : (
                <Fragment>
                    {!!data.length ? (
                        data.map((item) => {
                            const { id, avatar, full_name } = item;
                            return (
                                <li key={id} className="items" onClick={() => onClickItem(item)}>
                                    <div className="user-name">
                                        <div className="avt-img">
                                            <GdAvatar src={avatar} width={24} height={24} alt={full_name} />
                                        </div>
                                        <span className="txt-ellipsis fw-500 w-100" title={full_name}>
                                            {full_name}
                                        </span>
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <p className="p-2">{t('addons:user_is_empty')}</p>
                    )}
                </Fragment>
            )}
        </ul>
    );
};
