import classNames from 'classnames';
import React, { forwardRef, Fragment, useImperativeHandle, useReducer, useRef } from 'react';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { DEFAULT_ALL, DIVIDER } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { ACTIVE_HOURS, getReplyBehaviorOptions } from '../constants';

const SettingReplyBehavior = (
    { id = '', title = '', defaultValue = DEFAULT_ALL, behaviorHour = ACTIVE_HOURS, isHidden = false },
    ref
) => {
    const refDropdown = useRef(null);
    const replyBehaviorOptions = getReplyBehaviorOptions();
    const [state, dispatchState] = useReducer(reducer, {
        selected: replyBehaviorOptions.find((item) => item.id === +defaultValue) || replyBehaviorOptions[0]
    });
    const { selected: finalSelected } = state;

    useImperativeHandle(ref, () => ({
        getValue: () => finalSelected
    }));

    const _handleSelect = (item = {}) => {
        dispatchState((prev) => ({ ...prev, selected: item }));
        refDropdown.current && refDropdown.current._close();
    };

    const _renderOptions = () => {
        return (
            <ul className="scrolls">
                {replyBehaviorOptions.map((item, index) => {
                    const { id, title, hideWhenActiveHours } = item;
                    if (behaviorHour === ACTIVE_HOURS && hideWhenActiveHours) return null;
                    if (id === DIVIDER)
                        return <div key={`${index}-${DIVIDER}`} className="is-divider --horizontal w-100" />;
                    return (
                        <li
                            key={id}
                            className={classNames('items', { active: id === finalSelected.id })}
                            onClick={() => _handleSelect(item)}
                        >
                            {title}
                        </li>
                    );
                })}
            </ul>
        );
    };

    if (isHidden) return null;
    return (
        <Fragment>
            {title ? <p className="fw-500 black-darker3">{title}</p> : null}
            <DropdownPopper
                ref={refDropdown}
                id={id}
                buttonClassName="dropbtn items"
                wrapperListClass="v2-dropdown__menu"
                wrapperClassName="v2-dropdown"
                isUseToggle
                isCalculateWidth
                customButton={<CustomButton finalSelected={finalSelected} />}
            >
                {_renderOptions()}
            </DropdownPopper>
        </Fragment>
    );
};

const CustomButton = ({ finalSelected = {} }) => {
    return (
        <Fragment>
            <span className="txt-ellipsis">{finalSelected.title}</span>
            <span className="arrow">
                <IconArrowDown />
            </span>
        </Fragment>
    );
};

export default forwardRef(SettingReplyBehavior);
