export const TAB_BUTTON_SHAPE = [
    { value: 'rounded_square', label: 'rounded_square' },
    { value: 'circle', label: 'circle' }
];
export const SETTING_PORTAL_WIDGET = {
    LIST_COLOR_PORTAL: [
        { id: 1, name: 'blue', color: '#2860CA' },
        { id: 2, name: 'purple', color: '#7632F4' },
        { id: 3, name: 'magenta', color: '#DA1CE7' },
        { id: 4, name: 'pink', color: '#E71C8F' },
        { id: 5, name: 'red', color: '#D72B2E' },
        { id: 6, name: 'orange', color: '#FB6920' },
        { id: 7, name: 'olive', color: '#567C11' },
        { id: 8, name: 'green', color: '#067133' },
        { id: 9, name: 'aquamarine', color: '#03B0A2' },
        { id: 10, name: 'grey', color: '#515151' }
    ],
    DEFAULT_COLOR: { id: 8, name: 'green', color: '#067133' }
};
export const BACKGROUND_VARIANT_OPTION = {
    SOLID_COLOR: { value: 'solid_color', label: 'solid_color' },
    PATTERN: { value: 'pattern', label: 'pattern' }
};
export const BUTTON_SHAPE = 'button_shape';
export const PORTAL_COLOR = 'color';
export const BACKGROUND_VARIANT = 'background_variant';
