import { createPopper } from '@popperjs/core';
import React, { forwardRef, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CUSTOMER_STATUSES } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { LIMIT_QUOTES_LENGTH } from '../../constants';
import { OPPORTUNITIES_STATUSES, REFERENCE_TYPES } from '../../constants/types';

const Tooltip = forwardRef((props, ref) => {
    const { t } = useTranslation(['smartView', 'customers']);
    const [state, dispatchState] = useReducer(reducer, { data: null });
    const data = state?.data || null;
    const { operatorData, filter, field } = data?.operatorData || {};
    const refEl = useRef(null);

    useImperativeHandle(ref, () => ({
        visible: (data) => {
            dispatchState((prevState) => ({ ...prevState, data }));
        }
    }));

    useLayoutEffect(() => {
        const popperConfig = {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [{ name: 'offset', options: { offset: [0, 5] } }]
        };
        const popper = createPopper(data?.el, refEl.current, popperConfig);

        return () => {
            popper.destroy();
        };
    }, [data]);
    return (
        <div ref={refEl} className="tooltiptext  visibility-show" style={{ display: !!data ? 'flex' : 'none' }}>
            {!!data ? (
                <>
                    <p className="tooltiptext-title">{t(operatorData?.label)}</p>
                    {operatorData?.useQuotes ? (
                        <Quotes
                            values={filter?.value?.set?.values || []}
                            operatorId={filter?.operatorId}
                            field={field}
                        />
                    ) : null}
                </>
            ) : null}
        </div>
    );
});

const Quotes = ({ values = [], field }) => {
    const { t } = useTranslation();
    const users = useSelector(({ companyUsers }) => companyUsers?.users || []);
    const sources = useSelector(({ customerReducer }) => customerReducer?.sources);
    const services = useSelector(({ serviceReducer }) => serviceReducer?.list_service || []);
    const pipelines = useSelector(({ pipelines }) => pipelines?.data?.[0]?.pipelines || []);
    const schedulesList = useSelector(({ schedulesList }) => schedulesList?.data || []);
    const { data: dataItems } = useSelector(({ smartViews }) => smartViews.items);
    const objectStore = {
        users,
        services,
        customer_status: CUSTOMER_STATUSES,
        opportunity_status: OPPORTUNITIES_STATUSES,
        sources,
        pipelines,
        schedulesList,
        items: dataItems
    };
    const { keyGetValue = '', options = null, referencedType, isJobStatus } = field || {};
    const arrayFilter = options || objectStore?.[keyGetValue] || [];

    const _renderQuotes = () => {
        const quotes = values?.slice(0, LIMIT_QUOTES_LENGTH);
        const isTag = [REFERENCE_TYPES.TAG, REFERENCE_TYPES.ZIP, REFERENCE_TYPES.CITY].includes(referencedType);
        return quotes.map((quote, index) => {
            if (isJobStatus) return <QuoteStatusJob key={`${quote}_${index}`} quote={quote} />;
            if (isTag) return <span key={`${quote}_${index}`}>{quote}</span>;
            let quoteItem = arrayFilter.find((status) => {
                const idCompare = status?.value?.toString() || status?.id?.toString();
                return idCompare === quote?.toString();
            });
            if (!quoteItem && referencedType === REFERENCE_TYPES.SCHEDULES)
                quoteItem = { name: t('smartView:schedule_unassigned') };
            if (!quoteItem) return null;
            return (
                <span key={`${quote}_${index}`}>
                    {quoteItem?.name ? t(`customers:${quoteItem.name}`) : quoteItem?.full_name}
                </span>
            );
        });
    };
    return (
        <>
            {_renderQuotes()}
            {values?.length > LIMIT_QUOTES_LENGTH ? (
                <span>
                    +{values.length - LIMIT_QUOTES_LENGTH} {t('more')}
                </span>
            ) : null}
        </>
    );
};

const QuoteStatusJob = ({ quote }) => {
    const status = getJobStatus(quote);
    if (!status) return null;
    return (
        <span>
            {status.name} {status.label}
        </span>
    );
};

export default Tooltip;
