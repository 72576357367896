import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MessageTabs from 'app/modules/calendar/job/components/messaging/MessageTabs';
import { useSelector } from 'react-redux';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import classNames from 'classnames';
import IconClose from 'assets/icon/IconClose';
import Info from 'app/modules/jobdetail/tabs/settingmessaging/Info';
import ReactModal from 'react-modal';

const MessageConfig = ({ onClose, message, onSave, isModalLocation, customerId, isNotLoadingBtnSave = false }) => {
    const { t } = useTranslation(['customers', 'common']);
    const activateSms = useSelector((state) => state.auth.user?.settings?.addons?.activate_sms || false);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refInfo = useRef(null);

    function _handleCloseConfig(e) {
        e.stopPropagation();
        onClose();
    }

    function _handleSave() {
        const confirmations = document.getElementById('appointment_confirmations').getAttribute('data-value-selected');
        const reminders = document.getElementById('appointment_reminders').getAttribute('data-value-selected');
        const followups = document.getElementById('appointment_followups').getAttribute('data-value-selected');
        const broadcasts = document.getElementById('broadcasts').getAttribute('data-value-selected');
        const payment = document.getElementById('late_payment_reminders').getAttribute('data-value-selected');
        const finalInfo = refInfo?.current?.getValue();

        onSave(
            parseInt(confirmations),
            parseInt(reminders),
            parseInt(followups),
            parseInt(broadcasts),
            parseInt(payment),
            finalInfo?.sms || [],
            finalInfo?.email || [],
            () => {
                refButtonSave.current && refButtonSave.current.removeLoading();
                refStatusBar.current.showStatusBar('show_success', t('jobDetail:save_success'), LIST_STATUS.SUCCESS);
            }
        );
    }

    return (
        <ReactModal
            id="messagingPreferences"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className={classNames('modal container-modal messaging-preferences open', {
                '--appointment --wrap column-preferences': !isModalLocation
            })}
        >
            {isModalLocation && <div className="modal__overlay bg-fixed" onClick={_handleCloseConfig}></div>}
            <div className={classNames('modal__container', { '--center': isModalLocation })}>
                <StatusBar ref={refStatusBar} />
                <div className="header-modal">
                    <h3 className="header-modal__label">{`${isModalLocation ? t('jobDetail:override') : ''} ${t(
                        'jobDetail:messaging_preferences'
                    )}`}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={(e) => _handleCloseConfig(e)}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal scrolls">
                    {!isModalLocation && <Info ref={refInfo} settings={message} customerId={customerId} />}

                    <div className="box-preferences tabs">
                        <span className="txt-appointment">{t('jobDetail:appointment')}</span>
                        <div className="lines">
                            <span className="txt">{t('jobDetail:confirmations')}</span>
                            <MessageTabs
                                value={message.appointment_confirmations}
                                type={'appointment_confirmations'}
                                activateSms={activateSms}
                            />
                        </div>
                        <div className="lines">
                            <span className="txt">{t('jobDetail:reminders')}</span>
                            <MessageTabs
                                value={message.appointment_reminders}
                                type={'appointment_reminders'}
                                activateSms={activateSms}
                            />
                        </div>
                        <div className="lines">
                            <span className="txt">{t('jobDetail:followups')}</span>
                            <MessageTabs
                                value={message.appointment_followups}
                                type={'appointment_followups'}
                                activateSms={activateSms}
                            />
                        </div>
                        <div className="lines line-border" />
                        <div className="lines">
                            <span className="txt">{t('jobDetail:broadcasts')}</span>
                            <MessageTabs value={message.broadcasts} type={'broadcasts'} activateSms={activateSms} />
                        </div>
                        <div className="lines">
                            <span className="txt" title={t('jobDetail:late_payment_reminders')}>{t('jobDetail:late_payment_reminders')}</span>
                            <MessageTabs
                                value={message.late_payment_reminders}
                                type={'late_payment_reminders'}
                                activateSms={activateSms}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-modal btn-close">
                    <span onClick={(e) => _handleCloseConfig(e)} className="v2-btn-default --noborder --label">
                        {t('common:cancel')}
                    </span>
                    <ButtonSave
                        ref={refButtonSave}
                        isNotLoading={isNotLoadingBtnSave}
                        onSave={_handleSave}
                        wrapClass="v2-btn-main fs-14"
                    />
                </div>
            </div>
        </ReactModal>
    );
};

export default MessageConfig;
