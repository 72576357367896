import React from 'react';
import { useSelector } from 'react-redux';

import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { getPlanUser } from 'common/utils/PermissionUtils';
import AddonsOptionsFooterManage from '../../components/AddonsOptionsFooterManage';
import { getAddonsChatbotFooterOptionsConfig } from '../utils';
import AddonsChatbotPortalFooter from './AddonsChatbotPortalFooter';

const AddonsChatbotFooter = ({ keyword = '', scriptInfo = {} }) => {
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isBasicPlan, isGrowthPlan } = getPlanUser(userProfile);

    const _renderFooter = () => {
        const commonProps = { ...getAddonsChatbotFooterOptionsConfig({ keyword, isBasicPlan, isGrowthPlan }) };
        switch (keyword) {
            case ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS:
                return (
                    <div className="boxs__footer">
                        <AddonsOptionsFooterManage {...commonProps} />
                    </div>
                );
            case ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL:
                return <AddonsChatbotPortalFooter scriptInfo={scriptInfo} {...commonProps} />;
            default:
                return null;
        }
    };

    return _renderFooter();
};

export default AddonsChatbotFooter;
