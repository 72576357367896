import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import IconColumns from 'assets/icon/IconColumns';
import Options from './Options';
import IconPlus from 'assets/icon/IconPlus';
import ShowMore from './ShowMore';
import CheckListDetail from './checklist';
import { reducer } from 'app/const/Reducer';
import Loading, { LoadingMore } from './Loading';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    getJobTodo,
    actionCheckList,
    actionCheckListStatus,
    moveCheckList,
    actionCopyTemplate
} from 'app/const/api/Todo';
import Add from 'app/modules/jobdetail/tabs/timeline/todo/checklist/Add';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SaveAsTemplate from './SaveAsTemPlate';
import GdConfirm from 'app/components/confirm';
import RepeatTodo from './Repeat';
import RealTimeService from './RealTimeService';
import Progress from './Progress';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const JobTodo = forwardRef(({ jobId, jobTodo = {}, jobEvent = {} }, ref) => {
    const { t } = useTranslation(['jobDetail', 'setting']);
    const { total: totalCheckList, repeat: repeatWithJob } = jobTodo;
    const isJobRepeat = !!parseInt(jobEvent.repeat);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: !!totalCheckList,
        isLoading: true,
        isLoadingAddMore: false,
        data: [],
        showMore: true,
        renderShowMore: false,
        initAddTodo: 0
    });

    const {
        isLoading: finalIsLoading,
        data: finalData,
        showMore,
        renderShowMore,
        isLoadingAddMore,
        isVisible: fialIsVisible,
        initAddTodo
    } = state;

    const refFormAdd = useRef(null);
    const refSaveAsTemplate = useRef(null);
    const refButtonAddNew = useRef(null);
    const refConfirm = useRef(null);
    const refConfirmDeleteTodo = useRef(null);
    const numberCheckList = finalData.length;

    useImperativeHandle(ref, () => ({ _open: _openForm }));

    useEffect(() => {
        !!totalCheckList && _getJobTodo();
    }, []);

    useEffect(() => {
        const elm = document.getElementById('js-add-todolist');

        if (!numberCheckList) {
            if (elm && !fialIsVisible) {
                elm.classList.remove('is-disable');
            }
        } else {
            if (elm) {
                elm.classList.add('is-disable');
            }
        }
    }, [finalData, fialIsVisible]);

    const numberDone = useMemo(() => {
        return finalData.filter((item) => !!item.status).length;
    }, [finalData]);

    const percentCheckListDone = parseFloat(numberDone / numberCheckList);

    function _openForm() {
        dispatchState({
            isVisible: true,
            initAddTodo: Date.now(),
            isLoading: false
        });
    }

    function _getJobTodo(limit = 10, offset = 0) {
        clientQuery(getJobTodo(jobId), { method: 'GET', data: { limit, offset } }, _getJobTodoSuccess);
    }

    function _getJobTodoSuccess(response) {
        const dataResponse = [...response.data];

        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                data: dataResponse,
                renderShowMore: dataResponse.length > 10
            };
        });
    }

    const _openFormAdd = () => {
        refFormAdd.current._open();
        refButtonAddNew.current.className = 'v2-btn-default --icon-lg --transparent tooltip is-disable';
    };

    const _handleCloseFormAdd = (isSave = false) => {
        refButtonAddNew.current.className = 'v2-btn-default --icon-lg --transparent tooltip';
        if (!numberCheckList && !isSave) {
            setTimeout(() => {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        isVisible: false,
                        isLoading: true
                    };
                });
            }, 0);
        }
    };

    const _handleAddNewCheckList = (description) => {
        const newData = {
            id: uuidv4(),
            description,
            status: 0
        };

        dispatchState((prev) => {
            const newDataAdd = [...prev.data, { ...newData, addNew: true }];

            return {
                ...prev,
                data: newDataAdd,
                renderShowMore: newDataAdd.length > 10
            };
        });

        clientQuery(
            getJobTodo(jobId),
            // eslint-disable-next-line no-undef
            { method: 'POST', data: { ...newData, socket_id: global.socketIdJobDetail } },
            _handleAddSuccess
        );
    };

    const _handleAddSuccess = ({ data: newItem }) => {
        let shouldCallUpdate = false;
        let shouldCallUpdateStatus = false;

        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === newItem.id) {
                        const needUpdate = !!item.needUpdate;
                        const needUpdateStatus = !!item.needUpdateStatus;

                        if (needUpdate) {
                            shouldCallUpdate = item;
                        }

                        if (needUpdateStatus) {
                            shouldCallUpdateStatus = item;
                        }

                        return {
                            ...item,
                            ...newItem,
                            description: item.description,
                            needUpdate: false,
                            needUpdateStatus: false,
                            addNew: false
                        };
                    }
                    return item;
                })
            };
        });

        setTimeout(() => {
            if (shouldCallUpdate) {
                _callApiUpdateCheckList(shouldCallUpdate);
            }
        }, 0);

        setTimeout(() => {
            if (shouldCallUpdateStatus) {
                _callApiUpdateCheckListStatus(shouldCallUpdateStatus);
            }
        }, 0);
    };

    const _handleSelectTemplaceSuccess = ({ data: itemsAdded = [] }) => {
        dispatchState((prev) => {
            const newDataCheckList = [...prev.data, ...itemsAdded];

            return {
                ...prev,
                data: [...prev.data, ...itemsAdded],
                isLoadingAddMore: false,
                renderShowMore: newDataCheckList.length > 10
            };
        });
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: 'none',
        opacity: isDragging ? 0.7 : 1,
        backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
        border: 1
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const _onDragEnd = (result) => {
        if (!result.destination) {
            return false;
        }
        let arrayMove = [];
        const finalIndex = result.destination.index;
        dispatchState((prev) => {
            arrayMove = reorder(prev.data, result.source.index, result.destination.index);
            return {
                ...prev,
                data: arrayMove
            };
        });

        setTimeout(() => {
            const findPreItem = arrayMove[finalIndex - 1];
            const currentItem = arrayMove[finalIndex];
            clientQuery(moveCheckList(jobId), {
                method: 'PUT',
                data: { id: currentItem?.id || '', after_id: findPreItem?.id || '' },
                // eslint-disable-next-line no-undef
                socket_id: global.socketIdJobDetail
            });
        }, 0);
    };

    const _callApiUpdateCheckList = (newData) => {
        clientQuery(
            actionCheckList(jobId, newData.id),
            // eslint-disable-next-line no-undef
            { method: 'PUT', data: { description: newData.description, socket_id: global.socketIdJobDetail } }
        );
    };

    const _callApiUpdateCheckListStatus = (newData) => {
        clientQuery(
            actionCheckListStatus(jobId, newData.id),
            // eslint-disable-next-line no-undef
            { method: 'PUT', data: { status: newData.status, socket_id: global.socketIdJobDetail } }
        );
    };

    /**
     * If item new without api create response please not call api update
     * @param {*} newItem
     */
    const _handleUpdateItem = (newItem) => {
        let shouldCallUpdate = true;

        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === newItem.id) {
                        const isItemNew = !!item.addNew;
                        shouldCallUpdate = !isItemNew;
                        return {
                            ...item,
                            ...newItem,
                            needUpdate: isItemNew
                        };
                    }
                    return item;
                })
            };
        });

        setTimeout(() => {
            shouldCallUpdate && _callApiUpdateCheckList(newItem);
        }, 0);
    };

    const _handleUpdateStatus = (newItem) => {
        let shouldCallUpdate = true;

        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === newItem.id) {
                        const isItemNew = !!item.addNew;
                        shouldCallUpdate = !isItemNew;
                        return {
                            ...item,
                            ...newItem,
                            needUpdateStatus: isItemNew
                        };
                    }
                    return item;
                })
            };
        });

        setTimeout(() => {
            shouldCallUpdate && _callApiUpdateCheckListStatus(newItem);
        }, 0);
    };

    const _handleConfirmDelete = (itemId) => {
        refConfirm.current.open(itemId);
    };

    const _handleConfirmDeleteTodo = (itemId) => {
        refConfirmDeleteTodo.current.open(itemId);
    };

    const _handleToggleShowMore = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                showMore: !prev.showMore
            };
        });
    };

    const _handleDeleteItem = (itemId) => {
        dispatchState((prev) => {
            const newData = prev.data.filter((item) => {
                if (item.id !== itemId) {
                    return item;
                }
            });

            return {
                ...prev,
                data: newData,
                isVisible: !!newData.length,
                renderShowMore: newData.length > 10
            };
        });

        clientQuery(
            actionCheckList(jobId, itemId),
            // eslint-disable-next-line no-undef
            { method: 'DELETE', data: { socket_id: global.socketIdJobDetail } }
        );
    };

    const _handleDeleteTodo = () => {
        clientQuery(getJobTodo(jobId), { method: 'DELETE' });
        dispatchState((prev) => {
            return {
                ...prev,
                data: [],
                isVisible: false,
                renderShowMore: false
            };
        });
    };

    const _handleSelectTemplate = (templateId) => {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoadingAddMore: true,
                showMore: false
            };
        });

        clientQuery(
            actionCopyTemplate(jobId),
            // eslint-disable-next-line no-undef
            { method: 'PUT', data: { template_id: templateId, socket_id: global.socketIdJobDetail } },
            _handleSelectTemplaceSuccess
        );
    };

    const _handleOpenFormSaveAs = () => {
        refSaveAsTemplate.current._open(
            finalData
                .filter((item) => !!!item.status)
                .map((item) => {
                    return {
                        id: uuidv4(),
                        description: item.description
                    };
                })
        );
    };

    const _renderCheckList = () => {
        return (
            <DragDropContext onDragEnd={_onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {finalData.map((item, index) => {
                                if (showMore && index > 9) {
                                    return false;
                                }
                                const serviceItemId = item.id || index.toString();
                                return (
                                    <Draggable key={serviceItemId} draggableId={serviceItemId} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <CheckListDetail
                                                    onUpdate={_handleUpdateItem}
                                                    onDelete={_handleConfirmDelete}
                                                    onUpdateStatus={_handleUpdateStatus}
                                                    key={item.id}
                                                    itemDetail={item}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    if (finalIsLoading && fialIsVisible) {
        return <Loading />;
    }

    return (
        <Fragment>
            {fialIsVisible && (
                <div
                    className={classNames('wrap-todolist boxs --topnote is-show', {
                        'is-completed': percentCheckListDone === 1
                    })}
                >
                    <div className="left-icons">
                        <IconColumns isChecklist />
                    </div>
                    <div className="wrapper-template content">
                        <div className="content__header flexcenter">
                            <div className="flexcenter">
                                <h5 className="title-font15">{t('setting:todo_list')}</h5>
                                <span
                                    className={classNames('status-btn --sm', {
                                        'bg-orange-red': !!numberDone && percentCheckListDone !== 1,
                                        'bg-black-darker': !numberDone,
                                        'bg-green-default': percentCheckListDone === 1
                                    })}
                                >{`${numberDone}/${numberCheckList}`}</span>
                            </div>
                            <Progress percent={percentCheckListDone} numberDone={numberDone} />
                            <RepeatTodo
                                repeatWithJob={repeatWithJob}
                                jobId={jobId}
                                activeRepeat={!!numberCheckList}
                                isJobRepeat={isJobRepeat}
                            />
                            <Options
                                onSelectTemplate={_handleSelectTemplate}
                                onSaveAsTemplate={_handleOpenFormSaveAs}
                                onDeleteTodo={_handleConfirmDeleteTodo}
                                numberCheckList={numberCheckList}
                            />
                            <div
                                ref={refButtonAddNew}
                                onClick={_openFormAdd}
                                className="v2-btn-default --icon-lg --transparent tooltip"
                            >
                                <IconPlus />
                                <p className="tooltiptext top ml-n1">{t('jobDetail:add_a_new_todo')}</p>
                            </div>
                        </div>
                        <Add
                            autoOpen={initAddTodo}
                            ref={refFormAdd}
                            onSave={_handleAddNewCheckList}
                            onClose={_handleCloseFormAdd}
                        />
                        <GdConfirm
                            ref={refConfirm}
                            title={t('setting:delete_to')}
                            message={t('setting:message_delete_todo')}
                            titleConfirm={t('common:yes')}
                            listButton={{ confirm: true, cancel: true }}
                            onConfirm={_handleDeleteItem}
                        />
                        <GdConfirm
                            ref={refConfirmDeleteTodo}
                            title={t('setting:delete_to_list')}
                            message={t('setting:message_delete_todo_list')}
                            titleConfirm={t('common:yes')}
                            listButton={{ confirm: true, cancel: true }}
                            onConfirm={_handleDeleteTodo}
                        />
                        <SaveAsTemplate ref={refSaveAsTemplate} />
                        <div className="wrap-todo-items">
                            {_renderCheckList()}
                            {isLoadingAddMore && <LoadingMore />}
                            {renderShowMore && <ShowMore isShowMore={showMore} onToggle={_handleToggleShowMore} />}
                        </div>
                    </div>
                </div>
            )}
            <RealTimeService jobId={jobId} onUpdate={dispatchState} />
        </Fragment>
    );
});

export default JobTodo;
