import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GdConfirm from 'app/components/confirm';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { CALENDAR_GET_LIST_JOB } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/App';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { actionSendNotifyTechnician } from 'app/const/api/V2';
import DeleteJobInvoice from 'app/modules/jobdetail/modal/DeleteJobInvoice';
import DeleteJobRepeat from 'app/modules/jobdetail/modal/DeleteJobRepeat';
import IconBell from 'assets/icon/IconBell';
import IconSpeaker from 'assets/icon/IconSpeaker';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import { actionDeleteJob } from 'common/redux/actions/job';
import { clientQuery } from 'common/utils/ApiUtils';
import { isShowNotifyCustomer } from 'common/utils/JobStatusUtils';
import { DATA_WARNING_BY_TYPE, TYPE_WARNING } from '../const';
import MessagingCustomer from '../modal/MessagingCustomer';

const OptionsJobDetail = ({ jobData = {}, onClose = () => {} }) => {
    const jobDataDetail = { ...(jobData?.job || {}) };

    const [state, dispatchState] = useReducer(reducer, { isVisible: false, typeWarning: TYPE_WARNING.DELETE });
    const refOptions = useRef(null);
    const refDeleteJobInvoice = useRef(null);
    const refDeleteJobRepeat = useRef(null);
    const refConfirm = useRef(null);
    const refWarning = useRef(null);
    const refMessagingCustomer = useRef(null);

    const dispatch = useDispatch();
    const { t } = useTranslation(['customers', 'jobDetail']);

    const { isVisible: finalIsVisible, typeWarning: finalTypeWarning } = state;
    const jobId = jobDataDetail.id;
    const { titleButtonConfirm, modalClassName, descriptionClassName } = DATA_WARNING_BY_TYPE[finalTypeWarning] || {};

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_job_detail_options');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_job_detail_options');

        if (
            refOptions.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refOptions.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();

        dispatchState({ isVisible: !finalIsVisible });
    }

    const _handleDeleteSuccess = (reponse) => {
        _handleCloseWarning();
        const invoices = reponse?.invoice;

        if (invoices && Array.isArray(invoices)) {
            refDeleteJobInvoice.current.showModal(invoices);
            return false;
        }

        if (reponse.success && reponse?.recurrence) {
            refDeleteJobRepeat.current.showModal(_handleDelete);
            return false;
        }

        if (reponse.success && !reponse.error) {
            onClose();
            const newJobDetail = {
                ...jobDataDetail,
                parent_job_id: jobDataDetail.parent_job_id.length === 0 ? jobId : jobDataDetail.parent_job_id
            };
            dispatch(actionDeleteJob(newJobDetail));
        }
    };

    const _handleDelete = (flagDelete = 0) => {
        dispatchState({ isVisible: false });

        const optionQuery = {
            data: {
                // eslint-disable-next-line no-undef
                socket_id: global.socketIdJobDetail,
                ids: [jobId],
                delete: flagDelete
            },
            method: 'DELETE'
        };

        clientQuery(CALENDAR_GET_LIST_JOB, optionQuery, _handleDeleteSuccess, _handleDeleteFailure);
    };

    function _handleDeleteFailure(reponse) {
        _handleCloseWarning();
        refConfirm.current.open(null, reponse?.message?.toString() || t('customers:please_try_again'));
    }

    const _handleConfirmWarning = (_, typeConfirm) => {
        refWarning.current?._clearStatusAlert();

        switch (typeConfirm) {
            case TYPE_WARNING.DELETE:
                _handleDelete(0);
                return;
            case TYPE_WARNING.NOTIFY_TECHNICIAN:
                _handleWarningNotifyTechnician();
                return;
            default:
                break;
        }
    };

    const _handleWarningNotifyTechnician = () => {
        const _success = ({ message }) => {
            _onShowStatusAlert({ message, status: LIST_STATUS.SUCCESS });
        };

        const _fail = ({ message }) => {
            _onShowStatusAlert({ message });
        };

        const _finally = () => {
            _handleResetLoading();
        };

        clientQuery(
            actionSendNotifyTechnician(jobId),
            { method: 'POST', data: {}, toFormData: false },
            _success,
            _fail,
            _finally
        );
    };

    const _handleCloseWarning = () => {
        refWarning.current?._close();
        _handleResetLoading();
    };

    const _handleOpenMessaging = () => {
        _closeSearchResult();
        refMessagingCustomer.current?._handleOpen();
    };

    const _handleWarning = (type) => {
        if (type !== finalTypeWarning) {
            dispatchState((prev) => ({ ...prev, typeWarning: type }));
        }
        const dataWarningByType = DATA_WARNING_BY_TYPE[type] || {};
        refWarning.current?._open({
            title: dataWarningByType?.title ? t(`jobDetail:${dataWarningByType.title}`) : '',
            description: dataWarningByType?.description ? t(`jobDetail:${dataWarningByType.description}`) : '',
            dataConfirm: type
        });
    };

    const _handleResetLoading = () => {
        refWarning.current?._setLoadingBtnSave(false);
    };

    const _onShowStatusAlert = ({ message = '', status = LIST_STATUS.ERROR }) => {
        refWarning.current?._setStatusAlert(message, status);
    };

    return (
        <>
            <div className={classNames('v2-dropdown v2-dropdown--more', { active: finalIsVisible })} ref={refOptions}>
                <div className="dropbtn" onClick={(e) => _handleOpen(e)}>
                    <IconThreeDots />
                </div>
                {finalIsVisible && (
                    <div className="v2-dropdown__menu content-full scrolls" id="show_job_detail_options">
                        <ul>
                            {isShowNotifyCustomer(jobDataDetail?.status) ? (
                                <Fragment>
                                    <li className="items has-icon btn-modal" onClick={_handleOpenMessaging}>
                                        <IconSpeaker />
                                        {t('customers:messaging')}
                                    </li>
                                </Fragment>
                            ) : null}
                            <li
                                className="items has-icon btn-modal"
                                onClick={() => _handleWarning(TYPE_WARNING.NOTIFY_TECHNICIAN)}
                            >
                                <IconBell />
                                {t('jobDetail:notify_technician_title')}
                            </li>
                            <div className="line" />
                            <li
                                className="items has-icon red-default btn-modal"
                                onClick={() => _handleWarning(TYPE_WARNING.DELETE)}
                            >
                                <IconTrash />
                                {t('customers:delete_job')}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <DeleteJobInvoice ref={refDeleteJobInvoice} />
            <DeleteJobRepeat ref={refDeleteJobRepeat} />
            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
            />
            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                buttonSave
                titleButtonConfirm={t(`common:${titleButtonConfirm}`)}
                modalClassName={modalClassName}
                descriptionClassName={descriptionClassName}
                onConfirm={_handleConfirmWarning}
            />
            <MessagingCustomer ref={refMessagingCustomer} jobData={jobData} />
        </>
    );
};

export default OptionsJobDetail;
