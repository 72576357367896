import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getListInvoicesPayment } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { INVOICE_DETAIL_TYPE, PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionCloseJobDetail } from 'common/redux/actions/job/detail';
import { clientQuery } from 'common/utils/ApiUtils';
import { roundingMoney, transformToCurrency } from 'common/utils/NumberUtils';
import LoadingInvoices from './LoadingInvoices';

const PaymentInvoice = ({ invoiceNumber, onClose = () => {}, isPaymentCustomer }) => {
    const { t } = useTranslation();
    const { addPayment, updateNumberPaymentContext } = useContext(AddPaymentContext);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        checkAll: false
    });

    const isLoading = state.isLoading;
    const invoices = addPayment.invoices || [];
    const selected = addPayment.invoiceSelected || [];
    const customerId = addPayment.customerId;
    const isMethodACH = addPayment.paymentSelected.id === PAYMENT_METHODS.ACH;

    const dispatch = useDispatch();

    useEffect(() => {
        customerId && _getListInvoicesPayment();
    }, [customerId, addPayment.filterInvoice, addPayment.isReloadInvoice]);

    const _getListInvoicesPayment = () => {
        !isLoading && dispatchState({ isLoading: true });
        const _getListInvoicesPaymentSuccess = (response) => {
            const newInvoices = response?.data || [];

            dispatchState({
                isLoading: false
            });

            const invoiceSelected = invoiceNumber && !addPayment.isReloadInvoice ? [invoiceNumber] : [];

            updateNumberPaymentContext({
                invoices: newInvoices,
                invoiceSelected: invoiceSelected,
                checkAll: invoiceSelected.length === newInvoices.length && invoiceSelected.length !== 0
            });
        };

        const optionsQuery = {
            method: 'GET',
            data: {
                customer_id: customerId,
                status: addPayment.filterInvoice
            }
        };

        clientQuery(getListInvoicesPayment, optionsQuery, _getListInvoicesPaymentSuccess, () => {});
    };

    function _handleChange(e, invoiceChange) {
        e.preventDefault();

        let newSelected = [...selected];
        let findItem = null;
        newSelected = newSelected.filter((item) => {
            if (item === invoiceChange) {
                findItem = item;
            } else {
                return item;
            }
        });

        if (!findItem) {
            if (isMethodACH) newSelected = [invoiceChange];
            else newSelected.push(invoiceChange);
        }

        updateNumberPaymentContext({
            invoiceSelected: newSelected,
            checkAll: newSelected.length === invoices.length && newSelected.length !== 0
        });
    }

    function _handleChangeCheckAll() {
        if (isMethodACH) return;
        const prevCheckAll = addPayment.checkAll;

        updateNumberPaymentContext({
            checkAll: !prevCheckAll,
            invoiceSelected: prevCheckAll ? [] : invoices.map((item) => item.number)
        });
    }

    const _openInvoice = (e, invoiceData) => {
        e.preventDefault();

        const elm = document.getElementById('tab_inoive_detail_setting');
        elm && elm.classList.remove('show-addpayment');

        onClose();
        dispatch(actionCloseJobDetail());

        dispatch(
            actionOpenInvoice({
                id: invoiceData.id,
                status: '',
                total: '',
                isRecurring: false,
                type: INVOICE_DETAIL_TYPE.NORMAL
            })
        );
    };

    const _renderList = (list) => {
        if (isLoading) {
            return <LoadingInvoices />;
        }

        if (!Array.isArray(list) || list.length === 0) return null;

        return list.map((item) => {
            const invoiceNo = item.number;
            const isActive = selected.includes(invoiceNo);

            return (
                <div onClick={(e) => _handleChange(e, invoiceNo)} key={item.id} className="rows grid-content">
                    <div className="col-checkbox">
                        <div className="check-items">
                            <input id="check-invoice" type="checkbox" checked={isActive} onChange={() => {}} />
                            <div className="item-checkbox">
                                <label htmlFor="check-invoice" />
                            </div>
                        </div>
                    </div>
                    <div onClick={(e) => _openInvoice(e, item)} className="col-invoice">
                        <div className="txt-ellipsis cursor-pointer">{invoiceNo}</div>
                    </div>
                    <div className="col-date pl-1">
                        <span className="txt-ellipsis">{item.date}</span>
                    </div>
                    <div className="col-status pl-2">
                        <div className={`status-btn ${item.status}`}>{t(`customers:${item.status}`)}</div>
                    </div>
                    <div className="col-charge pl-2 txt-ellipsis">
                        {isPaymentCustomer ? item.total.format : roundingMoney(item.total.value)}
                    </div>
                    <div className="col-charge pl-2 txt-ellipsis">
                        {isPaymentCustomer ? item.amount_due.format : roundingMoney(item.amount_due.value)}
                    </div>
                    <div className="col-charge col-payment pl-2 txt-ellipsis">
                        {transformToCurrency(parseFloat(item.payment || 0), currency)}
                    </div>
                </div>
            );
        });
    };

    if (!addPayment.customerId) {
        return (
            <div className="table-grid">
                <div className="rows grid-content --message">
                    <p className="fw-500">{t('jobDetail:please_search_and_select_customer')}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="table-grid">
            <div className="rows grid-header">
                <div className="col-checkbox">
                    <div className="check-items">
                        <input
                            readOnly
                            id="check_all_invoice"
                            type="checkbox"
                            onChange={_handleChangeCheckAll}
                            checked={addPayment.checkAll}
                        />
                        <div className="item-checkbox">
                            <label htmlFor="check_all_invoice" />
                        </div>
                    </div>
                </div>
                <div className="col-invoice">{t('jobDetail:invoice')}</div>
                <div className="col-date pl-1">{t('common:date')}</div>
                <div className="col-status pl-2">{t('common:status')}</div>
                <div className="col-charge pl-2">{t('jobDetail:orig_amt')}</div>
                <div className="col-charge pl-2">{t('jobDetail:amt_due')}</div>
                <div className="col-charge col-payment pl-2">{t('customers:payment')}</div>
            </div>
            {_renderList(invoices)}
        </div>
    );
};

PaymentInvoice.propTypes = {
    invoiceData: PropTypes.object
};

PaymentInvoice.defaultProps = {
    invoiceData: {}
};

export default PaymentInvoice;
