import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CLASS_NAME_OPEN_LOCATION } from 'app/components/dropdown/constant';
import { reducer } from 'app/const/Reducer';
import {
    displayLocation,
    eventLocationDropdown,
    getQueryLocationMap
} from 'app/modules/customer/detail/locations/utils';
import { openLocation } from 'common/redux/actions/location';
import { convertLinkInString } from 'common/utils/StringUtils';
import PreviewNoteCollapse from './collapse';
import Messaging from './messaging/Messaging';

const Location = ({ customerLocation: customer = {}, finalCustomer = {}, jobId: idJob, customerId: idCustomer }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const refMessage = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        customerLocation: customer,
        jobId: idJob,
        customerId: idCustomer
    });
    const { customerLocation, jobId, customerId } = state;

    useEffect(() => {
        dispatchState({
            customerLocation: customer,
            jobId: idJob,
            customerId: idCustomer
        });

        eventLocationDropdown({ isUpdate: true });
    }, [idJob, customer]);

    const _handleEditLocation = () => {
        dispatch(
            openLocation({
                id: customerLocation.id,
                customer: finalCustomer,
                onUpdateLocationSuccess: _handleEditLocationSuccess
            })
        );
    };

    const _handleEditLocationSuccess = (data) => {
        data.messaging_preferences && refMessage.current.updateMessage(data.messaging_preferences);
        let newDataAddress = { ...customerLocation, name: data.location_name, note: data.location_note };
        const serviceAddress = data.address.service;

        if (serviceAddress) {
            newDataAddress = {
                ...newDataAddress,
                street1: serviceAddress.line1,
                street2: serviceAddress.line2,
                city: serviceAddress.city,
                state: serviceAddress.state,
                zip: serviceAddress.zip,
                lat: serviceAddress.address_lat,
                lng: serviceAddress.address_lng
            };
        }

        dispatchState({ customerLocation: newDataAddress });
    };

    return (
        <Fragment>
            <div className="wrap-information">
                <div className="flex-betweentop">
                    <div className="v2-btn-default btn-bg-purple tooltip" onClick={_handleEditLocation}>
                        <span className="fs-13 fw-600">{customerLocation.name}</span>
                        <p className="tooltiptext top">{t('customers:open_location')}</p>
                    </div>
                    <Messaging
                        ref={refMessage}
                        jobId={jobId}
                        locationId={customerLocation.id}
                        customerId={customerId}
                    />
                </div>
                <LocationItem customerLocation={customerLocation} />
            </div>
            <PreviewNoteCollapse
                title={t('customers:location_note')}
                note={convertLinkInString(customerLocation.note || '')}
                isInnerHTML
                extraClass="is-location white-space-pre"
            />
        </Fragment>
    );
};

const LocationItem = ({ customerLocation }) => {
    function _handleOpenLocation(target) {
        eventLocationDropdown({
            target,
            address: getQueryLocationMap(customerLocation),
            dataLatLng: { ...(customerLocation || {}) }
        });
    }

    return (
        <div
            className={classNames('wrap-information__label address-location cursor-pointer', CLASS_NAME_OPEN_LOCATION)}
            onClick={_handleOpenLocation}
        >
            {displayLocation(customerLocation)}
        </div>
    );
};

export default Location;
