export const SORT_TYPES = { ASC: 'asc', DESC: 'desc' };
export const OPPORTUNITIES_STATUSES = [
    { id: 1, name: 'active', value: 1 },
    { id: 2, name: 'won', value: 2 },
    { id: 3, name: 'lost', value: 3 }
];
export const JOB_STATUSES = [
    { id: 0, name: 'smartView:unconfirmed', value: 0 },
    { id: 1, name: 'smartView:confirmed', value: 1 },
    { id: 2, name: 'smartView:completed', value: 2 },
    { id: 3, name: 'smartView:reschedule', value: 3 },
    { id: 4, name: 'smartView:pending_confirmation', value: 4 },
    { id: 5, name: 'smartView:canceled', value: 5 },
    { id: 6, name: 'smartView:recurrence', value: 6 }
];
export const ESTIMATE_STATUSES = [
    { id: 0, name: 'smartView:draft', value: 'draft' },
    { id: 1, name: 'smartView:pending', value: 'pending' },
    { id: 2, name: 'smartView:won', value: 'won' },
    { id: 3, name: 'smartView:invoiced', value: 'invoiced' },
    { id: 4, name: 'smartView:lost', value: 'lost' }
];
export const CALL_DIRECTION_STATUSES_OPTIONS = [
    { id: 0, name: 'smartView:incoming_calls', value: 1 },
    { id: 1, name: 'smartView:outgoing_calls', value: 2 }
];
export const CALL_STATUSES_OPTIONS = [
    { id: 0, name: 'smartView:missed', value: 0 },
    { id: 1, name: 'smartView:success', value: 1 }
];

export const FIELDS_TYPE_SHOULD_FETCH = ['services', 'sources'];

export const OBJECT_TYPES = {
    CUSTOMER: 'CUSTOMER',
    TASK: 'TASK',
    DATE: 'DATE',
    JOB: 'JOB',
    CALL: 'CALL',
    OPPORTUNITY: 'OPPORTUNITY',
    SMS_MESSAGES: 'SMS_MESSAGES',
    EMAIL: 'EMAIL',
    SMS: 'SMS',
    ESTIMATE: 'ESTIMATE',

    CUSTOM_FIELD: 'CUSTOM_FIELD',
    CUSTOM_JOB_STATUSES: 'CUSTOM_JOB_STATUSES'
};

export const REFERENCE_TYPES = {
    STATUS_OPPORTUNITY: 'STATUS_OPPORTUNITY',
    SCHEDULES: 'SCHEDULES',
    TAG: 'TAG',
    CITY: 'CITY',
    ZIP: 'ZIP',
    ITEMS: 'ITEMS'
};

export const FIELDS_TYPE = {
    STATUS: 'STATUS',
    COUNT_TASK: 'COUNT_TASK',
    COUNT_SMS: 'COUNT_SMS',
    COUNT_CALL: 'COUNT_CALL',
    CREATED_DATE: 'CREATED_DATE',
    LATEST_CALL_CREATED_DATE: 'LATEST_CALL_CREATED_DATE',
    CUSTOMER_CREATED_DATE: 'CUSTOMER_CREATED_DATE',
    SOURCE_ID: 'SOURCE_ID',
    DATE_WITHOUT_PRESENT: 'DATE_WITHOUT_PRESENT',

    // Jobs Fields
    COUNT_JOB: 'COUNT_JOB',
    // Opportunity Fields
    STATUS_OPPORTUNITY: 'STATUS_OPPORTUNITY',
    REFERENCE_USER: 'REFERENCE_USER',
    CREATED_BY: 'CREATED_BY',
    // Custom for GD teams
    REFERENCE_TYPE: 'REFERENCE_TYPE',
    CUSTOM_FIELD: 'CUSTOM_FIELD',

    // Above is custom from GD teams
    INTEGER: 'INTEGER',

    TEXT: 'TEXT',
    ANY_TEXT: 'ANY_TEXT',
    ENUM: 'ENUM',
    SAVED_SEARCH: 'SAVED_SEARCH',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    DATE_TIME: 'DATE_TIME',
    FLOAT: 'FLOAT',
    DURATION: 'DURATION',
    BOOLEAN: 'BOOLEAN'
};

export const OPERATOR_IDS = {
    IS: 'is',
    NOT: 'not',
    EXACTLY: 'exactly',
    NOT_EXACTLY: 'not exactly',
    IN: 'in',
    NOT_IN: 'not in',
    SOME: 'some',
    NONE: 'none',
    CONTAINS_EXACT_WORDS: 'contains exact words',
    NOT_CONTAINS_EXACT_WORDS: 'does not contain exact words',
    CONTAINS_PHRASE: 'contains phrase',
    NOT_CONTAINS_PHRASE: 'does not contain phrase',
    CONTAINS_WORDS_STARTING_WITH: 'contains words starting with',
    NOT_CONTAINS_WORDS_STARTING_WITH: 'does not contain words starting with',
    EXACT_VALUE: 'exact value',
    NOT_EXACT_VALUE: 'not exact value',
    MORE: 'more',
    LESS: 'less',
    BEFORE: 'before',
    ON_OR_BEFORE: 'on or before',
    AFTER: 'after',
    ON_OR_AFTER: 'on or after',
    SV: 'sv',
    NOT_SV: 'not sv',
    WITHIN: 'within',
    NOT_WITHIN: 'not within',
    BETWEEN: 'between',
    NOT_BETWEEN: 'not between',
    PRESENT: 'present',
    NOT_PRESENT: 'not present',
    ANY_TEXT_CONTAINS_EXACT_WORDS: 'any text contains exact words',
    ANY_TEXT_NOT_CONTAINS_EXACT_WORDS: 'any text does not contain exact words',
    ANY_TEXT_CONTAINS_PHRASE: 'any text contains phrase',
    ANY_TEXT_NOT_CONTAINS_PHRASE: 'any text does not contain phrase',
    ANY_TEXT_CONTAINS_WORDS_STARTING_WITH: 'any text contains words starting with',
    ANY_TEXT_NOT_CONTAINS_WORDS_STARTING_WITH: 'any text does not contain words starting with',
    ANY_TEXT_EXACT_VALUE: 'any text exact value',
    ANY_TEXT_NOT_EXACT_VALUE: 'any text not exact value'
};
export const OPERATORS_EXCEPT_VALUE = [
    OPERATOR_IDS.NONE,
    OPERATOR_IDS.SOME,
    OPERATOR_IDS.PRESENT,
    OPERATOR_IDS.NOT_PRESENT
];

export const OPERATOR_TYPES = {
    [OPERATOR_IDS.IS]: { id: OPERATOR_IDS.IS, label: 'smartView:is', suffix: ':' },
    [OPERATOR_IDS.NOT]: {
        id: OPERATOR_IDS.NOT,
        label: 'smartView:is_not',
        prefix: 'not ',
        suffix: ':',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.EXACTLY]: { id: OPERATOR_IDS.EXACTLY, label: 'smartView:is_exactly…', suffix: ':' },
    [OPERATOR_IDS.NOT_EXACTLY]: {
        id: OPERATOR_IDS.NOT_EXACTLY,
        label: 'smartView:is_not_exactly…',
        prefix: 'not ',
        suffix: ':',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.IN]: {
        id: OPERATOR_IDS.IN,
        label: 'smartView:is_any_of…',
        suffix: ' in ',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_IN]: {
        id: OPERATOR_IDS.NOT_IN,
        label: 'smartView:is_not_any_of…',
        prefix: 'not ',
        suffix: ' in ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.SOME]: {
        id: OPERATOR_IDS.SOME,
        label: 'smartView:some',
        suffix: ' > 0',
        prefixToken: 'smartView:__some'
    },
    [OPERATOR_IDS.NONE]: {
        id: OPERATOR_IDS.NONE,
        label: 'smartView:none',
        suffix: ': 0',
        prefixToken: 'smartView:__none'
    },
    [OPERATOR_IDS.CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.CONTAINS_EXACT_WORDS,
        label: 'smartView:contains_exact_words…',
        suffix: ':',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.NOT_CONTAINS_EXACT_WORDS,
        label: 'smartView:does_not_contains_exact_words…',
        prefix: 'not ',
        suffix: ':',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.CONTAINS_PHRASE,
        label: 'smartView:contains_phrase',
        suffix: ':',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.NOT_CONTAINS_PHRASE,
        label: 'smartView:does_not_contains_phrase…',
        prefix: 'not ',
        suffix: ':',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:contains_words_starting_with…',
        suffix: ':'
    },
    [OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:does_not_contains_words_starting_with…',
        prefix: 'not ',
        suffix: ':',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.EXACT_VALUE]: {
        id: OPERATOR_IDS.EXACT_VALUE,
        label: 'smartView:is_exactly…',
        suffix: ':',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_EXACT_VALUE]: {
        id: OPERATOR_IDS.NOT_EXACT_VALUE,
        label: 'smartView:is_not_exactly…',
        prefix: 'not ',
        suffix: ':',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.MORE]: {
        id: OPERATOR_IDS.MORE,
        label: 'smartView:is_more_than…',
        suffix: ' > ',
        prefixToken: 'smartView:__more_than'
    },
    [OPERATOR_IDS.LESS]: {
        id: OPERATOR_IDS.LESS,
        label: 'smartView:is_less_than…',
        suffix: ' < ',
        prefixToken: 'smartView:__less_than'
    },
    [OPERATOR_IDS.BEFORE]: {
        id: OPERATOR_IDS.BEFORE,
        label: 'smartView:is_before…',
        prefixToken: 'smartView:__before'
    },
    [OPERATOR_IDS.ON_OR_BEFORE]: {
        id: OPERATOR_IDS.ON_OR_BEFORE,
        label: 'smartView:is_on_or_before…',
        prefixToken: 'smartView:__on_or_before'
    },
    [OPERATOR_IDS.AFTER]: { id: OPERATOR_IDS.AFTER, label: 'smartView:is_after…', prefixToken: 'smartView:__after' },
    [OPERATOR_IDS.ON_OR_AFTER]: {
        id: OPERATOR_IDS.ON_OR_AFTER,
        label: 'smartView:is_on_or_after…',
        prefixToken: 'smartView:__on_or_after'
    },
    [OPERATOR_IDS.SV]: { id: OPERATOR_IDS.SV, label: 'smartView:is_any_of…' },
    [OPERATOR_IDS.NOT_SV]: {
        id: OPERATOR_IDS.NOT_SV,
        label: 'smartView:is_not_any_of…',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.WITHIN]: {
        id: OPERATOR_IDS.WITHIN,
        label: 'smartView:is_within…',
        prefixToken: 'smartView:__within'
    },
    [OPERATOR_IDS.NOT_WITHIN]: {
        id: OPERATOR_IDS.NOT_WITHIN,
        label: 'smartView:is_not_within…',
        prefix: 'not (',
        valueSuffix: ')',
        prefixToken: 'smartView:__not_within'
    },
    [OPERATOR_IDS.BETWEEN]: {
        id: OPERATOR_IDS.BETWEEN,
        label: 'smartView:is_between…',
        prefixToken: 'smartView:__between'
    },
    [OPERATOR_IDS.NOT_BETWEEN]: {
        id: OPERATOR_IDS.NOT_BETWEEN,
        label: 'smartView:is_not_between…',
        prefix: 'not (',
        valueSuffix: ')',
        prefixToken: 'smartView:__not_between'
    },
    [OPERATOR_IDS.PRESENT]: {
        id: OPERATOR_IDS.PRESENT,
        label: 'smartView:is_present',
        suffix: ': *',
        prefixToken: 'smartView:__present'
    },
    [OPERATOR_IDS.NOT_PRESENT]: {
        id: OPERATOR_IDS.NOT_PRESENT,
        label: 'smartView:is_present',
        suffix: ': ""',
        prefixToken: 'smartView:__not_present'
    },
    [OPERATOR_IDS.ANY_TEXT_CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.ANY_TEXT_CONTAINS_EXACT_WORDS,
        label: 'smartView:contains_exact_words…',
        useQuotes: !0
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_EXACT_WORDS,
        label: 'smartView:does_not_contains_exact_words…',
        prefix: 'not ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.ANY_TEXT_CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.ANY_TEXT_CONTAINS_PHRASE,
        label: 'smartView:contains_phrase',
        useQuotes: !0
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_PHRASE,
        label: 'smartView:does_not_contains_phrase…',
        prefix: 'not ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.ANY_TEXT_CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.ANY_TEXT_CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:contains_words_starting_with…'
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:does_not_contains_words_starting_with…',
        prefix: 'not ',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.ANY_TEXT_EXACT_VALUE]: {
        id: OPERATOR_IDS.ANY_TEXT_EXACT_VALUE,
        label: 'smartView:is_exactly…',
        useQuotes: !0
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_EXACT_VALUE]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_EXACT_VALUE,
        label: 'smartView:is_not_exactly…',
        prefix: 'not ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    }
};
export const OPERATOR_IDS_DROPDOWN = {
    [OPERATOR_IDS.IS]: { id: OPERATOR_IDS.IS, label: 'smartView:is', suffix: ':' },
    [OPERATOR_IDS.NOT]: {
        id: OPERATOR_IDS.NOT,
        label: 'smartView:is_not',
        prefix: 'not ',
        suffix: ':',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.EXACTLY]: { id: OPERATOR_IDS.EXACTLY, label: 'smartView:is_exactly…', suffix: ':' },
    [OPERATOR_IDS.NOT_EXACTLY]: {
        id: OPERATOR_IDS.NOT_EXACTLY,
        label: 'smartView:is_not_exactly…',
        prefix: 'not ',
        suffix: ':',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.IN]: {
        id: OPERATOR_IDS.IN,
        label: 'smartView:is_any_of…',
        suffix: ' in ',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_IN]: {
        id: OPERATOR_IDS.NOT_IN,
        label: 'smartView:is_not_any_of…',
        prefix: 'not ',
        suffix: ' in ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.SOME]: {
        id: OPERATOR_IDS.SOME,
        label: 'smartView:some',
        suffix: ' > 0',
        prefixToken: 'smartView:__some'
    },
    [OPERATOR_IDS.NONE]: {
        id: OPERATOR_IDS.NONE,
        label: 'smartView:none',
        suffix: ': 0',
        prefixToken: 'smartView:__none'
    },
    [OPERATOR_IDS.CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.CONTAINS_EXACT_WORDS,
        label: 'smartView:contains_exact_words…',
        suffix: ':',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.NOT_CONTAINS_EXACT_WORDS,
        label: 'smartView:does_not_contains_exact_words…',
        prefix: 'not ',
        suffix: ':',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.CONTAINS_PHRASE,
        label: 'smartView:contains_phrase',
        suffix: ':',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.NOT_CONTAINS_PHRASE,
        label: 'smartView:does_not_contains_phrase…',
        prefix: 'not ',
        suffix: ':',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:contains_words_starting_with…',
        suffix: ':'
    },
    [OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:does_not_contains_words_starting_with…',
        prefix: 'not ',
        suffix: ':',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.EXACT_VALUE]: {
        id: OPERATOR_IDS.EXACT_VALUE,
        label: 'smartView:is_exactly…',
        suffix: ':',
        useQuotes: !0
    },
    [OPERATOR_IDS.NOT_EXACT_VALUE]: {
        id: OPERATOR_IDS.NOT_EXACT_VALUE,
        label: 'smartView:is_not_exactly…',
        prefix: 'not ',
        suffix: ':',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.MORE]: {
        id: OPERATOR_IDS.MORE,
        label: 'smartView:is_more_than…',
        suffix: ' > ',
        prefixToken: 'smartView:__more_than'
    },
    [OPERATOR_IDS.LESS]: {
        id: OPERATOR_IDS.LESS,
        label: 'smartView:is_less_than…',
        suffix: ' < ',
        prefixToken: 'smartView:__less_than'
    },
    [OPERATOR_IDS.BEFORE]: {
        id: OPERATOR_IDS.BEFORE,
        label: 'smartView:is_before…',
        prefixToken: 'smartView:__before'
    },
    [OPERATOR_IDS.ON_OR_BEFORE]: {
        id: OPERATOR_IDS.ON_OR_BEFORE,
        label: 'smartView:is_on_or_before…',
        prefixToken: 'smartView:__on_or_before'
    },
    [OPERATOR_IDS.AFTER]: { id: OPERATOR_IDS.AFTER, label: 'smartView:is_after…', prefixToken: 'smartView:__after' },
    [OPERATOR_IDS.ON_OR_AFTER]: {
        id: OPERATOR_IDS.ON_OR_AFTER,
        label: 'smartView:is_on_or_after…',
        prefixToken: 'smartView:__on_or_after'
    },
    [OPERATOR_IDS.SV]: { id: OPERATOR_IDS.SV, label: 'smartView:is_any_of…' },
    [OPERATOR_IDS.NOT_SV]: {
        id: OPERATOR_IDS.NOT_SV,
        label: 'smartView:is_not_any_of…',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.WITHIN]: {
        id: OPERATOR_IDS.WITHIN,
        label: 'smartView:is_within…',
        prefixToken: 'smartView:__within'
    },
    [OPERATOR_IDS.NOT_WITHIN]: {
        id: OPERATOR_IDS.NOT_WITHIN,
        label: 'smartView:is_not_within…',
        prefix: 'not (',
        valueSuffix: ')',
        prefixToken: 'smartView:__not_within'
    },
    [OPERATOR_IDS.BETWEEN]: {
        id: OPERATOR_IDS.BETWEEN,
        label: 'smartView:is_between…',
        prefixToken: 'smartView:__between'
    },
    [OPERATOR_IDS.NOT_BETWEEN]: {
        id: OPERATOR_IDS.NOT_BETWEEN,
        label: 'smartView:is_not_between…',
        prefix: 'not (',
        valueSuffix: ')',
        prefixToken: 'smartView:__not_between'
    },
    [OPERATOR_IDS.PRESENT]: {
        id: OPERATOR_IDS.PRESENT,
        label: 'smartView:is_present',
        suffix: ': *',
        prefixToken: 'smartView:__present'
    },
    [OPERATOR_IDS.NOT_PRESENT]: {
        id: OPERATOR_IDS.NOT_PRESENT,
        label: 'smartView:is_not_present',
        suffix: ': ""',
        prefixToken: 'smartView:__not_present'
    },
    [OPERATOR_IDS.ANY_TEXT_CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.ANY_TEXT_CONTAINS_EXACT_WORDS,
        label: 'smartView:contains_exact_words…',
        useQuotes: !0
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_EXACT_WORDS]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_EXACT_WORDS,
        label: 'smartView:does_not_contains_exact_words…',
        prefix: 'not ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.ANY_TEXT_CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.ANY_TEXT_CONTAINS_PHRASE,
        label: 'smartView:contains_phrase',
        useQuotes: !0
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_PHRASE]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_PHRASE,
        label: 'smartView:does_not_contains_phrase…',
        prefix: 'not ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.ANY_TEXT_CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.ANY_TEXT_CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:contains_words_starting_with…'
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_WORDS_STARTING_WITH]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_CONTAINS_WORDS_STARTING_WITH,
        label: 'smartView:does_not_contains_words_starting_with…',
        prefix: 'not ',
        prefixToken: 'smartView:__not'
    },
    [OPERATOR_IDS.ANY_TEXT_EXACT_VALUE]: {
        id: OPERATOR_IDS.ANY_TEXT_EXACT_VALUE,
        label: 'smartView:is_exactly…',
        useQuotes: !0
    },
    [OPERATOR_IDS.ANY_TEXT_NOT_EXACT_VALUE]: {
        id: OPERATOR_IDS.ANY_TEXT_NOT_EXACT_VALUE,
        label: 'smartView:is_not_exactly…',
        prefix: 'not ',
        useQuotes: !0,
        prefixToken: 'smartView:__not'
    }
};
