import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ContentNoteVisible from 'app/components/notevisible/Content';
import TemplateOptions from 'app/components/templatenotes';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconNote from 'assets/icon/IconNote';

const InvoiceTerms = ({ isInvoice = true }) => {
    const { t } = useTranslation(['jobDetail']);
    const refTerm = useRef(null);

    const refContentNoteVisible = useRef(null);
    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);
    const { terms, payment_terms, invoice_status_id } = invoiceData;
    const { content } = payment_terms || {};
    const finalTerm = content || terms;

    useEffect(() => {
        if (!!refTerm.current && !!finalTerm && finalTerm !== refTerm.current.value) refTerm.current.value = finalTerm;
    }, [terms]);

    const _handleSelectTemplate = (item) => {
        const content = item.content || '';
        refContentNoteVisible.current.setValue(content, false);
        onUpdateInvoiceDataContext({ payment_terms: item, terms: content });
    };

    const _handleUpdateTerm = (content) => onUpdateInvoiceDataContext({ terms: content });

    return (
        <div className="boxs --terms-note">
            <div className="left-icons">
                <IconNote isActiveColor />
            </div>
            <div className="content elm-parent has-edited">
                <div className="header-box">
                    <div className="name">
                        <div className="name__label">
                            <h4 className="fs-13">{t('jobDetail:terms')}</h4>
                            <span className="fs-10 fw-500 grey-generic">{t('jobDetail:visible_to_client')}</span>
                        </div>
                    </div>
                    <TemplateOptions
                        isShow
                        typeFilter={NOTES_TEMPLATE_TYPE.PAYMENT_TERMS}
                        isShowFilter={false}
                        templateValue={payment_terms || {}}
                        invoiceStatus={invoice_status_id}
                        isInvoice={isInvoice}
                        onSelectTemplate={_handleSelectTemplate}
                    />
                </div>
                <div className="wrap-content is-complete">
                    <div className="wrap-content-box">
                        <div className="description" dangerouslySetInnerHTML={{ __html: finalTerm }} />
                    </div>
                </div>
                <ContentNoteVisible
                    ref={refContentNoteVisible}
                    content={finalTerm}
                    showRightToolbar={false}
                    onBlur={_handleUpdateTerm}
                />
            </div>
        </div>
    );
};

export default InvoiceTerms;
