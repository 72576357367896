import { SPECIAL_PAYMENT_CARD } from 'app/const/Customers';
import { ADDONS_STRIPE_ACH } from 'app/const/addons';

export const INVOICE_ITEM_SIMPLE = {
    name: '',
    quantity: 1,
    cost: {
        value: 0,
        format: ''
    },
    tax1: null,
    tax2: null,
    subtotal: 0,
    description: '',
    one_time: 0,
    id: Date.now(),
    item_default: true
};

export const DEFAULT_VALUE = {
    id: null,
    discount: {
        type: 1,
        value: '',
        total: {
            value: 0,
            format: 0.0
        }
    },
    deposit: {
        value: '',
        type: 1,
        total: {
            value: 0,
            format: 0.0
        },
        invoices: [],
        is_paid: false
    },
    number: '',
    po_number: '',
    date: null,
    items: [{ ...INVOICE_ITEM_SIMPLE }],
    subtotal: 0,
    total: '0.00',
    trigger_action: '1',
    recurrence: {
        action: 0,
        offset: {
            MO: '0',
            TU: '0',
            WE: '0',
            TH: '1',
            FR: '0',
            SA: '0',
            SU: '0',
            bymonthday: '',
            byyearday: '',
            endDate: '',
            ends: 'never',
            frequency: 'OFF',
            interval: 1,
            occurances: 1,
            nextDateInvoice: null,
            dateIssue: null
        },
        repeat: 0,
        summary: 'does_not_repeat'
    },
    terms: '',
    note: '',
    taxes: []
};

export const TABS_INOIVE_REPEAT = {
    OFF: 'OFF',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
    NONE: 'NONE'
};

export const DISCOUNT_TYPE = {
    NUMBER: {
        type: 2,
        name: '$'
    },
    PERCENT: {
        type: 1,
        name: '%'
    }
};

export const ACTION_VALUE = {
    NONE: 0,
    SEND_EMAIL: 1,
    CHARGE: 2,
    CHARGE_AND_SEND_RECEIPT: 3,
    SEND_BY_EMAIL_AND_CC: 4,
    SEND_SMS: 5,
    SEND_EMAIL_AND_SMS: 6
};

export const INVOICE_STATUS = {
    DRAFT: 'draft',
    SENT: 'sent',
    VOID: 'void',
    WHITE_OFF: 'write off',
    PAID: 'paid',
    PARTIAL: 'partial'
};

export const INVOICE_PAYMENT_STATUS = [
    {
        value: '-1',
        name: 'all'
    },
    {
        value: 'draft',
        name: 'draft'
    },
    {
        value: 'sent',
        name: 'sent'
    }
];

export const PAYMENT_METHODS = {
    CASH: 'cash',
    CHECK: 'check',
    CREDIT: 'credit',
    CREDIT_CARD: 'credit card',
    STRIPE: 'stripe',
    SQUARE: 'square API',
    ACH: '_ach'
};

export const PAYMENT_METHODS_NOT_EDIT = [PAYMENT_METHODS.SQUARE, PAYMENT_METHODS.STRIPE, PAYMENT_METHODS.CREDIT];

export const SURCHARGE_METHOD = {
    STRIPE: 'STRIPE',
    SQUARE: 'SQUARE'
};

/**
 * PERCENT_FEE: percent_fee_per_transaction
 * FEE_PER: fee_per_transaction
 * MAX: max surcharge
 * MIN: min surcharge
 */
export const SURCHARGE_METHOD_CONFIG = {
    [PAYMENT_METHODS.STRIPE]: {
        PERCENT_FEE: 0.3,
        FEE_PER: 2.9
    },
    [PAYMENT_METHODS.SQUARE]: {
        PERCENT_FEE: 0.3,
        FEE_PER: 2.9
    },
    [PAYMENT_METHODS.ACH]: {
        PERCENT_FEE: 0,
        FEE_PER: 1.8,
        MIN: 1,
        MAX: 15
    }
};

export const LIST_PAYMENT_METHODS = [
    {
        id: 'cash',
        name: 'Cash'
    },
    {
        id: 'check',
        name: 'Check'
    },
    {
        id: 'credit',
        name: 'Credit'
    },
    {
        id: 'credit card',
        name: 'Credit Card'
    },
    {
        id: 'stripe',
        name: 'Stripe'
    },
    {
        id: 'square API',
        name: 'Square'
    },
    {
        id: '_ach',
        name: 'Bank Account ACH'
    }
];

export const TYPE_INVOICE_REPEAT = {
    OFF: 0,
    REPEAT: 1,
    LOCK: 2
};

export const INVOICE_DETAIL_TYPE = {
    ALL: 0,
    NORMAL: 1,
    RECURRING: 2
};

export const DEFAULT_METHOD_PAYMENT = {
    id: null,
    name: ''
};

export const SIGNATURE_INPUT_TYPE = {
    TYPE: 'type',
    DRAW: 'draw'
};

export const SIGNATURE_TYPE = {
    TECH: 'tech',
    CLIENT: 'client'
};

export const MAX_FONT_SIZE = 180;
export const FONT_SIGNATURE = 'Herr Von Muellerhoff';
export const PADDING_CHARACTERS = 110;

export const DEFAULT_IMAGE = {
    url: null,
    type: SIGNATURE_INPUT_TYPE.DRAW,
    text: '',
    isTyping: false,
    oldTextUploaded: ''
};

export const METHOD_GET_SURCHARGE = {
    [SPECIAL_PAYMENT_CARD.STRIPE]: SURCHARGE_METHOD.STRIPE,
    [SPECIAL_PAYMENT_CARD.SQUARE_API]: SURCHARGE_METHOD.SQUARE,
    [SPECIAL_PAYMENT_CARD.ACH]: ADDONS_STRIPE_ACH
};

export const LIST_METHOD_SURCHARGE = [
    SPECIAL_PAYMENT_CARD.SQUARE_API,
    SPECIAL_PAYMENT_CARD.STRIPE,
    SPECIAL_PAYMENT_CARD.ACH
];
