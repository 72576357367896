import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import CustomValueModal from './CustomValueModal';
import DropdownPopper from './DropdownPopper';

const DropdownRange = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const {
        unit = t('calendar:miles'),
        keyValue = '',
        options = [],
        defaultSelect = {},
        propsCustom = null,
        onSelect = () => {}
    } = props;
    const [state, dispatchState] = useReducer(reducer, { selected: defaultSelect });
    const { selected } = state;
    const refDropdown = useRef(null);
    const refCustomValue = useRef(null);
    useImperativeHandle(ref, () => ({ value: selected?.value || 0, setValue: () => null }));

    const handleSelect = (itemSelect) => {
        dispatchState((prevState) => ({ ...prevState, selected: itemSelect }));
        onSelect(keyValue, itemSelect?.value);
        refDropdown.current?._close();
    };
    const handleClickCustom = () => {
        refDropdown.current?._close();
        refCustomValue.current?.open(propsCustom ? { ...propsCustom, defaultValue: selected?.value, keyValue } : null);
    };
    const handleReset = (selected) => {
        dispatchState((prevState) => ({ ...prevState, selected }));
    };
    const handleUpdateCustom = (value) => {
        const newSelect = { label: value, value: Number(value) };
        dispatchState((prevState) => ({ ...prevState, selected: newSelect }));
        onSelect(keyValue, value, () => handleReset({ ...selected }));
    };

    return (
        <>
            <DropdownPopper
                ref={refDropdown}
                isUseToggle
                isCalculateWidth
                wrapperClassName="v2-dropdown dropdown-filter-magnet flex-1"
                wrapperListClass="v2-dropdown__menu content-checked"
                buttonClassName="dropbtn items"
                customButton={<CustomButton selected={selected} unit={unit} />}
            >
                <>
                    <ListItems options={options} selected={selected} onClick={handleSelect} />
                    {propsCustom ? (
                        <>
                            <div className="is-divider --horizontal" />
                            <div className="content-checked__all border-none" onClick={handleClickCustom}>
                                <div className="items btn-modal">{t('custom')}</div>
                            </div>
                        </>
                    ) : null}
                </>
            </DropdownPopper>
            <CustomValueModal ref={refCustomValue} onSave={handleUpdateCustom} />
        </>
    );
});
const CustomButton = ({ unit = '', selected = {} }) => {
    const { t } = useTranslation();
    const finalUnit = unit || t('calendar:miles');
    const unitDisplay = parseInt(selected.value) === 1 ? finalUnit.replace(/s$/, '') : finalUnit;
    return (
        <>
            <span className="txt-content">{selected.label}</span>
            <span className="black-darker ml-1 flex-1">{unitDisplay}</span>
            <span className="ml-1 arrow">
                <IconArrowDown />
            </span>
        </>
    );
};
const ListItems = ({ options = [], selected = {}, onClick = () => {} }) => {
    if (options.length === 0) return null;
    return (
        <ul className="scrolls">
            {options.map((option) => (
                <li
                    key={option.value}
                    className={classNames('items', { active: selected.value === option.value })}
                    onClick={() => onClick(option)}
                >
                    {option.label}
                </li>
            ))}
        </ul>
    );
};
DropdownRange.displayName = 'DropdownRange';
export default DropdownRange;
