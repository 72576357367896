import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import Item from 'app/modules/jobdetail/tabs/settingmessaging/email/Item';
import IconEmail from 'assets/icon/IconEmail';

const EmailTo = forwardRef(({ selected = [], openOptions, isFirstTimeFetch, data }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        options: selected.length > 0 ? selected : [{ id: '', email: t('customers:none'), type: 'empty' }]
    });

    useEffect(() => {
        dispatchState({
            options: selected.length > 0 ? selected : [{ id: '', email: t('customers:none'), type: 'empty' }]
        });
    }, [selected]);

    const finalOptions = state.options;

    function _addNewItem() {
        dispatchState({
            options: state.options.concat({ id: '', email: t('customers:none'), type: 'empty' })
        });
    }

    function _getValue() {
        return finalOptions.filter((item) => item.id.length !== 0);
    }

    useImperativeHandle(ref, () => ({
        getValue: _getValue
    }));

    function _removeItem(position) {
        const listTemp = [...state.options];
        dispatchState({
            options: listTemp.filter((item, index) => position !== index)
        });
    }

    function _handleSelectOption(newItem, position) {
        let listTemp = [...state.options];
        listTemp = listTemp.map((item, index) => {
            if (index === position) {
                return newItem;
            }
            return item;
        });
        dispatchState({
            options: listTemp
        });
    }

    function _renderListEmails() {
        const optionSelected = state.options.map((item) => item.id);
        return state.options.map((item, index) => {
            return (
                <Item
                    key={index.toString()}
                    total={optionSelected.length}
                    ids={optionSelected}
                    removeItem={_removeItem}
                    addItem={_addNewItem}
                    selectOption={_handleSelectOption}
                    openOptions={openOptions}
                    dataEmail={item}
                    index={index}
                    isFirstTimeFetch={isFirstTimeFetch}
                    data={data}
                />
            );
        });
    }

    return (
        <div className="lines">
            <div className="txt svg-conversation">
                <IconEmail isEmailAddress />
                <span className="flex-1 txt-ellipsis" title={t('customers:email_to')}>
                    {t('customers:email_to')}
                </span>
            </div>
            {_renderListEmails()}
        </div>
    );
});

EmailTo.displayName = 'Email_To';

export default EmailTo;
