import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreateJob, actionCreateJobPlan, actionCreateJobSuccess } from 'common/redux/actions/job/create';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionInactiveJob, CREATE_JOB_DETAIL } from 'app/const/Api';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { JOB_STATE, TABS } from 'app/modules/jobdetail/const';
import ReactModal from 'react-modal';
import { ACTIONS_RECURRING, NOTIFY_CUSTOMER, NOTIFY_TECH } from 'app/const/Job';
import { reducer } from 'app/const/Reducer';
import { convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import CreateJobService from 'app/modules/addjob/listener';
import DataRepeatContextProvider from 'app/modules/jobdetail/contexts/DataRepeatContext';
import { useEnterKeydownClick } from 'common/hooks';
import loadable from '@loadable/component';

const ConfirmAddInvoice = loadable(() => import('app/modules/jobdetail/modal/ConfirmAddInvoice'));
const IconClose = loadable(() => import('assets/icon/IconClose'));
const AddInvoice = loadable(() => import('app/modules/addjob/invoice'));
const AddEstimate = loadable(() => import('app/modules/addjob/estimate'));
const AddDocuments = loadable(() => import('app/modules/addjob/documents'));
const AddMaterials = loadable(() => import('app/modules/addjob/materials'));
const SideBar = loadable(() => import('app/modules/addjob/sidebar'));
const Footer = loadable(() => import('app/modules/addjob/Footer'));
const Customer = loadable(() => import('app/modules/addjob/customer'));
const Location = loadable(() => import('app/modules/addjob/location'));
const Service = loadable(() => import('app/modules/addjob/service'));
const JobInfo = loadable(() => import('app/modules/addjob/JobInfo'));

import { getListTimeWindow } from 'common/redux/actions/timeWindowAction';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddJob } from '../mixpanel/MixpanelAddJob';
import { mixpanelAddInvoice } from '../mixpanel/MixpanelAddInvoice';
import { mixpanelAddEstimate } from '../mixpanel/MixpanelAddEstimate';
import { DEFAULT_VALUE, TABS_INOIVE_REPEAT } from '../jobdetail/const/Invoice';
import { ESTIMATE_TYPE } from '../jobdetail/const/Estimate';
import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { useTranslation } from 'react-i18next';
import ServiceNotifyCustomer from './ServiceNotifyCustomer';
import { DEFAULT_TIME_TIMEOUT } from 'app/const/App';
import { getTypeConfirmation } from 'common/utils/JobStatusUtils';
import IconArrow from 'assets/icon/IconArrow';
import ServiceJobPlan from './listener/ServicePlan';
import { prepareCreateJob } from 'common/utils/JobUtils';
import ModalJobRecuring from 'app/modules/calendar/components/modal/JobRecuring';

const AddJob = ({ isPlanForm }) => {
    const { t } = useTranslation('jobDetail');
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        isVisibleCreate: false,
        start: '',
        schedule: null,
        tab: TABS.DETAIL,
        isFromJobPlan: false
    });

    const refJobData = useRef(null);
    const refFooter = useRef(null);
    const refStatusBar = useRef(null);
    const refInvoiceData = useRef(null);
    const refEstimateData = useRef(null);
    const refConfirm = useRef(null);
    const refSidebar = useRef(null);
    const refConfirmSendReminder = useRef(null);
    const refButtonSave = useRef(null);
    const refDataConfirm = useRef(null);
    const refServiceNotifyCustomer = useRef(null);
    const refServiceJobPlan = useRef(null);
    const refModalRecurring = useRef(null);

    const {
        isVisibleCreate: finalIsVisibleCreate,
        tab: finalCurrentTab,
        customer_id: stateCustomerId,
        onCreateSuccess: handleCreateSuccess = null,
        isFromJobPlan,
        job_state: jobState
    } = state;

    const isFirstTimeTemplate = useSelector(({ timeWindowReducer }) => timeWindowReducer.isFirstTime);
    const { settings: userSetting } = useSelector(({ auth }) => auth.user);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);

    const isActiveTimeWindow = userSetting?.addons?.time_window || false;

    useEffect(() => {
        if (finalIsVisibleCreate && isFirstTimeTemplate && isActiveTimeWindow) {
            dispatch(getListTimeWindow());
        }
    }, [finalIsVisibleCreate]);

    const defauleData = useMemo(() => {
        return prepareCreateJob(state);
    }, [finalIsVisibleCreate]);

    useEnterKeydownClick(finalIsVisibleCreate);

    const _handleOpenCreateJob = (jobData) => {
        if (!isPlanForm) {
            dispatchState({ isVisibleCreate: true, ...jobData });
        }
    };

    const _handleOpenCreateJobPlan = (jobData) => {
        if (isPlanForm) {
            dispatchState({ isVisibleCreate: true, isFromJobPlan: true, ...jobData });
        }
    };

    const _forceClose = () => {
        dispatchState(() => {
            return {
                isVisibleCreate: false,
                start: '',
                schedule: null,
                tab: TABS.DETAIL,
                isFromJobPlan: false
            };
        });
    };

    function _handleCloseCreateJob() {
        if (finalIsVisibleCreate && !isFromJobPlan) {
            _forceClose();
        }
    }

    function _handleCloseCreateJobPlan() {
        if (finalIsVisibleCreate && isFromJobPlan) {
            _forceClose();
        }
    }

    function _handleCloseCreate() {
        _forceClose();

        if (isFromJobPlan) {
            dispatch(actionCreateJobPlan(null));
        } else {
            dispatch(actionCreateJob(null));
        }
    }

    const _createJobWithServicePlan = () => {
        const newPlanJob = refJobData.current.getValue(JOB_STATE.CREATING);
        const listService = newPlanJob.services || [];
        const firstService = listService[0] || {};
        const { invoice, estimate, notify_tech, notify_customer } = firstService;

        if (listService.some((item) => !item.primary_schedule_id)) {
            _createJobFailed({ message: 'Please select Assign To for each service' });
            return false;
        }

        const newPlanJobData = {
            ...newPlanJob,
            // eslint-disable-next-line no-undef
            socket_id: global.mainCalendarSocketId
        };

        clientQuery(
            CREATE_JOB_DETAIL,
            { method: 'POST', data: newPlanJobData, toFormData: false },
            (res) =>
                _createJobSuccess(res, { ...firstService, invoice, estimate, notify_tech, notify_customer }, invoice),
            _createJobFailed
        );
    };

    const _triggerCreateJob = (isServicePlan) => {
        if (isServicePlan) {
            _createJobWithServicePlan();
            return;
        }

        const invoiceData = refInvoiceData.current?.getValue() || null;

        if (!invoiceData) {
            _handleCreateJob('0', jobState);
            return false;
        }

        const dateNow = convertTimeToISO(moment());
        const currentInvoiceData = invoiceData.date;
        const invoiceRecurrence = invoiceData?.recurrence || {};

        const isInvoiceDatePast =
            moment(currentInvoiceData).utc().startOf('day').unix() <= moment(dateNow).utc().startOf('day').unix();

        if (invoiceRecurrence.repeat && isInvoiceDatePast) {
            refConfirm.current._handleOpenForm(invoiceRecurrence.action);
        } else {
            _handleCreateJob('0', jobState);
        }
    };

    const _closeConfirmAddInvoice = () => {
        _removeLoading();
    };

    function _handleCreateJob(trigger_action, parentState = JOB_STATE.CREATING) {
        const converState = parentState === JOB_STATE.GET_DATA_FORM ? JOB_STATE.CREATING : parentState;
        const invoiceData = refInvoiceData.current?.getValue() || null;
        const estimateData = refEstimateData.current?.getValue() || null;
        const jobData = refJobData.current.getValue(converState);

        refConfirm.current._handleCloseForm();

        const { invoice: invoiceCurrent, estimate: estimateCurrent, date: newDate } = jobData;

        const convertedInvoice = invoiceCurrent
            ? _handleSaveInvoice(invoiceData, true, trigger_action, converState)
            : null;

        if (convertedInvoice === false) {
            return false;
        }

        const convertedEstimate = estimateCurrent ? _handleSaveInvoice(estimateData, false, '1', converState) : null;

        if (convertedEstimate === false) {
            return false;
        }

        const newJobData = {
            ...jobData,
            invoice: convertedInvoice,
            estimate: convertedEstimate,
            // eslint-disable-next-line no-undef
            socket_id: global.mainCalendarSocketId,
            job_cycle: jobData?.job_cycle || 1,
            // eslint-disable-next-line no-undef
            [NOTIFY_TECH]: global.notifyTechnician ? 1 : 0,
            [NOTIFY_CUSTOMER]: refSidebar.current?.getValue() || {}
        };

        if (isFromJobPlan) {
            if (parentState === JOB_STATE.GET_DATA_FORM) {
                return newJobData;
            }
            if (jobState === JOB_STATE.CREATING) {
                _updateFallback(newJobData);
            } else {
                if ((state.haveTriggerOther && newDate !== state.start) || !!newJobData.repeat) {
                    refModalRecurring.current._showOptions(newJobData, _updateJobPlan, _removeLoading);
                } else {
                    _updateJobPlan(ACTIONS_RECURRING.ONLY, newJobData);
                }
            }
        } else {
            clientQuery(
                CREATE_JOB_DETAIL,
                { method: 'POST', data: newJobData, toFormData: false },
                (res) => _createJobSuccess(res, newJobData, invoiceData),
                _createJobFailed
            );
        }
    }

    const _removeLoading = () => {
        refFooter.current && refFooter.current.removeLoading();
    };

    const _updateFallback = (newJobData) => {
        _removeLoading();
        refServiceJobPlan.current && refServiceJobPlan.current.onFallBack(newJobData);
        _handleCloseCreate();
    };

    const _updateJobPlanSuccess = (response, all) => {
        _removeLoading();
        refStatusBar.current.showStatusBar('show_error', response?.message?.toString(), LIST_STATUS.SUCCESS);
        refServiceJobPlan.current.onFallBack({
            ..._handleCreateJob('', JOB_STATE.GET_DATA_FORM),
            shouldUpdateRecurring: all === ACTIONS_RECURRING.RECURRING
        });
    };

    const _updateJobPlan = (all, data) => {
        clientQuery(
            actionInactiveJob(state.id),
            { method: 'PUT', data: { ...data, all: parseInt(all) + 1 }, toFormData: false },
            (res) => _updateJobPlanSuccess(res, all),
            _createJobFailed
        );
    };

    const _handleError = (isInvoice, msg) => {
        refStatusBar.current.showStatusBar(
            'show_error',
            msg ||
                t(
                    `common:${
                        isInvoice ? 'one_or_more_invoice_field_not_complete' : 'one_or_more_estimate_field_not_complete'
                    }`
                ),
            LIST_STATUS.ERROR
        );

        dispatchState({
            tab: isInvoice ? TABS.INVOICE : TABS.ESTIMATE
        });

        refFooter.current.removeLoading();
        return false;
    };

    const _checkDeposit = (isInvoice, depositValue, total, packageId) => {
        const finalValueDeposit = parseFloat(depositValue.total.value);

        if (parseFloat(total) >= 0 && finalValueDeposit > parseFloat(total)) {
            !isInvoice && document.getElementById(`deposit_cost_${packageId}`)?.classList?.add('field-error');
            _handleError(
                isInvoice,
                t('common:message_error_value_deposit_with_name', {
                    name: t(`common:${isInvoice ? 'invoice' : 'estimate'}`)
                })
            );
            return false;
        }
        return true;
    };

    function _handleSaveInvoice(refValue, isInvoice, trigger_action, parentState = JOB_STATE.CREATING) {
        const getDataJobPlan = isFromJobPlan && parentState === JOB_STATE.CREATING;

        const { items: listItems, type: estimateType, packages: estimatePackages, total: invoiceTotal } = refValue;
        const itemsSubmit = [];
        let checkPass = true;
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        if (!isInvoice && estimateType === ESTIMATE_TYPE.PACKAGES) {
            let checkPassDeposit = true;

            estimatePackages.every((itemPackage) => {
                const { id: packageId, name: packageName, deposit, total } = itemPackage;

                checkPassDeposit = _checkDeposit(isInvoice, deposit, total, packageId);

                if (!checkPassDeposit) {
                    return false;
                }

                if (!!!packageName) {
                    checkPass = false;
                    document.getElementById(`package_item_name_${packageId}`).classList.add('field-error');
                }

                if (!listItems.some((lineItem) => lineItem.package_id === packageId)) {
                    checkPass = false;
                    document.getElementById(`package_item_detail_${packageId}`).classList.remove('dp-hide');
                }

                return true;
            });

            if (!checkPassDeposit) {
                return false;
            }
        } else {
            if (!_checkDeposit(isInvoice, finalDeposit, invoiceTotal, '')) {
                return false;
            }
        }

        if (!checkPass) {
            return _handleError(isInvoice);
        }

        listItems.forEach((element) => {
            const tax1Detail = element.tax1 || {};
            const tax2Detail = element.tax2 || {};
            const checkItemCost = element?.cost?.value?.length !== 0;
            const { item_id: serviceItemId, id: itemId } = element;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    id: getDataJobPlan ? itemId : undefined,
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: getDataJobPlan ? element.cost : element.cost?.value || 0,
                    tax1_id: tax1Detail.id || '',
                    tax2_id: tax2Detail.id || '',
                    tax1_name: tax1Detail.name || '',
                    tax2_name: tax2Detail.name || '',
                    tax1_rate: tax1Detail.rate || '',
                    tax2_rate: tax2Detail.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    type: element.type || '',
                    package_id: element.package_id || '',
                    tax1: getDataJobPlan ? tax1Detail : null,
                    tax2: getDataJobPlan ? tax2Detail : null
                });
            } else {
                checkPass = false;

                if (!checkItemCost) {
                    const elmCost = document.getElementById(`service_item_cost_${itemId}`);
                    elmCost && elmCost.classList.add('field-error');
                }
                if (!serviceItemId) {
                    const elmItemDetail = document.getElementById(`service_item_detail_${itemId}`);
                    elmItemDetail && elmItemDetail.classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            return _handleError(isInvoice);
        }

        const recurrence = { ...refValue.recurrence };
        const offset = recurrence.offset || {};
        const finalDiscount = { ...refValue.discount };

        delete offset.dateIssue;
        delete offset.nextDateInvoice;
        delete finalDiscount.total;

        return {
            customer_id: refValue.customer_id,
            discount: finalDiscount,
            deposit: isInvoice ? undefined : finalDeposit,
            number: refValue.number,
            po_number: refValue.po_number,
            date: refValue.date,
            items: itemsSubmit,
            subtotal: refValue.subtotal,
            total: refValue.total,
            trigger_action: trigger_action,
            recurrence: isFromJobPlan
                ? recurrence
                : {
                      action: recurrence.action,
                      offset: offset
                  },
            location_id: refValue.location_id,
            terms: refValue.terms,
            note: refValue.note,
            note_repeat: !isInvoice || offset.frequency === TABS_INOIVE_REPEAT.OFF ? 0 : refValue.note_repeat,
            packages: estimatePackages,
            type: isInvoice ? '' : estimateType,
            payment_terms_id: refValue.payment_terms.id || ''
        };
    }

    function _createJobSuccess(response, newJobData, invoiceData) {
        if (newJobData.invoice) handleTrackingEvent(mixpanelAddInvoice({ id: profileId }));
        if (newJobData.estimate) handleTrackingEvent(mixpanelAddEstimate({ id: profileId }));
        if (handleCreateSuccess) handleCreateSuccess();
        handleTrackingEvent(mixpanelAddJob({ id: profileId }));
        _removeLoading();

        const { isNotifyCustomer, template } = newJobData.notify_customer || {};
        if (isNotifyCustomer) {
            refDataConfirm.current = { response, newJobData, invoiceData, template };
            refConfirmSendReminder.current?._open({
                description: t('desc_confirm_send_reminder', { name: t(getTypeConfirmation(newJobData?.status)) })
            });
        } else {
            _onRedirectJobDetail({ response, newJobData, invoiceData });
        }
    }

    function _createJobFailed(response) {
        refStatusBar.current.showStatusBar('show_error', response?.message?.toString(), LIST_STATUS.ERROR);
        refFooter.current.removeLoading();
    }

    function _handleChangeTab(tabChange) {
        dispatchState({ tab: tabChange });
    }

    function _handleDeleteTab() {
        dispatchState({ tab: TABS.DETAIL });
    }

    function _handleTriggerButtonSave(value) {
        refFooter.current.setDisable(value);
    }

    const _handleConfirmNotifyCustomer = () => {
        refConfirmSendReminder.current?._clearStatusAlert();
        const { response = {}, newJobData = {}, template = {} } = refDataConfirm.current;
        const { id: finalJobId, start: finalJobStart, end: finalJobEnd } = response.data || {};
        const {
            status: finalJobStatus,
            date: finalJobDate,
            primary_schedule_id: finalJobPrimarySchedule,
            schedule_ids: finalJobSchedules,
            hours: finalJobHours,
            minutes: finalJobMinutes
        } = newJobData;

        const { id: finalTemplateId, type: finalTemplateType } = template;
        const _success = ({ message }) => {
            _removeLoading();
            refConfirmSendReminder.current?._setStatusAlert(message, LIST_STATUS.SUCCESS);
            setTimeout(() => {
                _handleCloseNotifyCustomer();
            }, DEFAULT_TIME_TIMEOUT);
        };
        const _fail = ({ message }) => {
            _removeLoading();
            refConfirmSendReminder.current?._setStatusAlert(message);
        };
        const _removeLoading = () => {
            refButtonSave.current?.removeLoading();
        };
        refServiceNotifyCustomer.current?.onNotifyCustomer(
            {
                job_ids: [finalJobId],
                jobStatus: finalJobStatus,
                template_id: finalTemplateId,
                template_type: finalTemplateType,
                start: finalJobStart || finalJobDate,
                end:
                    finalJobEnd ||
                    convertTimeToISO(
                        moment(moment(finalJobDate)).utc().add(finalJobHours, 'hours').add(finalJobMinutes, 'minutes')
                    ),
                schedule: [finalJobPrimarySchedule, ...(finalJobSchedules || [])].toString()
            },
            _success,
            _fail
        );
    };

    const _handleCloseNotifyCustomer = () => {
        refConfirmSendReminder.current?._close();
        _handleClose();
    };

    const _onRedirectJobDetail = (data = {}) => {
        const { response } = data;
        _handleCloseCreate();
        dispatch(actionCreateJobSuccess(data));
        dispatch(actionOpenJobDetail({ id: response.data.id }));
    };

    const _handleClose = () => {
        delete refDataConfirm.current?.template;
        refConfirmSendReminder.current?._clearStatusAlert();
        _onRedirectJobDetail(refDataConfirm.current);
    };

    function _formCreateJob() {
        if (!finalIsVisibleCreate) {
            return false;
        }

        return (
            <ReactModal
                isOpen
                className="modal container-modal c-job-details add-new-job open"
                style={{ overlay: { backgroundColor: 'transparent', zIndex: 9999999 } }}
                onRequestClose={_handleCloseCreate}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleCloseCreate} />
                <div id={'refAddJobContainer'} className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('report:new_job')}</h3>
                        {isFromJobPlan && (
                            <div onClick={_handleCloseCreate} className="v2-btn-main has-icon mr-2 svg-white">
                                <IconArrow isPrev />
                                {t('setting:service_plan')}
                            </div>
                        )}
                        <div className="action">
                            <div className="btn-action btn-close" onClick={_handleCloseCreate}>
                                <IconClose />
                            </div>
                        </div>
                    </div>
                    <DataRepeatContextProvider defaultData={{ ...defauleData }}>
                        <div className="body-modal">
                            <div className="contents accessible-tabs-container">
                                <div className="tab-contents scrolls">
                                    <StatusBar ref={refStatusBar} />
                                    <div
                                        className={`tab-conts dashboard-job-detail show-form-schedule ${
                                            finalCurrentTab === TABS.DETAIL ? 'tab-content-active' : ''
                                        }`}
                                    >
                                        <div className="container-column ">
                                            <div className="schedule-wrapper details-job --has-sevice">
                                                <Customer isShowAdd={!!!stateCustomerId} />
                                                <Location />
                                                <Service autoFocus={!!stateCustomerId} />
                                                <div className="line" />
                                                <JobInfo ref={refJobData} />
                                            </div>
                                        </div>
                                    </div>
                                    <AddInvoice
                                        tabActive={finalCurrentTab === TABS.INVOICE}
                                        ref={refInvoiceData}
                                        onDelete={_handleDeleteTab}
                                        onTriggerBtnSave={_handleTriggerButtonSave}
                                    />
                                    <AddEstimate
                                        tabActive={finalCurrentTab === TABS.ESTIMATE}
                                        ref={refEstimateData}
                                        onDelete={_handleDeleteTab}
                                        onTriggerBtnSave={_handleTriggerButtonSave}
                                    />
                                    <AddDocuments tabActive={finalCurrentTab === TABS.DOCUMENTS} />
                                    <AddMaterials tabActive={finalCurrentTab === TABS.MATERIALS} />
                                </div>
                                <SideBar ref={refSidebar} onChangeTab={_handleChangeTab} tabActive={finalCurrentTab} />
                            </div>
                        </div>
                        <Footer onClose={_handleCloseCreate} onCreateJob={_triggerCreateJob} ref={refFooter} />
                    </DataRepeatContextProvider>
                </div>
                <ConfirmAddInvoice
                    ref={refConfirm}
                    title={t('setting:add_invoices')}
                    handleConfirm={_handleCreateJob}
                    onClose={_closeConfirmAddInvoice}
                />
            </ReactModal>
        );
    }

    const _renderService = () => {
        if (isPlanForm) {
            return (
                <ServiceJobPlan
                    ref={refServiceJobPlan}
                    onCloseCreateJobPlan={_handleCloseCreateJobPlan}
                    onCreateJobPlan={_handleOpenCreateJobPlan}
                />
            );
        }
        return <CreateJobService onOpenCreateJob={_handleOpenCreateJob} onCloseCreateJob={_handleCloseCreateJob} />;
    };

    return (
        <Fragment>
            {_formCreateJob()}
            {_renderService()}
            <ServiceNotifyCustomer ref={refServiceNotifyCustomer} />
            <GDModalWarning
                ref={refConfirmSendReminder}
                title={t('notify_customer_title')}
                footer={
                    <div className="footer-modal">
                        <div className="v2-btn-default --transparent" onClick={_handleCloseNotifyCustomer}>
                            {t('cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSave}
                            wrapClass="v2-btn-main ml-2"
                            title={t('send')}
                            onSave={_handleConfirmNotifyCustomer}
                        />
                    </div>
                }
                onClose={_handleClose}
            />
            <ModalJobRecuring ref={refModalRecurring} />
        </Fragment>
    );
};

export default AddJob;
