export const INSTALLATION_TYPE = {
    LIST: {
        1: { id: 1, value: 'standard' },
        2: { id: 2, value: 'existing_customer' },
        3: { id: 3, value: 'new_construction' },
        4: { id: 4, value: 'liquid_upgrade' },
        5: { id: 5, value: 'real_estate' },
        6: { id: 6, value: 'reactivation' },
        7: { id: 7, value: 'other_type_1' },
        8: { id: 8, value: 'other_type_2' },
        9: { id: 9, value: 'site_transfer' }
    },
    DEFAULT_VALUE: { id: 1, value: 'standard' }
};
export const LIST_STATUS = [
    { id: 1, value: 'active', label: 'active', color: '#1EAA5C' },
    { id: 2, value: 'inactive', label: 'inactive', color: '#666666' }
];
export const DEFAULT_STATUS_SENTRICON_COLOR = '#1EAA5C';
export const LIMIT_LOAD_ITEM_HISTORY = 20;
