import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import { RouteRender } from 'app/components/routes/RouteWithSubRoutes';
import { ADDONS_AI_AGENTS, ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import ErrorPage from 'app/modules/error';
import { getPlanUser } from 'common/utils/PermissionUtils';
import AIConversationDetail from '../testSmsChatbot/AIConversationDetail';
import SideMenu from './SideMenu';
import { ACCESS_TOKEN } from 'app/const/App';

const ChatbotLayout = ({ routes = [], ...props }) => {
    const { type: typeAgent } = useParams();
    const { t } = useTranslation();
    const activeChatbot = useSelector(({ auth }) => auth.user.settings.addons[ADDONS_AI_AGENTS]);
    const { profile: userProfile, company: companyInfo } = useSelector(({ auth }) => auth.user);

    const { isBasicPlan, isGrowthPlan } = getPlanUser(userProfile);
    const havePermissionChatbot = !isBasicPlan && !!activeChatbot;
    const havePermissionPortalAI = !!activeChatbot && isGrowthPlan;
    const havePermission =
        typeAgent === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL ? havePermissionPortalAI : havePermissionChatbot;
    const isAIPortal = typeAgent === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL;
    const refSideMenu = useRef(null);
    const refAIConversationDetail = useRef(null);

    const _handleOpenConversation = () => {
        if (isAIPortal) {
            _handleCloseConversation();
        } else {
            refAIConversationDetail.current._open();
        }
    };

    const _handleCloseConversation = () => {
        refSideMenu.current.setDisableButton(false);
    };

    function gd_get_message_from_iframes({ data }) {
        const { type } = data;
        switch (type) {
            case 'send-factory-active-button-chat':
                refSideMenu.current && refSideMenu.current.setDisableButton(false);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (isAIPortal) {
            _handleSuccess();
            window.addEventListener('message', gd_get_message_from_iframes, false);
        }

        return () => {
            window.removeEventListener('message', gd_get_message_from_iframes, false);
            window.GorillaDesk && window.GorillaDesk('hideChat');
            const existingScript = document.querySelector(
                // eslint-disable-next-line no-undef
                `script[src="${process.env.REACT_APP_EMBED_CHAT_URL}js/portal/portal.js"]`
            );

            if (existingScript) existingScript.remove();
        };
    }, []);

    const _handleSuccess = () => {
        window._gorilla = window._gorilla || {};
        window._gorilla.account_id = 'null';
        window._gorilla.owner_id = companyInfo.user_id;
        window._gorilla.loaded = false;
        window._gorilla.is_embed_test = true;
        window._gorilla.access_token = localStorage.getItem(ACCESS_TOKEN);

        const _gorillaInitPortal = () => {
            window._gorilla.loaded = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            // eslint-disable-next-line no-undef
            script.src = `${process.env.REACT_APP_EMBED_CHAT_URL}js/portal/portal.js`;

            const firstScript = document.getElementsByTagName('script')[0];

            firstScript.parentNode.insertBefore(script, firstScript);
        };

        if (document.readyState === 'complete') {
            _gorillaInitPortal(); // Call immediately if the load event has already fired
        } else if (window.addEventListener) {
            window.addEventListener('load', _gorillaInitPortal, false);
        } else if (window.attachEvent) {
            window.attachEvent('onload', _gorillaInitPortal);
        } else {
            window.onload = _gorillaInitPortal;
        }
    };

    if (!havePermission) return <ErrorPage errorMessage={t('page_is_unavailable')} />;
    return (
        <div className="container-chatbot addons-chatbot boxs-notification">
            <SideMenu ref={refSideMenu} onOpen={_handleOpenConversation} />
            <Switch>
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }

                    return <Route key={i} path={result.path} render={() => <RouteRender {...props} route={route} />} />;
                })}
            </Switch>
            <AIConversationDetail ref={refAIConversationDetail} onClose={_handleCloseConversation} />
        </div>
    );
};

export default ChatbotLayout;
