import React, { Fragment, useId, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { LIST_ESTIMATE_DYNAMIC_TYPE } from 'app/modules/jobdetail/const/Estimate';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';

export default function EstimateDynamic({ onSelect, parentType }) {
    const { t } = useTranslation();
    const refDropdown = useRef(null);
    const dropdowId = useId(null);

    const getSelected = useMemo(() => {
        return LIST_ESTIMATE_DYNAMIC_TYPE.find((item) => item.id === parentType) || {};
    }, [parentType]);

    const _selectNewValue = (e, newValue) => {
        onSelect(newValue);
        refDropdown.current._closeDropdown();
    };

    const _renderItemType = () => {
        return LIST_ESTIMATE_DYNAMIC_TYPE.map((item) => {
            const { id: itemId, name, color } = item;
            const isActive = itemId === parentType;

            return (
                <li
                    key={itemId}
                    className={`items --ticked ${isActive ? 'active' : ''}`}
                    onClick={(e) => _selectNewValue(e, itemId)}
                >
                    <IconCheck />
                    <div className="status-btn" style={{ '--bg-estimate': color }}>
                        {t(`setting:${name}`)}
                    </div>
                </li>
            );
        });
    };

    return (
        <div className="items-footer flexcenter gap-6">
            <CalendarDropdown
                ref={refDropdown}
                id={dropdowId}
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items status-btn white"
                wrapperListClass="v2-dropdown__menu content-full scrolls"
                styleButton={{ '--bg-estimate': getSelected.color }}
                customDropButton={() => (
                    <Fragment>
                        <p className="txt-ellipsis">{t(`setting:${getSelected.name}`)}</p>
                        <span className="arrow">
                            <IconArrowDown />
                        </span>
                    </Fragment>
                )}
            >
                <ul>{_renderItemType()}</ul>
            </CalendarDropdown>
        </div>
    );
}
