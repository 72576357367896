import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import ReactModal from 'react-modal';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import IconCheck from 'assets/icon/IconCheck';
import ButtonCopy from 'app/components/button/ButtonCopy';

const ApiKeyModalSuccess = forwardRef(
    (
        {
            modalClassName = 'modal container-modal modal-api-connect --your-api-key open',
            bodyClassName = 'body-modal'
        },
        ref
    ) => {
        const { t } = useTranslation();
        const [state, dispatchState] = useReducer(reducer, {
            isOpen: false,
            token: ''
        });

        const { token } = state;

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = ({ value }) => {
            dispatchState((prev) => ({
                ...prev,
                isOpen: true,
                token: value
            }));
        };
        const _close = () => {
            dispatchState((prev) => ({ ...prev, isOpen: false }));
        };

        if (!state.isOpen) return null;
        return (
            <ReactModal
                isOpen
                style={{ overlay: { background: 'transparent' } }}
                className={classNames(modalClassName)}
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className={'modal__container'}>
                    <div className={classNames(bodyClassName)}>
                        <div className="rows">
                            <IconCheck isSuccess={true} />
                        </div>
                        <div className="rows --title fw-bold fs-15 black">{t('addons:api_key_ready')}</div>
                        <div className="rows --api-key">
                            <div className="name txt-ellipsis black-jungle-green fs-14">{token}</div>
                            <ButtonCopy className="v2-btn-main --icon-lg svg-white-stroke tooltip" content={token} />
                        </div>
                        <div className="rows flex-column">
                            <p>{t('addons:modal_api_key_success_description_top')}</p>
                            <p>{t('addons:modal_api_key_success_description_bottom')}</p>
                        </div>
                        <div className="rows mt-3"></div>
                        <div className="rows">
                            <div className="v2-btn-main fs-14" onClick={_close}>
                                {t('common:confirm')}
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
);

export default ApiKeyModalSuccess;
