import i18next from 'i18next';
import {
    CUSTOM_ID,
    FUTURE_ID,
    NOW_ID,
    PAST_ID,
    RANGE_DAYS,
    RANGE_PAST_FUTURE,
    RANGE_START_END,
    RANGE_TIMES
} from '../../constants/dateOptions';
import { OPERATOR_IDS } from '../../constants/types';

const convertToDateWithS = (t, n, e) => {
    if (!e) e = n.lastIndexOf('s') > -1 ? n.slice(0, -1) : n;
    return 1 === t ? e : n;
};

export const offsetTostring = (dateData) => {
    return dateData
        ? RANGE_TIMES.filter((time) => Boolean(dateData[time]))
              .map((time) => `${dateData[time]} ${convertToDateWithS(dateData[time], i18next.t(`smartView:${time}`))}`)
              .join(' ')
        : '';
};

function getValueDate(before, after) {
    let valueDate = before;
    if (before?.value === NOW_ID) valueDate = after;
    const stringOffset = offsetTostring(valueDate?.offset);
    return RANGE_DAYS.includes(stringOffset) ? { value: `${stringOffset} ${valueDate?.value}` } : valueDate;
}

// _ is NOW_ID
const getDetailValue = ({ offset, direction = '', range, value, _ }, key = '') => {
    const { PAST } = RANGE_PAST_FUTURE;

    const valueKey = 'value' + key;
    const offsetKey = 'offset' + key;
    const customDateKey = 'customDate' + key;

    if (offset) {
        return {
            [valueKey]: direction.toUpperCase() === PAST ? PAST_ID : FUTURE_ID,
            [offsetKey]: RANGE_TIMES.reduce((acc, curr) => {
                acc[curr] = offset[curr];
                return acc;
            }, {})
        };
    } else if (range) {
        return { [valueKey]: range.toLowerCase().split('_').join(' ') };
    } else if (value) {
        return { [valueKey]: CUSTOM_ID, [customDateKey]: value };
    } else if (_ || _ === null) {
        return { [valueKey]: NOW_ID };
    } else {
        return { [valueKey]: NOW_ID };
    }
};

const isSameRange = (before, after) => {
    const beforeOffsetString = JSON.stringify(before?.offset);
    const afterOffsetString = JSON.stringify(after?.offset);
    return (
        before?.value === after?.value &&
        beforeOffsetString === afterOffsetString &&
        before?.customDate === after?.customDate
    );
};

const getValueMomentRange = ({ beforeMoment, onOrAfterMoment, negate }) => {
    const { START, END } = RANGE_START_END;

    if (beforeMoment && !onOrAfterMoment) {
        const { which } = beforeMoment;
        return {
            operatorId: which === END || which === END.toLowerCase() ? OPERATOR_IDS.ON_OR_BEFORE : OPERATOR_IDS.BEFORE,
            value: getDetailValue(beforeMoment)
        };
    }

    if (onOrAfterMoment && !beforeMoment) {
        const { which } = onOrAfterMoment;
        return {
            operatorId:
                which === START || which === START.toLowerCase() ? OPERATOR_IDS.ON_OR_AFTER : OPERATOR_IDS.AFTER,
            value: getDetailValue(onOrAfterMoment)
        };
    }

    const isFeatureOrPast = (valueCheck) => [PAST_ID, FUTURE_ID].includes(valueCheck);
    const beforeMomentValue = getDetailValue(beforeMoment),
        onOrAfterMomentValue = getDetailValue(onOrAfterMoment);
    const beforeValue = beforeMomentValue.value;
    const afterValue = onOrAfterMomentValue.value;

    const valueIsWithin =
        (beforeValue === NOW_ID && isFeatureOrPast(afterValue) && onOrAfterMomentValue.offset) ||
        (afterValue === NOW_ID && isFeatureOrPast(beforeValue) && beforeMomentValue.offset);

    if (valueIsWithin) {
        return {
            operatorId: negate ? OPERATOR_IDS.NOT_WITHIN : OPERATOR_IDS.WITHIN,
            value: getValueDate(beforeMomentValue, onOrAfterMomentValue)
        };
    }
    if (isSameRange(beforeMomentValue, onOrAfterMomentValue))
        return { operatorId: negate ? OPERATOR_IDS.NOT : OPERATOR_IDS.IS, value: beforeMomentValue };
    return {
        operatorId: negate ? OPERATOR_IDS.NOT_BETWEEN : OPERATOR_IDS.BETWEEN,
        value: { ...onOrAfterMomentValue, ...getDetailValue(beforeMoment, '2') }
    };
};

export const getOperatorMomentRange = (condition, negate = false) => {
    const { before, on_or_after } = condition;
    if (before && on_or_after)
        return getValueMomentRange({ negate, beforeMoment: before, onOrAfterMoment: on_or_after });
    if (before || on_or_after)
        return getValueMomentRange({
            negate,
            beforeMoment: before && { ...before, which: before.which_day_end || before.which },
            onOrAfterMoment: on_or_after && {
                ...on_or_after,
                which: on_or_after.which_day_end || on_or_after.which
            }
        });
    return { value: {}, negate };
};
