import { API_ENDPOINT } from 'app/const/URL';
import { ACCESS_TOKEN, LIVE_STATUS, ERROR_CODE, WEB, KEY_DEVICE_ID } from 'app/const/App';
import { stringify } from 'query-string';
import { clearUserData } from 'common/redux/actions/authAction';
import store from 'common/redux/store/configureStore';
import { getBranchId } from 'app/const/Branch';
import i18n from 'assets/i18n';
import { EN } from 'app/const/Settings';
import { LIST_API_ACCESS_REDIRECT_2FA } from 'app/modules/profileAuthentication/constants';

/**
 * Get data from API by GET
 *
 * @param {String} url
 * @param {*} dispatch
 */
export function getFetch(url, dispatch) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'GET',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId()
            }
        })
            .then((response) => {
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                reject('Oops! Please try again');
            });
    }).catch(() => {});
}

/**
 * Submit data to API by POST. Using this function for action INSERT
 *
 * @param {String} url
 * @param array data
 * @param {*} dispatch
 */
export function postFetch(url, data, dispatch) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'POST',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId()
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status

                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function postFetchWithoutToken(url, data, dispatch) {
    const deviceId = localStorage.getItem(KEY_DEVICE_ID) || '';

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'POST',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                platform: WEB,
                'gd-branch-id': getBranchId(),
                'gd-device-id': deviceId,
                'Accept-Language': i18n.language || EN,
                'gd-redirect-2fa': LIST_API_ACCESS_REDIRECT_2FA.includes(url)
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status

                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

/**
 * Submit data to API by PATCH. Using this function for action UPDATE
 *
 * @param String url
 * @param {*} data
 * @param {*} dispatch
 */
export function patchFetch(url, data, dispatch) {
    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'PATCH',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                platform: WEB,
                'gd-branch-id': getBranchId()
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                reject('Oops! Please try again');
            });
    }).catch(() => {});
}

/**
 * Delete data to API by DELETE. Using this function for action DELETE
 * @param {*} url
 * @param {*} data
 * @param {*} dispatch
 */
export function deleteFetch(url, data, dispatch) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'DELETE',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId()
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update later to check status and tracking

                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                reject('Oops! Please try again');
            });
    }).catch(() => {});
}

export function getFetchPDF(url) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'GET',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId()
            }
        })
            .then((response) => {
                //update more to check status

                return response;
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function fetchSaga(url, data, opts) {
    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'POST',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...opts
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function putWithToken(url, data = {}, opts = {}) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'PUT',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token,
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...opts
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function deleteWithToken(url, data = {}, opts = {}) {
    const token = localStorage.getItem(ACCESS_TOKEN);

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'DELETE',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...opts
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function postWithToken(url, data = {}, opts = {}) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);

    if (liveStatus === 'offline') return false;

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'POST',
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...opts
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);

        return {
            success: false
        };
    });
}

export function fetchWithToken(url, data, opts) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);

    if (liveStatus === 'offline') return false;

    return new Promise((resolve, reject) => {
        const stringData = data ? '?' + stringify(data) : '';
        fetch(API_ENDPOINT + url + stringData, {
            method: 'GET',
            'Cache-Control': 'public',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...opts
            }
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                // alert(`error API ${error}`);
                reject(error.message || 'Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function putFormData(url, formData) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);

    if (liveStatus === 'offline') return false;

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'PUT',
            headers: {
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId()
            },
            body: formData
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function postFormData(url, formData) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);

    if (liveStatus === 'offline') return false;

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method: 'POST',
            headers: {
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId()
            },
            body: formData
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function callAPI(url, method, data, header) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);
    if (liveStatus === 'offline') return false;

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method,
            'cache-control': 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...header
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                //update more to check status
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                // alert(`error API ${error}`);
                reject('Oops! Please try again');
            });
    }).catch(() => {
        // alert(err);
    });
}

export function formApi(url, data, callback, method = 'POST', options) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);

    const formData = new FormData();

    if (Object.keys(data).length) {
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
    }

    if (liveStatus === 'offline') return false;

    return new Promise((resolve, reject) => {
        fetch(API_ENDPOINT + url, {
            method,
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                token: token,
                platform: WEB,
                'gd-branch-id': getBranchId(),
                ...options
            },
            body: formData
        })
            .then((response) => {
                if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                    store?.dispatch(clearUserData());
                } else {
                    return response.json();
                }
            })
            .then((response) => resolve(response))
            .catch(() => reject('Oops! Please try again'));
    }).catch((err) => console.error('Err: ', err));
}

/**
 * API utils for handle api request with append case code `500`
 * @param {string} url - Endpoint
 * @param {object} params - Config header
 * @param {function} handleSuccess - Handle data when status is success (optional)
 * @param {function} handleFailure - Handle data when status is failure (optional)
 * @param {function} handleFinally - Handle data when status is done (optional)
 * @param {boolean} isSetUrl - If true, we're using `url` and not transform it for endpoint url
 * @param isGetStatusCode {boolean} - if true we're just get response status
 * @returns Promise
 * @example - client('login', {username, password}).then(handleUserResponse,handleUserError)
 */
export async function clientQuery(
    url,
    {
        data,
        headers: customHeaders,
        method = 'GET',
        toFormData = true,
        useGdBranchID = true,
        abortController = {},
        ...customConfig
    } = {},
    handleSuccess,
    handleFailure,
    handleFinally,
    isSetUrl = false,
    isGetStatusCode = false
) {
    const endpoint = method === 'GET' ? url + '?' + stringify(data) : url;
    let body = data && method !== 'GET' ? JSON.stringify(data) : undefined;
    const token = localStorage.getItem(ACCESS_TOKEN);
    const liveStatus = sessionStorage.getItem(LIVE_STATUS);

    if (liveStatus === 'offline') return false;

    const subConfig = { token, platform: WEB };

    if (useGdBranchID) subConfig['gd-branch-id'] = getBranchId();
    if (!token) subConfig['Accept-Language'] = i18n.language || EN;
    if (method !== 'GET' && toFormData) {
        body = jsonToFormData(data);
    } else {
        subConfig['Content-Type'] = data ? 'application/json' : undefined;
    }

    const signal = abortController?.signal || new AbortController().signal;

    const config = {
        method,
        body,
        headers: {
            ...subConfig,
            ...customHeaders
        },
        signal: signal,
        ...customConfig
    };

    return window
        .fetch(isSetUrl ? url : API_ENDPOINT + endpoint, config)
        .then(async (response) => {
            if (response.status === ERROR_CODE.EXPIRED_TOKEN) {
                // remove token and redirect to login page the page for them
                store?.dispatch(clearUserData());
                return Promise.reject({ message: 'Please re-authenticate.' });
            }

            if (isGetStatusCode) return response.status;
            const data = await response.json();

            isFunction(handleFinally) && handleFinally(data);

            if (response.ok && data.success) {
                isFunction(handleSuccess) && handleSuccess(data);
                return data;
            } else {
                isFunction(handleFailure) && handleFailure({ ...data, statusCode: response.status });
                return data;
            }
        })
        .catch((error) => {
            const { message: errorMessage = '', name: errorName = '' } = error || {};
            let finalMessage = '';
            if (!errorMessage) {
                finalMessage = 'Oops! Please try again';
            } else {
                finalMessage =
                    errorMessage === 'Failed to fetch' || errorMessage === 'Load failed'
                        ? 'Failed to load data. No response received from the server. Please check your connection and try again.'
                        : errorMessage;
            }
            if (isFunction(handleFailure)) {
                handleFailure({
                    message: finalMessage,
                    isAborted: errorName === 'AbortError',
                    typeError: errorName
                });
            }
            Promise.reject(finalMessage);
        })
        .finally(() => {
            isFunction(handleFinally) && handleFinally();
        });
}

const isFunction = (fn) => typeof fn === 'function';

function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data === null ? '' : data;
        formData.append(parentKey, value);
    }
}
