import { RatingIcon } from 'app/modules/notifications/components/IconNotification';
import IconCompany from 'assets/icon/IconCompany';
import IconInvoice from 'assets/icon/IconInvoice';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import IconUser from 'assets/icon/IconUser';
import IconYoutube from 'assets/icon/IconYoutube';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_JOB = 'ADD_JOB';
export const ADD_SERVICE_TEMPLATES = 'ADD_SERVICE_TEMPLATES';
export const NEXT_STEP_DEMO = 'NEXT_STEP_DEMO';
export const NEXT_STEP_ADD_LOGO = 'NEXT_STEP_ADD_LOGO';
export const ADD_INVOICE = 'ADD_INVOICE';
export const PLANS_AND_PRICING = 'PLANS_AND_PRICING';
export const BOOK_A_COACHING_CALL = 'https://gorilladesk.com/academy/getting-started/coaching-call/';
export const ADD_LOGO = 'ADD_LOGO';
export const ONBOARDING_POPULAR_VIDEOS = [
    {
        id: 1,
        title: 'welcome_video',
        icon: <IconYoutube isOnBoarding />,
        type: NEXT_STEP_DEMO,
        link: 'https://fast.wistia.net/embed/iframe/0y28tgj7ii',
        isHideStartOnBoarding: true,
        isNextStep: true,
        isHideNotTrial: true
    },
    {
        id: 2,
        title: 'watch_the_demo_video',
        icon: <IconYoutube isOnBoarding />,
        type: NEXT_STEP_ADD_LOGO,
        link: 'https://fast.wistia.net/embed/iframe/i8pda6gmye',
        isHideStartOnBoarding: true,
        isNextStep: true
    },
    {
        id: 8,
        title: 'add_logo',
        icon: <IconCompany isOnBoarding />,
        type: ADD_LOGO,
        link: 'https://fast.wistia.net/embed/iframe/khyuu7v8xt',
        isCompleted: false
    },
    {
        id: 3,
        title: 'add_customer',
        icon: <IconUser isOnBoarding />,
        type: ADD_CUSTOMER,
        link: 'https://fast.wistia.net/embed/iframe/zj1g2b0bv4',
        isCompleted: false
    },
    {
        id: 4,
        title: 'add_job',
        icon: <IconServiceTemplate isHasColor />,
        type: ADD_JOB,
        link: 'https://fast.wistia.net/embed/iframe/hq1k3zx2wy',
        isCompleted: false
    },
    {
        id: 5,
        title: 'add_an_invoice',
        icon: <IconInvoice isPurple />,
        type: ADD_INVOICE,
        link: 'https://fast.wistia.net/embed/iframe/cxtdknrwq1',
        isCompleted: false
    },
    {
        id: 6,
        title: 'plans_and_pricing',
        icon: <RatingIcon />,
        type: PLANS_AND_PRICING,
        link: 'https://fast.wistia.net/embed/iframe/zf28brlxny'
    }
];
export const LIST_ON_BOARDING_FAQ = [
    {
        id: 1,
        title: 'import_your_own_customer_list',
        link_article: 'https://intercom.help/gorilladesk/en/articles/2845405-import-your-own-customer-list'
    },
    {
        id: 2,
        title: 'job_import',
        link_article: 'https://intercom.help/gorilladesk/en/articles/7061830-job-import'
    },
    {
        id: 3,
        title: 'config_setting_title',
        link: 'https://fast.wistia.net/embed/iframe/x8pacuoduu',
        description: 'config_setting_desc',
        isHideStartOnBoarding: true
    },
    {
        id: 4,
        title: 'create_service_template_title',
        link: 'https://fast.wistia.net/embed/iframe/50t8jg8vw3',
        description: 'create_service_template_desc',
        type: ADD_SERVICE_TEMPLATES
    },
    {
        id: 5,
        title: 'customize_your_paperwork_title',
        link: 'https://fast.wistia.net/embed/iframe/h1ai12alp0',
        description: 'customize_your_paperwork_desc',
        isHideStartOnBoarding: true
    },
    {
        id: 6,
        title: 'integrate_stripe_square_title',
        link: 'https://fast.wistia.net/embed/iframe/d54u4a1t7q',
        description: 'integrate_stripe_square_desc',
        isHideStartOnBoarding: true
    }
];
export const LIST_VIDEO_LIBRARY = [
    {
        id: 47,
        title: 'plans_and_pricing',
        link: 'https://fast.wistia.net/embed/iframe/zf28brlxny'
    },
    {
        id: 7,
        title: 'documents_title',
        link: 'https://fast.wistia.net/embed/iframe/oopzhioi4m'
    },
    {
        id: 8,
        title: 'custom_documents_title',
        link: 'https://fast.wistia.net/embed/iframe/2gp9nl3ozh'
    },
    {
        id: 11,
        title: 'material_usage_title',
        link: 'https://fast.wistia.net/embed/iframe/6xy49kza6y'
    },
    {
        id: 1,
        title: 'square_stripe_title',
        link: 'https://fast.wistia.net/embed/iframe/d54u4a1t7q'
    },
    {
        id: 48,
        title: 'sms_text_messaging',
        link: 'https://www.youtube.com/embed/4AXYNnaTVLk?rel=0'
    },
    {
        id: 10,
        title: 'gps_tracking_title',
        link: 'https://fast.wistia.net/embed/iframe/bhlvl86qda'
    },
    {
        id: 15,
        title: 'review_engine_title',
        link: 'https://fast.wistia.net/embed/iframe/1ce9pkhpro'
    },
    {
        id: 5,
        title: 'customer_portal_title',
        link: 'https://fast.wistia.net/embed/iframe/zyv1908lso'
    },
    {
        id: 6,
        title: 'device_tracking_title',
        link: 'https://fast.wistia.net/embed/iframe/ri3apnts5r'
    },
    {
        id: 16,
        title: 'route_optimization_title',
        link: 'https://fast.wistia.net/embed/iframe/mjqa5cpez8'
    },
    {
        id: 9,
        title: 'dynamic_estimate_title',
        link: 'https://fast.wistia.net/embed/iframe/bl0mxq2l2h'
    },
    {
        id: 3,
        title: 'custom_fields_title',
        link: 'https://fast.wistia.net/embed/iframe/i2rg4gvinp'
    },
    {
        id: 4,
        title: 'custom_job_status_title',
        link: 'https://fast.wistia.net/embed/iframe/v3nnn6lm0b'
    },
    {
        id: 13,
        title: 'online_booking_title',
        link: 'https://fast.wistia.net/embed/iframe/urtje6fk49'
    },
    {
        id: 2,
        title: 'commission_tracking_title',
        link: 'https://fast.wistia.net/embed/iframe/1xs2t5lje7'
    },
    {
        id: 12,
        title: 'multi_unit_tracking_title',
        link: 'https://fast.wistia.net/embed/iframe/dc7epbh5q5'
    },
    {
        id: 14,
        title: 'opportunity_pipeline_title',
        link: 'https://fast.wistia.net/embed/iframe/7xbhk2elbd'
    },
    {
        id: 17,
        title: 'best_available_time_title',
        link: 'https://fast.wistia.net/embed/iframe/ya559pe1k9'
    },
    {
        id: 18,
        title: 'job_magnet_title',
        link: 'https://fast.wistia.net/embed/iframe/jkkdohr10h'
    },
    {
        id: 19,
        title: 'smart_views_title',
        link: 'https://fast.wistia.net/embed/iframe/mktlppqlim'
    },
    {
        id: 20,
        title: 'triggers_title',
        link: 'https://www.youtube.com/embed/sgmgsye3GnI'
    },
    {
        id: 21,
        title: 'voip_by_gorilladesk_title',
        link: 'https://fast.wistia.net/embed/iframe/1hjylr6cut'
    },
    {
        id: 22,
        title: 'send_receive_calls_title',
        link: 'https://fast.wistia.net/embed/iframe/1hjylr6cut'
    },
    {
        id: 23,
        title: 'zapier_title',
        link: 'https://fast.wistia.net/embed/iframe/nvu28sfmz9'
    },
    {
        id: 24,
        title: 'branch_manager_title',
        link: 'https://fast.wistia.net/embed/iframe/bx963yfgum'
    },
    {
        id: 25,
        title: 'company_settings_title',
        link: 'https://fast.wistia.net/embed/iframe/x8pacuoduu'
    },
    {
        id: 26,
        title: 'tax_line_settings_title',
        link: 'https://fast.wistia.net/embed/iframe/cevo9m9mc6'
    },
    {
        id: 27,
        title: 'service_template_title',
        link: 'https://fast.wistia.net/embed/iframe/ti6vstwk94'
    },
    {
        id: 28,
        title: 'adding_jobs_desktop_title',
        link: 'https://fast.wistia.net/embed/iframe/7qruqbrwza'
    },
    {
        id: 29,
        title: 'adding_jobs_mobile_title',
        link: 'https://fast.wistia.net/embed/iframe/lqcp6kec40'
    },
    {
        id: 30,
        title: 'appointment_confirmations_title',
        link: 'https://fast.wistia.net/embed/iframe/ht2jlv01lh'
    },
    {
        id: 31,
        title: 'appointment_reminders_title',
        link: 'https://fast.wistia.net/embed/iframe/po5nw3d3su'
    },
    {
        id: 32,
        title: 'appointment_followups_title',
        link: 'https://fast.wistia.net/embed/iframe/neba5ijzy5'
    },
    {
        id: 33,
        title: 'late_payment_reminders_title',
        link: 'https://fast.wistia.net/embed/iframe/1w2hsmqdzj'
    },
    {
        id: 34,
        title: 'messaging_preferences_title',
        link: 'https://fast.wistia.net/embed/iframe/e2c12fgxgj'
    },
    {
        id: 35,
        title: 'completing_jobs_desktop_title',
        link: 'https://fast.wistia.net/embed/iframe/floii131os'
    },
    {
        id: 36,
        title: 'completing_jobs_mobile_title',
        link: 'https://fast.wistia.net/embed/iframe/xkvjj85pqz'
    },
    {
        id: 37,
        title: 'note_templates_title',
        link: 'https://fast.wistia.net/embed/iframe/xzhz1rdjo3'
    },
    {
        id: 38,
        title: 'add_users_permissions_title',
        link: 'https://fast.wistia.net/embed/iframe/5qkq2m0hnw'
    },
    {
        id: 39,
        title: 'create_assign_schedules_title',
        link: 'https://fast.wistia.net/embed/iframe/ip0h6uh4gm'
    },
    {
        id: 40,
        title: 'paperwork_templates_title',
        link: 'https://fast.wistia.net/embed/iframe/h1ai12alp0'
    },
    {
        id: 41,
        title: 'schedule_groups_title',
        link: 'https://fast.wistia.net/embed/iframe/9jlmt0jw5s'
    },
    {
        id: 42,
        title: 'tags_tagging_title',
        link: 'https://fast.wistia.net/embed/iframe/ypjgwg9qq7'
    },
    {
        id: 43,
        title: 'email_templates_title',
        link: 'https://www.youtube.com/embed/dNgxCGa_J34'
    },
    {
        id: 44,
        title: 'stripe_square_subscription_billing_title',
        link: 'https://fast.wistia.net/embed/iframe/wt9je966d3'
    },
    {
        id: 45,
        title: 'sketch_title',
        link: 'https://fast.wistia.net/embed/iframe/cmk8nbamm7'
    },
    {
        id: 46,
        title: 'map_drawing_tool_title',
        link: 'https://www.youtube.com/embed/P5yu5h12pfA'
    }
];
