import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';

const AssignTo = forwardRef(({ defaultValue, classCover = 'rows' }, ref) => {
    const { t } = useTranslation();
    const companyUsers = useSelector((state) => state.companyUsers.users || []);
    const userData = useSelector(({ auth }) => auth.user.profile);

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        selected:
            companyUsers.find((item) => parseInt(item.id) === parseInt(defaultValue || userData.id)) ||
            companyUsers[0] ||
            {}
    });
    const finalIsVisible = state.isVisible;
    const userSelected = state.selected;

    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return userSelected;
        },
        resetValue: () => {
            setState({
                selected:
                    companyUsers.find((item) => parseInt(item.id) === parseInt(defaultValue || userData.id)) ||
                    companyUsers[0] ||
                    {}
            });
        }
    }));

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_company_user');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_company_user');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    function _handleSelectUser(e, user) {
        setState({
            isVisible: false,
            selected: user
        });
    }

    function _renderListUser() {
        if (!finalIsVisible) {
            return false;
        }

        return companyUsers.map((item) => {
            const isAvtive = userSelected.id === item.id;
            return (
                <li
                    key={item.id}
                    className={`items ${isAvtive ? 'active' : ''}`}
                    onClick={(e) => _handleSelectUser(e, item)}
                >
                    <div className="txt-ellipsis">{item.first_name.concat(' ', item.last_name)}</div>
                </li>
            );
        });
    }

    return (
        <div className={classCover}>
            <div className="txt">{t('customers:assign_to')}</div>
            <div className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                <div onClick={_handleOpen} className="dropbtn items">
                    <div className="txt-ellipsis">
                        {userSelected.id ? userSelected.first_name.concat(' ', userSelected.last_name) : ''}
                    </div>
                    <div className="arrow">
                        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {' '}
                            <path
                                d="M4.5 7.5L8 11L11.5 7.5"
                                stroke="#4D5054"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />{' '}
                        </svg>
                    </div>
                </div>
                <div className="v2-dropdown__menu scrolls" id={'show_list_company_user'}>
                    <ul>{_renderListUser()}</ul>
                </div>
            </div>
        </div>
    );
});

export default AssignTo;
