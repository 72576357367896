import React, { useEffect, useReducer, useRef } from 'react';
import SearchCustomer from 'app/modules/calendar/components/sidebar/components/Search';
import FilterJobStatus from 'app/modules/calendar/components/sidebar/components/workpool/FilterJobStatus';
import FilterTags from 'app/modules/calendar/components/sidebar/components/workpool/FilterTags';
import WorkPoolJobList from 'app/modules/calendar/components/sidebar/components/workpool/List';
import TabsBar from 'app/modules/calendar/components/sidebar/components/workpool/Tabs';
import { WOORPOOL_TAB } from 'app/modules/calendar/const/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { actionJobWorkPool } from 'common/redux/actions/customers/jobAction';
import { convertEvents } from 'app/modules/calendar/ultil/Calendar';
import WorkPoolService from 'app/modules/calendar/components/sidebar/components/workpool/Services';
import { clientQuery } from 'common/utils/ApiUtils';
import { COUNT_CUSTOMER_JOB_WOOK_POOL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { EVENT_CUSTOM_NAME } from 'app/modules/calendar/map/constant';

const WorkPoolList = ({ activeTab }) => {
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        tab: WOORPOOL_TAB.POOL,
        customer: null,
        status: -1,
        tags: -1,
        isLoading: true,
        jobList: [],
        showMore: false,
        fetchWithNewColor: null,
        numberPool: 0,
        numberMissed: 0,
        fistTimeCount: true,
        refresh: 0
    });
    const { color } = useSelector(({ calendar }) => calendar);

    const isFirstTime = useRef(true);
    const refListWorkPool = useRef(null);

    const {
        customer: finalCustomer,
        jobList: finalJobList,
        isLoading: finalLoading,
        fetchWithNewColor: flagFetchWithNewColor,
        tab: finalTab,
        status: finalStatus,
        tags: finalTags,
        refresh: finalRefresh,
        showMore,
        numberPool: finalNumberPool,
        numberMissed: finalNumberMissed
    } = state;

    useEffect(() => {
        if (refListWorkPool.current)
            refListWorkPool.current.addEventListener(EVENT_CUSTOM_NAME.UPDATE_WORK_POOL, _handleUpdate);
        return () => {
            if (refListWorkPool.current)
                refListWorkPool.current.removeEventListener(EVENT_CUSTOM_NAME.UPDATE_WORK_POOL, _handleUpdate);
        };
    }, [finalJobList]);

    const _handleUpdate = (event) => {
        const idsDelete = event.detail?.idsWorkPool || [];
        if (idsDelete.length) {
            dispatchState((prevState) => {
                return { ...prevState, jobList: prevState.jobList.filter((item) => !idsDelete.includes(item.id)) };
            });
            _countNumberJob();
        }
    };

    useEffect(() => {
        if (finalTab === WOORPOOL_TAB.POOL) {
            const element = document.getElementById('sidebar_content_workpool_number_pool');
            if (!element) {
                return false;
            }
            if (finalNumberPool > 0) {
                element.innerHTML = parseInt(finalNumberPool) > 99 ? '99+' : finalNumberPool;
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        }
    }, [finalNumberPool]);

    useEffect(() => {
        if (!isFirstTime.current && activeTab && color !== flagFetchWithNewColor) {
            dispatchState({
                jobList: [],
                isLoading: true,
                fetchWithNewColor: color
            });
        }
        isFirstTime.current = false;
    }, [color, activeTab]);

    useEffect(() => {
        if (!isFirstTime.current && !activeTab) {
            dispatchState({
                jobList: [],
                isLoading: true,
                fetchWithNewColor: color
            });
        }
        _fetchWookPool(true);
    }, [finalTab, finalCustomer, finalStatus, finalTags, finalRefresh, color]);

    const handleOnScrollContent = (e) => {
        if (!finalLoading && isScrollToEndBottom(e.target) && showMore) {
            _triggerLoadMore();
        }
    };

    const _triggerLoadMore = () => {
        dispatchState({ isLoading: true });
        _fetchWookPool();
    };

    function _fetchWookPool(reset = false) {
        dispatch(
            actionJobWorkPool(
                {
                    customer_id: finalCustomer,
                    statuses: finalStatus.toString(),
                    tags: finalTags.toString(),
                    color_id: color,
                    type: finalTab === WOORPOOL_TAB.POOL ? 1 : 0,
                    offset: reset ? 0 : finalJobList.length
                },
                _fetchWookPoolSuccess,
                _fetchWookPoolFailed
            )
        );
    }

    function _fetchWookPoolSuccess(response) {
        if (finalJobList.length === 0 && state.fistTimeCount) {
            _countNumberJob();
        }

        dispatchState((prev) => {
            return {
                ...prev,
                jobList: [...prev.jobList, ...convertEvents(response.data)],
                showMore: response.show_more,
                isLoading: false,
                fetchWithNewColor: color
            };
        });
    }

    function _countNumberJob() {
        const optionsQuery = {
            method: 'GET'
        };
        clientQuery(COUNT_CUSTOMER_JOB_WOOK_POOL, optionsQuery, _countNumberJobSuccess);
    }

    function _countNumberJobSuccess(response) {
        const { pool: numberJobPool, missed: numberJobMiss } = response.data || {};

        dispatchState({
            numberPool: numberJobPool,
            numberMissed: numberJobMiss,
            fistTimeCount: false
        });
    }

    function _fetchWookPoolFailed() {
        dispatchState({ isLoading: false });
    }

    function _updateJobList(dataUpdate) {
        dispatchState({ ...dataUpdate });
        _countNumberJob();
    }

    function _getStatus(selected) {
        dispatchState({ status: selected, jobList: [], isLoading: true });
    }

    function _getCustomer(selected) {
        isFirstTime.current = false;

        dispatchState({
            customer: selected?.id || null,
            jobList: [],
            isLoading: true,
            refresh: Date.now()
        });
    }

    function _selectTab(tab) {
        dispatchState({ tab: tab, jobList: [], isLoading: true });
    }

    function _selectTags(selected) {
        dispatchState({ tags: selected, jobList: [], isLoading: true });
    }

    const handleReload = () => {
        dispatchState({ jobList: [], isLoading: true, refresh: new Date().getTime() });
    };

    function _renderFilterTag() {
        if (finalCustomer && finalCustomer !== -1) {
            return <FilterTags onSelect={_selectTags} customerId={finalCustomer} />;
        }

        return false;
    }

    return (
        <div
            id="external-events-list"
            ref={refListWorkPool}
            className={`tab-conts detail-workpool detail-joblist ${activeTab ? 'tab-content-active' : ''} `}
        >
            <div className="scrolls flex-column" onScroll={handleOnScrollContent}>
                {activeTab && (
                    <div className="tab-header">
                        <TabsBar
                            numberPool={finalNumberPool}
                            numberMissed={finalNumberMissed}
                            tab={finalTab}
                            onSelect={_selectTab}
                        />
                    </div>
                )}

                <SearchCustomer selectCustomer={_getCustomer} />
                <FilterJobStatus onSelect={_getStatus} />
                {_renderFilterTag()}

                <WorkPoolJobList activeTab={activeTab} isLoading={finalLoading} jobList={finalJobList} />

                <WorkPoolService
                    isFirstTime={isFirstTime.current}
                    jobList={finalJobList}
                    onReload={handleReload}
                    onUpdateJobs={_updateJobList}
                    onLoad={_triggerLoadMore}
                />
            </div>
        </div>
    );
};

export default WorkPoolList;
