/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useLayoutEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Route, useHistory, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import LoadingFullPage from 'app/components/loading';
import { PUBLIC_PATHS_CHECK } from 'app/config/router';
import { CUSTOMERS, CUSTOMERS_PORTAL } from 'app/config/routes';
import {
    ACCESS_TOKEN,
    ACCOUNT_ROLE,
    DEFAULT_CURRENCY_SYMBOL,
    KEY_CURRENT_BRANCH,
    LOGIN_BY_TOKEN_TYPES
} from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import {
    AUTH_LOGIN,
    AUTH_LOGIN_BY_TOKEN,
    AUTH_PASSWORD_RESET,
    AUTH_SIGN_UP_ACTIVATE,
    AUTH_SIGN_UP_SOCIAL,
    HOME,
    SETTINGS_PLANS,
    CALENDAR,
    AUTH
} from 'app/const/Route';
import LoginByToken from 'app/modules/auth/loginByToken';
import ErrorPage from 'app/modules/error';
import NotHaveSchedules from 'app/modules/error/notHaveSchedules';
import { setAppLoading } from 'common/redux/actions/appAction';
import { getRetrieveUser } from 'common/redux/actions/authAction';

import SignupActivate from 'app/modules/auth/signupActivate';
import ChangePassword from 'app/modules/auth/changePassword';
import SignUpSocial from 'app/modules/auth/signupSocial';

// Import AuthenticatedApp and UnAuthenticatedApp
import AuthenticatedApp from /* webpackPrefetch: true */ './AuthenticatedApp';
import UnAuthenticatedApp from './UnAuthenticatedApp';
import { ErrorBoundary } from 'react-error-boundary';
import { checkIsExpiredAccount } from 'common/utils/PermissionUtils';
import { LIST_COUNTRY } from 'app/const/Settings';
import { companyCountryCode, companySettingCurrencySymbol } from 'common/utils/ObjectUtils';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { MIXPANEL_KEY_LOCAL_STORAGE_UTM_PARAMS, MIXPANEL_UTM } from 'app/const/Mixpanel';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelLogin } from 'app/modules/mixpanel/MixpanelLogin';
import {
    addBranchPath,
    addPrefixPath,
    getBranchId,
    isBranchId,
    isBranchStartPrefix,
    setBranchId
} from 'app/const/Branch';
import * as LinkRouter from 'app/const/Route';
import { getParamsCustomerList } from 'app/modules/customer/utils';
import i18n from 'assets/i18n';
import 'moment/locale/es';
import 'moment/locale/en-ca';

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname, search } = useLocation();

    const isPublicRoute =
        PUBLIC_PATHS_CHECK.some((item) => pathname.includes(item)) &&
        !matchPath(pathname, {
            path: `/:branchid${CUSTOMERS_PORTAL}`
        });

    const [state, dispatchState] = useReducer(reducer, {
        technicianAccessDesktop: false,
        isExpired: false,
        emptySchedules: false
    });

    const loading = useSelector(({ app }) => app.loading);
    const { isLogin, user } = useSelector(({ auth }) => auth);

    const profile = user?.profile;
    const countryCode = user?.company?.country || LIST_COUNTRY[0].value;
    const settingCurrency = user?.settings?.currency?.symbol || DEFAULT_CURRENCY_SYMBOL;
    const remainingDays = profile?.plan?.remaining_days <= 0;
    const role = profile?.role;
    const isTechnician = role === ACCOUNT_ROLE.TECHNICIAN;

    const { technicianAccessDesktop, emptySchedules } = state;

    useLayoutEffect(() => {}, []);

    useEffect(() => {
        if (!!countryCode) companyCountryCode.countryCode = countryCode;
    }, [countryCode]);

    useEffect(() => {
        if (!!settingCurrency) companySettingCurrencySymbol.settingCurrencySymbol = settingCurrency;
    }, [settingCurrency]);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            batch_requests: true,
            cross_subdomain_cookie: true
        });

        if (search.includes(MIXPANEL_UTM)) setLocalStorage(MIXPANEL_KEY_LOCAL_STORAGE_UTM_PARAMS, search);
        if (isPublicRoute) {
            dispatch(setAppLoading(false));
            return;
        }

        // User token
        const token = localStorage.getItem(ACCESS_TOKEN);
        const branchFromUrl = pathname.split('/')[1];

        let currentBranchId = localStorage.getItem(KEY_CURRENT_BRANCH);

        if (isBranchId(branchFromUrl) && branchFromUrl !== currentBranchId) {
            localStorage.setItem(KEY_CURRENT_BRANCH, branchFromUrl);
            currentBranchId = branchFromUrl;
        }

        setBranchId(currentBranchId);

        if (token) {
            // If user token is available, get user info and
            // company users list for schedules in calendar
            dispatch(getRetrieveUser(_handleRetrieveSuccess, _handleRetrieveFailed));
        } else {
            // Remove loading component and redirect to login page
            dispatch(setAppLoading(false));
            history.replace(pathname === HOME ? AUTH_LOGIN : pathname + search);
        }
    }, []);

    /**
     * Check Technician access Desktop when load app
     * @param response
     */
    const checkTechnicianAccessDesktop = (response) => {
        const permissions = response?.permissions?.enabled || [];
        const role = response?.profile?.role;
        const isAccessDesktop = permissions.includes('accessDesktop');
        const isTechnician = role === ACCOUNT_ROLE.TECHNICIAN;
        const isExpired = checkIsExpiredAccount(response?.profile);
        const technicianAccessDesktop = isTechnician && !isAccessDesktop;

        if (isExpired) history.replace(addBranchPath(SETTINGS_PLANS));
        if (technicianAccessDesktop) dispatchState({ technicianAccessDesktop, isExpired });
    };

    const _handleRetrieveSuccess = (response) => {
        handleTrackingEvent(mixpanelLogin({ profile: response.profile, company: response.company, isHaveToken: true }));
        const branchId = getBranchId();
        i18n.changeLanguage(response.company.language);
        const arrayPath = pathname.split('/');
        const branchIdUrl = arrayPath[1];

        let finalPath = pathname;

        if (!isBranchId(branchIdUrl)) {
            if (isBranchStartPrefix(branchIdUrl)) {
                finalPath = finalPath.replace(branchIdUrl, branchId);
            } else {
                if (
                    !branchIdUrl ||
                    Object.keys(LinkRouter)
                        .map((key) => LinkRouter[key])
                        .includes(`/${branchIdUrl}`)
                ) {
                    finalPath = addBranchPath(pathname);
                } else {
                    finalPath = finalPath.replace(branchIdUrl, branchId);
                }
            }
        } else {
            finalPath = finalPath.replace(branchIdUrl, branchId);
        }

        history.replace({
            pathname:
                finalPath.includes(LOGIN_BY_TOKEN_TYPES.CLASSIC) || finalPath.includes(AUTH)
                    ? addBranchPath(CALENDAR)
                    : finalPath,
            search,
            state: null
        });
        checkTechnicianAccessDesktop(response);
    };

    const _handleRetrieveFailed = () => {
        history.replace(AUTH_LOGIN);
    };

    const _handleCheckEmprySchedules = () => {
        isTechnician &&
            dispatchState({
                emptySchedules: true
            });
    };

    const _redirectHome = (response) => {
        history.replace(addBranchPath(HOME));
        _handleRetrieveSuccess(response);
    };

    const _onReload = () => {
        dispatch(getRetrieveUser(_redirectHome, _handleRetrieveFailed));
    };

    const _handleError = (e, info) => {
        // eslint-disable-next-line no-undef
        if (process.env.REACT_APP_ENV === 'beta') {
            if (e.name === 'ChunkLoadError' || e.name === 'Error') return;
            const isCustomerList = matchPath(pathname, { path: addPrefixPath(CUSTOMERS), exact: true });
            // const keys = Object.keys(localStorage) || [];
            // const values = keys.map((key) => ({ [key]: localStorage.getItem(key) }));

            const finalLogs = {
                path: pathname,
                profile: profile?.id,
                company: user?.company?.name,
                type: e.name,
                message: e.message,
                stackComponent: info?.componentStack
            };
            if (isCustomerList) {
                finalLogs.customer = getParamsCustomerList();
            }

            // eslint-disable-next-line no-undef
            fetch(process.env.REACT_APP_URL_WEB_HOOK_SLACK, {
                method: 'POST',
                body: JSON.stringify({
                    type: 'crash',
                    text: finalLogs
                }),
                headers: { Accept: 'application/json' }
            });
        }
    };

    if (loading) return <LoadingFullPage />;
    if (isTechnician && remainingDays) return <ErrorPage isBack companyInfo={user.company} />;
    if (technicianAccessDesktop) return <ErrorPage />;
    if (emptySchedules) return <NotHaveSchedules />;

    return (
        <>
            {isPublicRoute ? (
                <Fragment>
                    <Route exact render={() => <LoginByToken onReload={_onReload} />} path={AUTH_LOGIN_BY_TOKEN} />
                    <Route exact component={SignupActivate} path={AUTH_SIGN_UP_ACTIVATE} />
                    <Route exact component={ChangePassword} path={AUTH_PASSWORD_RESET} />
                    <Route exact component={() => <SignUpSocial isPublic />} path={AUTH_SIGN_UP_SOCIAL} />
                </Fragment>
            ) : isLogin ? (
                <AuthenticatedApp />
            ) : (
                <UnAuthenticatedApp />
            )}
            <Services onCheckEmptySchedule={_handleCheckEmprySchedules} />
        </>
    );
};

const Services = ({ onCheckEmptySchedule }) => {
    const calendar = useSelector(({ calendar }) => calendar);

    useEffect(() => {
        if (!calendar.schedules.length) {
            onCheckEmptySchedule();
        }
    }, [calendar.schedules]);
};

export default App;
