import { GOOGLE_PLACES_API_URL } from 'app/const/Keys';
import { LINK_CDN_IMG_BROKEN } from 'app/const/URL';
import { getLocalStorageValue } from './LocalStorageUtils';
import { BROWSER_DEVICES } from 'app/const/Voip';
import { DEVICE_MOBILE } from 'app/const/Flatform';
import { LABEL_CHART_THE_MONTHS_OF_YEAR, LIST_STATUS } from 'app/const/App';
import moment from 'moment';
import i18n from 'assets/i18n';

export function debounce(fn, time) {
    let timeoutId;
    return wrapper;
    function wrapper(...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            timeoutId = null;
            fn(...args);
        }, time);
    }
}

export function guid() {
    const s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);

    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function deepCloneObject(obj) {
    if (obj === null) return null;
    const clone = { ...obj };
    Object.keys(clone).forEach(
        (key) => (clone[key] = typeof obj[key] === 'object' ? deepCloneObject(obj[key]) : obj[key])
    );
    if (Array.isArray(obj)) {
        clone.length = obj.length;
        return Array.from(clone);
    }
    return clone;
}

export function showImageLoadError(event, fallbackUrl = '') {
    event.target.src = fallbackUrl || LINK_CDN_IMG_BROKEN;
}

/**
 * Given an array of objects, group them by the value of a key
 * @returns An object with the key being the value of the key passed in and the value being an array of
 * all the objects that have that value for the key.
 */
export function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
    }, {});
}

export const convertStylesStringToObject = (stringStyles) => {
    return typeof stringStyles === 'string'
        ? stringStyles.split(';').reduce((acc, style) => {
              const colonPosition = style.indexOf(':');

              if (colonPosition === -1) {
                  return acc;
              }

              const camelCaseProperty = style
                      .substr(0, colonPosition)
                      .trim()
                      .replace(/^-ms-/, 'ms-')
                      .replace(/-./g, (c) => c.substr(1).toUpperCase()),
                  value = style.substr(colonPosition + 1).trim();

              return value ? { ...acc, [camelCaseProperty]: value } : acc;
          }, {})
        : {};
};

export const decodeHtmlspecialchars = (finalValue) => {
    if (typeof finalValue !== 'string') {
        return '';
    }
    const parser = new DOMParser();
    return parser.parseFromString(finalValue, 'text/html').body.textContent;
};

export const isScrollToEndBottom = ({ scrollHeight, scrollTop, clientHeight }, valueCheck = 1) => {
    return Math.abs(scrollHeight - (scrollTop + clientHeight)) <= valueCheck;
};

/**
 * If the browser supports WebGL, return true, otherwise return false.
 * @returns A boolean value.
 */
export const isWebglSupport = () => {
    try {
        const canvas = document.createElement('canvas');
        return (
            !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
};

export const loadGoogleScript = ({ key = '', callback = () => {} }) => {
    if (!key) throw new Error('Google API key is required');

    if (!window.google) {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = GOOGLE_PLACES_API_URL(key);
        window.document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', callback);
    }
};

export const getToggleLocal = (id) => {
    return getLocalStorageValue(id) && getLocalStorageValue(id) === 'false' ? false : true;
};

export const handleStickyMaterial = (id, sticky, data = []) => {
    const ids = [];
    const newData = data.map((item) => {
        if (item.id === id) item.sticky = sticky;
        item.sticky && ids.push(item.id);
        return item;
    });
    return {
        ids,
        list: newData.sort((prev, cur) => {
            const prevSticky = prev.sticky;
            const curSticky = cur.sticky;
            if (prevSticky > curSticky) return -1;
            if (prevSticky < curSticky) return 1;
            return prev.name.trim().localeCompare(cur.name.trim());
        })
    };
};

export const isValidLngLat = (lngLat) => {
    if (Array.isArray(lngLat)) return !isNaN(lngLat[0]) && !isNaN(lngLat[1]);
    return !isNaN(lngLat.lng) && !isNaN(lngLat.lat);
};

export const removeDuplicates = (arr, key) => {
    const uniqueMap = new Map();
    const result = [];

    for (const obj of arr) {
        if (!uniqueMap.has(obj[key])) {
            uniqueMap.set(obj[key], true);
            result.push(obj);
        }
    }

    return result;
};

export const addEventListeners = (element, eventTypes, listeners) => {
    if (eventTypes.length !== listeners.length)
        throw new Error('Number of event types must match number of listeners.');
    for (let i = 0; i < eventTypes.length; i++) {
        element.addEventListener(eventTypes[i], listeners[i]);
    }
};

export const removeEventListeners = (element, eventTypes, listeners) => {
    if (eventTypes.length !== listeners.length)
        throw new Error('Number of event types must match number of listeners.');
    for (let i = 0; i < eventTypes.length; i++) {
        element.removeEventListener(eventTypes[i], listeners[i]);
    }
};

export const preventPasteImage = (e) => {
    const eventData = window.clipboardData || e.clipboardData;
    const theFile = eventData.files[0];
    if (theFile) e.preventDefault();
};

export const _handelSortApiName = (listApi) => {
    return listApi.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
};

export const detectBrowser = () => {
    if (/Edg/.test(navigator.userAgent)) return BROWSER_DEVICES.EDGE;

    if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) return BROWSER_DEVICES.CHROME;

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return BROWSER_DEVICES.SAFARI;

    if (/firefox/i.test(navigator.userAgent)) return BROWSER_DEVICES.FIREFOX;

    return BROWSER_DEVICES.OTHER;
};

export const measureConnectionSpeed = (imageURIParam) => {
    const downloadSizeInBits = 12000000;
    const metric = 'MBps';

    const imageURI = imageURIParam ? imageURIParam : 'https://cdn.gorilladesk.com/assets/footer.png';

    return new Promise((resolve, reject) => {
        const startTime = new Date().getTime();

        const timer = setTimeout(() => {
            resolve({ metric, speed: 0 });
        }, 5000);

        fetch(imageURI, { cache: 'no-cache' })
            .then(() => {
                const endTime = new Date().getTime();
                const duration = (endTime - startTime) / 1000;
                const speed = downloadSizeInBits / (1024 * 1024 * duration);

                resolve({ metric, speed });
            })
            .catch(reject)
            .finally(() => {
                timer && clearTimeout(timer);
            });
    });
};

export const detectKeyPress = (e, key) => {
    return (e.which || e.keyCode || 0) === key;
};

export const detectMobile = () => {
    const userAgentDevice = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgentDevice)) return DEVICE_MOBILE.WINDOW_PHONE;

    if (/android/i.test(userAgentDevice)) return DEVICE_MOBILE.ANDROID;

    if (/iPad|iPhone|iPod/.test(userAgentDevice) && !window.MSStream) return DEVICE_MOBILE.IOS;

    if (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
        return DEVICE_MOBILE.IOS;

    return null;
};
/**
 * The function `getNestedValue` retrieves a nested value from an object using a dot-separated key.
 * @param rowData - The `rowData` parameter is an object that represents a row of data. It contains
 * nested properties that can be accessed using dot notation.
 * @param keyValue - The `keyValue` parameter is a string that represents a nested property in an
 * object. It uses dot notation to specify the path to the desired property. For example, if you have
 * an object like this:
 * @returns the nested value from the rowData object based on the provided keyValue.
 */
export function getNestedValue(rowData, keyValue) {
    const keys = keyValue.split('.');
    return keys.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), rowData);
}

export const getClientIdVoip = (text = '') => {
    return text?.match(/^(?:client:)?gd-([0-9]+)-([0-9]+)$/i)?.[2] || null;
};

export const showStatusBar = ({ id, message = '', success = false, type = '', refAlert }) => {
    if (!refAlert.current) return;
    refAlert.current.clearAllStatusBar();
    refAlert.current.showStatusBar(id, message, type || (success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR));
};

export const getListStaffs = ({ users = [], crew = {}, isHasCrews = false }) => {
    return isHasCrews
        ? users.concat(Object.values(crew.users || {})).sort((a, b) => a.full_name.localeCompare(b.full_name))
        : users;
};

export const deepCloneArray = (arr) => {
    return JSON.parse(JSON.stringify(arr));
};
export const getDataWithFreeSeat = ({ data = [], checkFreeSeat = false }) => {
    return data.filter((item) => (checkFreeSeat ? item.is_free_seat : !item.is_free_seat));
};
export const renderDatePickerDayName = () => {
    const localizedDayNames = moment
        .localeData(i18n.language)
        .weekdaysShort()
        .map((day) => day.slice(0, 2).charAt(0).toUpperCase() + day.slice(1, 2).toLowerCase());
    return (
        <div className="react-datepicker__day-names custom-day-names">
            {localizedDayNames.map((dayName) => (
                <div key={dayName} className="react-datepicker__day-name custom-day-name">
                    {dayName}
                </div>
            ))}
        </div>
    );
};
export const getLabelDataChart = () => {
    return LABEL_CHART_THE_MONTHS_OF_YEAR.map((item) => i18n.t(`report:${item.toLowerCase()}`));
};
export const getPeriodName = (name = '') => {
    return i18n.t(`customers:${name.toLowerCase().replace('-', '_')}`);
};
