import classNames from 'classnames';
import { Fragment, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';

const DropdownPaymentTerms = ({ keyGetValue = '', data = [], type = 0, onHandleChange = () => {} }, refDropdown) => {
    const { t } = useTranslation();
    const selectedItem = data.find(({ type: itemType }) => itemType === type) || {};
    const { value } = selectedItem;

    return (
        <CalendarDropdown
            ref={refDropdown}
            buttonClassName="dropbtn items shadow-none"
            customDropButton={() => (
                <Fragment>
                    <div className="txt-ellipsis text-capitalize">{value}</div>
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </Fragment>
            )}
        >
            <ul>
                {data.map(({ type: itemType, value }, index) => {
                    const _handleClick = () => {
                        onHandleChange({ key: keyGetValue, value: itemType });
                        refDropdown.current._closeDropdown();
                    };
                    return (
                        <li
                            key={index}
                            className={classNames('items text-capitalize', { active: itemType === type })}
                            onClick={_handleClick}
                        >
                            {t(`jobDetail:${value}`)}
                        </li>
                    );
                })}
            </ul>
        </CalendarDropdown>
    );
};

export default forwardRef(DropdownPaymentTerms);
