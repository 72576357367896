/* eslint-disable no-unused-vars */
import { SETTINGS_TEMPLATES_ESTIMATE } from 'app/config/routes';
import { DEFAULT_ALL } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { GET_TEMPLATES_ESTIMATES, actionTemplateEstimates } from 'app/const/api/V2';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconDocument';
import IconFile from 'assets/icon/IconFile';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Search from './Search';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { DEFAULT_VALUE_PACKAGE, ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import IconLoading from 'assets/icon/IconLoading';
import { ESTIMATES_TEMPLATE_OPTIONS } from 'app/const/Notes';
import { useSelector } from 'react-redux';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';

export default function EstimateTemplate({ isDisable = false }) {
    const { t } = useTranslation(['header', 'common']);
    const { invoiceData, onChangeWithPreData, onChangeEstimatePackage } = useContext(AddInvoiceContext);
    const history = useHistory();
    const {
        type: estimateType,
        locationInfo: { tax1: locationTax1, tax2: locationTax2 },
        items: estimateItems
    } = invoiceData;
    const isActiveLocationTax = !!locationTax1 || !!locationTax2;
    const { list_taxes } = useSelector((store) => store.taxesReducer);

    const refDropdown = useRef(null);
    const refConfirm = useRef(null);

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        isFetched: 0,
        type: DEFAULT_ALL,
        currentType: '',
        data: [],
        cursor: '',
        isLoadMore: false,
        isLoadingMore: false,
        isLoading: true,
        isChange: 0,
        selected: null
    });

    const {
        isVisible: finalIsVisible,
        isFetched: finalIsFetched,
        type: finalType,
        currentType,
        cursor: finalCursor,
        isChange: finalChange,
        isLoadingMore,
        isLoading,
        isLoadMore,
        data: finalData,
        selected: templateSelected,
        keyword: finalKeyword
    } = state;

    useEffect(() => {
        if ((finalIsVisible && !finalIsFetched) || finalChange) {
            _getList();
        }
    }, [finalIsVisible, finalChange]);

    useEffect(() => {
        if (estimateType !== currentType) {
            setState((prev) => {
                return {
                    ...prev,
                    selected: null,
                    currentType: ''
                };
            });
        }
    }, [estimateType]);

    const _handleFetchSuccess = ({ data, cursor, show_more }) => {
        setState((prev) => {
            return {
                ...prev,
                isFetched: Date.now(),
                data: [...prev.data, ...data],
                cursor,
                isLoadMore: show_more,
                isLoadingMore: false,
                isLoading: false
            };
        });
    };

    const _handleFetchFailed = () => {
        setState((prev) => ({ ...prev, isLoading: false, isLoadingMore: false }));
    };

    const _getList = (params = {}) => {
        clientQuery(
            GET_TEMPLATES_ESTIMATES,
            {
                data: {
                    fields: 'manager',
                    type: finalType.toString(),
                    cursor: finalCursor,
                    limit: 10,
                    ...params,
                    keyword: finalKeyword
                },
                method: 'GET'
            },
            _handleFetchSuccess,
            _handleFetchFailed
        );
    };

    const _handleVisible = () => {
        setState((prev) => ({ ...prev, isVisible: !prev.isVisible }));
    };

    function _redirectToManage() {
        history.replace(
            addBranchPath(
                SETTINGS_TEMPLATES_ESTIMATE.replace(
                    ':id',
                    ESTIMATES_TEMPLATE_OPTIONS.find((item) => item.parentId === estimateType)?.id
                )
            )
        );
    }

    const _handleChangeKeyword = (keyword) => {
        setState((prev) => {
            return {
                ...prev,
                keyword,
                isLoading: true,
                data: [],
                cursor: '',
                isChange: Date.now()
            };
        });
    };

    const _handleChangeType = (newType) => {
        setState((prev) => {
            return {
                ...prev,
                type: newType,
                isLoading: true,
                data: [],
                cursor: '',
                isChange: Date.now()
            };
        });
    };

    const handleOnScrollContent = (e) => {
        if (!isLoadingMore && !isLoading && isScrollToEndBottom(e.target) && isLoadMore) {
            setState((prev) => {
                return {
                    ...prev,
                    isLoadingMore: true,
                    isChange: Date.now()
                };
            });
        }
    };

    const _getType = (currentType) => {
        switch (currentType) {
            case ESTIMATE_TYPE.BASIC:
                return t('setting:basic_estimate');
            case ESTIMATE_TYPE.DYNAMIC:
                return t('setting:dynamic_estimate');
            case ESTIMATE_TYPE.PACKAGES:
                return t('setting:package_estimate');
            default:
                break;
        }
    };

    const _convertItemWithLocationTax = (convertItems) => {
        if (!isActiveLocationTax) {
            return convertItems.map((item) => {
                return {
                    ...item,
                    old_tax1: item.tax1,
                    old_tax2: item.tax2,
                    changedTax: true
                };
            });
        }
        return convertItems.map((item) => {
            return {
                ...item,
                tax1: list_taxes.find((itemTax1) => itemTax1.id === locationTax1),
                tax2: list_taxes.find((itemTax2) => itemTax2.id === locationTax2),
                old_tax1: item.tax1,
                old_tax2: item.tax2,
                changedTax: true
            };
        });
    };

    const _handleSelectSuccess = ({ data }) => {
        const { type: getEstimateType, discount = {}, deposit, items, packages, note, terms } = data;

        switch (getEstimateType) {
            case ESTIMATE_TYPE.BASIC:
                onChangeWithPreData((prev) => {
                    return {
                        ...prev,
                        type: ESTIMATE_TYPE.BASIC,
                        items: _convertItemWithLocationTax(items),
                        discount: discount.type ? discount : DEFAULT_VALUE_PACKAGE.discount,
                        deposit: deposit.type ? deposit : DEFAULT_VALUE_PACKAGE.deposit,
                        packages: [],
                        note,
                        terms
                    };
                });
                break;
            case ESTIMATE_TYPE.DYNAMIC:
                onChangeWithPreData((prev) => {
                    return {
                        ...prev,
                        type: ESTIMATE_TYPE.DYNAMIC,
                        items: _convertItemWithLocationTax(items),
                        packages: [],
                        discount: discount.type ? discount : DEFAULT_VALUE_PACKAGE.discount,
                        deposit: deposit.type ? deposit : DEFAULT_VALUE_PACKAGE.deposit,
                        note,
                        terms
                    };
                });
                break;
            case ESTIMATE_TYPE.PACKAGES:
                onChangeEstimatePackage((prev) => {
                    return {
                        ...prev,
                        type: ESTIMATE_TYPE.PACKAGES,
                        items: _convertItemWithLocationTax(items),
                        packages: packages,
                        taxes: [],
                        discount: {},
                        note,
                        terms
                    };
                });
                break;
            default:
                break;
        }
    };

    const _fail = () => {};

    const _replaceTemplate = (item) => {
        setState((prev) => {
            return {
                ...prev,
                selected: item,
                currentType: item.type
            };
        });
        refDropdown.current?._closeDropdown();
        clientQuery(actionTemplateEstimates(item.id), { method: 'GET' }, _handleSelectSuccess, _fail);
    };

    const _handleSelectTemplate = (item) => {
        const numberItem = estimateItems.length;
        if (numberItem > 1 || (numberItem === 1 && !estimateItems.some((item) => !!item.item_default))) {
            refConfirm.current.open(item, t('jobDetail:overwrite_current_template'));
            return false;
        }
        _replaceTemplate(item);
    };

    const _renderData = () => {
        if (isLoading) {
            return (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            );
        }

        if (!finalData.length) {
            return (
                <li className="items pointer-events-none">
                    <p className="word-break flex-1">
                        {finalKeyword
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:estimates') })}
                    </p>
                </li>
            );
        }

        return finalData.map((item) => {
            const { id: itemId, name, type } = item;
            return (
                <li
                    onClick={() => _handleSelectTemplate(item)}
                    key={itemId}
                    className="items justify-space-between gap-4"
                >
                    <p className="word-break flex-1">{name}</p>
                    <span className="status-btn --sm bg-granite-gray">{_getType(type)}</span>
                </li>
            );
        });
    };

    const _renderList = () => {
        return (
            <div className="container-column">
                <ul>
                    <li className="items has-icon" onClick={_redirectToManage}>
                        <IconFile />
                        <p className="txt-ellipsis">{t('jobDetail:manage_templates')}</p>
                    </li>
                </ul>
                <Search
                    dataFilter={finalType}
                    onChange={_handleChangeKeyword}
                    onChangeFilter={_handleChangeType}
                    keyword={finalKeyword}
                />
                <ul className="box-auto scrolls" onScroll={handleOnScrollContent}>
                    {_renderData()}
                    {isLoadingMore && (
                        <div className="items justify-center">
                            <div className="loading -ajaxbar">
                                <IconLoading />
                            </div>
                        </div>
                    )}
                </ul>
            </div>
        );
    };

    return (
        <div className="sm-row">
            <div className="txt --large">
                <IconCheck />
                <span className="flex-1 txt-ellipsis" title={t('setting:template')}>
                    {t('setting:template')}
                </span>
            </div>
            <div className={`detail ${isDisable ? 'is-disable' : ''}`}>
                <CalendarDropdown
                    id="customer_phones"
                    ref={refDropdown}
                    buttonClassName="dropbtn v2-btn-default has-icon"
                    isLoading={false}
                    wrapperClassName="v2-dropdown dropdown-estimate-templates"
                    wrapperListClass="v2-dropdown__menu content-search content-full"
                    customDropButton={() => (
                        <Fragment>
                            <IconFile />
                            <p className="txt-ellipsis">{templateSelected?.name || t('jobDetail:select_template')}</p>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    )}
                    onVisible={_handleVisible}
                >
                    {_renderList()}
                </CalendarDropdown>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_replaceTemplate}
            />
        </div>
    );
}
