import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_VOIP } from 'app/config/routes';
import { KEY_VOIP } from 'app/const/addons';
import { ACCOUNT_DETAIL_VOIP, LIST_USER_VOIP, assignVoipNumber, settingVoipNumber } from 'app/const/api/Voip';
import { ACCOUNT_ROLE, LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { updateUserSettings } from 'common/redux/actions/authAction';
import { actionTriggerLoadNumbers } from 'common/redux/actions/voipAction';
import { clientQuery } from 'common/utils/ApiUtils';
import ActiveUserGridView from './ActiveUserGridView';
import { ActiveUsersContext, ActiveUsersProvider } from './ActiveUsersProvider';

export const ActiveVOIPUsers = () => {
    const { t } = useTranslation('addons');
    const dispatch = useDispatch();
    const refAlert = useRef(null);
    const refFirstTime = useRef(true);
    let timer = null;

    const {
        profile: { id: userId, role: userRole },
        settings: { voip, addons }
    } = useSelector(({ auth }) => auth.user);

    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    if (!isSupperAdmin || !(KEY_VOIP in addons)) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        account: {},
        isLoading: true
    });

    const { data: finalData, account, isLoading } = state;

    useEffect(() => {
        fetchListVoipUser();
        _handleVisibleChange();

        document.addEventListener('visibilitychange', _handleVisibleChange);
        return () => {
            _handleVisibleChange({ isUnmount: true });
            document.removeEventListener('visibilitychange', _handleVisibleChange);
        };
    }, []);

    const fetchListVoipUser = () => {
        const _handleSuccess = ({ data: finalData, account }) => {
            refFirstTime.current = false;

            dispatchState((prev) => ({
                data: finalData.map((item) => ({
                    ...item,
                    ...handleActions()
                })),
                account: account || prev.account,
                isLoading: false
            }));
        };

        const listQuery = [clientQuery(LIST_USER_VOIP, { method: 'GET' })];
        if (refFirstTime.current) listQuery.push(clientQuery(ACCOUNT_DETAIL_VOIP, { method: 'GET' }));

        Promise.all(listQuery).then(([listUser, accountDetail]) => {
            const { success: isSuccess, message } = listUser;
            if (!isSuccess) {
                _handleShowStatus({ message });
            }
            _handleSuccess({ data: listUser.success ? listUser.data : [], account: accountDetail?.data?.account });
        });
    };

    const handleActions = () => {
        return {
            onDelete: _handleDeleteUser,
            onGroupNumber: _handleGroupStatus,
            onChangeOption: _handleChangeOption,
            onAssign: _handleAssign,
            onSwap: _handleSwap,
            onShowStatus: _handleShowStatus
        };
    };

    const _handleDeleteUser = (id) => {
        dispatchState((prev) => ({ ...prev, data: prev.data.filter((item) => item.id !== id) }));
        _setFetchListNumber();
    };

    const _handleGroupStatus = ({ id, phoneGroupId, status, phoneNumber = '', onFail = () => {} }) => {
        const _handleChangeStatus = (value) => {
            dispatchState((prev) => ({
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === id) {
                        let listGroupNumbers = [...item.group_number];
                        if (!value) {
                            listGroupNumbers = listGroupNumbers.filter((item) => item.id !== phoneGroupId);
                        } else {
                            listGroupNumbers = [...listGroupNumbers, { id: phoneGroupId, phone_number: phoneNumber }];
                        }
                        item.group_number = listGroupNumbers;
                    }
                    return item;
                })
            }));
        };

        const _handleFail = (err) => {
            onFail();
            _handleChangeStatus(!status);
            _handleShowStatus(err);
        };

        _handleChangeStatus(status);
        _setFetchListNumber();

        clientQuery(
            assignVoipNumber(id),
            {
                method: 'PUT',
                toFormData: false,
                data: { number_id: phoneGroupId, unassign: status ? 0 : 1 }
            },
            () => {},
            _handleFail
        );
    };

    const _handleChangeOption = ({ id, status, keyword, onFail = () => {} }) => {
        const _handleChangeStatus = (status) => {
            const value = status ? 1 : 0;
            dispatchState((prev) => ({
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === id) {
                        item[keyword] = value;
                    }
                    return item;
                })
            }));
        };

        _handleChangeStatus(status);

        const _handleSuccess = () => {
            if (parseInt(id) !== userId) return;
            dispatch(updateUserSettings({ voip: { ...voip, [keyword]: status ? 1 : 0 } }));
        };

        const _handleFail = (err) => {
            onFail();
            _handleChangeStatus(!status);
            _handleShowStatus(err);
        };

        clientQuery(
            settingVoipNumber(id),
            { method: 'PUT', toFormData: false, data: { keyword, value: status ? 1 : 0 } },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleAssign = ({ id, dataNumber = {}, isUnassign = false }) => {
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.id === id) {
                    if (isUnassign) {
                        item.personal_number = item.personal_number.filter((phone) => phone.id !== dataNumber.id);
                    } else {
                        item.personal_number.push(dataNumber);
                    }
                }
                return item;
            })
        }));

        _setFetchListNumber();
    };

    const _handleSwap = ({ userId, newUserId, dataOld = [] }) => {
        dispatchState((prev) => {
            let oldIndex = -1;
            let newIndex = -1;
            let flag = 0;
            const newData = prev.data;
            for (let i = 0; i < newData.length; i++) {
                if (newData[i]['id'] * 1 === userId * 1) {
                    oldIndex = i;
                    flag++;
                }
                if (newData[i]['id'] * 1 === newUserId * 1) {
                    newIndex = i;
                    flag++;
                }
                if (flag === 2) break;
            }

            const temp = newData[newIndex]['personal_number'];
            newData[oldIndex]['personal_number'] = temp;
            newData[newIndex]['personal_number'] = dataOld;

            return { ...prev, data: newData };
        });

        _setFetchListNumber();
    };

    const _handleAddUser = (data = {}) => {
        dispatchState((prev) => ({
            ...prev,
            data: [...prev.data, { ...data, personal_number: null, group_number: [], recording: 0, ...handleActions() }]
        }));
    };

    const _handleShowStatus = ({ message, isSuccess = false }) => {
        refAlert.current.showStatusBar({
            id: 'active_user',
            message,
            type: isSuccess ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
    };

    const _setFetchListNumber = () => {
        dispatch(actionTriggerLoadNumbers());
        // eslint-disable-next-line no-undef
        global.userPhoneNumbers = null;
    };

    const _handleVisibleChange = (param) => {
        const { isUnmount = false } = param || {};
        const isVisible = !document.hidden && !isUnmount;

        if (isVisible) {
            timer = setInterval(() => {
                fetchListVoipUser();
            }, 60000);
            return;
        }
        clearInterval(timer);
    };

    return (
        <div className="container-table container-user-list w-100">
            <div className="container-print">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_VOIP)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('voip_by_gorilladesk')}
                    </Link>
                </div>
                <AlertCustomer ref={refAlert} />
                <ActiveUsersProvider>
                    <ActiveUsersContext.Consumer>
                        {({ groupNumber }) => {
                            const commonProps = {
                                isLoading,
                                data: finalData,
                                account,
                                groupNumber,
                                handleAddUser: _handleAddUser,
                                handleFailed: _handleShowStatus
                            };
                            return (
                                <Fragment>
                                    <ActiveUserGridView isVoipTechnicianSeat={false} {...commonProps} />
                                    <ActiveUserGridView isVoipTechnicianSeat {...commonProps} />
                                </Fragment>
                            );
                        }}
                    </ActiveUsersContext.Consumer>
                </ActiveUsersProvider>
            </div>
        </div>
    );
};
