import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { iaVAlidUnicode } from 'common/utils/StringUtils';
import GdAvatar from 'app/components/avatar';
import { SMS_SENDING, MODULE_SMS, SMS_TYPE } from 'app/const/Sms';
import SmsAttachments from './SmsAttachments';
import { replaceContentHaveBreakDown } from '../utils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import ItemPhoneTo from 'app/modules/inbox/components/ItemPhoneTo';

function SmsItem({
    sms,
    handleOnClick,
    isShowButtonDelete = false,
    isHiddenMark = false,
    onMarkSms = () => {},
    onResend = () => {},
    onDelete = () => {}
}) {
    const { t } = useTranslation(['header']);
    const refTimer = useRef(null);
    const {
        attachments,
        date,
        status,
        type,
        id,
        is_read,
        message,
        sender = {},
        customer,
        error_message,
        receiver: finalReceiver,
        phone: finalPhone
    } = sms;
    delete finalReceiver?.key;

    const messageReplace = replaceContentHaveBreakDown(message);
    const isReceive = type === MODULE_SMS.TYPE_SMS.TO;
    const isSendFailed = status === 'failed' && type === MODULE_SMS.TYPE_SMS.GO;

    const numberAttachments = attachments?.length;

    const finalSender = isReceive ? customer : sender;
    const hasAttachment = numberAttachments ? ' has-attachment' : '';
    const sendFailure = isSendFailed ? ' box-send-fail' : '';
    const hasManyimage = numberAttachments > 1 ? ' has-manyimage' : '';
    const sendTo = type === MODULE_SMS.TYPE_SMS.TO ? 'box-message-to' : 'box-message-send';
    const isUrl = iaVAlidUnicode(finalSender.avatar, true);

    const senderFullName = finalSender?.full_name || '';
    const finalValueRead = Number(is_read) === MODULE_SMS.STATUS_LIST_CONVERSATION.READ;
    const customerTitle = customer?.title;
    const { phone, type: phoneType } = finalPhone || {};

    const [smsValueChecked, setSmsValueChecked] = useState(finalValueRead);

    useEffect(() => {
        if (finalValueRead !== smsValueChecked) {
            setSmsValueChecked(finalValueRead);
        }
    }, [is_read]);

    const handleSelectSmsInput = (e, id, isRead) => {
        e && e.stopPropagation();

        setSmsValueChecked(!smsValueChecked);
        if (refTimer.current) clearTimeout(refTimer.current);
        refTimer.current = setTimeout(() => {
            onMarkSms(id, isRead);
        }, 500);
    };

    const _resendSms = () => onResend(id);

    const _renderAvatar = () => {
        return (
            <div className="images">
                {isUrl ? (
                    <div className="avt-img tooltip">
                        <GdAvatar src={finalSender.avatar} alt={senderFullName} width={24} height={24} />
                        <p className="tooltiptext top">{senderFullName}</p>
                    </div>
                ) : (
                    <div className="avt tooltip">
                        {finalSender.avatar}
                        <p className="tooltiptext top">{senderFullName}</p>
                    </div>
                )}
            </div>
        );
    };

    const _renderNameContent = () => {
        return (
            <div className="name-content">
                {status === SMS_SENDING ? (
                    <div className="sending">
                        <span>{t('header:sending')}</span>
                        <div className="sending-load">
                            <div></div>
                        </div>
                    </div>
                ) : status === 'failed' && type === MODULE_SMS.TYPE_SMS.GO ? (
                    <div onClick={_resendSms} className="resend">
                        {' '}
                        {t('header:resend')} <span>⟳</span>
                    </div>
                ) : (
                    <span className="time">{date || 'Today, 10:13am'}</span>
                )}
                {isShowButtonDelete && isReceive ? (
                    <span className="v2-btn-default has-bg-red --small ml-1" onClick={() => onDelete(id)}>
                        {t('common:delete')}
                    </span>
                ) : null}
            </div>
        );
    };

    const _renderSmsReceive = () => {
        return (
            <Fragment>
                {_renderAvatar()}
                <div className="wrap-items">
                    <div className="message-to-detail">
                        {!numberAttachments && (
                            <div
                                className="description"
                                dangerouslySetInnerHTML={{
                                    __html: messageReplace
                                }}
                            />
                        )}

                        <SmsAttachments
                            data={attachments}
                            description={messageReplace}
                            boxAttachClass={hasManyimage}
                            onClick={handleOnClick}
                            smsType={SMS_TYPE.RECEIVE}
                        />
                        {!isHiddenMark ? (
                            <div className="check-items">
                                <input type="checkbox" checked={smsValueChecked} readOnly />
                                <div className="item-checkbox">
                                    <label
                                        onClick={(e) => handleSelectSmsInput(e, id, is_read)}
                                        htmlFor={`check-message-${id}`}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="name-content">
                        <div className="name">
                            <span className="phone-name">{customer.full_name?.trim() || customer.name?.trim()}</span>
                            <div className="flexcenter gap-2">
                                <span className="phone">{`${phoneType}, ${formatPhoneNumberVoip(phone)}`}</span>
                                {!!customerTitle ? (
                                    <div className="label-content" title={customerTitle}>
                                        {customerTitle}
                                    </div>
                                ) : null}
                                {!!finalReceiver ? <ItemPhoneTo {...finalReceiver} /> : null}
                            </div>
                        </div>
                    </div>
                    {_renderNameContent()}
                </div>
            </Fragment>
        );
    };

    const _renderSmsSend = () => {
        return (
            <Fragment>
                <div className="wrap-items">
                    {!numberAttachments && (
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: `${messageReplace} ${isSendFailed ? '<span>!</span>' : ''}`
                            }}
                        />
                    )}

                    <SmsAttachments
                        data={attachments}
                        description={messageReplace}
                        boxAttachClass={hasManyimage}
                        onClick={handleOnClick}
                        smsType={SMS_TYPE.SEND}
                    />
                    {_renderNameContent()}
                    {!!error_message && (
                        <div className="red-default text-right fs-12">
                            {t('common:error')}: {error_message}
                        </div>
                    )}
                </div>
                {_renderAvatar()}
            </Fragment>
        );
    };

    return (
        <div className={`wrapper-message ${sendTo}${sendFailure}${hasAttachment}`}>
            {isReceive ? _renderSmsReceive() : _renderSmsSend()}
        </div>
    );
}

export default SmsItem;
