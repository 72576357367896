import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { COLUMN_HEADER_DATE_STYLE, COLUMN_HEADER_STYLE } from '../../const/EventCustom';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import i18n from 'assets/i18n';

function HeaderContent({
    date = '',
    holidayData = null,
    isDayGridMonth = false,
    isListView = false,
    schedule = {},
    onClick = () => {}
}) {
    const dateObject = moment(date).locale(i18n.language).utc();

    const _handleClick = () => {
        onClick(date);
    };

    if (isListView) {
        const user = getUserInfo(schedule?.user_id) || {};
        const isToday = moment(dateObject).isSame(moment(), 'day');

        return (
            <div
                className={classNames('rows --title', { active: isToday })}
                style={{ '--color-holiday': holidayData?.color || '' }}
            >
                <div className="flexcenter">
                    <div className="title-date flexcenter gap-6">
                        <span className="date">{dateObject.format('DD')}</span>
                        <span className="col-label">{dateObject.format('MMM, ddd')}</span>
                    </div>
                    <div className="schedule-user">
                        <div className="tech-name">
                            {user?.avatar ? (
                                <div className="avt-img">
                                    <img src={user?.avatar} width="24" height="24" />
                                </div>
                            ) : (
                                <div className="avt">{schedule?.name?.substring(0, 2)}</div>
                            )}
                            <span className="txt-ellipsis">{schedule.name}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (isDayGridMonth) {
        return (
            <div style={COLUMN_HEADER_STYLE}>
                <p>{dateObject.format('ddd')}</p>
            </div>
        );
    }
    return (
        <div
            style={{ ...COLUMN_HEADER_STYLE, cursor: 'pointer' }}
            data-date={dateObject.format('YYYY-MM-DD')}
            data-header="true"
            onClick={_handleClick}
        >
            <p className="header-main-info" style={COLUMN_HEADER_DATE_STYLE}>
                {dateObject.format('D')}
            </p>
            <p>{dateObject.format('ddd')}</p>
        </div>
    );
}

export default HeaderContent;
