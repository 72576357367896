import classNames from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import GdRichText from 'app/components/richtext';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import StatusBar from 'app/components/status/statusbar';
import { DEFAULT_ALL } from 'app/const/App';
import { NOTES_TEMPLATE_TYPE, TITLE_NOTES_TEMPLATE } from 'app/const/Notes';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { ADD_TEMPLATE_NOTE, updateTemplateNote } from 'app/const/api/Notes';
import { getTitleTemplate } from 'app/modules/settings/utils';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { DUE_DATE, INITIAL_STATE_PAYMENT_TERMS, NAME, PAYMENT_FEE } from '../const';
import Footer from './Footer';
import Header from './Header';
import HeaderPaymentTerms from './HeaderPaymentTerms';
import Loading from './Loading';

const Detail = ({ onAddNew = () => {}, onUpdate = () => {} }, ref) => {
    const { id } = useParams();
    const { t } = useTranslation(['setting']);
    const [state, dispatchState] = useReducer(reducer, {
        name: '',
        content: '',
        id: null,
        type: DEFAULT_ALL,
        isLoading: false,
        isOpen: false,
        isEdit: false,
        ...INITIAL_STATE_PAYMENT_TERMS
    });

    const {
        id: finalId,
        type: finalType,
        name: finalName,
        content: finalContent,
        isLoading: finalIsLoading,
        isOpen: finalIsOpen,
        isEdit: finalIsEdit,
        [DUE_DATE]: finalDueDate,
        [PAYMENT_FEE]: finalPaymentFee
    } = state;
    const isPaymentTerms = finalType === NOTES_TEMPLATE_TYPE.PAYMENT_TERMS;

    const refStatusBar = useRef(null);
    const refHeader = useRef(null);
    const refFooter = useRef(null);
    const refRichText = useRef(null);
    const refHeaderPaymentTerms = useRef(null);

    useImperativeHandle(ref, () => ({
        _getDetail,
        _addNew: _openNewForm,
        _showStatusBar
    }));

    useEffect(() => {
        !finalIsLoading && dispatchState({ isLoading: true });
    }, [id]);

    useEffect(() => {
        if (!finalIsLoading && finalId) {
            if (isPaymentTerms && refHeaderPaymentTerms.current) {
                refHeaderPaymentTerms.current._resetInputs();
            } else if (refHeader.current) {
                refHeader.current._setInput(finalName);
                refHeader.current._autoFocus();
            }
            refRichText.current.setValue(finalContent);
        }
    }, [finalId]);

    const _getDetail = (item) => {
        if (!item) {
            dispatchState({ isLoading: false, isOpen: false });
            return;
        }
        dispatchState({ ...item, type: item.type, isLoading: false, isOpen: true, isEdit: true });
    };

    const _openNewForm = (type) => {
        dispatchState({
            name: '',
            content: '',
            id: null,
            type,
            isLoading: false,
            isOpen: true,
            isEdit: false,
            ...INITIAL_STATE_PAYMENT_TERMS
        });
        if (type === NOTES_TEMPLATE_TYPE.PAYMENT_TERMS && refHeaderPaymentTerms.current) {
            refHeaderPaymentTerms.current._clearInputs();
        } else if (refHeader.current) {
            refHeader.current._setInput('');
            refHeader.current._autoFocus();
        }
        refRichText.current.setValue('');
    };

    const _close = () => {
        dispatchState({
            isOpen: false
        });
    };

    const _changeName = (e) => {
        _setDisableSave(!!!e.target.value);
    };

    const _removeLoadingSave = () => {
        refFooter.current && refFooter.current._removeLoadingSave();
    };

    const _getMessageSuccess = () => {
        if (isPaymentTerms) return 'update_payment_terms_success';
        return finalIsEdit ? 'changes_saved_successfully' : 'created_note_success';
    };

    const _addOrUpdateNote = (content) => {
        refStatusBar.current && refStatusBar.current.clearAllStatusBar();
        const _success = ({ data }) => {
            _removeLoadingSave();
            if (finalIsEdit) {
                onUpdate(data);
            } else {
                onAddNew(data);
                _openNewForm(finalType);
            }
            _showStatusBar('success', t(_getMessageSuccess()), LIST_STATUS.SUCCESS);
        };

        const _fail = ({ message }) => {
            _removeLoadingSave();
            _showStatusBar('error', message, LIST_STATUS.ERROR);
        };

        let data = {};
        if (isPaymentTerms && refHeaderPaymentTerms.current) {
            data = refHeaderPaymentTerms.current._getValues() || {};
        } else if (refHeader.current) {
            data[NAME] = refHeader.current._getValueInput();
        }
        data = { ...data, content, type: finalType };

        const msgError = [];
        Object.entries(data).forEach(
            ([key, value]) => !value && msgError.push(t('customers:cannot_be_blank', { name: t(key) }))
        );
        if (!!msgError.length) {
            refStatusBar.current?.handeAddStatus({
                id: 'add_or_edit_template',
                message: msgError,
                type: LIST_STATUS.ERROR
            });
            _removeLoadingSave();
            return;
        }
        clientQuery(
            finalIsEdit ? updateTemplateNote(finalId) : ADD_TEMPLATE_NOTE,
            { method: finalIsEdit ? 'PUT' : 'POST', data, toFormData: false },
            _success,
            _fail
        );
    };

    const _saveNote = () => {
        let content = refRichText.current.getValue();
        content = content === '<br/>' ? '' : content;
        _addOrUpdateNote(content);
    };

    const _setDisableSave = (disable = false) => {
        refFooter.current._setDisableSave(disable);
    };

    const _checkDisableSave = (text) => {
        finalContent !== text && _setDisableSave(false);
    };

    const _showStatusBar = (id, message, status) => {
        showStatusBar({ id, message, success: status, refAlert: refStatusBar });
    };

    if (finalIsLoading) return <Loading />;

    return (
        <div className={classNames('wrap-note-template flex-column flex-1', { 'payment-term': isPaymentTerms })}>
            <StatusBar ref={refStatusBar} />
            <div className={classNames('wrap-note-template__content flex-column', { 'is-show': finalIsOpen })}>
                {isPaymentTerms ? (
                    <HeaderPaymentTerms
                        ref={refHeaderPaymentTerms}
                        termId={finalId}
                        termName={finalName}
                        dueDate={finalDueDate}
                        paymentFee={finalPaymentFee}
                        titleTemplate={<TitleTemplate isEdit={finalIsEdit} type={finalType} />}
                    />
                ) : (
                    <Header
                        ref={refHeader}
                        defaultValue={finalName}
                        onChangeName={_changeName}
                        titleTemplate={<TitleTemplate isEdit={finalIsEdit} type={finalType} />}
                    />
                )}
                <div className="template-content flex-column flex-1">
                    <GdRichText
                        ref={refRichText}
                        toolbarConfig={{
                            left: [
                                {
                                    group: 'style',
                                    children: [
                                        { type: TYPE_FUNCTION_RICHTEXT.EMOJI },
                                        { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                                        { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                                        { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                                        { type: TYPE_FUNCTION_RICHTEXT.LINK },
                                        { type: TYPE_FUNCTION_RICHTEXT.UL },
                                        { type: TYPE_FUNCTION_RICHTEXT.OL }
                                    ]
                                }
                            ]
                        }}
                        defaultContent={finalContent}
                        onChange={_checkDisableSave}
                    />
                    <Footer ref={refFooter} onClose={_close} isEdit={finalIsEdit} onSave={_saveNote} />
                </div>
            </div>
        </div>
    );
};
export default forwardRef(Detail);

export const TitleTemplate = ({ fileTranslation = 'setting', isEdit = false, title = '', type = '' }) => {
    const { t } = useTranslation(fileTranslation);
    const finalTitle = !!title ? title : getTitleTemplate({ title: TITLE_NOTES_TEMPLATE, value: type });

    return <h5 className="title --sm">{t(isEdit ? 'edit' : 'add').concat(' ', t(finalTitle))}</h5>;
};
